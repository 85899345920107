import { lazy } from 'react';
import { Route, Navigate } from 'react-router-dom';
import WithSuspenseLoadingSpinner from '../components/hoc/WithSuspenseLoadingSpinner';
import { dashboardDocumentRoutes } from './dashboard.business.documents.routes';
import PermissionGuard from '@/guards/PermissionGuard';
import { GroupNames } from '@/components/hoc/UserGroupContext';

const BusinessSettingsEditPage = lazy(
  () => import('../features/dashboard/business-settings'),
);
const MyPlan = lazy(() => import('../features/dashboard/my-plan'));

const PolicyDocumentsPage = lazy(
  () => import('../features/dashboard/business-settings/policies'),
);
const TaxRatesPage = lazy(
  () => import('../features/dashboard/business-settings/taxes'),
);
const NoteTagsPage = lazy(
  () => import('../features/dashboard/business-settings/note-tags'),
);
const DigitalDocumentCreatePage = lazy(
  () => import('../features/dashboard/business-settings/documents/create'),
);
const BankConnectionPage = lazy(
  () => import('../features/dashboard/business-settings/bank-connection'),
);
const GeneralSettingsSubscreen = lazy(
  () => import('../features/dashboard/business-settings/general-settings'),
);
const EmailTriggersSubscreen = lazy(
  () => import('../features/dashboard/business-settings/email-triggers'),
);

export const dashboardBusinessRoutes = (
  <Route
    element={<PermissionGuard permissions={[GroupNames.BusinessSettings]} />}
  >
    <Route
      path="business-settings"
      element={
        <WithSuspenseLoadingSpinner>
          <BusinessSettingsEditPage />
        </WithSuspenseLoadingSpinner>
      }
    >
      <Route index element={<Navigate to="general" replace />} />
      <Route
        path="general"
        element={
          <WithSuspenseLoadingSpinner>
            <GeneralSettingsSubscreen />
          </WithSuspenseLoadingSpinner>
        }
      />
      <Route
        path="emails"
        element={
          <WithSuspenseLoadingSpinner>
            <EmailTriggersSubscreen />
          </WithSuspenseLoadingSpinner>
        }
      />

      <Route
        path="policies"
        element={
          <WithSuspenseLoadingSpinner>
            <PolicyDocumentsPage />
          </WithSuspenseLoadingSpinner>
        }
      />
      <Route
        path="note-tags"
        element={
          <WithSuspenseLoadingSpinner>
            <NoteTagsPage />
          </WithSuspenseLoadingSpinner>
        }
      />
      <Route
        path="taxes"
        element={
          <WithSuspenseLoadingSpinner>
            <TaxRatesPage />
          </WithSuspenseLoadingSpinner>
        }
      />
      <Route
        path="bank-connection"
        element={
          <PermissionGuard
            permissions={[GroupNames.BusinessSettings]}
            ownerRequired
          >
            <WithSuspenseLoadingSpinner>
              <BankConnectionPage />
            </WithSuspenseLoadingSpinner>
          </PermissionGuard>
        }
      />
      <Route
        path="my-plan"
        element={
          <PermissionGuard
            permissions={[GroupNames.BusinessSettings]}
            ownerRequired
          >
            <WithSuspenseLoadingSpinner>
              <MyPlan />
            </WithSuspenseLoadingSpinner>
          </PermissionGuard>
        }
      />
      {/* NOT USED */}
      {/* <Route
      path="lead-capture-form"
      element={(
        <WithSuspenseLoadingSpinner>
          <LeadCaptureForm />
        </WithSuspenseLoadingSpinner>
      )}
    /> */}
      {dashboardDocumentRoutes}
      <Route
        path="documents/create"
        element={
          <WithSuspenseLoadingSpinner>
            <DigitalDocumentCreatePage />
          </WithSuspenseLoadingSpinner>
        }
      />
    </Route>
  </Route>
);
