import { lazy } from 'react';
import { Route } from 'react-router-dom';
import WithSuspenseLoadingSpinner from '../components/hoc/WithSuspenseLoadingSpinner';
import PermissionGuard from '@/guards/PermissionGuard';
import { GroupNames } from '@/components/hoc/UserGroupContext';

const MigrationsPage = lazy(() => import('../features/dashboard/migrations'));
const ImportMembersPage = lazy(
  () =>
    import(
      '../features/dashboard/migrations/import-members-page/ImportMembersPage'
    ),
);
const AssignPlansPage = lazy(
  () =>
    import(
      '../features/dashboard/migrations/assign-plans-page/AssignPlansPage'
    ),
);
const SendWelcomeEmailsPage = lazy(
  () =>
    import(
      '../features/dashboard/migrations/send-welcome-emails/SendWelcomeEmailsPage'
    ),
);
const MigratedUsersPage = lazy(
  () =>
    import('../features/dashboard/migrations/migrated-users/MigratedUsersPage'),
);

export const dashboardMigrationsRoutes = (
  <Route element={<PermissionGuard permissions={[GroupNames.Migrations]} />}>
    <Route
      path="migrations"
      element={
        <WithSuspenseLoadingSpinner>
          <MigrationsPage />
        </WithSuspenseLoadingSpinner>
      }
    >
      <Route
        index
        element={
          <WithSuspenseLoadingSpinner>
            <ImportMembersPage />
          </WithSuspenseLoadingSpinner>
        }
      />
      <Route
        path="assign-plans"
        element={
          <WithSuspenseLoadingSpinner>
            <AssignPlansPage />
          </WithSuspenseLoadingSpinner>
        }
      />
      <Route
        path="send-welcome-emails"
        element={
          <WithSuspenseLoadingSpinner>
            <SendWelcomeEmailsPage />
          </WithSuspenseLoadingSpinner>
        }
      />
      <Route
        path="migrated-users"
        element={
          <WithSuspenseLoadingSpinner>
            <MigratedUsersPage />
          </WithSuspenseLoadingSpinner>
        }
      />
    </Route>
  </Route>
);
