import { rtkQueryApi as api } from "./rtk-query-api";
export const addTagTypes = [
  "all-users",
  "appointment-packages",
  "appointment-reservations",
  "credit-packages",
  "appointment-types",
  "attendances",
  "user-memberships",
  "auth-token",
  "auth",
  "business-settings",
  "calendar",
  "classes",
  "events",
  "class-reservations",
  "class-types",
  "members",
  "connection-test",
  "countries",
  "currencies",
  "digital-document-versions",
  "digital-documents",
  "users",
  "user-document-signatures",
  "discounts",
  "email-triggers",
  "event-reservations",
  "groups",
  "notification-preferences",
  "membership-plans",
  "payments",
  "policy-documents",
  "product-categories",
  "product-images",
  "product-options",
  "products",
  "reservations",
  "reserve-event",
  "schema",
  "settings",
  "stripe-payments",
  "tax-rates",
  "timezones",
  "user-membership-attendances",
  "user-note-tags",
  "user-notes",
  "working-hours",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      allUsersList: build.query<AllUsersListApiResponse, AllUsersListApiArg>({
        query: (queryArg) => ({
          url: `/api/all-users/`,
          params: {
            at_risk: queryArg.atRisk,
            attendance__check_out: queryArg.attendanceCheckOut,
            attendance__check_out__isnull: queryArg.attendanceCheckOutIsnull,
            attendance__check_out__range: queryArg.attendanceCheckOutRange,
            birthday_month: queryArg.birthdayMonth,
            days_until_birthday: queryArg.daysUntilBirthday,
            has_active_membership: queryArg.hasActiveMembership,
            id__in: queryArg.idIn,
            migration_status: queryArg.migrationStatus,
            migration_status__in: queryArg.migrationStatusIn,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            search: queryArg.search,
          },
        }),
        providesTags: ["all-users"],
      }),
      allUsersRetrieve: build.query<
        AllUsersRetrieveApiResponse,
        AllUsersRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/api/all-users/${queryArg.id}/` }),
        providesTags: ["all-users"],
      }),
      allUsersHistoryList: build.query<
        AllUsersHistoryListApiResponse,
        AllUsersHistoryListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/all-users/${queryArg.id}/history/`,
          params: {
            no_pagination: queryArg.noPagination,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["all-users"],
      }),
      appointmentPackagesList: build.query<
        AppointmentPackagesListApiResponse,
        AppointmentPackagesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/appointment-packages/`,
          params: {
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            package_type: queryArg.packageType,
            page: queryArg.page,
            per_page: queryArg.perPage,
            search: queryArg.search,
          },
        }),
        providesTags: ["appointment-packages"],
      }),
      appointmentPackagesCreate: build.mutation<
        AppointmentPackagesCreateApiResponse,
        AppointmentPackagesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/appointment-packages/`,
          method: "POST",
          body: queryArg.appointmentPackage,
        }),
        invalidatesTags: ["appointment-packages"],
      }),
      appointmentPackagesRetrieve: build.query<
        AppointmentPackagesRetrieveApiResponse,
        AppointmentPackagesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/appointment-packages/${queryArg.id}/`,
        }),
        providesTags: ["appointment-packages"],
      }),
      appointmentPackagesUpdate: build.mutation<
        AppointmentPackagesUpdateApiResponse,
        AppointmentPackagesUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/appointment-packages/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.appointmentPackage,
        }),
        invalidatesTags: ["appointment-packages"],
      }),
      appointmentPackagesPartialUpdate: build.mutation<
        AppointmentPackagesPartialUpdateApiResponse,
        AppointmentPackagesPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/appointment-packages/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedAppointmentPackage,
        }),
        invalidatesTags: ["appointment-packages"],
      }),
      appointmentPackagesDestroy: build.mutation<
        AppointmentPackagesDestroyApiResponse,
        AppointmentPackagesDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/appointment-packages/${queryArg.id}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["appointment-packages"],
      }),
      appointmentReservationsList: build.query<
        AppointmentReservationsListApiResponse,
        AppointmentReservationsListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/appointment-reservations/`,
          params: {
            credit_package__appointment_type:
              queryArg.creditPackageAppointmentType,
            is_expired: queryArg.isExpired,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            staff: queryArg.staff,
            start_date_time: queryArg.startDateTime,
            start_date_time__range: queryArg.startDateTimeRange,
            status: queryArg.status,
            user: queryArg.user,
          },
        }),
        providesTags: ["appointment-reservations"],
      }),
      appointmentReservationsRetrieve: build.query<
        AppointmentReservationsRetrieveApiResponse,
        AppointmentReservationsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/appointment-reservations/${queryArg.id}/`,
        }),
        providesTags: ["appointment-reservations"],
      }),
      appointmentReservationsCancelReservationDestroy: build.mutation<
        AppointmentReservationsCancelReservationDestroyApiResponse,
        AppointmentReservationsCancelReservationDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/appointment-reservations/${queryArg.id}/cancel-reservation/`,
          method: "DELETE",
        }),
        invalidatesTags: ["appointment-reservations"],
      }),
      appointmentReservationsEditReservationCreate: build.mutation<
        AppointmentReservationsEditReservationCreateApiResponse,
        AppointmentReservationsEditReservationCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/appointment-reservations/${queryArg.id}/edit-reservation/`,
          method: "POST",
          body: queryArg.editReservation,
        }),
        invalidatesTags: ["appointment-reservations"],
      }),
      appointmentReservationsAvailableTimesRetrieve: build.query<
        AppointmentReservationsAvailableTimesRetrieveApiResponse,
        AppointmentReservationsAvailableTimesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/appointment-reservations/available-times/`,
          params: {
            appointment_type: queryArg.appointmentType,
            date: queryArg.date,
            staff: queryArg.staff,
          },
        }),
        providesTags: ["appointment-reservations"],
      }),
      appointmentReservationsMyReservationsList: build.query<
        AppointmentReservationsMyReservationsListApiResponse,
        AppointmentReservationsMyReservationsListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/appointment-reservations/my-reservations/`,
          params: {
            credit_package__appointment_type:
              queryArg.creditPackageAppointmentType,
            is_expired: queryArg.isExpired,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            staff: queryArg.staff,
            start_date_time: queryArg.startDateTime,
            start_date_time__range: queryArg.startDateTimeRange,
            status: queryArg.status,
            user: queryArg.user,
          },
        }),
        providesTags: ["appointment-reservations"],
      }),
      appointmentReservationsReserveAppointmentCreate: build.mutation<
        AppointmentReservationsReserveAppointmentCreateApiResponse,
        AppointmentReservationsReserveAppointmentCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/appointment-reservations/reserve-appointment/`,
          method: "POST",
          body: queryArg.reserveAppointment,
        }),
        invalidatesTags: ["appointment-reservations", "credit-packages"],
      }),
      appointmentTypesList: build.query<
        AppointmentTypesListApiResponse,
        AppointmentTypesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/appointment-types/`,
          params: {
            id__in: queryArg.idIn,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            search: queryArg.search,
          },
        }),
        providesTags: ["appointment-types"],
      }),
      appointmentTypesCreate: build.mutation<
        AppointmentTypesCreateApiResponse,
        AppointmentTypesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/appointment-types/`,
          method: "POST",
          body: queryArg.appointmentType,
        }),
        invalidatesTags: ["appointment-types"],
      }),
      appointmentTypesRetrieve: build.query<
        AppointmentTypesRetrieveApiResponse,
        AppointmentTypesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/appointment-types/${queryArg.id}/`,
        }),
        providesTags: ["appointment-types"],
      }),
      appointmentTypesUpdate: build.mutation<
        AppointmentTypesUpdateApiResponse,
        AppointmentTypesUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/appointment-types/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.appointmentType,
        }),
        invalidatesTags: ["appointment-types"],
      }),
      appointmentTypesPartialUpdate: build.mutation<
        AppointmentTypesPartialUpdateApiResponse,
        AppointmentTypesPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/appointment-types/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedAppointmentType,
        }),
        invalidatesTags: ["appointment-types"],
      }),
      appointmentTypesDestroy: build.mutation<
        AppointmentTypesDestroyApiResponse,
        AppointmentTypesDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/appointment-types/${queryArg.id}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["appointment-types"],
      }),
      appointmentTypesStaffList: build.query<
        AppointmentTypesStaffListApiResponse,
        AppointmentTypesStaffListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/appointment-types/${queryArg.id}/staff/`,
          params: {
            id__in: queryArg.idIn,
            ordering: queryArg.ordering,
            search: queryArg.search,
          },
        }),
        providesTags: ["appointment-types"],
      }),
      attendancesList: build.query<
        AttendancesListApiResponse,
        AttendancesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/attendances/`,
          params: {
            check_in: queryArg.checkIn,
            check_in__range: queryArg.checkInRange,
            check_in_date: queryArg.checkInDate,
            check_in_date_range_after: queryArg.checkInDateRangeAfter,
            check_in_date_range_before: queryArg.checkInDateRangeBefore,
            check_out: queryArg.checkOut,
            check_out__isnull: queryArg.checkOutIsnull,
            check_out__range: queryArg.checkOutRange,
            employee: queryArg.employee,
            membership_plan__in: queryArg.membershipPlanIn,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            user: queryArg.user,
          },
        }),
        providesTags: ["attendances"],
      }),
      attendancesRetrieve: build.query<
        AttendancesRetrieveApiResponse,
        AttendancesRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/api/attendances/${queryArg.id}/` }),
        providesTags: ["attendances"],
      }),
      attendancesAggregatedUserVisitsRetrieve: build.query<
        AttendancesAggregatedUserVisitsRetrieveApiResponse,
        AttendancesAggregatedUserVisitsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/attendances/aggregated-user-visits/`,
          params: {
            end_date: queryArg.endDate,
            interval: queryArg.interval,
            start_date: queryArg.startDate,
          },
        }),
        providesTags: ["attendances"],
      }),
      attendancesAggregatedUserVisitsListList: build.query<
        AttendancesAggregatedUserVisitsListListApiResponse,
        AttendancesAggregatedUserVisitsListListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/attendances/aggregated-user-visits-list/`,
          params: {
            end_date: queryArg.endDate,
            interval: queryArg.interval,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            start_date: queryArg.startDate,
          },
        }),
        providesTags: ["attendances"],
      }),
      attendancesMyAttendancesList: build.query<
        AttendancesMyAttendancesListApiResponse,
        AttendancesMyAttendancesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/attendances/my-attendances/`,
          params: {
            check_in: queryArg.checkIn,
            check_in__range: queryArg.checkInRange,
            check_in_date: queryArg.checkInDate,
            check_in_date_range_after: queryArg.checkInDateRangeAfter,
            check_in_date_range_before: queryArg.checkInDateRangeBefore,
            check_out: queryArg.checkOut,
            check_out__isnull: queryArg.checkOutIsnull,
            check_out__range: queryArg.checkOutRange,
            employee: queryArg.employee,
            membership_plan__in: queryArg.membershipPlanIn,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            user: queryArg.user,
          },
        }),
        providesTags: ["attendances"],
      }),
      attendancesPerformCheckInCreate: build.mutation<
        AttendancesPerformCheckInCreateApiResponse,
        AttendancesPerformCheckInCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/attendances/perform-check-in/`,
          method: "POST",
          body: queryArg.performCheckIn,
        }),
        invalidatesTags: ["attendances", "user-memberships"],
      }),
      attendancesPerformCheckInWithReservationCreate: build.mutation<
        AttendancesPerformCheckInWithReservationCreateApiResponse,
        AttendancesPerformCheckInWithReservationCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/attendances/perform-check-in-with-reservation/`,
          method: "POST",
          body: queryArg.performCheckInWithReservation,
        }),
        invalidatesTags: ["appointment-reservations"],
      }),
      attendancesPerformCheckOutCreate: build.mutation<
        AttendancesPerformCheckOutCreateApiResponse,
        AttendancesPerformCheckOutCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/attendances/perform-check-out/`,
          method: "POST",
          body: queryArg.performCheckOut,
        }),
        invalidatesTags: ["attendances"],
      }),
      attendancesPerformCheckOutWithReservationCreate: build.mutation<
        AttendancesPerformCheckOutWithReservationCreateApiResponse,
        AttendancesPerformCheckOutWithReservationCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/attendances/perform-check-out-with-reservation/`,
          method: "POST",
          body: queryArg.performCheckOutWithReservation,
        }),
        invalidatesTags: ["attendances"],
      }),
      authTokenCreate: build.mutation<
        AuthTokenCreateApiResponse,
        AuthTokenCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/auth-token/`,
          method: "POST",
          body: queryArg.authToken,
        }),
        invalidatesTags: ["auth-token"],
      }),
      authDeleteTenantCreate: build.mutation<
        AuthDeleteTenantCreateApiResponse,
        AuthDeleteTenantCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/auth/delete-tenant/`,
          method: "POST",
          body: queryArg.tenantDelete,
        }),
        invalidatesTags: ["auth"],
      }),
      authLoginCreate: build.mutation<
        AuthLoginCreateApiResponse,
        AuthLoginCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/auth/login/`,
          method: "POST",
          body: queryArg.tokenObtainPair,
        }),
        invalidatesTags: ["auth"],
      }),
      authPasswordChangeCreate: build.mutation<
        AuthPasswordChangeCreateApiResponse,
        AuthPasswordChangeCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/auth/password/change/`,
          method: "POST",
          body: queryArg.passwordChange,
        }),
        invalidatesTags: ["auth"],
      }),
      authPasswordResetCreate: build.mutation<
        AuthPasswordResetCreateApiResponse,
        AuthPasswordResetCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/auth/password/reset/`,
          method: "POST",
          body: queryArg.passwordReset,
        }),
        invalidatesTags: ["auth"],
      }),
      authPasswordResetConfirmCreate: build.mutation<
        AuthPasswordResetConfirmCreateApiResponse,
        AuthPasswordResetConfirmCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/auth/password/reset/confirm/`,
          method: "POST",
          body: queryArg.passwordResetConfirm,
        }),
        invalidatesTags: ["auth"],
      }),
      businessSettingsRetrieve: build.query<
        BusinessSettingsRetrieveApiResponse,
        BusinessSettingsRetrieveApiArg
      >({
        query: () => ({ url: `/api/business-settings/` }),
        providesTags: ["business-settings"],
      }),
      businessSettingsUpdate: build.mutation<
        BusinessSettingsUpdateApiResponse,
        BusinessSettingsUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/business-settings/`,
          method: "PUT",
          body: queryArg.businessSettings,
        }),
        invalidatesTags: ["business-settings"],
      }),
      businessSettingsPartialUpdate: build.mutation<
        BusinessSettingsPartialUpdateApiResponse,
        BusinessSettingsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/business-settings/`,
          method: "PATCH",
          body: queryArg.patchedBusinessSettings,
        }),
        invalidatesTags: ["business-settings"],
      }),
      businessSettingsHistoryList: build.query<
        BusinessSettingsHistoryListApiResponse,
        BusinessSettingsHistoryListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/business-settings/history/`,
          params: {
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["business-settings"],
      }),
      businessSettingsHistoryRetrieve: build.query<
        BusinessSettingsHistoryRetrieveApiResponse,
        BusinessSettingsHistoryRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/business-settings/history/${queryArg.id}/`,
        }),
        providesTags: ["business-settings"],
      }),
      calendarRetrieve: build.query<
        CalendarRetrieveApiResponse,
        CalendarRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/calendar/`,
          params: {
            end_date: queryArg.endDate,
            start_date: queryArg.startDate,
          },
        }),
        providesTags: [
          "calendar",
          "classes",
          "events",
          "appointment-reservations",
        ],
      }),
      classReservationsList: build.query<
        ClassReservationsListApiResponse,
        ClassReservationsListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/class-reservations/`,
          params: {
            class_instance: queryArg.classInstance,
            is_expired: queryArg.isExpired,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            start_date_time: queryArg.startDateTime,
            start_date_time__range: queryArg.startDateTimeRange,
            status: queryArg.status,
            user: queryArg.user,
          },
        }),
        providesTags: ["class-reservations", "classes"],
      }),
      classReservationsRetrieve: build.query<
        ClassReservationsRetrieveApiResponse,
        ClassReservationsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/class-reservations/${queryArg.id}/`,
        }),
        providesTags: ["class-reservations", "classes"],
      }),
      classReservationsCancelReservationDestroy: build.mutation<
        ClassReservationsCancelReservationDestroyApiResponse,
        ClassReservationsCancelReservationDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/class-reservations/${queryArg.id}/cancel-reservation/`,
          method: "DELETE",
        }),
        invalidatesTags: ["class-reservations", "classes"],
      }),
      classReservationsReserveClassCreate: build.mutation<
        ClassReservationsReserveClassCreateApiResponse,
        ClassReservationsReserveClassCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/class-reservations/reserve-class/`,
          method: "POST",
          body: queryArg.reserveClass,
        }),
        invalidatesTags: ["class-reservations", "classes"],
      }),
      classTypesList: build.query<
        ClassTypesListApiResponse,
        ClassTypesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/class-types/`,
          params: {
            id__in: queryArg.idIn,
            is_free_session: queryArg.isFreeSession,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            search: queryArg.search,
          },
        }),
        providesTags: ["class-types"],
      }),
      classTypesCreate: build.mutation<
        ClassTypesCreateApiResponse,
        ClassTypesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/class-types/`,
          method: "POST",
          body: queryArg.classType,
        }),
        invalidatesTags: ["class-types"],
      }),
      classTypesRetrieve: build.query<
        ClassTypesRetrieveApiResponse,
        ClassTypesRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/api/class-types/${queryArg.id}/` }),
        providesTags: ["class-types"],
      }),
      classTypesUpdate: build.mutation<
        ClassTypesUpdateApiResponse,
        ClassTypesUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/class-types/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.classType,
        }),
        invalidatesTags: ["class-types"],
      }),
      classTypesPartialUpdate: build.mutation<
        ClassTypesPartialUpdateApiResponse,
        ClassTypesPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/class-types/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedClassType,
        }),
        invalidatesTags: ["class-types"],
      }),
      classTypesDestroy: build.mutation<
        ClassTypesDestroyApiResponse,
        ClassTypesDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/class-types/${queryArg.id}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["class-types"],
      }),
      classesList: build.query<ClassesListApiResponse, ClassesListApiArg>({
        query: (queryArg) => ({
          url: `/api/classes/`,
          params: {
            class_type: queryArg.classType,
            class_type__in: queryArg.classTypeIn,
            coach: queryArg.coach,
            coach__in: queryArg.coachIn,
            date: queryArg.date,
            date__range: queryArg.dateRange,
            is_expired: queryArg.isExpired,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            search: queryArg.search,
          },
        }),
        providesTags: ["classes"],
      }),
      classesRetrieve: build.query<
        ClassesRetrieveApiResponse,
        ClassesRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/api/classes/${queryArg.id}/` }),
        providesTags: ["classes"],
      }),
      classesUpdate: build.mutation<
        ClassesUpdateApiResponse,
        ClassesUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/classes/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.class,
        }),
        invalidatesTags: ["classes"],
      }),
      classesPartialUpdate: build.mutation<
        ClassesPartialUpdateApiResponse,
        ClassesPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/classes/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedClass,
        }),
        invalidatesTags: ["classes"],
      }),
      classesDestroy: build.mutation<
        ClassesDestroyApiResponse,
        ClassesDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/classes/${queryArg.id}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["classes"],
      }),
      classesAttendeesList: build.query<
        ClassesAttendeesListApiResponse,
        ClassesAttendeesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/classes/${queryArg.id}/attendees/`,
          params: {
            class_type: queryArg.classType,
            class_type__in: queryArg.classTypeIn,
            coach: queryArg.coach,
            coach__in: queryArg.coachIn,
            date: queryArg.date,
            date__range: queryArg.dateRange,
            is_expired: queryArg.isExpired,
            ordering: queryArg.ordering,
            search: queryArg.search,
          },
        }),
        providesTags: ["classes"],
      }),
      classesCreateClassesCreate: build.mutation<
        ClassesCreateClassesCreateApiResponse,
        ClassesCreateClassesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/classes/create-classes/`,
          method: "POST",
          body: queryArg.createClass,
          params: {
            ordering: queryArg.ordering,
          },
        }),
        invalidatesTags: ["classes"],
      }),
      communicationHistoryList: build.query<
        CommunicationHistoryListApiResponse,
        CommunicationHistoryListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/communication-history/`,
          params: {
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            user: queryArg.user,
          },
        }),
        providesTags: ["members"],
      }),
      communicationHistoryRetrieve: build.query<
        CommunicationHistoryRetrieveApiResponse,
        CommunicationHistoryRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/communication-history/${queryArg.id}/`,
        }),
        providesTags: ["members"],
      }),
      communicationHistoryMarkAsReadCreate: build.mutation<
        CommunicationHistoryMarkAsReadCreateApiResponse,
        CommunicationHistoryMarkAsReadCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/communication-history/${queryArg.id}/mark-as-read/`,
          method: "POST",
          body: queryArg.communicationHistory,
        }),
        invalidatesTags: ["members"],
      }),
      communicationHistoryMyCommunicationHistoryList: build.query<
        CommunicationHistoryMyCommunicationHistoryListApiResponse,
        CommunicationHistoryMyCommunicationHistoryListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/communication-history/my-communication-history/`,
          params: {
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            user: queryArg.user,
          },
        }),
        providesTags: ["members"],
      }),
      connectionTestRetrieve: build.query<
        ConnectionTestRetrieveApiResponse,
        ConnectionTestRetrieveApiArg
      >({
        query: () => ({ url: `/api/connection-test/` }),
        providesTags: ["connection-test"],
      }),
      countriesRetrieve: build.query<
        CountriesRetrieveApiResponse,
        CountriesRetrieveApiArg
      >({
        query: () => ({ url: `/api/countries/` }),
        providesTags: ["countries"],
      }),
      creditPackagesList: build.query<
        CreditPackagesListApiResponse,
        CreditPackagesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/credit-packages/`,
          params: {
            appointment_type: queryArg.appointmentType,
            is_active: queryArg.isActive,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            user: queryArg.user,
          },
        }),
        providesTags: ["credit-packages"],
      }),
      creditPackagesRetrieve: build.query<
        CreditPackagesRetrieveApiResponse,
        CreditPackagesRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/api/credit-packages/${queryArg.id}/` }),
        providesTags: ["credit-packages"],
      }),
      creditPackagesAddByAppointmentPackageCardCreate: build.mutation<
        CreditPackagesAddByAppointmentPackageCardCreateApiResponse,
        CreditPackagesAddByAppointmentPackageCardCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/credit-packages/add-by-appointment-package-card/`,
          method: "POST",
          body: queryArg.addCreditsByAppointmentPackage,
        }),
        invalidatesTags: ["credit-packages"],
      }),
      creditPackagesAddByAppointmentPackageCashCreate: build.mutation<
        CreditPackagesAddByAppointmentPackageCashCreateApiResponse,
        CreditPackagesAddByAppointmentPackageCashCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/credit-packages/add-by-appointment-package-cash/`,
          method: "POST",
          body: queryArg.addCreditsByAppointmentPackage,
        }),
        invalidatesTags: ["credit-packages"],
      }),
      creditPackagesAddCardCreate: build.mutation<
        CreditPackagesAddCardCreateApiResponse,
        CreditPackagesAddCardCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/credit-packages/add-card/`,
          method: "POST",
          body: queryArg.addCredits,
        }),
        invalidatesTags: ["credit-packages"],
      }),
      creditPackagesAddCashCreate: build.mutation<
        CreditPackagesAddCashCreateApiResponse,
        CreditPackagesAddCashCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/credit-packages/add-cash/`,
          method: "POST",
          body: queryArg.addCredits,
        }),
        invalidatesTags: ["credit-packages"],
      }),
      creditPackagesBuyByAppointmentPackageCreate: build.mutation<
        CreditPackagesBuyByAppointmentPackageCreateApiResponse,
        CreditPackagesBuyByAppointmentPackageCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/credit-packages/buy-by-appointment-package/`,
          method: "POST",
          body: queryArg.buyCreditsByAppointmentPackage,
        }),
        invalidatesTags: ["credit-packages"],
      }),
      creditPackagesMyCreditPackagesList: build.query<
        CreditPackagesMyCreditPackagesListApiResponse,
        CreditPackagesMyCreditPackagesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/credit-packages/my-credit-packages/`,
          params: {
            appointment_type: queryArg.appointmentType,
            is_active: queryArg.isActive,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            user: queryArg.user,
          },
        }),
        providesTags: ["credit-packages"],
      }),
      creditPackagesRemainingCreditsRetrieve: build.query<
        CreditPackagesRemainingCreditsRetrieveApiResponse,
        CreditPackagesRemainingCreditsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/credit-packages/remaining-credits/`,
          params: {
            appointment_type: queryArg.appointmentType,
            user: queryArg.user,
          },
        }),
        providesTags: ["credit-packages"],
      }),
      currenciesRetrieve: build.query<
        CurrenciesRetrieveApiResponse,
        CurrenciesRetrieveApiArg
      >({
        query: () => ({ url: `/api/currencies/` }),
        providesTags: ["currencies"],
      }),
      digitalDocumentVersionsList: build.query<
        DigitalDocumentVersionsListApiResponse,
        DigitalDocumentVersionsListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/digital-document-versions/`,
          params: {
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["digital-document-versions"],
      }),
      digitalDocumentVersionsRetrieve: build.query<
        DigitalDocumentVersionsRetrieveApiResponse,
        DigitalDocumentVersionsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/digital-document-versions/${queryArg.id}/`,
        }),
        providesTags: ["digital-document-versions"],
      }),
      digitalDocumentsList: build.query<
        DigitalDocumentsListApiResponse,
        DigitalDocumentsListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/digital-documents/`,
          params: {
            attach_to: queryArg.attachTo,
            is_active: queryArg.isActive,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            search: queryArg.search,
            title: queryArg.title,
          },
        }),
        providesTags: ["digital-documents"],
      }),
      digitalDocumentsCreate: build.mutation<
        DigitalDocumentsCreateApiResponse,
        DigitalDocumentsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/digital-documents/`,
          method: "POST",
          body: queryArg.digitalDocument,
        }),
        invalidatesTags: ["digital-documents"],
      }),
      digitalDocumentsRetrieve: build.query<
        DigitalDocumentsRetrieveApiResponse,
        DigitalDocumentsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/digital-documents/${queryArg.id}/`,
        }),
        providesTags: ["digital-documents"],
      }),
      digitalDocumentsUpdate: build.mutation<
        DigitalDocumentsUpdateApiResponse,
        DigitalDocumentsUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/digital-documents/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.digitalDocument,
        }),
        invalidatesTags: ["digital-documents"],
      }),
      digitalDocumentsPartialUpdate: build.mutation<
        DigitalDocumentsPartialUpdateApiResponse,
        DigitalDocumentsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/digital-documents/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedDigitalDocument,
        }),
        invalidatesTags: ["digital-documents"],
      }),
      digitalDocumentsDestroy: build.mutation<
        DigitalDocumentsDestroyApiResponse,
        DigitalDocumentsDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/digital-documents/${queryArg.id}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["digital-documents"],
      }),
      digitalDocumentsHistoryList: build.query<
        DigitalDocumentsHistoryListApiResponse,
        DigitalDocumentsHistoryListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/digital-documents/${queryArg.id}/history/`,
          params: {
            no_pagination: queryArg.noPagination,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["digital-documents"],
      }),
      digitalDocumentsPublishPartialUpdate: build.mutation<
        DigitalDocumentsPublishPartialUpdateApiResponse,
        DigitalDocumentsPublishPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/digital-documents/${queryArg.id}/publish/`,
          method: "PATCH",
          body: queryArg.patchedDigitalDocument,
        }),
        invalidatesTags: ["digital-documents"],
      }),
      digitalDocumentsRequiredSignaturesList: build.query<
        DigitalDocumentsRequiredSignaturesListApiResponse,
        DigitalDocumentsRequiredSignaturesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/digital-documents/${queryArg.id}/required-signatures/`,
          params: {
            no_pagination: queryArg.noPagination,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: [
          "digital-documents",
          "users",
          "user-document-signatures",
        ],
      }),
      digitalDocumentsAllowedVariablesRetrieve: build.query<
        DigitalDocumentsAllowedVariablesRetrieveApiResponse,
        DigitalDocumentsAllowedVariablesRetrieveApiArg
      >({
        query: () => ({ url: `/api/digital-documents/allowed-variables/` }),
        providesTags: ["digital-documents"],
      }),
      digitalDocumentsRequiredRetrieve: build.query<
        DigitalDocumentsRequiredRetrieveApiResponse,
        DigitalDocumentsRequiredRetrieveApiArg
      >({
        query: () => ({ url: `/api/digital-documents/required/` }),
        providesTags: ["digital-documents"],
      }),
      digitalDocumentsSignCreate: build.mutation<
        DigitalDocumentsSignCreateApiResponse,
        DigitalDocumentsSignCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/digital-documents/sign/`,
          method: "POST",
          body: queryArg.signDocument,
        }),
        invalidatesTags: ["digital-documents"],
      }),
      discountsList: build.query<DiscountsListApiResponse, DiscountsListApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/discounts/`,
            params: {
              applicable_to_appointments: queryArg.applicableToAppointments,
              applicable_to_memberships: queryArg.applicableToMemberships,
              applicable_to_products: queryArg.applicableToProducts,
              is_active: queryArg.isActive,
              name: queryArg.name,
              no_pagination: queryArg.noPagination,
              ordering: queryArg.ordering,
              page: queryArg.page,
              per_page: queryArg.perPage,
              search: queryArg.search,
            },
          }),
          providesTags: ["discounts"],
        },
      ),
      discountsCreate: build.mutation<
        DiscountsCreateApiResponse,
        DiscountsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/discounts/`,
          method: "POST",
          body: queryArg.discount,
        }),
        invalidatesTags: ["discounts"],
      }),
      discountsRetrieve: build.query<
        DiscountsRetrieveApiResponse,
        DiscountsRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/api/discounts/${queryArg.id}/` }),
        providesTags: ["discounts"],
      }),
      discountsUpdate: build.mutation<
        DiscountsUpdateApiResponse,
        DiscountsUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/discounts/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.discount,
        }),
        invalidatesTags: ["discounts"],
      }),
      discountsPartialUpdate: build.mutation<
        DiscountsPartialUpdateApiResponse,
        DiscountsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/discounts/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedDiscount,
        }),
        invalidatesTags: ["discounts"],
      }),
      discountsDestroy: build.mutation<
        DiscountsDestroyApiResponse,
        DiscountsDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/discounts/${queryArg.id}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["discounts"],
      }),
      discountsHistoryList: build.query<
        DiscountsHistoryListApiResponse,
        DiscountsHistoryListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/discounts/${queryArg.id}/history/`,
          params: {
            no_pagination: queryArg.noPagination,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["discounts"],
      }),
      emailTriggersList: build.query<
        EmailTriggersListApiResponse,
        EmailTriggersListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/email-triggers/`,
          params: {
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            search: queryArg.search,
          },
        }),
        providesTags: ["email-triggers"],
      }),
      emailTriggersRetrieve: build.query<
        EmailTriggersRetrieveApiResponse,
        EmailTriggersRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/api/email-triggers/${queryArg.id}/` }),
        providesTags: ["email-triggers"],
      }),
      emailTriggersUpdate: build.mutation<
        EmailTriggersUpdateApiResponse,
        EmailTriggersUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/email-triggers/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.emailTrigger,
        }),
        invalidatesTags: ["email-triggers"],
      }),
      emailTriggersPartialUpdate: build.mutation<
        EmailTriggersPartialUpdateApiResponse,
        EmailTriggersPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/email-triggers/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedEmailTrigger,
        }),
        invalidatesTags: ["email-triggers"],
      }),
      eventReservationsList: build.query<
        EventReservationsListApiResponse,
        EventReservationsListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/event-reservations/`,
          params: {
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["event-reservations"],
      }),
      eventReservationsRetrieve: build.query<
        EventReservationsRetrieveApiResponse,
        EventReservationsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/event-reservations/${queryArg.id}/`,
        }),
        providesTags: ["event-reservations"],
      }),
      eventReservationsCancelReservationDestroy: build.mutation<
        EventReservationsCancelReservationDestroyApiResponse,
        EventReservationsCancelReservationDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/event-reservations/${queryArg.id}/cancel-reservation/`,
          method: "DELETE",
        }),
        invalidatesTags: ["event-reservations"],
      }),
      eventReservationsMyReservationsList: build.query<
        EventReservationsMyReservationsListApiResponse,
        EventReservationsMyReservationsListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/event-reservations/my-reservations/`,
          params: {
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["event-reservations"],
      }),
      eventsList: build.query<EventsListApiResponse, EventsListApiArg>({
        query: (queryArg) => ({
          url: `/api/events/`,
          params: {
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            search: queryArg.search,
            staff: queryArg.staff,
            staff__in: queryArg.staffIn,
            start_date: queryArg.startDate,
            start_date__range: queryArg.startDateRange,
          },
        }),
        providesTags: ["events"],
      }),
      eventsCreate: build.mutation<EventsCreateApiResponse, EventsCreateApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/events/`,
            method: "POST",
            body: queryArg.event,
          }),
          invalidatesTags: ["events"],
        },
      ),
      eventsRetrieve: build.query<
        EventsRetrieveApiResponse,
        EventsRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/api/events/${queryArg.id}/` }),
        providesTags: ["events"],
      }),
      eventsUpdate: build.mutation<EventsUpdateApiResponse, EventsUpdateApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/events/${queryArg.id}/`,
            method: "PUT",
            body: queryArg.event,
          }),
          invalidatesTags: ["events"],
        },
      ),
      eventsPartialUpdate: build.mutation<
        EventsPartialUpdateApiResponse,
        EventsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/events/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedEvent,
        }),
        invalidatesTags: ["events"],
      }),
      eventsDestroy: build.mutation<
        EventsDestroyApiResponse,
        EventsDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/events/${queryArg.id}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["events"],
      }),
      eventsAttendeesList: build.query<
        EventsAttendeesListApiResponse,
        EventsAttendeesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/events/${queryArg.id}/attendees/`,
          params: {
            ordering: queryArg.ordering,
            search: queryArg.search,
            staff: queryArg.staff,
            staff__in: queryArg.staffIn,
            start_date: queryArg.startDate,
            start_date__range: queryArg.startDateRange,
          },
        }),
        providesTags: ["events"],
      }),
      groupsList: build.query<GroupsListApiResponse, GroupsListApiArg>({
        query: (queryArg) => ({
          url: `/api/groups/`,
          params: {
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            search: queryArg.search,
          },
        }),
        providesTags: ["groups"],
      }),
      groupsRetrieve: build.query<
        GroupsRetrieveApiResponse,
        GroupsRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/api/groups/${queryArg.id}/` }),
        providesTags: ["groups"],
      }),
      membersList: build.query<MembersListApiResponse, MembersListApiArg>({
        query: (queryArg) => ({
          url: `/api/members/`,
          params: {
            at_risk: queryArg.atRisk,
            attendance__check_out: queryArg.attendanceCheckOut,
            attendance__check_out__isnull: queryArg.attendanceCheckOutIsnull,
            attendance__check_out__range: queryArg.attendanceCheckOutRange,
            birthday_month: queryArg.birthdayMonth,
            days_until_birthday: queryArg.daysUntilBirthday,
            has_active_membership: queryArg.hasActiveMembership,
            id__in: queryArg.idIn,
            migration_status: queryArg.migrationStatus,
            migration_status__in: queryArg.migrationStatusIn,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            search: queryArg.search,
          },
        }),
        providesTags: ["members"],
      }),
      membersCreate: build.mutation<
        MembersCreateApiResponse,
        MembersCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/members/`,
          method: "POST",
          body: queryArg.user,
        }),
        invalidatesTags: ["members"],
      }),
      membersRetrieve: build.query<
        MembersRetrieveApiResponse,
        MembersRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/api/members/${queryArg.id}/` }),
        providesTags: ["members"],
      }),
      membersUpdate: build.mutation<
        MembersUpdateApiResponse,
        MembersUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/members/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.user,
        }),
        invalidatesTags: ["members"],
      }),
      membersPartialUpdate: build.mutation<
        MembersPartialUpdateApiResponse,
        MembersPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/members/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedUser,
        }),
        invalidatesTags: ["members"],
      }),
      membersDestroy: build.mutation<
        MembersDestroyApiResponse,
        MembersDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/members/${queryArg.id}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["members"],
      }),
      membersDocumentsRequiredList: build.query<
        MembersDocumentsRequiredListApiResponse,
        MembersDocumentsRequiredListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/members/${queryArg.id}/documents-required/`,
          params: {
            no_pagination: queryArg.noPagination,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["digital-documents"],
      }),
      membersHistoryList: build.query<
        MembersHistoryListApiResponse,
        MembersHistoryListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/members/${queryArg.id}/history/`,
          params: {
            no_pagination: queryArg.noPagination,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["members"],
      }),
      membersAggregatedMembersCountsRetrieve: build.query<
        MembersAggregatedMembersCountsRetrieveApiResponse,
        MembersAggregatedMembersCountsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/members/aggregated-members-counts/`,
          params: {
            end_date: queryArg.endDate,
            interval: queryArg.interval,
            start_date: queryArg.startDate,
          },
        }),
        providesTags: ["members"],
      }),
      membersAggregatedMembersCountsListList: build.query<
        MembersAggregatedMembersCountsListListApiResponse,
        MembersAggregatedMembersCountsListListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/members/aggregated-members-counts-list/`,
          params: {
            end_date: queryArg.endDate,
            interval: queryArg.interval,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            start_date: queryArg.startDate,
          },
        }),
        providesTags: ["members"],
      }),
      membersAssignMembershipCreate: build.mutation<
        MembersAssignMembershipCreateApiResponse,
        MembersAssignMembershipCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/members/assign-membership/`,
          method: "POST",
          body: queryArg.assignMembership,
        }),
        invalidatesTags: ["members"],
      }),
      membersDownloadCsvRetrieve: build.query<
        MembersDownloadCsvRetrieveApiResponse,
        MembersDownloadCsvRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/members/download-csv/`,
          params: {
            at_risk: queryArg.atRisk,
            attendance__check_out: queryArg.attendanceCheckOut,
            attendance__check_out__isnull: queryArg.attendanceCheckOutIsnull,
            attendance__check_out__range: queryArg.attendanceCheckOutRange,
            birthday_month: queryArg.birthdayMonth,
            days_until_birthday: queryArg.daysUntilBirthday,
            has_active_membership: queryArg.hasActiveMembership,
            id__in: queryArg.idIn,
            migration_status: queryArg.migrationStatus,
            migration_status__in: queryArg.migrationStatusIn,
            ordering: queryArg.ordering,
            search: queryArg.search,
          },
        }),
        providesTags: ["members"],
      }),
      membersDownloadTemplateRetrieve: build.query<
        MembersDownloadTemplateRetrieveApiResponse,
        MembersDownloadTemplateRetrieveApiArg
      >({
        query: () => ({ url: `/api/members/download-template/` }),
        providesTags: ["members"],
      }),
      membersImportUsersCreate: build.mutation<
        MembersImportUsersCreateApiResponse,
        MembersImportUsersCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/members/import-users/`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["members"],
      }),
      membersMyNotificationPreferencesRetrieve: build.query<
        MembersMyNotificationPreferencesRetrieveApiResponse,
        MembersMyNotificationPreferencesRetrieveApiArg
      >({
        query: () => ({ url: `/api/members/my-notification-preferences/` }),
        providesTags: ["notification-preferences"],
      }),
      membersMyNotificationPreferencesPartialUpdate: build.mutation<
        MembersMyNotificationPreferencesPartialUpdateApiResponse,
        MembersMyNotificationPreferencesPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/members/my-notification-preferences/`,
          method: "PATCH",
          body: queryArg.patchedNotificationPreference,
        }),
        invalidatesTags: ["notification-preferences"],
      }),
      membersReferralSourcesPercentageRetrieve: build.query<
        MembersReferralSourcesPercentageRetrieveApiResponse,
        MembersReferralSourcesPercentageRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/members/referral-sources-percentage/`,
          params: {
            end_date: queryArg.endDate,
            start_date: queryArg.startDate,
          },
        }),
        providesTags: ["members"],
      }),
      membersSendEmailToAllCreate: build.mutation<
        MembersSendEmailToAllCreateApiResponse,
        MembersSendEmailToAllCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/members/send-email-to-all/`,
          method: "POST",
          body: queryArg.emailBroadcast,
          params: {
            at_risk: queryArg.atRisk,
            attendance__check_out: queryArg.attendanceCheckOut,
            attendance__check_out__isnull: queryArg.attendanceCheckOutIsnull,
            attendance__check_out__range: queryArg.attendanceCheckOutRange,
            birthday_month: queryArg.birthdayMonth,
            days_until_birthday: queryArg.daysUntilBirthday,
            has_active_membership: queryArg.hasActiveMembership,
            id__in: queryArg.idIn,
            migration_status: queryArg.migrationStatus,
            migration_status__in: queryArg.migrationStatusIn,
            ordering: queryArg.ordering,
            search: queryArg.search,
          },
        }),
        invalidatesTags: ["members"],
      }),
      membersSendNotificationCreate: build.mutation<
        MembersSendNotificationCreateApiResponse,
        MembersSendNotificationCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/members/send-notification/`,
          method: "POST",
          body: queryArg.notification,
        }),
        invalidatesTags: ["members"],
      }),
      membersSendWelcomeEmailCreate: build.mutation<
        MembersSendWelcomeEmailCreateApiResponse,
        MembersSendWelcomeEmailCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/members/send-welcome-email/`,
          method: "POST",
          body: queryArg.sendWelcomeEmail,
        }),
        invalidatesTags: ["members"],
      }),
      membershipPlansList: build.query<
        MembershipPlansListApiResponse,
        MembershipPlansListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/membership-plans/`,
          params: {
            id__in: queryArg.idIn,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            plan_type: queryArg.planType,
            search: queryArg.search,
            show_on_member_app: queryArg.showOnMemberApp,
          },
        }),
        providesTags: ["membership-plans"],
      }),
      membershipPlansCreate: build.mutation<
        MembershipPlansCreateApiResponse,
        MembershipPlansCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/membership-plans/`,
          method: "POST",
          body: queryArg.membershipPlan,
        }),
        invalidatesTags: ["membership-plans"],
      }),
      membershipPlansRetrieve: build.query<
        MembershipPlansRetrieveApiResponse,
        MembershipPlansRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/api/membership-plans/${queryArg.id}/` }),
        providesTags: ["membership-plans"],
      }),
      membershipPlansUpdate: build.mutation<
        MembershipPlansUpdateApiResponse,
        MembershipPlansUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/membership-plans/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.membershipPlan,
        }),
        invalidatesTags: ["membership-plans"],
      }),
      membershipPlansPartialUpdate: build.mutation<
        MembershipPlansPartialUpdateApiResponse,
        MembershipPlansPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/membership-plans/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedMembershipPlan,
        }),
        invalidatesTags: ["membership-plans"],
      }),
      membershipPlansDestroy: build.mutation<
        MembershipPlansDestroyApiResponse,
        MembershipPlansDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/membership-plans/${queryArg.id}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["membership-plans"],
      }),
      membershipPlansArchiveRetrieve: build.query<
        MembershipPlansArchiveRetrieveApiResponse,
        MembershipPlansArchiveRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/membership-plans/${queryArg.id}/archive/`,
        }),
        providesTags: ["membership-plans"],
      }),
      membershipPlansDownloadMembersCsvRetrieve: build.query<
        MembershipPlansDownloadMembersCsvRetrieveApiResponse,
        MembershipPlansDownloadMembersCsvRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/membership-plans/${queryArg.id}/download-members-csv/`,
        }),
        providesTags: ["membership-plans"],
      }),
      membershipPlansHistoryList: build.query<
        MembershipPlansHistoryListApiResponse,
        MembershipPlansHistoryListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/membership-plans/${queryArg.id}/history/`,
          params: {
            no_pagination: queryArg.noPagination,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["membership-plans"],
      }),
      membershipPlansSendEmailToAllCreate: build.mutation<
        MembershipPlansSendEmailToAllCreateApiResponse,
        MembershipPlansSendEmailToAllCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/membership-plans/${queryArg.id}/send-email-to-all/`,
          method: "POST",
          body: queryArg.emailBroadcast,
        }),
        invalidatesTags: ["membership-plans"],
      }),
      membershipPlansUnarchiveRetrieve: build.query<
        MembershipPlansUnarchiveRetrieveApiResponse,
        MembershipPlansUnarchiveRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/membership-plans/${queryArg.id}/unarchive/`,
        }),
        providesTags: ["membership-plans"],
      }),
      membershipPlansArchivedList: build.query<
        MembershipPlansArchivedListApiResponse,
        MembershipPlansArchivedListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/membership-plans/archived/`,
          params: {
            id__in: queryArg.idIn,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            plan_type: queryArg.planType,
            search: queryArg.search,
            show_on_member_app: queryArg.showOnMemberApp,
          },
        }),
        providesTags: ["membership-plans"],
      }),
      paymentsAddressRetrieve: build.query<
        PaymentsAddressRetrieveApiResponse,
        PaymentsAddressRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/api/payments/address/${queryArg.id}/` }),
        providesTags: ["payments"],
      }),
      paymentsAggregatedRevenueRetrieve: build.query<
        PaymentsAggregatedRevenueRetrieveApiResponse,
        PaymentsAggregatedRevenueRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/payments/aggregated-revenue/`,
          params: {
            end_date: queryArg.endDate,
            interval: queryArg.interval,
            start_date: queryArg.startDate,
          },
        }),
        providesTags: ["payments"],
      }),
      paymentsAggregatedRevenueListList: build.query<
        PaymentsAggregatedRevenueListListApiResponse,
        PaymentsAggregatedRevenueListListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/payments/aggregated-revenue-list/`,
          params: {
            end_date: queryArg.endDate,
            interval: queryArg.interval,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            start_date: queryArg.startDate,
          },
        }),
        providesTags: ["payments"],
      }),
      paymentsInvoiceItemTaxesList: build.query<
        PaymentsInvoiceItemTaxesListApiResponse,
        PaymentsInvoiceItemTaxesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/payments/invoice-item-taxes/`,
          params: {
            id: queryArg.id,
            id__in: queryArg.idIn,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["payments"],
      }),
      paymentsInvoiceItemTaxesRetrieve: build.query<
        PaymentsInvoiceItemTaxesRetrieveApiResponse,
        PaymentsInvoiceItemTaxesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/payments/invoice-item-taxes/${queryArg.id}/`,
        }),
        providesTags: ["payments"],
      }),
      paymentsInvoiceItemsList: build.query<
        PaymentsInvoiceItemsListApiResponse,
        PaymentsInvoiceItemsListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/payments/invoice-items/`,
          params: {
            invoice: queryArg.invoice,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["payments"],
      }),
      paymentsInvoiceItemsCreate: build.mutation<
        PaymentsInvoiceItemsCreateApiResponse,
        PaymentsInvoiceItemsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/payments/invoice-items/`,
          method: "POST",
          body: queryArg.invoiceItemPolymorphic,
        }),
        invalidatesTags: ["payments"],
      }),
      paymentsInvoiceItemsRetrieve: build.query<
        PaymentsInvoiceItemsRetrieveApiResponse,
        PaymentsInvoiceItemsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/payments/invoice-items/${queryArg.id}/`,
        }),
        providesTags: ["payments"],
      }),
      paymentsInvoiceItemsDestroy: build.mutation<
        PaymentsInvoiceItemsDestroyApiResponse,
        PaymentsInvoiceItemsDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/payments/invoice-items/${queryArg.id}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["payments"],
      }),
      paymentsInvoicesList: build.query<
        PaymentsInvoicesListApiResponse,
        PaymentsInvoicesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/payments/invoices/`,
          params: {
            appointment_discount: queryArg.appointmentDiscount,
            customer: queryArg.customer,
            discount: queryArg.discount,
            is_forgiven: queryArg.isForgiven,
            is_open: queryArg.isOpen,
            is_paid: queryArg.isPaid,
            membership_discount: queryArg.membershipDiscount,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            product_discount: queryArg.productDiscount,
            search: queryArg.search,
          },
        }),
        providesTags: ["payments"],
      }),
      paymentsInvoicesCreate: build.mutation<
        PaymentsInvoicesCreateApiResponse,
        PaymentsInvoicesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/payments/invoices/`,
          method: "POST",
          body: queryArg.invoice,
        }),
        invalidatesTags: ["payments"],
      }),
      paymentsInvoicesRetrieve: build.query<
        PaymentsInvoicesRetrieveApiResponse,
        PaymentsInvoicesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/payments/invoices/${queryArg.id}/`,
        }),
        providesTags: ["payments"],
      }),
      paymentsInvoicesDestroy: build.mutation<
        PaymentsInvoicesDestroyApiResponse,
        PaymentsInvoicesDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/payments/invoices/${queryArg.id}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["payments"],
      }),
      paymentsInvoicesForgiveInvoicePartialUpdate: build.mutation<
        PaymentsInvoicesForgiveInvoicePartialUpdateApiResponse,
        PaymentsInvoicesForgiveInvoicePartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/payments/invoices/${queryArg.id}/forgive-invoice/`,
          method: "PATCH",
          body: queryArg.patchedInvoice,
        }),
        invalidatesTags: ["payments"],
      }),
      paymentsInvoicesGeneratePdfRetrieve: build.query<
        PaymentsInvoicesGeneratePdfRetrieveApiResponse,
        PaymentsInvoicesGeneratePdfRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/payments/invoices/${queryArg.id}/generate-pdf/`,
        }),
        providesTags: ["payments"],
      }),
      paymentsInvoicesHistoryList: build.query<
        PaymentsInvoicesHistoryListApiResponse,
        PaymentsInvoicesHistoryListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/payments/invoices/${queryArg.id}/history/`,
          params: {
            no_pagination: queryArg.noPagination,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["payments"],
      }),
      paymentsInvoicesPayCreate: build.mutation<
        PaymentsInvoicesPayCreateApiResponse,
        PaymentsInvoicesPayCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/payments/invoices/${queryArg.id}/pay/`,
          method: "POST",
          body: queryArg.payment,
        }),
        invalidatesTags: ["payments"],
      }),
      paymentsInvoicesRefundCreate: build.mutation<
        PaymentsInvoicesRefundCreateApiResponse,
        PaymentsInvoicesRefundCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/payments/invoices/${queryArg.id}/refund/`,
          method: "POST",
          body: queryArg.payment,
        }),
        invalidatesTags: ["payments"],
      }),
      paymentsInvoicesRefundCardCreate: build.mutation<
        PaymentsInvoicesRefundCardCreateApiResponse,
        PaymentsInvoicesRefundCardCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/payments/invoices/${queryArg.id}/refund-card/`,
          method: "POST",
          body: queryArg.invoice,
        }),
        invalidatesTags: ["payments"],
      }),
      paymentsInvoicesUpdateShippingAddressPartialUpdate: build.mutation<
        PaymentsInvoicesUpdateShippingAddressPartialUpdateApiResponse,
        PaymentsInvoicesUpdateShippingAddressPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/payments/invoices/${queryArg.id}/update-shipping-address/`,
          method: "PATCH",
          body: queryArg.patchedAddress,
        }),
        invalidatesTags: ["payments"],
      }),
      paymentsInvoicesMyInvoicesList: build.query<
        PaymentsInvoicesMyInvoicesListApiResponse,
        PaymentsInvoicesMyInvoicesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/payments/invoices/my-invoices/`,
          params: {
            appointment_discount: queryArg.appointmentDiscount,
            customer: queryArg.customer,
            discount: queryArg.discount,
            is_forgiven: queryArg.isForgiven,
            is_open: queryArg.isOpen,
            is_paid: queryArg.isPaid,
            membership_discount: queryArg.membershipDiscount,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            product_discount: queryArg.productDiscount,
            search: queryArg.search,
          },
        }),
        providesTags: ["payments"],
      }),
      paymentsProductInvoiceItemsList: build.query<
        PaymentsProductInvoiceItemsListApiResponse,
        PaymentsProductInvoiceItemsListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/payments/product-invoice-items/`,
          params: {
            invoice: queryArg.invoice,
            invoice__is_paid: queryArg.invoiceIsPaid,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            product_option__product: queryArg.productOptionProduct,
          },
        }),
        providesTags: ["payments"],
      }),
      paymentsProductInvoiceItemsCreate: build.mutation<
        PaymentsProductInvoiceItemsCreateApiResponse,
        PaymentsProductInvoiceItemsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/payments/product-invoice-items/`,
          method: "POST",
          body: queryArg.productInvoiceItem,
        }),
        invalidatesTags: ["payments"],
      }),
      paymentsProductInvoiceItemsRetrieve: build.query<
        PaymentsProductInvoiceItemsRetrieveApiResponse,
        PaymentsProductInvoiceItemsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/payments/product-invoice-items/${queryArg.id}/`,
        }),
        providesTags: ["payments"],
      }),
      paymentsProductInvoiceItemsDestroy: build.mutation<
        PaymentsProductInvoiceItemsDestroyApiResponse,
        PaymentsProductInvoiceItemsDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/payments/product-invoice-items/${queryArg.id}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["payments"],
      }),
      paymentsProductRevenuePerIntervalRetrieve: build.query<
        PaymentsProductRevenuePerIntervalRetrieveApiResponse,
        PaymentsProductRevenuePerIntervalRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/payments/product-revenue-per-interval/`,
          params: {
            end_date: queryArg.endDate,
            interval: queryArg.interval,
            product_id: queryArg.productId,
            start_date: queryArg.startDate,
          },
        }),
        providesTags: ["payments"],
      }),
      paymentsProductSalesCountPerIntervalRetrieve: build.query<
        PaymentsProductSalesCountPerIntervalRetrieveApiResponse,
        PaymentsProductSalesCountPerIntervalRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/payments/product-sales-count-per-interval/`,
          params: {
            end_date: queryArg.endDate,
            interval: queryArg.interval,
            product_id: queryArg.productId,
            start_date: queryArg.startDate,
          },
        }),
        providesTags: ["payments"],
      }),
      paymentsTransactionsList: build.query<
        PaymentsTransactionsListApiResponse,
        PaymentsTransactionsListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/payments/transactions/`,
          params: {
            date_time: queryArg.dateTime,
            date_time__date__range_after: queryArg.dateTimeDateRangeAfter,
            date_time__date__range_before: queryArg.dateTimeDateRangeBefore,
            date_time__gt: queryArg.dateTimeGt,
            date_time__lt: queryArg.dateTimeLt,
            date_time__range: queryArg.dateTimeRange,
            invoice: queryArg.invoice,
            invoice__customer: queryArg.invoiceCustomer,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["payments"],
      }),
      paymentsTransactionsRetrieve: build.query<
        PaymentsTransactionsRetrieveApiResponse,
        PaymentsTransactionsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/payments/transactions/${queryArg.id}/`,
        }),
        providesTags: ["payments"],
      }),
      paymentsTransactionsMyTransactionsList: build.query<
        PaymentsTransactionsMyTransactionsListApiResponse,
        PaymentsTransactionsMyTransactionsListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/payments/transactions/my-transactions/`,
          params: {
            date_time: queryArg.dateTime,
            date_time__date__range_after: queryArg.dateTimeDateRangeAfter,
            date_time__date__range_before: queryArg.dateTimeDateRangeBefore,
            date_time__gt: queryArg.dateTimeGt,
            date_time__lt: queryArg.dateTimeLt,
            date_time__range: queryArg.dateTimeRange,
            invoice: queryArg.invoice,
            invoice__customer: queryArg.invoiceCustomer,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["payments"],
      }),
      paymentsTransactionsReportRetrieve: build.query<
        PaymentsTransactionsReportRetrieveApiResponse,
        PaymentsTransactionsReportRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/payments/transactions/report/`,
          params: {
            end_date: queryArg.endDate,
            start_date: queryArg.startDate,
          },
        }),
        providesTags: ["payments"],
      }),
      policyDocumentsList: build.query<
        PolicyDocumentsListApiResponse,
        PolicyDocumentsListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/policy-documents/`,
          params: {
            is_active: queryArg.isActive,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            search: queryArg.search,
            title: queryArg.title,
          },
        }),
        providesTags: ["policy-documents"],
      }),
      policyDocumentsCreate: build.mutation<
        PolicyDocumentsCreateApiResponse,
        PolicyDocumentsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/policy-documents/`,
          method: "POST",
          body: queryArg.policyDocument,
        }),
        invalidatesTags: ["policy-documents"],
      }),
      policyDocumentsRetrieve: build.query<
        PolicyDocumentsRetrieveApiResponse,
        PolicyDocumentsRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/api/policy-documents/${queryArg.id}/` }),
        providesTags: ["policy-documents"],
      }),
      policyDocumentsUpdate: build.mutation<
        PolicyDocumentsUpdateApiResponse,
        PolicyDocumentsUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/policy-documents/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.policyDocument,
        }),
        invalidatesTags: ["policy-documents"],
      }),
      policyDocumentsPartialUpdate: build.mutation<
        PolicyDocumentsPartialUpdateApiResponse,
        PolicyDocumentsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/policy-documents/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedPolicyDocument,
        }),
        invalidatesTags: ["policy-documents"],
      }),
      policyDocumentsDestroy: build.mutation<
        PolicyDocumentsDestroyApiResponse,
        PolicyDocumentsDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/policy-documents/${queryArg.id}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["policy-documents"],
      }),
      productCategoriesList: build.query<
        ProductCategoriesListApiResponse,
        ProductCategoriesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/product-categories/`,
          params: {
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            search: queryArg.search,
          },
        }),
        providesTags: ["product-categories"],
      }),
      productCategoriesCreate: build.mutation<
        ProductCategoriesCreateApiResponse,
        ProductCategoriesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/product-categories/`,
          method: "POST",
          body: queryArg.productCategory,
        }),
        invalidatesTags: ["product-categories"],
      }),
      productCategoriesRetrieve: build.query<
        ProductCategoriesRetrieveApiResponse,
        ProductCategoriesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/product-categories/${queryArg.id}/`,
        }),
        providesTags: ["product-categories"],
      }),
      productCategoriesUpdate: build.mutation<
        ProductCategoriesUpdateApiResponse,
        ProductCategoriesUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/product-categories/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.productCategory,
        }),
        invalidatesTags: ["product-categories"],
      }),
      productCategoriesPartialUpdate: build.mutation<
        ProductCategoriesPartialUpdateApiResponse,
        ProductCategoriesPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/product-categories/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedProductCategory,
        }),
        invalidatesTags: ["product-categories"],
      }),
      productCategoriesDestroy: build.mutation<
        ProductCategoriesDestroyApiResponse,
        ProductCategoriesDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/product-categories/${queryArg.id}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["product-categories"],
      }),
      productImagesList: build.query<
        ProductImagesListApiResponse,
        ProductImagesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/product-images/`,
          params: {
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            product: queryArg.product,
          },
        }),
        providesTags: ["product-images"],
      }),
      productImagesCreate: build.mutation<
        ProductImagesCreateApiResponse,
        ProductImagesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/product-images/`,
          method: "POST",
          body: queryArg.productImage,
        }),
        invalidatesTags: ["product-images"],
      }),
      productImagesRetrieve: build.query<
        ProductImagesRetrieveApiResponse,
        ProductImagesRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/api/product-images/${queryArg.id}/` }),
        providesTags: ["product-images"],
      }),
      productImagesUpdate: build.mutation<
        ProductImagesUpdateApiResponse,
        ProductImagesUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/product-images/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.productImage,
        }),
        invalidatesTags: ["product-images"],
      }),
      productImagesPartialUpdate: build.mutation<
        ProductImagesPartialUpdateApiResponse,
        ProductImagesPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/product-images/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedProductImage,
        }),
        invalidatesTags: ["product-images"],
      }),
      productImagesDestroy: build.mutation<
        ProductImagesDestroyApiResponse,
        ProductImagesDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/product-images/${queryArg.id}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["product-images"],
      }),
      productOptionsList: build.query<
        ProductOptionsListApiResponse,
        ProductOptionsListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/product-options/`,
          params: {
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            product: queryArg.product,
            search: queryArg.search,
          },
        }),
        providesTags: ["product-options"],
      }),
      productOptionsCreate: build.mutation<
        ProductOptionsCreateApiResponse,
        ProductOptionsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/product-options/`,
          method: "POST",
          body: queryArg.productOption,
        }),
        invalidatesTags: ["product-options"],
      }),
      productOptionsRetrieve: build.query<
        ProductOptionsRetrieveApiResponse,
        ProductOptionsRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/api/product-options/${queryArg.id}/` }),
        providesTags: ["product-options"],
      }),
      productOptionsUpdate: build.mutation<
        ProductOptionsUpdateApiResponse,
        ProductOptionsUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/product-options/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.productOption,
        }),
        invalidatesTags: ["product-options"],
      }),
      productOptionsPartialUpdate: build.mutation<
        ProductOptionsPartialUpdateApiResponse,
        ProductOptionsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/product-options/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedProductOption,
        }),
        invalidatesTags: ["product-options"],
      }),
      productOptionsDestroy: build.mutation<
        ProductOptionsDestroyApiResponse,
        ProductOptionsDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/product-options/${queryArg.id}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["product-options"],
      }),
      productsList: build.query<ProductsListApiResponse, ProductsListApiArg>({
        query: (queryArg) => ({
          url: `/api/products/`,
          params: {
            category: queryArg.category,
            id__in: queryArg.idIn,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            search: queryArg.search,
            status: queryArg.status,
          },
        }),
        providesTags: ["products"],
      }),
      productsCreate: build.mutation<
        ProductsCreateApiResponse,
        ProductsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/products/`,
          method: "POST",
          body: queryArg.product,
        }),
        invalidatesTags: ["products"],
      }),
      productsRetrieve: build.query<
        ProductsRetrieveApiResponse,
        ProductsRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/api/products/${queryArg.id}/` }),
        providesTags: ["products"],
      }),
      productsUpdate: build.mutation<
        ProductsUpdateApiResponse,
        ProductsUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/products/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.product,
        }),
        invalidatesTags: ["products"],
      }),
      productsPartialUpdate: build.mutation<
        ProductsPartialUpdateApiResponse,
        ProductsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/products/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedProduct,
        }),
        invalidatesTags: ["products"],
      }),
      productsDestroy: build.mutation<
        ProductsDestroyApiResponse,
        ProductsDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/products/${queryArg.id}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["products"],
      }),
      productsHistoryList: build.query<
        ProductsHistoryListApiResponse,
        ProductsHistoryListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/products/${queryArg.id}/history/`,
          params: {
            no_pagination: queryArg.noPagination,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["products"],
      }),
      reservationsList: build.query<
        ReservationsListApiResponse,
        ReservationsListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reservations/`,
          params: {
            is_expired: queryArg.isExpired,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            user: queryArg.user,
          },
        }),
        providesTags: ["reservations"],
      }),
      reservationsRetrieve: build.query<
        ReservationsRetrieveApiResponse,
        ReservationsRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/api/reservations/${queryArg.id}/` }),
        providesTags: ["reservations"],
      }),
      reservationsCancelReservationDestroy: build.mutation<
        ReservationsCancelReservationDestroyApiResponse,
        ReservationsCancelReservationDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reservations/${queryArg.id}/cancel-reservation/`,
          method: "DELETE",
        }),
        invalidatesTags: ["reservations"],
      }),
      reservationsMyReservationsList: build.query<
        ReservationsMyReservationsListApiResponse,
        ReservationsMyReservationsListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reservations/my-reservations/`,
          params: {
            is_expired: queryArg.isExpired,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            user: queryArg.user,
          },
        }),
        providesTags: ["reservations"],
      }),
      reserveEventCreate: build.mutation<
        ReserveEventCreateApiResponse,
        ReserveEventCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reserve-event/`,
          method: "POST",
          body: queryArg.reserveEvent,
        }),
        invalidatesTags: ["reserve-event"],
      }),
      schemaRetrieve: build.query<
        SchemaRetrieveApiResponse,
        SchemaRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/schema/`,
          params: {
            lang: queryArg.lang,
          },
        }),
        providesTags: ["schema"],
      }),
      settingsRegisterPushTokenCreate: build.mutation<
        SettingsRegisterPushTokenCreateApiResponse,
        SettingsRegisterPushTokenCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/register-push-token/`,
          method: "POST",
          body: queryArg.registerPushTokenParameters,
        }),
        invalidatesTags: ["settings"],
      }),
      stripePaymentsCheckoutSuccessCreate: build.mutation<
        StripePaymentsCheckoutSuccessCreateApiResponse,
        StripePaymentsCheckoutSuccessCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/stripe-payments/checkout-success/`,
          method: "POST",
          body: queryArg.checkoutSuccessCreate,
        }),
        invalidatesTags: ["stripe-payments"],
      }),
      stripePaymentsCreateCustomerPortalCreate: build.mutation<
        StripePaymentsCreateCustomerPortalCreateApiResponse,
        StripePaymentsCreateCustomerPortalCreateApiArg
      >({
        query: () => ({
          url: `/api/stripe-payments/create-customer-portal/`,
          method: "POST",
        }),
        invalidatesTags: ["stripe-payments"],
      }),
      stripePaymentsCreateFinegymCheckoutSessionCreate: build.mutation<
        StripePaymentsCreateFinegymCheckoutSessionCreateApiResponse,
        StripePaymentsCreateFinegymCheckoutSessionCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/stripe-payments/create-finegym-checkout-session/`,
          method: "POST",
          body: queryArg.createFinegymCheckoutSession,
        }),
        invalidatesTags: ["stripe-payments"],
      }),
      stripePaymentsCreateInvoiceCheckoutSessionCreate: build.mutation<
        StripePaymentsCreateInvoiceCheckoutSessionCreateApiResponse,
        StripePaymentsCreateInvoiceCheckoutSessionCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/stripe-payments/create-invoice-checkout-session/`,
          method: "POST",
          body: queryArg.createInvoiceCheckoutSession,
        }),
        invalidatesTags: ["stripe-payments"],
      }),
      stripePaymentsCreateInvoicePaymentIntentCreate: build.mutation<
        StripePaymentsCreateInvoicePaymentIntentCreateApiResponse,
        StripePaymentsCreateInvoicePaymentIntentCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/stripe-payments/create-invoice-payment-intent/`,
          method: "POST",
          body: queryArg.createInvoicePaymentIntent,
        }),
        invalidatesTags: ["stripe-payments"],
      }),
      stripePaymentsCreateStripeAccountCreate: build.mutation<
        StripePaymentsCreateStripeAccountCreateApiResponse,
        StripePaymentsCreateStripeAccountCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/stripe-payments/create-stripe-account/`,
          method: "POST",
          body: queryArg.createStripeAccountType,
        }),
        invalidatesTags: ["stripe-payments"],
      }),
      stripePaymentsCurrentAccountRetrieve: build.query<
        StripePaymentsCurrentAccountRetrieveApiResponse,
        StripePaymentsCurrentAccountRetrieveApiArg
      >({
        query: () => ({ url: `/api/stripe-payments/current-account/` }),
        providesTags: ["stripe-payments"],
      }),
      stripePaymentsCustomerRetrieve: build.query<
        StripePaymentsCustomerRetrieveApiResponse,
        StripePaymentsCustomerRetrieveApiArg
      >({
        query: () => ({ url: `/api/stripe-payments/customer/` }),
        providesTags: ["stripe-payments"],
      }),
      stripePaymentsInvoicesList: build.query<
        StripePaymentsInvoicesListApiResponse,
        StripePaymentsInvoicesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/stripe-payments/invoices/`,
          params: {
            ordering: queryArg.ordering,
          },
        }),
        providesTags: ["stripe-payments"],
      }),
      stripePaymentsInvoicesRetrieve: build.query<
        StripePaymentsInvoicesRetrieveApiResponse,
        StripePaymentsInvoicesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/stripe-payments/invoices/${queryArg.id}/`,
        }),
        providesTags: ["stripe-payments"],
      }),
      stripePaymentsInvoicesUpcomingRetrieve: build.query<
        StripePaymentsInvoicesUpcomingRetrieveApiResponse,
        StripePaymentsInvoicesUpcomingRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/stripe-payments/invoices/upcoming/`,
          params: {
            subscription_id: queryArg.subscriptionId,
          },
        }),
        providesTags: ["stripe-payments"],
      }),
      stripePaymentsPlansList: build.query<
        StripePaymentsPlansListApiResponse,
        StripePaymentsPlansListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/stripe-payments/plans/`,
          params: {
            ordering: queryArg.ordering,
          },
        }),
        providesTags: ["stripe-payments"],
      }),
      stripePaymentsPlansRetrieve: build.query<
        StripePaymentsPlansRetrieveApiResponse,
        StripePaymentsPlansRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/stripe-payments/plans/${queryArg.id}/`,
        }),
        providesTags: ["stripe-payments"],
      }),
      stripePaymentsPricesList: build.query<
        StripePaymentsPricesListApiResponse,
        StripePaymentsPricesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/stripe-payments/prices/`,
          params: {
            ordering: queryArg.ordering,
          },
        }),
        providesTags: ["stripe-payments"],
      }),
      stripePaymentsPricesRetrieve: build.query<
        StripePaymentsPricesRetrieveApiResponse,
        StripePaymentsPricesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/stripe-payments/prices/${queryArg.id}/`,
        }),
        providesTags: ["stripe-payments"],
      }),
      stripePaymentsProductsList: build.query<
        StripePaymentsProductsListApiResponse,
        StripePaymentsProductsListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/stripe-payments/products/`,
          params: {
            ordering: queryArg.ordering,
          },
        }),
        providesTags: ["stripe-payments"],
      }),
      stripePaymentsProductsRetrieve: build.query<
        StripePaymentsProductsRetrieveApiResponse,
        StripePaymentsProductsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/stripe-payments/products/${queryArg.id}/`,
        }),
        providesTags: ["stripe-payments"],
      }),
      stripePaymentsSessionRetrieveCreate: build.mutation<
        StripePaymentsSessionRetrieveCreateApiResponse,
        StripePaymentsSessionRetrieveCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/stripe-payments/session-retrieve/`,
          method: "POST",
          body: queryArg.sessionRetrieve,
        }),
        invalidatesTags: ["stripe-payments"],
      }),
      stripePaymentsSubscriptionRetrieve: build.query<
        StripePaymentsSubscriptionRetrieveApiResponse,
        StripePaymentsSubscriptionRetrieveApiArg
      >({
        query: () => ({ url: `/api/stripe-payments/subscription/` }),
        providesTags: ["stripe-payments"],
      }),
      taxRatesList: build.query<TaxRatesListApiResponse, TaxRatesListApiArg>({
        query: (queryArg) => ({
          url: `/api/tax-rates/`,
          params: {
            is_archived: queryArg.isArchived,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            search: queryArg.search,
          },
        }),
        providesTags: ["tax-rates"],
      }),
      taxRatesCreate: build.mutation<
        TaxRatesCreateApiResponse,
        TaxRatesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/tax-rates/`,
          method: "POST",
          body: queryArg.taxRate,
        }),
        invalidatesTags: ["tax-rates"],
      }),
      taxRatesRetrieve: build.query<
        TaxRatesRetrieveApiResponse,
        TaxRatesRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/api/tax-rates/${queryArg.id}/` }),
        providesTags: ["tax-rates"],
      }),
      taxRatesUpdate: build.mutation<
        TaxRatesUpdateApiResponse,
        TaxRatesUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/tax-rates/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.taxRate,
        }),
        invalidatesTags: ["tax-rates"],
      }),
      taxRatesPartialUpdate: build.mutation<
        TaxRatesPartialUpdateApiResponse,
        TaxRatesPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/tax-rates/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedTaxRate,
        }),
        invalidatesTags: ["tax-rates"],
      }),
      taxRatesArchiveCreate: build.mutation<
        TaxRatesArchiveCreateApiResponse,
        TaxRatesArchiveCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/tax-rates/${queryArg.id}/archive/`,
          method: "POST",
          body: queryArg.taxRate,
        }),
        invalidatesTags: ["tax-rates"],
      }),
      taxRatesUnarchiveCreate: build.mutation<
        TaxRatesUnarchiveCreateApiResponse,
        TaxRatesUnarchiveCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/tax-rates/${queryArg.id}/unarchive/`,
          method: "POST",
          body: queryArg.taxRate,
        }),
        invalidatesTags: ["tax-rates"],
      }),
      timezonesRetrieve: build.query<
        TimezonesRetrieveApiResponse,
        TimezonesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/timezones/`,
          params: {
            country_code: queryArg.countryCode,
          },
        }),
        providesTags: ["timezones"],
      }),
      userDocumentSignaturesList: build.query<
        UserDocumentSignaturesListApiResponse,
        UserDocumentSignaturesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user-document-signatures/`,
          params: {
            document_version__document: queryArg.documentVersionDocument,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            user: queryArg.user,
          },
        }),
        providesTags: ["user-document-signatures"],
      }),
      userDocumentSignaturesRetrieve: build.query<
        UserDocumentSignaturesRetrieveApiResponse,
        UserDocumentSignaturesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user-document-signatures/${queryArg.id}/`,
        }),
        providesTags: ["user-document-signatures"],
      }),
      userDocumentSignaturesGenerateSignedDocumentRetrieve: build.query<
        UserDocumentSignaturesGenerateSignedDocumentRetrieveApiResponse,
        UserDocumentSignaturesGenerateSignedDocumentRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user-document-signatures/${queryArg.id}/generate-signed-document/`,
        }),
        providesTags: ["user-document-signatures"],
      }),
      userDocumentSignaturesAdminSignCreate: build.mutation<
        UserDocumentSignaturesAdminSignCreateApiResponse,
        UserDocumentSignaturesAdminSignCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user-document-signatures/admin-sign/`,
          method: "POST",
          body: queryArg.adminSignDocument,
        }),
        invalidatesTags: ["user-document-signatures", "digital-documents"],
      }),
      userMembershipAttendancesList: build.query<
        UserMembershipAttendancesListApiResponse,
        UserMembershipAttendancesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user-membership-attendances/`,
          params: {
            check_in: queryArg.checkIn,
            check_in__range: queryArg.checkInRange,
            check_in_date: queryArg.checkInDate,
            check_out: queryArg.checkOut,
            check_out__isnull: queryArg.checkOutIsnull,
            check_out__range: queryArg.checkOutRange,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            user: queryArg.user,
            user_membership: queryArg.userMembership,
          },
        }),
        providesTags: ["user-membership-attendances", "attendances"],
      }),
      userMembershipAttendancesRetrieve: build.query<
        UserMembershipAttendancesRetrieveApiResponse,
        UserMembershipAttendancesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user-membership-attendances/${queryArg.id}/`,
        }),
        providesTags: ["user-membership-attendances", "attendances"],
      }),
      userMembershipsList: build.query<
        UserMembershipsListApiResponse,
        UserMembershipsListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user-memberships/`,
          params: {
            discount: queryArg.discount,
            is_active: queryArg.isActive,
            is_archived: queryArg.isArchived,
            membership_plan: queryArg.membershipPlan,
            membership_plan__allow_check_ins:
              queryArg.membershipPlanAllowCheckIns,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            user: queryArg.user,
          },
        }),
        providesTags: ["user-memberships", "payments"],
      }),
      userMembershipsRetrieve: build.query<
        UserMembershipsRetrieveApiResponse,
        UserMembershipsRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/api/user-memberships/${queryArg.id}/` }),
        providesTags: ["user-memberships", "payments"],
      }),
      userMembershipsArchiveUpdate: build.mutation<
        UserMembershipsArchiveUpdateApiResponse,
        UserMembershipsArchiveUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user-memberships/${queryArg.id}/archive/`,
          method: "PUT",
          body: queryArg.archiveUserMembership,
        }),
        invalidatesTags: ["user-memberships", "members"],
      }),
      userMembershipsChangeDiscountPartialUpdate: build.mutation<
        UserMembershipsChangeDiscountPartialUpdateApiResponse,
        UserMembershipsChangeDiscountPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user-memberships/${queryArg.id}/change-discount/`,
          method: "PATCH",
          body: queryArg.patchedChangeDiscount,
        }),
        invalidatesTags: ["user-memberships", "payments"],
      }),
      userMembershipsChangeNextBillDatePartialUpdate: build.mutation<
        UserMembershipsChangeNextBillDatePartialUpdateApiResponse,
        UserMembershipsChangeNextBillDatePartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user-memberships/${queryArg.id}/change-next-bill-date/`,
          method: "PATCH",
          body: queryArg.patchedChangeNextBillDate,
        }),
        invalidatesTags: ["user-memberships", "payments"],
      }),
      userMembershipsChangePaymentTypePartialUpdate: build.mutation<
        UserMembershipsChangePaymentTypePartialUpdateApiResponse,
        UserMembershipsChangePaymentTypePartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user-memberships/${queryArg.id}/change-payment-type/`,
          method: "PATCH",
          body: queryArg.patchedChangePaymentType,
        }),
        invalidatesTags: ["user-memberships", "payments"],
      }),
      userMembershipsAddMembershipCardCreate: build.mutation<
        UserMembershipsAddMembershipCardCreateApiResponse,
        UserMembershipsAddMembershipCardCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user-memberships/add-membership-card/`,
          method: "POST",
          body: queryArg.addUserMembership,
        }),
        invalidatesTags: ["user-memberships", "payments"],
      }),
      userMembershipsAddMembershipCashCreate: build.mutation<
        UserMembershipsAddMembershipCashCreateApiResponse,
        UserMembershipsAddMembershipCashCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user-memberships/add-membership-cash/`,
          method: "POST",
          body: queryArg.addUserMembership,
        }),
        invalidatesTags: ["user-memberships", "members"],
      }),
      userMembershipsBuyMembershipCreate: build.mutation<
        UserMembershipsBuyMembershipCreateApiResponse,
        UserMembershipsBuyMembershipCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user-memberships/buy-membership/`,
          method: "POST",
          body: queryArg.buyMembership,
        }),
        invalidatesTags: ["user-memberships", "payments"],
      }),
      userMembershipsMyMembershipsList: build.query<
        UserMembershipsMyMembershipsListApiResponse,
        UserMembershipsMyMembershipsListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user-memberships/my-memberships/`,
          params: {
            discount: queryArg.discount,
            is_active: queryArg.isActive,
            is_archived: queryArg.isArchived,
            membership_plan: queryArg.membershipPlan,
            membership_plan__allow_check_ins:
              queryArg.membershipPlanAllowCheckIns,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            user: queryArg.user,
          },
        }),
        providesTags: ["user-memberships", "payments"],
      }),
      userNoteTagsList: build.query<
        UserNoteTagsListApiResponse,
        UserNoteTagsListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user-note-tags/`,
          params: {
            id__in: queryArg.idIn,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            search: queryArg.search,
          },
        }),
        providesTags: ["user-note-tags"],
      }),
      userNoteTagsCreate: build.mutation<
        UserNoteTagsCreateApiResponse,
        UserNoteTagsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user-note-tags/`,
          method: "POST",
          body: queryArg.userNoteTag,
        }),
        invalidatesTags: ["user-note-tags"],
      }),
      userNoteTagsRetrieve: build.query<
        UserNoteTagsRetrieveApiResponse,
        UserNoteTagsRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/api/user-note-tags/${queryArg.id}/` }),
        providesTags: ["user-note-tags"],
      }),
      userNoteTagsUpdate: build.mutation<
        UserNoteTagsUpdateApiResponse,
        UserNoteTagsUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user-note-tags/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.userNoteTag,
        }),
        invalidatesTags: ["user-note-tags"],
      }),
      userNoteTagsPartialUpdate: build.mutation<
        UserNoteTagsPartialUpdateApiResponse,
        UserNoteTagsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user-note-tags/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedUserNoteTag,
        }),
        invalidatesTags: ["user-note-tags"],
      }),
      userNoteTagsDestroy: build.mutation<
        UserNoteTagsDestroyApiResponse,
        UserNoteTagsDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user-note-tags/${queryArg.id}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["user-note-tags"],
      }),
      userNotesList: build.query<UserNotesListApiResponse, UserNotesListApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/user-notes/`,
            params: {
              no_pagination: queryArg.noPagination,
              ordering: queryArg.ordering,
              page: queryArg.page,
              per_page: queryArg.perPage,
              search: queryArg.search,
              user: queryArg.user,
            },
          }),
          providesTags: ["user-notes"],
        },
      ),
      userNotesCreate: build.mutation<
        UserNotesCreateApiResponse,
        UserNotesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user-notes/`,
          method: "POST",
          body: queryArg.userNote,
        }),
        invalidatesTags: ["user-notes"],
      }),
      userNotesRetrieve: build.query<
        UserNotesRetrieveApiResponse,
        UserNotesRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/api/user-notes/${queryArg.id}/` }),
        providesTags: ["user-notes"],
      }),
      userNotesUpdate: build.mutation<
        UserNotesUpdateApiResponse,
        UserNotesUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user-notes/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.userNote,
        }),
        invalidatesTags: ["user-notes"],
      }),
      userNotesPartialUpdate: build.mutation<
        UserNotesPartialUpdateApiResponse,
        UserNotesPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user-notes/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedUserNote,
        }),
        invalidatesTags: ["user-notes"],
      }),
      userNotesDestroy: build.mutation<
        UserNotesDestroyApiResponse,
        UserNotesDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user-notes/${queryArg.id}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["user-notes"],
      }),
      usersList: build.query<UsersListApiResponse, UsersListApiArg>({
        query: (queryArg) => ({
          url: `/api/users/`,
          params: {
            at_risk: queryArg.atRisk,
            attendance__check_out: queryArg.attendanceCheckOut,
            attendance__check_out__isnull: queryArg.attendanceCheckOutIsnull,
            attendance__check_out__range: queryArg.attendanceCheckOutRange,
            birthday_month: queryArg.birthdayMonth,
            days_until_birthday: queryArg.daysUntilBirthday,
            has_active_membership: queryArg.hasActiveMembership,
            id__in: queryArg.idIn,
            migration_status: queryArg.migrationStatus,
            migration_status__in: queryArg.migrationStatusIn,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            search: queryArg.search,
          },
        }),
        providesTags: ["users"],
      }),
      usersCreate: build.mutation<UsersCreateApiResponse, UsersCreateApiArg>({
        query: (queryArg) => ({
          url: `/api/users/`,
          method: "POST",
          body: queryArg.user,
        }),
        invalidatesTags: ["users"],
      }),
      usersRetrieve: build.query<UsersRetrieveApiResponse, UsersRetrieveApiArg>(
        {
          query: (queryArg) => ({ url: `/api/users/${queryArg.id}/` }),
          providesTags: ["users"],
        },
      ),
      usersUpdate: build.mutation<UsersUpdateApiResponse, UsersUpdateApiArg>({
        query: (queryArg) => ({
          url: `/api/users/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.user,
        }),
        invalidatesTags: ["users"],
      }),
      usersPartialUpdate: build.mutation<
        UsersPartialUpdateApiResponse,
        UsersPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedUser,
        }),
        invalidatesTags: ["users"],
      }),
      usersDestroy: build.mutation<UsersDestroyApiResponse, UsersDestroyApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/users/${queryArg.id}/`,
            method: "DELETE",
          }),
          invalidatesTags: ["users"],
        },
      ),
      usersAssignedUsersList: build.query<
        UsersAssignedUsersListApiResponse,
        UsersAssignedUsersListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/${queryArg.id}/assigned-users/`,
          params: {
            at_risk: queryArg.atRisk,
            attendance__check_out: queryArg.attendanceCheckOut,
            attendance__check_out__isnull: queryArg.attendanceCheckOutIsnull,
            attendance__check_out__range: queryArg.attendanceCheckOutRange,
            birthday_month: queryArg.birthdayMonth,
            days_until_birthday: queryArg.daysUntilBirthday,
            has_active_membership: queryArg.hasActiveMembership,
            id__in: queryArg.idIn,
            migration_status: queryArg.migrationStatus,
            migration_status__in: queryArg.migrationStatusIn,
            no_pagination: queryArg.noPagination,
            ordering: queryArg.ordering,
            page: queryArg.page,
            per_page: queryArg.perPage,
            search: queryArg.search,
          },
        }),
        providesTags: ["users"],
      }),
      usersHistoryList: build.query<
        UsersHistoryListApiResponse,
        UsersHistoryListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/${queryArg.id}/history/`,
          params: {
            no_pagination: queryArg.noPagination,
            page: queryArg.page,
            per_page: queryArg.perPage,
          },
        }),
        providesTags: ["users"],
      }),
      usersUpdateWorkingHoursPartialUpdate: build.mutation<
        UsersUpdateWorkingHoursPartialUpdateApiResponse,
        UsersUpdateWorkingHoursPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/${queryArg.id}/update-working-hours/`,
          method: "PATCH",
          body: queryArg.patchedWorkingHours,
        }),
        invalidatesTags: ["users"],
      }),
      usersEnterFrontdeskModeCreate: build.mutation<
        UsersEnterFrontdeskModeCreateApiResponse,
        UsersEnterFrontdeskModeCreateApiArg
      >({
        query: () => ({
          url: `/api/users/enter-frontdesk-mode/`,
          method: "POST",
        }),
        invalidatesTags: ["users"],
      }),
      usersExitFrontdeskModeCreate: build.mutation<
        UsersExitFrontdeskModeCreateApiResponse,
        UsersExitFrontdeskModeCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/exit-frontdesk-mode/`,
          method: "POST",
          body: queryArg.frontdeskMode,
        }),
        invalidatesTags: ["users"],
      }),
      usersMeRetrieve: build.query<
        UsersMeRetrieveApiResponse,
        UsersMeRetrieveApiArg
      >({
        query: () => ({ url: `/api/users/me/` }),
        providesTags: ["users"],
      }),
      usersRetrieveByPinCodeCreate: build.mutation<
        UsersRetrieveByPinCodeCreateApiResponse,
        UsersRetrieveByPinCodeCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/retrieve-by-pin-code/`,
          method: "POST",
          body: queryArg.pinCode,
        }),
        invalidatesTags: ["users"],
      }),
      usersUpdateProfileUpdate: build.mutation<
        UsersUpdateProfileUpdateApiResponse,
        UsersUpdateProfileUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/update-profile/`,
          method: "PUT",
          body: queryArg.user,
        }),
        invalidatesTags: ["users"],
      }),
      usersUpdateProfilePartialUpdate: build.mutation<
        UsersUpdateProfilePartialUpdateApiResponse,
        UsersUpdateProfilePartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/update-profile/`,
          method: "PATCH",
          body: queryArg.patchedUser,
        }),
        invalidatesTags: ["users"],
      }),
      workingHoursRetrieve: build.query<
        WorkingHoursRetrieveApiResponse,
        WorkingHoursRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/api/working-hours/${queryArg.id}/` }),
        providesTags: ["working-hours"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as rtkQueryApi };
export type AllUsersListApiResponse = /** status 200  */ PaginatedUserListRead;
export type AllUsersListApiArg = {
  atRisk?: boolean;
  attendanceCheckOut?: string;
  attendanceCheckOutIsnull?: boolean;
  /** Multiple values may be separated by commas. */
  attendanceCheckOutRange?: string[];
  birthdayMonth?: number;
  daysUntilBirthday?: number;
  hasActiveMembership?: boolean;
  /** Multiple values may be separated by commas. */
  idIn?: number[];
  /** * `Not Imported` - Not Imported
   * `Imported` - Imported
   * `Membership Assigned` - Membership Assigned
   * `Welcome Email Sent` - Welcome Email Sent */
  migrationStatus?:
    | "Imported"
    | "Membership Assigned"
    | "Not Imported"
    | "Welcome Email Sent";
  /** Multiple values may be separated by commas. */
  migrationStatusIn?: string[];
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  /** A search term. */
  search?: string;
};
export type AllUsersRetrieveApiResponse = /** status 200  */ UserRead;
export type AllUsersRetrieveApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
};
export type AllUsersHistoryListApiResponse =
  /** status 200  */ PaginatedHistoricalUserListRead;
export type AllUsersHistoryListApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
};
export type AppointmentPackagesListApiResponse =
  /** status 200  */ PaginatedAppointmentPackageListRead;
export type AppointmentPackagesListApiArg = {
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  packageType?: number;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  /** A search term. */
  search?: string;
};
export type AppointmentPackagesCreateApiResponse =
  /** status 201  */ AppointmentPackageRead;
export type AppointmentPackagesCreateApiArg = {
  appointmentPackage: AppointmentPackage;
};
export type AppointmentPackagesRetrieveApiResponse =
  /** status 200  */ AppointmentPackageRead;
export type AppointmentPackagesRetrieveApiArg = {
  /** A unique integer value identifying this appointment package. */
  id: number;
};
export type AppointmentPackagesUpdateApiResponse =
  /** status 200  */ AppointmentPackageRead;
export type AppointmentPackagesUpdateApiArg = {
  /** A unique integer value identifying this appointment package. */
  id: number;
  appointmentPackage: AppointmentPackage;
};
export type AppointmentPackagesPartialUpdateApiResponse =
  /** status 200  */ AppointmentPackageRead;
export type AppointmentPackagesPartialUpdateApiArg = {
  /** A unique integer value identifying this appointment package. */
  id: number;
  patchedAppointmentPackage: PatchedAppointmentPackage;
};
export type AppointmentPackagesDestroyApiResponse = unknown;
export type AppointmentPackagesDestroyApiArg = {
  /** A unique integer value identifying this appointment package. */
  id: number;
};
export type AppointmentReservationsListApiResponse =
  /** status 200  */ PaginatedAppointmentReservationListRead;
export type AppointmentReservationsListApiArg = {
  creditPackageAppointmentType?: number;
  /** Is expired */
  isExpired?: boolean;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  staff?: number;
  startDateTime?: string;
  /** Multiple values may be separated by commas. */
  startDateTimeRange?: string[];
  /** * `checked_out` - Checked Out
   * `checked_in` - Checked In
   * `no_show` - No Show
   * `can_check_in` - Can Check In
   * `reserved` - Reserved */
  status?:
    | "can_check_in"
    | "checked_in"
    | "checked_out"
    | "no_show"
    | "reserved";
  user?: number;
};
export type AppointmentReservationsRetrieveApiResponse =
  /** status 200  */ AppointmentReservationRead;
export type AppointmentReservationsRetrieveApiArg = {
  /** A unique integer value identifying this appointment reservation. */
  id: number;
};
export type AppointmentReservationsCancelReservationDestroyApiResponse =
  unknown;
export type AppointmentReservationsCancelReservationDestroyApiArg = {
  /** A unique integer value identifying this appointment reservation. */
  id: number;
};
export type AppointmentReservationsEditReservationCreateApiResponse =
  /** status 200  */ AppointmentReservationRead;
export type AppointmentReservationsEditReservationCreateApiArg = {
  /** A unique integer value identifying this appointment reservation. */
  id: number;
  editReservation: EditReservation;
};
export type AppointmentReservationsAvailableTimesRetrieveApiResponse =
  /** status 200 List of available times */ AvailableTimeSlot[];
export type AppointmentReservationsAvailableTimesRetrieveApiArg = {
  appointmentType: number;
  date: string;
  staff?: number;
};
export type AppointmentReservationsMyReservationsListApiResponse =
  /** status 200  */ PaginatedAppointmentReservationListRead;
export type AppointmentReservationsMyReservationsListApiArg = {
  creditPackageAppointmentType?: number;
  /** Is expired */
  isExpired?: boolean;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  staff?: number;
  startDateTime?: string;
  /** Multiple values may be separated by commas. */
  startDateTimeRange?: string[];
  /** * `checked_out` - Checked Out
   * `checked_in` - Checked In
   * `no_show` - No Show
   * `can_check_in` - Can Check In
   * `reserved` - Reserved */
  status?:
    | "can_check_in"
    | "checked_in"
    | "checked_out"
    | "no_show"
    | "reserved";
  user?: number;
};
export type AppointmentReservationsReserveAppointmentCreateApiResponse =
  /** status 200  */ ReserveAppointment;
export type AppointmentReservationsReserveAppointmentCreateApiArg = {
  reserveAppointment: ReserveAppointment;
};
export type AppointmentTypesListApiResponse =
  /** status 200  */ PaginatedAppointmentTypeListRead;
export type AppointmentTypesListApiArg = {
  /** Multiple values may be separated by commas. */
  idIn?: number[];
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  /** A search term. */
  search?: string;
};
export type AppointmentTypesCreateApiResponse =
  /** status 201  */ AppointmentTypeRead;
export type AppointmentTypesCreateApiArg = {
  appointmentType: AppointmentType;
};
export type AppointmentTypesRetrieveApiResponse =
  /** status 200  */ AppointmentTypeRead;
export type AppointmentTypesRetrieveApiArg = {
  /** A unique integer value identifying this appointment type. */
  id: number;
};
export type AppointmentTypesUpdateApiResponse =
  /** status 200  */ AppointmentTypeRead;
export type AppointmentTypesUpdateApiArg = {
  /** A unique integer value identifying this appointment type. */
  id: number;
  appointmentType: AppointmentType;
};
export type AppointmentTypesPartialUpdateApiResponse =
  /** status 200  */ AppointmentTypeRead;
export type AppointmentTypesPartialUpdateApiArg = {
  /** A unique integer value identifying this appointment type. */
  id: number;
  patchedAppointmentType: PatchedAppointmentType;
};
export type AppointmentTypesDestroyApiResponse = unknown;
export type AppointmentTypesDestroyApiArg = {
  /** A unique integer value identifying this appointment type. */
  id: number;
};
export type AppointmentTypesStaffListApiResponse =
  /** status 200  */ UserRead[];
export type AppointmentTypesStaffListApiArg = {
  /** A unique integer value identifying this appointment type. */
  id: number;
  /** Multiple values may be separated by commas. */
  idIn?: number[];
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A search term. */
  search?: string;
};
export type AttendancesListApiResponse =
  /** status 200  */ PaginatedAttendancePolymorphicListRead;
export type AttendancesListApiArg = {
  checkIn?: string;
  /** Multiple values may be separated by commas. */
  checkInRange?: string[];
  checkInDate?: string;
  checkInDateRangeAfter?: string;
  checkInDateRangeBefore?: string;
  checkOut?: string;
  checkOutIsnull?: boolean;
  /** Multiple values may be separated by commas. */
  checkOutRange?: string[];
  employee?: number;
  /** Multiple values may be separated by commas. */
  membershipPlanIn?: number[];
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  user?: number;
};
export type AttendancesRetrieveApiResponse =
  /** status 200  */ AttendancePolymorphicRead;
export type AttendancesRetrieveApiArg = {
  /** A unique integer value identifying this attendance. */
  id: number;
};
export type AttendancesAggregatedUserVisitsRetrieveApiResponse =
  /** status 200  */ AggregatedUserVisits;
export type AttendancesAggregatedUserVisitsRetrieveApiArg = {
  endDate?: string;
  /** * `day` - day
   * `week` - week
   * `month` - month */
  interval: "day" | "week" | "month";
  startDate: string;
};
export type AttendancesAggregatedUserVisitsListListApiResponse =
  /** status 200  */ PaginatedAggregatedUserVisitsListList;
export type AttendancesAggregatedUserVisitsListListApiArg = {
  endDate?: string;
  /** * `day` - day
   * `week` - week
   * `month` - month */
  interval: "day" | "week" | "month";
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  startDate: string;
};
export type AttendancesMyAttendancesListApiResponse =
  /** status 200  */ PaginatedAttendancePolymorphicListRead;
export type AttendancesMyAttendancesListApiArg = {
  checkIn?: string;
  /** Multiple values may be separated by commas. */
  checkInRange?: string[];
  checkInDate?: string;
  checkInDateRangeAfter?: string;
  checkInDateRangeBefore?: string;
  checkOut?: string;
  checkOutIsnull?: boolean;
  /** Multiple values may be separated by commas. */
  checkOutRange?: string[];
  employee?: number;
  /** Multiple values may be separated by commas. */
  membershipPlanIn?: number[];
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  user?: number;
};
export type AttendancesPerformCheckInCreateApiResponse =
  /** status 200  */ AttendanceRead;
export type AttendancesPerformCheckInCreateApiArg = {
  performCheckIn: PerformCheckIn;
};
export type AttendancesPerformCheckInWithReservationCreateApiResponse =
  /** status 200  */ PerformCheckInWithReservation;
export type AttendancesPerformCheckInWithReservationCreateApiArg = {
  performCheckInWithReservation: PerformCheckInWithReservation;
};
export type AttendancesPerformCheckOutCreateApiResponse =
  /** status 200  */ AttendanceRead;
export type AttendancesPerformCheckOutCreateApiArg = {
  performCheckOut: PerformCheckOut;
};
export type AttendancesPerformCheckOutWithReservationCreateApiResponse =
  /** status 200  */ PerformCheckOutWithReservation;
export type AttendancesPerformCheckOutWithReservationCreateApiArg = {
  performCheckOutWithReservation: PerformCheckOutWithReservation;
};
export type AuthTokenCreateApiResponse = /** status 200  */ AuthTokenRead;
export type AuthTokenCreateApiArg = {
  authToken: AuthTokenWrite;
};
export type AuthDeleteTenantCreateApiResponse = unknown;
export type AuthDeleteTenantCreateApiArg = {
  tenantDelete: TenantDeleteWrite;
};
export type AuthLoginCreateApiResponse = /** status 200  */ TokenObtainPairRead;
export type AuthLoginCreateApiArg = {
  tokenObtainPair: TokenObtainPairWrite;
};
export type AuthPasswordChangeCreateApiResponse =
  /** status 200  */ RestAuthDetailRead;
export type AuthPasswordChangeCreateApiArg = {
  passwordChange: PasswordChange;
};
export type AuthPasswordResetCreateApiResponse =
  /** status 200  */ RestAuthDetailRead;
export type AuthPasswordResetCreateApiArg = {
  passwordReset: PasswordReset;
};
export type AuthPasswordResetConfirmCreateApiResponse =
  /** status 200  */ RestAuthDetailRead;
export type AuthPasswordResetConfirmCreateApiArg = {
  passwordResetConfirm: PasswordResetConfirm;
};
export type BusinessSettingsRetrieveApiResponse =
  /** status 200  */ BusinessSettingsRead;
export type BusinessSettingsRetrieveApiArg = void;
export type BusinessSettingsUpdateApiResponse =
  /** status 200  */ BusinessSettingsRead;
export type BusinessSettingsUpdateApiArg = {
  businessSettings: BusinessSettings;
};
export type BusinessSettingsPartialUpdateApiResponse =
  /** status 200  */ BusinessSettingsRead;
export type BusinessSettingsPartialUpdateApiArg = {
  patchedBusinessSettings: PatchedBusinessSettings;
};
export type BusinessSettingsHistoryListApiResponse =
  /** status 200  */ PaginatedBusinessSettingsHistoricalListRead;
export type BusinessSettingsHistoryListApiArg = {
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
};
export type BusinessSettingsHistoryRetrieveApiResponse =
  /** status 200  */ BusinessSettingsHistoricalRead;
export type BusinessSettingsHistoryRetrieveApiArg = {
  id: string;
};
export type CalendarRetrieveApiResponse = unknown;
export type CalendarRetrieveApiArg = {
  /** End Date (YYYY-MM-DD) */
  endDate: string;
  /** Start Date (YYYY-MM-DD) */
  startDate: string;
};
export type ClassReservationsListApiResponse =
  /** status 200  */ PaginatedClassReservationListRead;
export type ClassReservationsListApiArg = {
  classInstance?: number;
  /** Is expired */
  isExpired?: boolean;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  startDateTime?: string;
  /** Multiple values may be separated by commas. */
  startDateTimeRange?: string[];
  /** * `checked_out` - Checked Out
   * `checked_in` - Checked In
   * `no_show` - No Show
   * `can_check_in` - Can Check In
   * `reserved` - Reserved */
  status?:
    | "can_check_in"
    | "checked_in"
    | "checked_out"
    | "no_show"
    | "reserved";
  user?: number;
};
export type ClassReservationsRetrieveApiResponse =
  /** status 200  */ ClassReservationRead;
export type ClassReservationsRetrieveApiArg = {
  /** A unique integer value identifying this class reservation. */
  id: number;
};
export type ClassReservationsCancelReservationDestroyApiResponse = unknown;
export type ClassReservationsCancelReservationDestroyApiArg = {
  /** A unique integer value identifying this class reservation. */
  id: number;
};
export type ClassReservationsReserveClassCreateApiResponse =
  /** status 200  */ ClassReservationRead;
export type ClassReservationsReserveClassCreateApiArg = {
  reserveClass: ReserveClass;
};
export type ClassTypesListApiResponse =
  /** status 200  */ PaginatedClassTypeListRead;
export type ClassTypesListApiArg = {
  /** Multiple values may be separated by commas. */
  idIn?: number[];
  isFreeSession?: boolean;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  /** A search term. */
  search?: string;
};
export type ClassTypesCreateApiResponse = /** status 201  */ ClassTypeRead;
export type ClassTypesCreateApiArg = {
  classType: ClassType;
};
export type ClassTypesRetrieveApiResponse = /** status 200  */ ClassTypeRead;
export type ClassTypesRetrieveApiArg = {
  /** A unique integer value identifying this class type. */
  id: number;
};
export type ClassTypesUpdateApiResponse = /** status 200  */ ClassTypeRead;
export type ClassTypesUpdateApiArg = {
  /** A unique integer value identifying this class type. */
  id: number;
  classType: ClassType;
};
export type ClassTypesPartialUpdateApiResponse =
  /** status 200  */ ClassTypeRead;
export type ClassTypesPartialUpdateApiArg = {
  /** A unique integer value identifying this class type. */
  id: number;
  patchedClassType: PatchedClassType;
};
export type ClassTypesDestroyApiResponse = unknown;
export type ClassTypesDestroyApiArg = {
  /** A unique integer value identifying this class type. */
  id: number;
};
export type ClassesListApiResponse = /** status 200  */ PaginatedClassListRead;
export type ClassesListApiArg = {
  classType?: number;
  /** Multiple values may be separated by commas. */
  classTypeIn?: number[];
  coach?: number;
  /** Multiple values may be separated by commas. */
  coachIn?: number[];
  date?: string;
  /** Multiple values may be separated by commas. */
  dateRange?: string[];
  isExpired?: boolean;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  /** A search term. */
  search?: string;
};
export type ClassesRetrieveApiResponse = /** status 200  */ ClassRead;
export type ClassesRetrieveApiArg = {
  /** A unique integer value identifying this class. */
  id: number;
};
export type ClassesUpdateApiResponse = /** status 200  */ ClassRead;
export type ClassesUpdateApiArg = {
  /** A unique integer value identifying this class. */
  id: number;
  class: Class;
};
export type ClassesPartialUpdateApiResponse = /** status 200  */ ClassRead;
export type ClassesPartialUpdateApiArg = {
  /** A unique integer value identifying this class. */
  id: number;
  patchedClass: PatchedClass;
};
export type ClassesDestroyApiResponse = unknown;
export type ClassesDestroyApiArg = {
  /** A unique integer value identifying this class. */
  id: number;
};
export type ClassesAttendeesListApiResponse = /** status 200  */ UserRead[];
export type ClassesAttendeesListApiArg = {
  classType?: number;
  /** Multiple values may be separated by commas. */
  classTypeIn?: number[];
  coach?: number;
  /** Multiple values may be separated by commas. */
  coachIn?: number[];
  date?: string;
  /** Multiple values may be separated by commas. */
  dateRange?: string[];
  /** A unique integer value identifying this class. */
  id: number;
  isExpired?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A search term. */
  search?: string;
};
export type ClassesCreateClassesCreateApiResponse =
  /** status 200  */ ClassRead[];
export type ClassesCreateClassesCreateApiArg = {
  /** Which field to use when ordering the results. */
  ordering?: string;
  createClass: CreateClass;
};
export type CommunicationHistoryListApiResponse =
  /** status 200  */ PaginatedCommunicationHistoryListRead;
export type CommunicationHistoryListApiArg = {
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  user?: number;
};
export type CommunicationHistoryRetrieveApiResponse =
  /** status 200  */ CommunicationHistoryRead;
export type CommunicationHistoryRetrieveApiArg = {
  /** A unique integer value identifying this communication history. */
  id: number;
};
export type CommunicationHistoryMarkAsReadCreateApiResponse =
  /** status 200  */ CommunicationHistoryRead;
export type CommunicationHistoryMarkAsReadCreateApiArg = {
  /** A unique integer value identifying this communication history. */
  id: number;
  communicationHistory: CommunicationHistory;
};
export type CommunicationHistoryMyCommunicationHistoryListApiResponse =
  /** status 200  */ PaginatedCommunicationHistoryListRead;
export type CommunicationHistoryMyCommunicationHistoryListApiArg = {
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  user?: number;
};
export type ConnectionTestRetrieveApiResponse = /** status 200  */ ClientRead;
export type ConnectionTestRetrieveApiArg = void;
export type CountriesRetrieveApiResponse =
  /** status 200 List of countries */ Country[];
export type CountriesRetrieveApiArg = void;
export type CreditPackagesListApiResponse =
  /** status 200  */ PaginatedCreditPackageListRead;
export type CreditPackagesListApiArg = {
  appointmentType?: number;
  isActive?: boolean;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  user?: number;
};
export type CreditPackagesRetrieveApiResponse =
  /** status 200  */ CreditPackageRead;
export type CreditPackagesRetrieveApiArg = {
  /** A unique integer value identifying this credit package. */
  id: number;
};
export type CreditPackagesAddByAppointmentPackageCardCreateApiResponse =
  /** status 200  */ CheckoutSessionResponse;
export type CreditPackagesAddByAppointmentPackageCardCreateApiArg = {
  addCreditsByAppointmentPackage: AddCreditsByAppointmentPackage;
};
export type CreditPackagesAddByAppointmentPackageCashCreateApiResponse =
  /** status 200  */ CreditPackageRead;
export type CreditPackagesAddByAppointmentPackageCashCreateApiArg = {
  addCreditsByAppointmentPackage: AddCreditsByAppointmentPackage;
};
export type CreditPackagesAddCardCreateApiResponse =
  /** status 200  */ CheckoutSessionResponse;
export type CreditPackagesAddCardCreateApiArg = {
  addCredits: AddCredits;
};
export type CreditPackagesAddCashCreateApiResponse =
  /** status 200  */ CreditPackageRead;
export type CreditPackagesAddCashCreateApiArg = {
  addCredits: AddCredits;
};
export type CreditPackagesBuyByAppointmentPackageCreateApiResponse =
  /** status 200  */ PaymentIntentResponse;
export type CreditPackagesBuyByAppointmentPackageCreateApiArg = {
  buyCreditsByAppointmentPackage: BuyCreditsByAppointmentPackage;
};
export type CreditPackagesMyCreditPackagesListApiResponse =
  /** status 200  */ PaginatedCreditPackageListRead;
export type CreditPackagesMyCreditPackagesListApiArg = {
  appointmentType?: number;
  isActive?: boolean;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  user?: number;
};
export type CreditPackagesRemainingCreditsRetrieveApiResponse =
  /** status 200  */ RemainingCreditsResponse;
export type CreditPackagesRemainingCreditsRetrieveApiArg = {
  appointmentType: number;
  user: number;
};
export type CurrenciesRetrieveApiResponse =
  /** status 200 List of active currencies */ Currency[];
export type CurrenciesRetrieveApiArg = void;
export type DigitalDocumentVersionsListApiResponse =
  /** status 200  */ PaginatedDigitalDocumentVersionListRead;
export type DigitalDocumentVersionsListApiArg = {
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
};
export type DigitalDocumentVersionsRetrieveApiResponse =
  /** status 200  */ DigitalDocumentVersionRead;
export type DigitalDocumentVersionsRetrieveApiArg = {
  /** A unique integer value identifying this digital document version. */
  id: number;
};
export type DigitalDocumentsListApiResponse =
  /** status 200  */ PaginatedDigitalDocumentListRead;
export type DigitalDocumentsListApiArg = {
  /** * `person` - Person
   * `plan` - Plan
   * `appointment` - Appointment */
  attachTo?: "appointment" | "person" | "plan";
  isActive?: boolean;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  /** A search term. */
  search?: string;
  title?: string;
};
export type DigitalDocumentsCreateApiResponse =
  /** status 201  */ DigitalDocumentRead;
export type DigitalDocumentsCreateApiArg = {
  digitalDocument: DigitalDocument;
};
export type DigitalDocumentsRetrieveApiResponse =
  /** status 200  */ DigitalDocumentRead;
export type DigitalDocumentsRetrieveApiArg = {
  /** A unique integer value identifying this digital document. */
  id: number;
};
export type DigitalDocumentsUpdateApiResponse =
  /** status 200  */ DigitalDocumentRead;
export type DigitalDocumentsUpdateApiArg = {
  /** A unique integer value identifying this digital document. */
  id: number;
  digitalDocument: DigitalDocument;
};
export type DigitalDocumentsPartialUpdateApiResponse =
  /** status 200  */ DigitalDocumentRead;
export type DigitalDocumentsPartialUpdateApiArg = {
  /** A unique integer value identifying this digital document. */
  id: number;
  patchedDigitalDocument: PatchedDigitalDocument;
};
export type DigitalDocumentsDestroyApiResponse = unknown;
export type DigitalDocumentsDestroyApiArg = {
  /** A unique integer value identifying this digital document. */
  id: number;
};
export type DigitalDocumentsHistoryListApiResponse =
  /** status 200  */ PaginatedHistoricalListRead;
export type DigitalDocumentsHistoryListApiArg = {
  /** A unique integer value identifying this digital document. */
  id: number;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
};
export type DigitalDocumentsPublishPartialUpdateApiResponse =
  /** status 200  */ DigitalDocumentRead;
export type DigitalDocumentsPublishPartialUpdateApiArg = {
  /** A unique integer value identifying this digital document. */
  id: number;
  patchedDigitalDocument: PatchedDigitalDocument;
};
export type DigitalDocumentsRequiredSignaturesListApiResponse =
  /** status 200  */ PaginatedUserListRead;
export type DigitalDocumentsRequiredSignaturesListApiArg = {
  /** A unique integer value identifying this digital document. */
  id: number;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
};
export type DigitalDocumentsAllowedVariablesRetrieveApiResponse =
  /** status 200  */ DigitalDocumentAllowedVariable[];
export type DigitalDocumentsAllowedVariablesRetrieveApiArg = void;
export type DigitalDocumentsRequiredRetrieveApiResponse =
  /** status 200 List of available times */ DigitalDocumentRead[];
export type DigitalDocumentsRequiredRetrieveApiArg = void;
export type DigitalDocumentsSignCreateApiResponse =
  /** status 200  */ UserDocumentSignatureRead;
export type DigitalDocumentsSignCreateApiArg = {
  signDocument: SignDocument;
};
export type DiscountsListApiResponse =
  /** status 200  */ PaginatedDiscountListRead;
export type DiscountsListApiArg = {
  applicableToAppointments?: boolean;
  applicableToMemberships?: boolean;
  applicableToProducts?: boolean;
  isActive?: boolean;
  name?: string;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  /** A search term. */
  search?: string;
};
export type DiscountsCreateApiResponse = /** status 201  */ DiscountRead;
export type DiscountsCreateApiArg = {
  discount: Discount;
};
export type DiscountsRetrieveApiResponse = /** status 200  */ DiscountRead;
export type DiscountsRetrieveApiArg = {
  /** A unique integer value identifying this discount. */
  id: number;
};
export type DiscountsUpdateApiResponse = /** status 200  */ DiscountRead;
export type DiscountsUpdateApiArg = {
  /** A unique integer value identifying this discount. */
  id: number;
  discount: Discount;
};
export type DiscountsPartialUpdateApiResponse = /** status 200  */ DiscountRead;
export type DiscountsPartialUpdateApiArg = {
  /** A unique integer value identifying this discount. */
  id: number;
  patchedDiscount: PatchedDiscount;
};
export type DiscountsDestroyApiResponse = unknown;
export type DiscountsDestroyApiArg = {
  /** A unique integer value identifying this discount. */
  id: number;
};
export type DiscountsHistoryListApiResponse =
  /** status 200  */ PaginatedHistoricalListRead;
export type DiscountsHistoryListApiArg = {
  /** A unique integer value identifying this discount. */
  id: number;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
};
export type EmailTriggersListApiResponse =
  /** status 200  */ PaginatedEmailTriggerListRead;
export type EmailTriggersListApiArg = {
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  /** A search term. */
  search?: string;
};
export type EmailTriggersRetrieveApiResponse =
  /** status 200  */ EmailTriggerRead;
export type EmailTriggersRetrieveApiArg = {
  /** A unique integer value identifying this email trigger. */
  id: number;
};
export type EmailTriggersUpdateApiResponse =
  /** status 200  */ EmailTriggerRead;
export type EmailTriggersUpdateApiArg = {
  /** A unique integer value identifying this email trigger. */
  id: number;
  emailTrigger: EmailTrigger;
};
export type EmailTriggersPartialUpdateApiResponse =
  /** status 200  */ EmailTriggerRead;
export type EmailTriggersPartialUpdateApiArg = {
  /** A unique integer value identifying this email trigger. */
  id: number;
  patchedEmailTrigger: PatchedEmailTrigger;
};
export type EventReservationsListApiResponse =
  /** status 200  */ PaginatedEventReservationListRead;
export type EventReservationsListApiArg = {
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
};
export type EventReservationsRetrieveApiResponse =
  /** status 200  */ EventReservationRead;
export type EventReservationsRetrieveApiArg = {
  /** A unique integer value identifying this event reservation. */
  id: number;
};
export type EventReservationsCancelReservationDestroyApiResponse = unknown;
export type EventReservationsCancelReservationDestroyApiArg = {
  /** A unique integer value identifying this event reservation. */
  id: number;
};
export type EventReservationsMyReservationsListApiResponse =
  /** status 200  */ PaginatedEventReservationListRead;
export type EventReservationsMyReservationsListApiArg = {
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
};
export type EventsListApiResponse = /** status 200  */ PaginatedEventListRead;
export type EventsListApiArg = {
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  /** A search term. */
  search?: string;
  staff?: number;
  /** Multiple values may be separated by commas. */
  staffIn?: number[];
  startDate?: string;
  /** Multiple values may be separated by commas. */
  startDateRange?: string[];
};
export type EventsCreateApiResponse = /** status 201  */ EventRead;
export type EventsCreateApiArg = {
  event: Event;
};
export type EventsRetrieveApiResponse = /** status 200  */ EventRead;
export type EventsRetrieveApiArg = {
  /** A unique integer value identifying this event. */
  id: number;
};
export type EventsUpdateApiResponse = /** status 200  */ EventRead;
export type EventsUpdateApiArg = {
  /** A unique integer value identifying this event. */
  id: number;
  event: Event;
};
export type EventsPartialUpdateApiResponse = /** status 200  */ EventRead;
export type EventsPartialUpdateApiArg = {
  /** A unique integer value identifying this event. */
  id: number;
  patchedEvent: PatchedEvent;
};
export type EventsDestroyApiResponse = unknown;
export type EventsDestroyApiArg = {
  /** A unique integer value identifying this event. */
  id: number;
};
export type EventsAttendeesListApiResponse = /** status 200  */ UserRead[];
export type EventsAttendeesListApiArg = {
  /** A unique integer value identifying this event. */
  id: number;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A search term. */
  search?: string;
  staff?: number;
  /** Multiple values may be separated by commas. */
  staffIn?: number[];
  startDate?: string;
  /** Multiple values may be separated by commas. */
  startDateRange?: string[];
};
export type GroupsListApiResponse = /** status 200  */ PaginatedGroupListRead;
export type GroupsListApiArg = {
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  /** A search term. */
  search?: string;
};
export type GroupsRetrieveApiResponse = /** status 200  */ GroupRead;
export type GroupsRetrieveApiArg = {
  /** A unique integer value identifying this group. */
  id: number;
};
export type MembersListApiResponse = /** status 200  */ PaginatedUserListRead;
export type MembersListApiArg = {
  atRisk?: boolean;
  attendanceCheckOut?: string;
  attendanceCheckOutIsnull?: boolean;
  /** Multiple values may be separated by commas. */
  attendanceCheckOutRange?: string[];
  birthdayMonth?: number;
  daysUntilBirthday?: number;
  hasActiveMembership?: boolean;
  /** Multiple values may be separated by commas. */
  idIn?: number[];
  /** * `Not Imported` - Not Imported
   * `Imported` - Imported
   * `Membership Assigned` - Membership Assigned
   * `Welcome Email Sent` - Welcome Email Sent */
  migrationStatus?:
    | "Imported"
    | "Membership Assigned"
    | "Not Imported"
    | "Welcome Email Sent";
  /** Multiple values may be separated by commas. */
  migrationStatusIn?: string[];
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  /** A search term. */
  search?: string;
};
export type MembersCreateApiResponse = /** status 201  */ UserRead;
export type MembersCreateApiArg = {
  user: User;
};
export type MembersRetrieveApiResponse = /** status 200  */ UserRead;
export type MembersRetrieveApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
};
export type MembersUpdateApiResponse = /** status 200  */ UserRead;
export type MembersUpdateApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
  user: User;
};
export type MembersPartialUpdateApiResponse = /** status 200  */ UserRead;
export type MembersPartialUpdateApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
  patchedUser: PatchedUser;
};
export type MembersDestroyApiResponse = unknown;
export type MembersDestroyApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
};
export type MembersDocumentsRequiredListApiResponse =
  /** status 200  */ PaginatedDigitalDocumentListRead;
export type MembersDocumentsRequiredListApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
};
export type MembersHistoryListApiResponse =
  /** status 200  */ PaginatedHistoricalUserListRead;
export type MembersHistoryListApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
};
export type MembersAggregatedMembersCountsRetrieveApiResponse =
  /** status 200  */ AggregatedMembersCounts;
export type MembersAggregatedMembersCountsRetrieveApiArg = {
  endDate?: string;
  /** * `day` - day
   * `week` - week
   * `month` - month */
  interval: "day" | "week" | "month";
  startDate: string;
};
export type MembersAggregatedMembersCountsListListApiResponse =
  /** status 200  */ PaginatedAggregatedMembersCountsListList;
export type MembersAggregatedMembersCountsListListApiArg = {
  endDate?: string;
  /** * `day` - day
   * `week` - week
   * `month` - month */
  interval: "day" | "week" | "month";
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  startDate: string;
};
export type MembersAssignMembershipCreateApiResponse =
  /** status 200  */ UserMembershipRead;
export type MembersAssignMembershipCreateApiArg = {
  assignMembership: AssignMembership;
};
export type MembersDownloadCsvRetrieveApiResponse = /** status 200  */ Blob;
export type MembersDownloadCsvRetrieveApiArg = {
  atRisk?: boolean;
  attendanceCheckOut?: string;
  attendanceCheckOutIsnull?: boolean;
  /** Multiple values may be separated by commas. */
  attendanceCheckOutRange?: string[];
  birthdayMonth?: number;
  daysUntilBirthday?: number;
  hasActiveMembership?: boolean;
  /** Multiple values may be separated by commas. */
  idIn?: number[];
  /** * `Not Imported` - Not Imported
   * `Imported` - Imported
   * `Membership Assigned` - Membership Assigned
   * `Welcome Email Sent` - Welcome Email Sent */
  migrationStatus?:
    | "Imported"
    | "Membership Assigned"
    | "Not Imported"
    | "Welcome Email Sent";
  /** Multiple values may be separated by commas. */
  migrationStatusIn?: string[];
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A search term. */
  search?: string;
};
export type MembersDownloadTemplateRetrieveApiResponse = unknown;
export type MembersDownloadTemplateRetrieveApiArg = void;
export type MembersImportUsersCreateApiResponse =
  /** status 201  */ ImportUser[];
export type MembersImportUsersCreateApiArg = {
  body: ImportUser[];
};
export type MembersMyNotificationPreferencesRetrieveApiResponse =
  /** status 200  */ NotificationPreference;
export type MembersMyNotificationPreferencesRetrieveApiArg = void;
export type MembersMyNotificationPreferencesPartialUpdateApiResponse =
  /** status 200  */ NotificationPreference;
export type MembersMyNotificationPreferencesPartialUpdateApiArg = {
  patchedNotificationPreference: PatchedNotificationPreference;
};
export type MembersReferralSourcesPercentageRetrieveApiResponse = unknown;
export type MembersReferralSourcesPercentageRetrieveApiArg = {
  endDate?: string;
  startDate: string;
};
export type MembersSendEmailToAllCreateApiResponse = /** status 200  */ Blob;
export type MembersSendEmailToAllCreateApiArg = {
  atRisk?: boolean;
  attendanceCheckOut?: string;
  attendanceCheckOutIsnull?: boolean;
  /** Multiple values may be separated by commas. */
  attendanceCheckOutRange?: string[];
  birthdayMonth?: number;
  daysUntilBirthday?: number;
  hasActiveMembership?: boolean;
  /** Multiple values may be separated by commas. */
  idIn?: number[];
  /** * `Not Imported` - Not Imported
   * `Imported` - Imported
   * `Membership Assigned` - Membership Assigned
   * `Welcome Email Sent` - Welcome Email Sent */
  migrationStatus?:
    | "Imported"
    | "Membership Assigned"
    | "Not Imported"
    | "Welcome Email Sent";
  /** Multiple values may be separated by commas. */
  migrationStatusIn?: string[];
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A search term. */
  search?: string;
  emailBroadcast: EmailBroadcast;
};
export type MembersSendNotificationCreateApiResponse = /** status 200  */ Blob;
export type MembersSendNotificationCreateApiArg = {
  notification: Notification;
};
export type MembersSendWelcomeEmailCreateApiResponse = /** status 200  */ {
  [key: string]: any;
};
export type MembersSendWelcomeEmailCreateApiArg = {
  sendWelcomeEmail: SendWelcomeEmail;
};
export type MembershipPlansListApiResponse =
  /** status 200  */ PaginatedMembershipPlanListRead;
export type MembershipPlansListApiArg = {
  /** Multiple values may be separated by commas. */
  idIn?: number[];
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  /** * `recurring` - Recurring
   * `non_recurring` - Non-recurring
   * `punchcard` - Punchcard */
  planType?: "non_recurring" | "punchcard" | "recurring";
  /** A search term. */
  search?: string;
  showOnMemberApp?: boolean;
};
export type MembershipPlansCreateApiResponse =
  /** status 201  */ MembershipPlanRead;
export type MembershipPlansCreateApiArg = {
  membershipPlan: MembershipPlan;
};
export type MembershipPlansRetrieveApiResponse =
  /** status 200  */ MembershipPlanRead;
export type MembershipPlansRetrieveApiArg = {
  /** A unique integer value identifying this membership plan. */
  id: number;
};
export type MembershipPlansUpdateApiResponse =
  /** status 200  */ MembershipPlanRead;
export type MembershipPlansUpdateApiArg = {
  /** A unique integer value identifying this membership plan. */
  id: number;
  membershipPlan: MembershipPlan;
};
export type MembershipPlansPartialUpdateApiResponse =
  /** status 200  */ MembershipPlanRead;
export type MembershipPlansPartialUpdateApiArg = {
  /** A unique integer value identifying this membership plan. */
  id: number;
  patchedMembershipPlan: PatchedMembershipPlan;
};
export type MembershipPlansDestroyApiResponse = unknown;
export type MembershipPlansDestroyApiArg = {
  /** A unique integer value identifying this membership plan. */
  id: number;
};
export type MembershipPlansArchiveRetrieveApiResponse =
  /** status 200  */ MembershipPlanRead;
export type MembershipPlansArchiveRetrieveApiArg = {
  /** A unique integer value identifying this membership plan. */
  id: number;
};
export type MembershipPlansDownloadMembersCsvRetrieveApiResponse =
  /** status 200  */ Blob;
export type MembershipPlansDownloadMembersCsvRetrieveApiArg = {
  /** A unique integer value identifying this membership plan. */
  id: number;
};
export type MembershipPlansHistoryListApiResponse =
  /** status 200  */ PaginatedHistoricalMembershipPlanListRead;
export type MembershipPlansHistoryListApiArg = {
  /** A unique integer value identifying this membership plan. */
  id: number;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
};
export type MembershipPlansSendEmailToAllCreateApiResponse =
  /** status 200  */ Blob;
export type MembershipPlansSendEmailToAllCreateApiArg = {
  /** A unique integer value identifying this membership plan. */
  id: number;
  emailBroadcast: EmailBroadcast;
};
export type MembershipPlansUnarchiveRetrieveApiResponse =
  /** status 200  */ MembershipPlanRead;
export type MembershipPlansUnarchiveRetrieveApiArg = {
  /** A unique integer value identifying this membership plan. */
  id: number;
};
export type MembershipPlansArchivedListApiResponse =
  /** status 200  */ PaginatedMembershipPlanListRead;
export type MembershipPlansArchivedListApiArg = {
  /** Multiple values may be separated by commas. */
  idIn?: number[];
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  /** * `recurring` - Recurring
   * `non_recurring` - Non-recurring
   * `punchcard` - Punchcard */
  planType?: "non_recurring" | "punchcard" | "recurring";
  /** A search term. */
  search?: string;
  showOnMemberApp?: boolean;
};
export type PaymentsAddressRetrieveApiResponse = /** status 200  */ AddressRead;
export type PaymentsAddressRetrieveApiArg = {
  /** A unique integer value identifying this address. */
  id: number;
};
export type PaymentsAggregatedRevenueRetrieveApiResponse =
  /** status 200  */ AggregatedRevenue;
export type PaymentsAggregatedRevenueRetrieveApiArg = {
  endDate?: string;
  /** * `day` - day
   * `week` - week
   * `month` - month */
  interval: "day" | "week" | "month";
  startDate: string;
};
export type PaymentsAggregatedRevenueListListApiResponse =
  /** status 200  */ PaginatedAggregatedRevenueListList;
export type PaymentsAggregatedRevenueListListApiArg = {
  endDate?: string;
  /** * `day` - day
   * `week` - week
   * `month` - month */
  interval: "day" | "week" | "month";
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  startDate: string;
};
export type PaymentsInvoiceItemTaxesListApiResponse =
  /** status 200  */ PaginatedInvoiceItemTaxListRead;
export type PaymentsInvoiceItemTaxesListApiArg = {
  id?: number;
  /** Multiple values may be separated by commas. */
  idIn?: number[];
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
};
export type PaymentsInvoiceItemTaxesRetrieveApiResponse =
  /** status 200  */ InvoiceItemTaxRead;
export type PaymentsInvoiceItemTaxesRetrieveApiArg = {
  /** A unique integer value identifying this invoice item tax. */
  id: number;
};
export type PaymentsInvoiceItemsListApiResponse =
  /** status 200  */ PaginatedInvoiceItemPolymorphicListRead;
export type PaymentsInvoiceItemsListApiArg = {
  invoice?: string;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
};
export type PaymentsInvoiceItemsCreateApiResponse =
  /** status 201  */ InvoiceItemPolymorphicRead;
export type PaymentsInvoiceItemsCreateApiArg = {
  invoiceItemPolymorphic: InvoiceItemPolymorphic;
};
export type PaymentsInvoiceItemsRetrieveApiResponse =
  /** status 200  */ InvoiceItemPolymorphicRead;
export type PaymentsInvoiceItemsRetrieveApiArg = {
  /** A unique value identifying this invoice item. */
  id: string;
};
export type PaymentsInvoiceItemsDestroyApiResponse = unknown;
export type PaymentsInvoiceItemsDestroyApiArg = {
  /** A unique value identifying this invoice item. */
  id: string;
};
export type PaymentsInvoicesListApiResponse =
  /** status 200  */ PaginatedInvoiceListRead;
export type PaymentsInvoicesListApiArg = {
  appointmentDiscount?: number;
  customer?: number;
  discount?: number;
  isForgiven?: boolean;
  isOpen?: boolean;
  isPaid?: boolean;
  membershipDiscount?: number;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  productDiscount?: number;
  /** A search term. */
  search?: string;
};
export type PaymentsInvoicesCreateApiResponse = /** status 201  */ InvoiceRead;
export type PaymentsInvoicesCreateApiArg = {
  invoice: Invoice;
};
export type PaymentsInvoicesRetrieveApiResponse =
  /** status 200  */ InvoiceRead;
export type PaymentsInvoicesRetrieveApiArg = {
  /** A unique value identifying this invoice. */
  id: string;
};
export type PaymentsInvoicesDestroyApiResponse = unknown;
export type PaymentsInvoicesDestroyApiArg = {
  /** A unique value identifying this invoice. */
  id: string;
};
export type PaymentsInvoicesForgiveInvoicePartialUpdateApiResponse =
  /** status 200  */ InvoiceRead;
export type PaymentsInvoicesForgiveInvoicePartialUpdateApiArg = {
  /** A unique value identifying this invoice. */
  id: string;
  patchedInvoice: PatchedInvoice;
};
export type PaymentsInvoicesGeneratePdfRetrieveApiResponse =
  /** status 200  */ InvoiceRead;
export type PaymentsInvoicesGeneratePdfRetrieveApiArg = {
  /** A unique value identifying this invoice. */
  id: string;
};
export type PaymentsInvoicesHistoryListApiResponse =
  /** status 200  */ PaginatedHistoricalListRead;
export type PaymentsInvoicesHistoryListApiArg = {
  /** A unique value identifying this invoice. */
  id: string;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
};
export type PaymentsInvoicesPayCreateApiResponse = /** status 200  */ Payment;
export type PaymentsInvoicesPayCreateApiArg = {
  /** A unique value identifying this invoice. */
  id: string;
  payment: Payment;
};
export type PaymentsInvoicesRefundCreateApiResponse =
  /** status 200  */ Payment;
export type PaymentsInvoicesRefundCreateApiArg = {
  /** A unique value identifying this invoice. */
  id: string;
  payment: Payment;
};
export type PaymentsInvoicesRefundCardCreateApiResponse =
  /** status 200  */ InvoiceRead;
export type PaymentsInvoicesRefundCardCreateApiArg = {
  /** A unique value identifying this invoice. */
  id: string;
  invoice: Invoice;
};
export type PaymentsInvoicesUpdateShippingAddressPartialUpdateApiResponse =
  /** status 200  */ AddressRead;
export type PaymentsInvoicesUpdateShippingAddressPartialUpdateApiArg = {
  /** A unique value identifying this invoice. */
  id: string;
  patchedAddress: PatchedAddress;
};
export type PaymentsInvoicesMyInvoicesListApiResponse =
  /** status 200  */ PaginatedInvoiceListRead;
export type PaymentsInvoicesMyInvoicesListApiArg = {
  appointmentDiscount?: number;
  customer?: number;
  discount?: number;
  isForgiven?: boolean;
  isOpen?: boolean;
  isPaid?: boolean;
  membershipDiscount?: number;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  productDiscount?: number;
  /** A search term. */
  search?: string;
};
export type PaymentsProductInvoiceItemsListApiResponse =
  /** status 200  */ PaginatedProductInvoiceItemListRead;
export type PaymentsProductInvoiceItemsListApiArg = {
  invoice?: string;
  invoiceIsPaid?: boolean;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  productOptionProduct?: number;
};
export type PaymentsProductInvoiceItemsCreateApiResponse =
  /** status 201  */ ProductInvoiceItemRead;
export type PaymentsProductInvoiceItemsCreateApiArg = {
  productInvoiceItem: ProductInvoiceItem;
};
export type PaymentsProductInvoiceItemsRetrieveApiResponse =
  /** status 200  */ ProductInvoiceItemRead;
export type PaymentsProductInvoiceItemsRetrieveApiArg = {
  /** A unique value identifying this product invoice item. */
  id: string;
};
export type PaymentsProductInvoiceItemsDestroyApiResponse = unknown;
export type PaymentsProductInvoiceItemsDestroyApiArg = {
  /** A unique value identifying this product invoice item. */
  id: string;
};
export type PaymentsProductRevenuePerIntervalRetrieveApiResponse =
  /** status 200  */ ProductRevenuePerInterval;
export type PaymentsProductRevenuePerIntervalRetrieveApiArg = {
  endDate?: string;
  /** * `day` - day
   * `week` - week
   * `month` - month */
  interval: "day" | "week" | "month";
  productId?: string;
  startDate: string;
};
export type PaymentsProductSalesCountPerIntervalRetrieveApiResponse =
  /** status 200  */ ProductSalesCountPerInterval;
export type PaymentsProductSalesCountPerIntervalRetrieveApiArg = {
  endDate?: string;
  /** * `day` - day
   * `week` - week
   * `month` - month */
  interval: "day" | "week" | "month";
  productId?: string;
  startDate: string;
};
export type PaymentsTransactionsListApiResponse =
  /** status 200  */ PaginatedTransactionListRead;
export type PaymentsTransactionsListApiArg = {
  dateTime?: string;
  dateTimeDateRangeAfter?: string;
  dateTimeDateRangeBefore?: string;
  dateTimeGt?: string;
  dateTimeLt?: string;
  /** Multiple values may be separated by commas. */
  dateTimeRange?: string[];
  invoice?: string;
  invoiceCustomer?: number;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
};
export type PaymentsTransactionsRetrieveApiResponse =
  /** status 200  */ TransactionRead;
export type PaymentsTransactionsRetrieveApiArg = {
  /** A unique value identifying this transaction. */
  id: string;
};
export type PaymentsTransactionsMyTransactionsListApiResponse =
  /** status 200  */ PaginatedTransactionListRead;
export type PaymentsTransactionsMyTransactionsListApiArg = {
  dateTime?: string;
  dateTimeDateRangeAfter?: string;
  dateTimeDateRangeBefore?: string;
  dateTimeGt?: string;
  dateTimeLt?: string;
  /** Multiple values may be separated by commas. */
  dateTimeRange?: string[];
  invoice?: string;
  invoiceCustomer?: number;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
};
export type PaymentsTransactionsReportRetrieveApiResponse =
  /** status 200  */ TransactionReport;
export type PaymentsTransactionsReportRetrieveApiArg = {
  endDate?: string;
  startDate: string;
};
export type PolicyDocumentsListApiResponse =
  /** status 200  */ PaginatedPolicyDocumentListRead;
export type PolicyDocumentsListApiArg = {
  isActive?: boolean;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  /** A search term. */
  search?: string;
  title?: string;
};
export type PolicyDocumentsCreateApiResponse =
  /** status 201  */ PolicyDocumentRead;
export type PolicyDocumentsCreateApiArg = {
  policyDocument: PolicyDocument;
};
export type PolicyDocumentsRetrieveApiResponse =
  /** status 200  */ PolicyDocumentRead;
export type PolicyDocumentsRetrieveApiArg = {
  /** A unique integer value identifying this policy document. */
  id: number;
};
export type PolicyDocumentsUpdateApiResponse =
  /** status 200  */ PolicyDocumentRead;
export type PolicyDocumentsUpdateApiArg = {
  /** A unique integer value identifying this policy document. */
  id: number;
  policyDocument: PolicyDocument;
};
export type PolicyDocumentsPartialUpdateApiResponse =
  /** status 200  */ PolicyDocumentRead;
export type PolicyDocumentsPartialUpdateApiArg = {
  /** A unique integer value identifying this policy document. */
  id: number;
  patchedPolicyDocument: PatchedPolicyDocument;
};
export type PolicyDocumentsDestroyApiResponse = unknown;
export type PolicyDocumentsDestroyApiArg = {
  /** A unique integer value identifying this policy document. */
  id: number;
};
export type ProductCategoriesListApiResponse =
  /** status 200  */ PaginatedProductCategoryListRead;
export type ProductCategoriesListApiArg = {
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  /** A search term. */
  search?: string;
};
export type ProductCategoriesCreateApiResponse =
  /** status 201  */ ProductCategoryRead;
export type ProductCategoriesCreateApiArg = {
  productCategory: ProductCategory;
};
export type ProductCategoriesRetrieveApiResponse =
  /** status 200  */ ProductCategoryRead;
export type ProductCategoriesRetrieveApiArg = {
  /** A unique integer value identifying this product category. */
  id: number;
};
export type ProductCategoriesUpdateApiResponse =
  /** status 200  */ ProductCategoryRead;
export type ProductCategoriesUpdateApiArg = {
  /** A unique integer value identifying this product category. */
  id: number;
  productCategory: ProductCategory;
};
export type ProductCategoriesPartialUpdateApiResponse =
  /** status 200  */ ProductCategoryRead;
export type ProductCategoriesPartialUpdateApiArg = {
  /** A unique integer value identifying this product category. */
  id: number;
  patchedProductCategory: PatchedProductCategory;
};
export type ProductCategoriesDestroyApiResponse = unknown;
export type ProductCategoriesDestroyApiArg = {
  /** A unique integer value identifying this product category. */
  id: number;
};
export type ProductImagesListApiResponse =
  /** status 200  */ PaginatedProductImageListRead;
export type ProductImagesListApiArg = {
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  product?: number;
};
export type ProductImagesCreateApiResponse =
  /** status 201  */ ProductImageRead;
export type ProductImagesCreateApiArg = {
  productImage: ProductImage;
};
export type ProductImagesRetrieveApiResponse =
  /** status 200  */ ProductImageRead;
export type ProductImagesRetrieveApiArg = {
  /** A unique integer value identifying this product image. */
  id: number;
};
export type ProductImagesUpdateApiResponse =
  /** status 200  */ ProductImageRead;
export type ProductImagesUpdateApiArg = {
  /** A unique integer value identifying this product image. */
  id: number;
  productImage: ProductImage;
};
export type ProductImagesPartialUpdateApiResponse =
  /** status 200  */ ProductImageRead;
export type ProductImagesPartialUpdateApiArg = {
  /** A unique integer value identifying this product image. */
  id: number;
  patchedProductImage: PatchedProductImage;
};
export type ProductImagesDestroyApiResponse = unknown;
export type ProductImagesDestroyApiArg = {
  /** A unique integer value identifying this product image. */
  id: number;
};
export type ProductOptionsListApiResponse =
  /** status 200  */ PaginatedProductOptionListRead;
export type ProductOptionsListApiArg = {
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  product?: number;
  /** A search term. */
  search?: string;
};
export type ProductOptionsCreateApiResponse =
  /** status 201  */ ProductOptionRead;
export type ProductOptionsCreateApiArg = {
  productOption: ProductOption;
};
export type ProductOptionsRetrieveApiResponse =
  /** status 200  */ ProductOptionRead;
export type ProductOptionsRetrieveApiArg = {
  /** A unique integer value identifying this product option. */
  id: number;
};
export type ProductOptionsUpdateApiResponse =
  /** status 200  */ ProductOptionRead;
export type ProductOptionsUpdateApiArg = {
  /** A unique integer value identifying this product option. */
  id: number;
  productOption: ProductOption;
};
export type ProductOptionsPartialUpdateApiResponse =
  /** status 200  */ ProductOptionRead;
export type ProductOptionsPartialUpdateApiArg = {
  /** A unique integer value identifying this product option. */
  id: number;
  patchedProductOption: PatchedProductOption;
};
export type ProductOptionsDestroyApiResponse = unknown;
export type ProductOptionsDestroyApiArg = {
  /** A unique integer value identifying this product option. */
  id: number;
};
export type ProductsListApiResponse =
  /** status 200  */ PaginatedProductListRead;
export type ProductsListApiArg = {
  category?: number;
  /** Multiple values may be separated by commas. */
  idIn?: number[];
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  /** A search term. */
  search?: string;
  /** * `active` - Active
   * `draft` - Draft */
  status?: "active" | "draft";
};
export type ProductsCreateApiResponse = /** status 201  */ ProductRead;
export type ProductsCreateApiArg = {
  product: Product;
};
export type ProductsRetrieveApiResponse = /** status 200  */ ProductRead;
export type ProductsRetrieveApiArg = {
  /** A unique integer value identifying this product. */
  id: number;
};
export type ProductsUpdateApiResponse = /** status 200  */ ProductRead;
export type ProductsUpdateApiArg = {
  /** A unique integer value identifying this product. */
  id: number;
  product: Product;
};
export type ProductsPartialUpdateApiResponse = /** status 200  */ ProductRead;
export type ProductsPartialUpdateApiArg = {
  /** A unique integer value identifying this product. */
  id: number;
  patchedProduct: PatchedProduct;
};
export type ProductsDestroyApiResponse = unknown;
export type ProductsDestroyApiArg = {
  /** A unique integer value identifying this product. */
  id: number;
};
export type ProductsHistoryListApiResponse =
  /** status 200  */ PaginatedHistoricalListRead;
export type ProductsHistoryListApiArg = {
  /** A unique integer value identifying this product. */
  id: number;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
};
export type ReservationsListApiResponse =
  /** status 200  */ PaginatedReservationPolymorphicListRead;
export type ReservationsListApiArg = {
  isExpired?: boolean;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  user?: number;
};
export type ReservationsRetrieveApiResponse =
  /** status 200  */ ReservationPolymorphicRead;
export type ReservationsRetrieveApiArg = {
  /** A unique integer value identifying this reservation. */
  id: number;
};
export type ReservationsCancelReservationDestroyApiResponse =
  /** status 200  */ ReservationPolymorphicRead;
export type ReservationsCancelReservationDestroyApiArg = {
  /** A unique integer value identifying this reservation. */
  id: number;
};
export type ReservationsMyReservationsListApiResponse =
  /** status 200  */ PaginatedReservationPolymorphicListRead;
export type ReservationsMyReservationsListApiArg = {
  isExpired?: boolean;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  user?: number;
};
export type ReserveEventCreateApiResponse =
  /** status 200  */ EventReservationRead;
export type ReserveEventCreateApiArg = {
  reserveEvent: ReserveEvent;
};
export type SchemaRetrieveApiResponse = /** status 200  */ {
  [key: string]: any;
};
export type SchemaRetrieveApiArg = {
  lang?: "bs" | "en";
};
export type SettingsRegisterPushTokenCreateApiResponse = unknown;
export type SettingsRegisterPushTokenCreateApiArg = {
  registerPushTokenParameters: RegisterPushTokenParameters;
};
export type StripePaymentsCheckoutSuccessCreateApiResponse = unknown;
export type StripePaymentsCheckoutSuccessCreateApiArg = {
  checkoutSuccessCreate: CheckoutSuccessCreate;
};
export type StripePaymentsCreateCustomerPortalCreateApiResponse =
  /** status 200  */ CreateCustomerPortalSession;
export type StripePaymentsCreateCustomerPortalCreateApiArg = void;
export type StripePaymentsCreateFinegymCheckoutSessionCreateApiResponse =
  /** status 200  */ CreateFinegymCheckoutSessionResponse;
export type StripePaymentsCreateFinegymCheckoutSessionCreateApiArg = {
  createFinegymCheckoutSession: CreateFinegymCheckoutSession;
};
export type StripePaymentsCreateInvoiceCheckoutSessionCreateApiResponse =
  /** status 200  */ CreateInvoiceCheckoutSessionResponse;
export type StripePaymentsCreateInvoiceCheckoutSessionCreateApiArg = {
  createInvoiceCheckoutSession: CreateInvoiceCheckoutSession;
};
export type StripePaymentsCreateInvoicePaymentIntentCreateApiResponse =
  /** status 200  */ CreateInvoicePaymentIntentResponse;
export type StripePaymentsCreateInvoicePaymentIntentCreateApiArg = {
  createInvoicePaymentIntent: CreateInvoicePaymentIntent;
};
export type StripePaymentsCreateStripeAccountCreateApiResponse =
  /** status 200  */ CreateStripeAccount;
export type StripePaymentsCreateStripeAccountCreateApiArg = {
  createStripeAccountType: CreateStripeAccountType;
};
export type StripePaymentsCurrentAccountRetrieveApiResponse =
  /** status 200  */ StripeAccount;
export type StripePaymentsCurrentAccountRetrieveApiArg = void;
export type StripePaymentsCustomerRetrieveApiResponse =
  /** status 200  */ StripeCustomer;
export type StripePaymentsCustomerRetrieveApiArg = void;
export type StripePaymentsInvoicesListApiResponse =
  /** status 200  */ StripeInvoice[];
export type StripePaymentsInvoicesListApiArg = {
  /** Which field to use when ordering the results. */
  ordering?: string;
};
export type StripePaymentsInvoicesRetrieveApiResponse =
  /** status 200  */ StripeInvoice;
export type StripePaymentsInvoicesRetrieveApiArg = {
  id: string;
};
export type StripePaymentsInvoicesUpcomingRetrieveApiResponse =
  /** status 200  */ StripeUpcomingInvoice;
export type StripePaymentsInvoicesUpcomingRetrieveApiArg = {
  /** The ID of the subscription for which to retrieve the upcoming invoice. */
  subscriptionId: string;
};
export type StripePaymentsPlansListApiResponse =
  /** status 200  */ StripePlan[];
export type StripePaymentsPlansListApiArg = {
  /** Which field to use when ordering the results. */
  ordering?: string;
};
export type StripePaymentsPlansRetrieveApiResponse =
  /** status 200  */ StripePlan;
export type StripePaymentsPlansRetrieveApiArg = {
  id: string;
};
export type StripePaymentsPricesListApiResponse =
  /** status 200  */ StripePrice[];
export type StripePaymentsPricesListApiArg = {
  /** Which field to use when ordering the results. */
  ordering?: string;
};
export type StripePaymentsPricesRetrieveApiResponse =
  /** status 200  */ StripePrice;
export type StripePaymentsPricesRetrieveApiArg = {
  id: string;
};
export type StripePaymentsProductsListApiResponse =
  /** status 200  */ StripeProduct[];
export type StripePaymentsProductsListApiArg = {
  /** Which field to use when ordering the results. */
  ordering?: string;
};
export type StripePaymentsProductsRetrieveApiResponse =
  /** status 200  */ StripeProduct;
export type StripePaymentsProductsRetrieveApiArg = {
  id: string;
};
export type StripePaymentsSessionRetrieveCreateApiResponse =
  /** status 200  */ Session;
export type StripePaymentsSessionRetrieveCreateApiArg = {
  sessionRetrieve: SessionRetrieve;
};
export type StripePaymentsSubscriptionRetrieveApiResponse =
  /** status 200  */ StripeSubscription;
export type StripePaymentsSubscriptionRetrieveApiArg = void;
export type TaxRatesListApiResponse =
  /** status 200  */ PaginatedTaxRateListRead;
export type TaxRatesListApiArg = {
  isArchived?: boolean;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  /** A search term. */
  search?: string;
};
export type TaxRatesCreateApiResponse = /** status 200  */ TaxRateRead;
export type TaxRatesCreateApiArg = {
  taxRate: TaxRate;
};
export type TaxRatesRetrieveApiResponse = /** status 200  */ TaxRateRead;
export type TaxRatesRetrieveApiArg = {
  /** A unique integer value identifying this tax rate. */
  id: number;
};
export type TaxRatesUpdateApiResponse = /** status 200  */ TaxRateRead;
export type TaxRatesUpdateApiArg = {
  /** A unique integer value identifying this tax rate. */
  id: number;
  taxRate: TaxRate;
};
export type TaxRatesPartialUpdateApiResponse = /** status 200  */ TaxRateRead;
export type TaxRatesPartialUpdateApiArg = {
  /** A unique integer value identifying this tax rate. */
  id: number;
  patchedTaxRate: PatchedTaxRate;
};
export type TaxRatesArchiveCreateApiResponse = /** status 200  */ TaxRateRead;
export type TaxRatesArchiveCreateApiArg = {
  /** A unique integer value identifying this tax rate. */
  id: number;
  taxRate: TaxRate;
};
export type TaxRatesUnarchiveCreateApiResponse = /** status 200  */ TaxRateRead;
export type TaxRatesUnarchiveCreateApiArg = {
  /** A unique integer value identifying this tax rate. */
  id: number;
  taxRate: TaxRate;
};
export type TimezonesRetrieveApiResponse =
  /** status 200 List of timezones */ TimeZone[];
export type TimezonesRetrieveApiArg = {
  countryCode?: string;
};
export type UserDocumentSignaturesListApiResponse =
  /** status 200  */ PaginatedUserDocumentSignatureListRead;
export type UserDocumentSignaturesListApiArg = {
  documentVersionDocument?: number;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  user?: number;
};
export type UserDocumentSignaturesRetrieveApiResponse =
  /** status 200  */ UserDocumentSignatureRead;
export type UserDocumentSignaturesRetrieveApiArg = {
  /** A unique integer value identifying this user document signature. */
  id: number;
};
export type UserDocumentSignaturesGenerateSignedDocumentRetrieveApiResponse =
  /** status 200  */ UserDocumentSignatureRead;
export type UserDocumentSignaturesGenerateSignedDocumentRetrieveApiArg = {
  /** A unique integer value identifying this user document signature. */
  id: number;
};
export type UserDocumentSignaturesAdminSignCreateApiResponse =
  /** status 200  */ UserDocumentSignatureRead;
export type UserDocumentSignaturesAdminSignCreateApiArg = {
  adminSignDocument: AdminSignDocument;
};
export type UserMembershipAttendancesListApiResponse =
  /** status 200  */ PaginatedUserMembershipAttendanceListRead;
export type UserMembershipAttendancesListApiArg = {
  checkIn?: string;
  /** Multiple values may be separated by commas. */
  checkInRange?: string[];
  checkInDate?: string;
  checkOut?: string;
  checkOutIsnull?: boolean;
  /** Multiple values may be separated by commas. */
  checkOutRange?: string[];
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  user?: number;
  userMembership?: number;
};
export type UserMembershipAttendancesRetrieveApiResponse =
  /** status 200  */ UserMembershipAttendanceRead;
export type UserMembershipAttendancesRetrieveApiArg = {
  /** A unique integer value identifying this user membership attendance. */
  id: number;
};
export type UserMembershipsListApiResponse =
  /** status 200  */ PaginatedUserMembershipListRead;
export type UserMembershipsListApiArg = {
  discount?: number;
  isActive?: boolean;
  isArchived?: boolean;
  membershipPlan?: number;
  membershipPlanAllowCheckIns?: boolean;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  user?: number;
};
export type UserMembershipsRetrieveApiResponse =
  /** status 200  */ UserMembershipRead;
export type UserMembershipsRetrieveApiArg = {
  /** A unique integer value identifying this user membership. */
  id: number;
};
export type UserMembershipsArchiveUpdateApiResponse =
  /** status 200  */ UserMembershipRead;
export type UserMembershipsArchiveUpdateApiArg = {
  /** A unique integer value identifying this user membership. */
  id: number;
  archiveUserMembership: ArchiveUserMembership;
};
export type UserMembershipsChangeDiscountPartialUpdateApiResponse =
  /** status 200  */ UserMembershipRead;
export type UserMembershipsChangeDiscountPartialUpdateApiArg = {
  /** A unique integer value identifying this user membership. */
  id: number;
  patchedChangeDiscount: PatchedChangeDiscount;
};
export type UserMembershipsChangeNextBillDatePartialUpdateApiResponse =
  /** status 200  */ UserMembershipRead;
export type UserMembershipsChangeNextBillDatePartialUpdateApiArg = {
  /** A unique integer value identifying this user membership. */
  id: number;
  patchedChangeNextBillDate: PatchedChangeNextBillDate;
};
export type UserMembershipsChangePaymentTypePartialUpdateApiResponse =
  /** status 200  */ UserMembershipRead;
export type UserMembershipsChangePaymentTypePartialUpdateApiArg = {
  /** A unique integer value identifying this user membership. */
  id: number;
  patchedChangePaymentType: PatchedChangePaymentType;
};
export type UserMembershipsAddMembershipCardCreateApiResponse =
  /** status 200  */ CheckoutSessionResponse;
export type UserMembershipsAddMembershipCardCreateApiArg = {
  addUserMembership: AddUserMembership;
};
export type UserMembershipsAddMembershipCashCreateApiResponse =
  /** status 200  */ UserMembershipRead;
export type UserMembershipsAddMembershipCashCreateApiArg = {
  addUserMembership: AddUserMembership;
};
export type UserMembershipsBuyMembershipCreateApiResponse =
  /** status 200  */ BuyMembershipResponse;
export type UserMembershipsBuyMembershipCreateApiArg = {
  buyMembership: BuyMembership;
};
export type UserMembershipsMyMembershipsListApiResponse =
  /** status 200  */ PaginatedUserMembershipListRead;
export type UserMembershipsMyMembershipsListApiArg = {
  discount?: number;
  isActive?: boolean;
  isArchived?: boolean;
  membershipPlan?: number;
  membershipPlanAllowCheckIns?: boolean;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  user?: number;
};
export type UserNoteTagsListApiResponse =
  /** status 200  */ PaginatedUserNoteTagListRead;
export type UserNoteTagsListApiArg = {
  /** Multiple values may be separated by commas. */
  idIn?: number[];
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  /** A search term. */
  search?: string;
};
export type UserNoteTagsCreateApiResponse = /** status 201  */ UserNoteTagRead;
export type UserNoteTagsCreateApiArg = {
  userNoteTag: UserNoteTag;
};
export type UserNoteTagsRetrieveApiResponse =
  /** status 200  */ UserNoteTagRead;
export type UserNoteTagsRetrieveApiArg = {
  /** A unique integer value identifying this user note tag. */
  id: number;
};
export type UserNoteTagsUpdateApiResponse = /** status 200  */ UserNoteTagRead;
export type UserNoteTagsUpdateApiArg = {
  /** A unique integer value identifying this user note tag. */
  id: number;
  userNoteTag: UserNoteTag;
};
export type UserNoteTagsPartialUpdateApiResponse =
  /** status 200  */ UserNoteTagRead;
export type UserNoteTagsPartialUpdateApiArg = {
  /** A unique integer value identifying this user note tag. */
  id: number;
  patchedUserNoteTag: PatchedUserNoteTag;
};
export type UserNoteTagsDestroyApiResponse = unknown;
export type UserNoteTagsDestroyApiArg = {
  /** A unique integer value identifying this user note tag. */
  id: number;
};
export type UserNotesListApiResponse =
  /** status 200  */ PaginatedUserNoteListRead;
export type UserNotesListApiArg = {
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  /** A search term. */
  search?: string;
  user?: number;
};
export type UserNotesCreateApiResponse = /** status 201  */ UserNoteRead;
export type UserNotesCreateApiArg = {
  userNote: UserNote;
};
export type UserNotesRetrieveApiResponse = /** status 200  */ UserNoteRead;
export type UserNotesRetrieveApiArg = {
  /** A unique integer value identifying this user note. */
  id: number;
};
export type UserNotesUpdateApiResponse = /** status 200  */ UserNoteRead;
export type UserNotesUpdateApiArg = {
  /** A unique integer value identifying this user note. */
  id: number;
  userNote: UserNote;
};
export type UserNotesPartialUpdateApiResponse = /** status 200  */ UserNoteRead;
export type UserNotesPartialUpdateApiArg = {
  /** A unique integer value identifying this user note. */
  id: number;
  patchedUserNote: PatchedUserNote;
};
export type UserNotesDestroyApiResponse = unknown;
export type UserNotesDestroyApiArg = {
  /** A unique integer value identifying this user note. */
  id: number;
};
export type UsersListApiResponse = /** status 200  */ PaginatedUserListRead;
export type UsersListApiArg = {
  atRisk?: boolean;
  attendanceCheckOut?: string;
  attendanceCheckOutIsnull?: boolean;
  /** Multiple values may be separated by commas. */
  attendanceCheckOutRange?: string[];
  birthdayMonth?: number;
  daysUntilBirthday?: number;
  hasActiveMembership?: boolean;
  /** Multiple values may be separated by commas. */
  idIn?: number[];
  /** * `Not Imported` - Not Imported
   * `Imported` - Imported
   * `Membership Assigned` - Membership Assigned
   * `Welcome Email Sent` - Welcome Email Sent */
  migrationStatus?:
    | "Imported"
    | "Membership Assigned"
    | "Not Imported"
    | "Welcome Email Sent";
  /** Multiple values may be separated by commas. */
  migrationStatusIn?: string[];
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  /** A search term. */
  search?: string;
};
export type UsersCreateApiResponse = /** status 201  */ UserRead;
export type UsersCreateApiArg = {
  user: User;
};
export type UsersRetrieveApiResponse = /** status 200  */ UserRead;
export type UsersRetrieveApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
};
export type UsersUpdateApiResponse = /** status 200  */ UserRead;
export type UsersUpdateApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
  user: User;
};
export type UsersPartialUpdateApiResponse = /** status 200  */ UserRead;
export type UsersPartialUpdateApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
  patchedUser: PatchedUser;
};
export type UsersDestroyApiResponse = unknown;
export type UsersDestroyApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
};
export type UsersAssignedUsersListApiResponse =
  /** status 200  */ PaginatedUserListRead;
export type UsersAssignedUsersListApiArg = {
  atRisk?: boolean;
  attendanceCheckOut?: string;
  attendanceCheckOutIsnull?: boolean;
  /** Multiple values may be separated by commas. */
  attendanceCheckOutRange?: string[];
  birthdayMonth?: number;
  daysUntilBirthday?: number;
  hasActiveMembership?: boolean;
  /** A unique integer value identifying this user. */
  id: number;
  /** Multiple values may be separated by commas. */
  idIn?: number[];
  /** * `Not Imported` - Not Imported
   * `Imported` - Imported
   * `Membership Assigned` - Membership Assigned
   * `Welcome Email Sent` - Welcome Email Sent */
  migrationStatus?:
    | "Imported"
    | "Membership Assigned"
    | "Not Imported"
    | "Welcome Email Sent";
  /** Multiple values may be separated by commas. */
  migrationStatusIn?: string[];
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
  /** A search term. */
  search?: string;
};
export type UsersHistoryListApiResponse =
  /** status 200  */ PaginatedHistoricalUserListRead;
export type UsersHistoryListApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
  /** Set to true to disable pagination */
  noPagination?: boolean;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  perPage?: number;
};
export type UsersUpdateWorkingHoursPartialUpdateApiResponse =
  /** status 200  */ WorkingHours;
export type UsersUpdateWorkingHoursPartialUpdateApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
  patchedWorkingHours: PatchedWorkingHours;
};
export type UsersEnterFrontdeskModeCreateApiResponse = unknown;
export type UsersEnterFrontdeskModeCreateApiArg = void;
export type UsersExitFrontdeskModeCreateApiResponse =
  /** status 200  */ FrontdeskMode;
export type UsersExitFrontdeskModeCreateApiArg = {
  frontdeskMode: FrontdeskModeWrite;
};
export type UsersMeRetrieveApiResponse = /** status 200  */ UserRead;
export type UsersMeRetrieveApiArg = void;
export type UsersRetrieveByPinCodeCreateApiResponse =
  /** status 200  */ UserRead;
export type UsersRetrieveByPinCodeCreateApiArg = {
  pinCode: PinCode;
};
export type UsersUpdateProfileUpdateApiResponse = /** status 200  */ UserRead;
export type UsersUpdateProfileUpdateApiArg = {
  user: User;
};
export type UsersUpdateProfilePartialUpdateApiResponse =
  /** status 200  */ UserRead;
export type UsersUpdateProfilePartialUpdateApiArg = {
  patchedUser: PatchedUser;
};
export type WorkingHoursRetrieveApiResponse = /** status 200  */ WorkingHours;
export type WorkingHoursRetrieveApiArg = {
  /** A unique integer value identifying this working hours. */
  id: number;
};
export type LanguageEnum = "en" | "bs";
export type ReferredByEnum =
  | "walk_in"
  | "google"
  | "facebook"
  | "advertisement"
  | "website_blog"
  | "existing_member"
  | "staff_member"
  | "other";
export type CountryEnum =
  | "AF"
  | "AX"
  | "AL"
  | "DZ"
  | "AS"
  | "AD"
  | "AO"
  | "AI"
  | "AQ"
  | "AG"
  | "AR"
  | "AM"
  | "AW"
  | "AU"
  | "AT"
  | "AZ"
  | "BS"
  | "BH"
  | "BD"
  | "BB"
  | "BY"
  | "BE"
  | "BZ"
  | "BJ"
  | "BM"
  | "BT"
  | "BO"
  | "BQ"
  | "BA"
  | "BW"
  | "BV"
  | "BR"
  | "IO"
  | "BN"
  | "BG"
  | "BF"
  | "BI"
  | "CV"
  | "KH"
  | "CM"
  | "CA"
  | "KY"
  | "CF"
  | "TD"
  | "CL"
  | "CN"
  | "CX"
  | "CC"
  | "CO"
  | "KM"
  | "CG"
  | "CD"
  | "CK"
  | "CR"
  | "CI"
  | "HR"
  | "CU"
  | "CW"
  | "CY"
  | "CZ"
  | "DK"
  | "DJ"
  | "DM"
  | "DO"
  | "EC"
  | "EG"
  | "SV"
  | "GQ"
  | "ER"
  | "EE"
  | "SZ"
  | "ET"
  | "FK"
  | "FO"
  | "FJ"
  | "FI"
  | "FR"
  | "GF"
  | "PF"
  | "TF"
  | "GA"
  | "GM"
  | "GE"
  | "DE"
  | "GH"
  | "GI"
  | "GR"
  | "GL"
  | "GD"
  | "GP"
  | "GU"
  | "GT"
  | "GG"
  | "GN"
  | "GW"
  | "GY"
  | "HT"
  | "HM"
  | "VA"
  | "HN"
  | "HK"
  | "HU"
  | "IS"
  | "IN"
  | "ID"
  | "IR"
  | "IQ"
  | "IE"
  | "IM"
  | "IL"
  | "IT"
  | "JM"
  | "JP"
  | "JE"
  | "JO"
  | "KZ"
  | "KE"
  | "KI"
  | "KW"
  | "KG"
  | "LA"
  | "LV"
  | "LB"
  | "LS"
  | "LR"
  | "LY"
  | "LI"
  | "LT"
  | "LU"
  | "MO"
  | "MG"
  | "MW"
  | "MY"
  | "MV"
  | "ML"
  | "MT"
  | "MH"
  | "MQ"
  | "MR"
  | "MU"
  | "YT"
  | "MX"
  | "FM"
  | "MD"
  | "MC"
  | "MN"
  | "ME"
  | "MS"
  | "MA"
  | "MZ"
  | "MM"
  | "NA"
  | "NR"
  | "NP"
  | "NL"
  | "NC"
  | "NZ"
  | "NI"
  | "NE"
  | "NG"
  | "NU"
  | "NF"
  | "KP"
  | "MK"
  | "MP"
  | "NO"
  | "OM"
  | "PK"
  | "PW"
  | "PS"
  | "PA"
  | "PG"
  | "PY"
  | "PE"
  | "PH"
  | "PN"
  | "PL"
  | "PT"
  | "PR"
  | "QA"
  | "RE"
  | "RO"
  | "RU"
  | "RW"
  | "BL"
  | "SH"
  | "KN"
  | "LC"
  | "MF"
  | "PM"
  | "VC"
  | "WS"
  | "SM"
  | "ST"
  | "SA"
  | "SN"
  | "RS"
  | "SC"
  | "SL"
  | "SG"
  | "SX"
  | "SK"
  | "SI"
  | "SB"
  | "SO"
  | "ZA"
  | "GS"
  | "KR"
  | "SS"
  | "ES"
  | "LK"
  | "SD"
  | "SR"
  | "SJ"
  | "SE"
  | "CH"
  | "SY"
  | "TW"
  | "TJ"
  | "TZ"
  | "TH"
  | "TL"
  | "TG"
  | "TK"
  | "TO"
  | "TT"
  | "TN"
  | "TR"
  | "TM"
  | "TC"
  | "TV"
  | "UG"
  | "UA"
  | "AE"
  | "GB"
  | "UM"
  | "US"
  | "UY"
  | "UZ"
  | "VU"
  | "VE"
  | "VN"
  | "VG"
  | "VI"
  | "WF"
  | "EH"
  | "YE"
  | "ZM"
  | "ZW";
export type User = {
  name: string;
  email: string;
  phone?: string;
  profile_photo?: string | null;
  language?: LanguageEnum;
  birthday: string;
  assigned_to?: number | null;
  referred_by?: ReferredByEnum;
  referrer?: number | null;
  street_address?: string | null;
  city?: string | null;
  state?: string | null;
  postal_code?: string | null;
  country: CountryEnum;
  guardian_name?: string | null;
  guardian_email?: string | null;
  guardian_phone?: string;
  groups?: number[];
};
export type RoleEnum = "staff" | "member";
export type MigrationStatusEnum =
  | "Not Imported"
  | "Imported"
  | "Membership Assigned"
  | "Welcome Email Sent";
export type UserRead = {
  id: number;
  name: string;
  email: string;
  phone?: string;
  profile_photo?: string | null;
  language?: LanguageEnum;
  has_active_membership: boolean;
  created_by: number | null;
  is_in_frontdesk_mode: boolean;
  pin_code: string;
  stripe_customer_id: string | null;
  birthday: string;
  assigned_to?: number | null;
  referred_by?: ReferredByEnum;
  referrer?: number | null;
  street_address?: string | null;
  city?: string | null;
  state?: string | null;
  postal_code?: string | null;
  country: CountryEnum;
  guardian_name?: string | null;
  guardian_email?: string | null;
  guardian_phone?: string;
  is_owner: string;
  working_hours: number;
  days_until_birthday: number;
  role: RoleEnum;
  is_instructor: string;
  groups?: number[];
  migration_status: MigrationStatusEnum;
};
export type PaginatedUserList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: User[];
};
export type PaginatedUserListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: UserRead[];
};
export type TypeA2CEnum = "validation_error";
export type AttrE49Enum = "attendance__check_out";
export type CodeE7CEnum = "invalid";
export type AllUsersListAttendanceCheckOutErrorComponent = {
  attr: AttrE49Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type Attr107Enum = "attendance__check_out__range";
export type Code241Enum = "invalid" | "invalid_values";
export type AllUsersListAttendanceCheckOutRangeErrorComponent = {
  attr: Attr107Enum;
  code: Code241Enum;
  detail: string;
};
export type Attr12BEnum = "id__in";
export type Code90FEnum = "invalid" | "max_value";
export type AllUsersListIdInErrorComponent = {
  attr: Attr12BEnum;
  code: Code90FEnum;
  detail: string;
};
export type Attr461Enum = "migration_status";
export type Code099Enum = "invalid_choice";
export type AllUsersListMigrationStatusErrorComponent = {
  attr: Attr461Enum;
  code: Code099Enum;
  detail: string;
};
export type Attr5B2Enum = "migration_status__in";
export type Code0E9Enum = "null_characters_not_allowed";
export type AllUsersListMigrationStatusInErrorComponent = {
  attr: Attr5B2Enum;
  code: Code0E9Enum;
  detail: string;
};
export type Attr09EEnum = "birthday_month";
export type AllUsersListBirthdayMonthErrorComponent = {
  attr: Attr09EEnum;
  code: Code90FEnum;
  detail: string;
};
export type Attr69DEnum = "days_until_birthday";
export type AllUsersListDaysUntilBirthdayErrorComponent = {
  attr: Attr69DEnum;
  code: Code90FEnum;
  detail: string;
};
export type AllUsersListError =
  | ({
      attr: "attendance__check_out";
    } & AllUsersListAttendanceCheckOutErrorComponent)
  | ({
      attr: "attendance__check_out__range";
    } & AllUsersListAttendanceCheckOutRangeErrorComponent)
  | ({
      attr: "id__in";
    } & AllUsersListIdInErrorComponent)
  | ({
      attr: "migration_status";
    } & AllUsersListMigrationStatusErrorComponent)
  | ({
      attr: "migration_status__in";
    } & AllUsersListMigrationStatusInErrorComponent)
  | ({
      attr: "birthday_month";
    } & AllUsersListBirthdayMonthErrorComponent)
  | ({
      attr: "days_until_birthday";
    } & AllUsersListDaysUntilBirthdayErrorComponent);
export type AllUsersListValidationError = {
  type: TypeA2CEnum;
  errors: AllUsersListError[];
};
export type Type5D3Enum = "client_error";
export type ParseErrorCodeEnum = "parse_error";
export type ParseError = {
  code: ParseErrorCodeEnum;
  detail: string;
  attr: string | null;
};
export type ParseErrorResponse = {
  type: Type5D3Enum;
  errors: ParseError[];
};
export type AllUsersListErrorResponse400 =
  | ({
      type: "validation_error";
    } & AllUsersListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type Error401CodeEnum = "authentication_failed" | "not_authenticated";
export type Error401 = {
  code: Error401CodeEnum;
  detail: string;
  attr: string | null;
};
export type ErrorResponse401 = {
  type: Type5D3Enum;
  errors: Error401[];
};
export type Error403CodeEnum = "permission_denied";
export type Error403 = {
  code: Error403CodeEnum;
  detail: string;
  attr: string | null;
};
export type ErrorResponse403 = {
  type: Type5D3Enum;
  errors: Error403[];
};
export type Error404CodeEnum = "not_found";
export type Error404 = {
  code: Error404CodeEnum;
  detail: string;
  attr: string | null;
};
export type ErrorResponse404 = {
  type: Type5D3Enum;
  errors: Error404[];
};
export type Error405CodeEnum = "method_not_allowed";
export type Error405 = {
  code: Error405CodeEnum;
  detail: string;
  attr: string | null;
};
export type ErrorResponse405 = {
  type: Type5D3Enum;
  errors: Error405[];
};
export type Error406CodeEnum = "not_acceptable";
export type Error406 = {
  code: Error406CodeEnum;
  detail: string;
  attr: string | null;
};
export type ErrorResponse406 = {
  type: Type5D3Enum;
  errors: Error406[];
};
export type Error415CodeEnum = "unsupported_media_type";
export type Error415 = {
  code: Error415CodeEnum;
  detail: string;
  attr: string | null;
};
export type ErrorResponse415 = {
  type: Type5D3Enum;
  errors: Error415[];
};
export type ErrorResponse500TypeEnum = "server_error";
export type Error500CodeEnum = "error";
export type Error500 = {
  code: Error500CodeEnum;
  detail: string;
  attr: string | null;
};
export type ErrorResponse500 = {
  type: ErrorResponse500TypeEnum;
  errors: Error500[];
};
export type AllUsersRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type HistoryTypeEnum = "+" | "~" | "-";
export type HistoricalUser = {
  referred_by: string;
  language: string;
  id?: number;
  last_login?: string | null;
  /** Designates that this user has all permissions without explicitly assigning them. */
  is_superuser?: boolean;
  /** Designates whether the user can log into this admin site. */
  is_staff?: boolean;
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  is_active?: boolean;
  date_joined?: string;
  name: string;
  email: string;
  profile_photo?: string | null;
  birthday?: string | null;
  is_in_frontdesk_mode?: boolean;
  pin_code: string;
  street_address?: string | null;
  city?: string | null;
  state?: string | null;
  postal_code?: string | null;
  country: CountryEnum;
  phone?: string;
  is_lead?: boolean;
  guardian_name?: string | null;
  guardian_email?: string | null;
  guardian_phone?: string;
  history_date: string;
  history_change_reason?: string | null;
  history_type: HistoryTypeEnum;
  created_by?: number | null;
  history_user?: number | null;
};
export type HistoricalUserRead = {
  history_id: number;
  assigned_to: string;
  referrer: string;
  referred_by: string;
  language: string;
  id?: number;
  last_login?: string | null;
  /** Designates that this user has all permissions without explicitly assigning them. */
  is_superuser?: boolean;
  /** Designates whether the user can log into this admin site. */
  is_staff?: boolean;
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  is_active?: boolean;
  date_joined?: string;
  name: string;
  email: string;
  profile_photo?: string | null;
  created_on: string;
  birthday?: string | null;
  is_in_frontdesk_mode?: boolean;
  pin_code: string;
  street_address?: string | null;
  city?: string | null;
  state?: string | null;
  postal_code?: string | null;
  country: CountryEnum;
  phone?: string;
  is_lead?: boolean;
  guardian_name?: string | null;
  guardian_email?: string | null;
  guardian_phone?: string;
  history_date: string;
  history_change_reason?: string | null;
  history_type: HistoryTypeEnum;
  created_by?: number | null;
  history_user?: number | null;
};
export type PaginatedHistoricalUserList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: HistoricalUser[];
};
export type PaginatedHistoricalUserListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: HistoricalUserRead[];
};
export type AllUsersHistoryListErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type AppointmentPackage = {
  name: string;
  credits: number;
  /** Number of days after which credits expire */
  expire_credits_after: number;
  package_price: string;
  available_on_mobile_app?: boolean;
  description?: string;
  package_type: number;
};
export type AppointmentPackageRead = {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  credits: number;
  /** Number of days after which credits expire */
  expire_credits_after: number;
  package_price_currency: string;
  package_price: string;
  available_on_mobile_app?: boolean;
  description?: string;
  package_type: number;
};
export type PaginatedAppointmentPackageList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: AppointmentPackage[];
};
export type PaginatedAppointmentPackageListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: AppointmentPackageRead[];
};
export type Attr928Enum = "package_type";
export type AppointmentPackagesListPackageTypeErrorComponent = {
  attr: Attr928Enum;
  code: Code099Enum;
  detail: string;
};
export type AppointmentPackagesListError = {
  attr: "package_type";
} & AppointmentPackagesListPackageTypeErrorComponent;
export type AppointmentPackagesListValidationError = {
  type: TypeA2CEnum;
  errors: AppointmentPackagesListError[];
};
export type AppointmentPackagesListErrorResponse400 =
  | ({
      type: "validation_error";
    } & AppointmentPackagesListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type AttrD81Enum = "non_field_errors";
export type Code023Enum = "invalid" | "null";
export type AppointmentPackagesCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type Attr5EbEnum = "name";
export type Code6AeEnum =
  | "blank"
  | "invalid"
  | "max_length"
  | "null"
  | "null_characters_not_allowed"
  | "required"
  | "surrogate_characters_not_allowed"
  | "unique";
export type AppointmentPackagesCreateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code6AeEnum;
  detail: string;
};
export type Attr4FfEnum = "credits";
export type Code937Enum =
  | "invalid"
  | "max_string_length"
  | "max_value"
  | "min_value"
  | "null"
  | "required";
export type AppointmentPackagesCreateCreditsErrorComponent = {
  attr: Attr4FfEnum;
  code: Code937Enum;
  detail: string;
};
export type AttrFc6Enum = "expire_credits_after";
export type AppointmentPackagesCreateExpireCreditsAfterErrorComponent = {
  attr: AttrFc6Enum;
  code: Code937Enum;
  detail: string;
};
export type AttrD3FEnum = "package_price";
export type Code9BdEnum =
  | "invalid"
  | "invalid_currency"
  | "max_decimal_places"
  | "max_digits"
  | "max_string_length"
  | "max_whole_digits"
  | "null"
  | "required";
export type AppointmentPackagesCreatePackagePriceErrorComponent = {
  attr: AttrD3FEnum;
  code: Code9BdEnum;
  detail: string;
};
export type AttrF0DEnum = "available_on_mobile_app";
export type AppointmentPackagesCreateAvailableOnMobileAppErrorComponent = {
  attr: AttrF0DEnum;
  code: Code023Enum;
  detail: string;
};
export type Attr50BEnum = "description";
export type Code0E6Enum =
  | "invalid"
  | "null"
  | "null_characters_not_allowed"
  | "surrogate_characters_not_allowed";
export type AppointmentPackagesCreateDescriptionErrorComponent = {
  attr: Attr50BEnum;
  code: Code0E6Enum;
  detail: string;
};
export type Code6C0Enum =
  | "does_not_exist"
  | "incorrect_type"
  | "null"
  | "required";
export type AppointmentPackagesCreatePackageTypeErrorComponent = {
  attr: Attr928Enum;
  code: Code6C0Enum;
  detail: string;
};
export type AppointmentPackagesCreateError =
  | ({
      attr: "non_field_errors";
    } & AppointmentPackagesCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & AppointmentPackagesCreateNameErrorComponent)
  | ({
      attr: "credits";
    } & AppointmentPackagesCreateCreditsErrorComponent)
  | ({
      attr: "expire_credits_after";
    } & AppointmentPackagesCreateExpireCreditsAfterErrorComponent)
  | ({
      attr: "package_price";
    } & AppointmentPackagesCreatePackagePriceErrorComponent)
  | ({
      attr: "available_on_mobile_app";
    } & AppointmentPackagesCreateAvailableOnMobileAppErrorComponent)
  | ({
      attr: "description";
    } & AppointmentPackagesCreateDescriptionErrorComponent)
  | ({
      attr: "package_type";
    } & AppointmentPackagesCreatePackageTypeErrorComponent);
export type AppointmentPackagesCreateValidationError = {
  type: TypeA2CEnum;
  errors: AppointmentPackagesCreateError[];
};
export type AppointmentPackagesCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & AppointmentPackagesCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type AppointmentPackagesRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type AppointmentPackagesUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type AppointmentPackagesUpdateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code6AeEnum;
  detail: string;
};
export type AppointmentPackagesUpdateCreditsErrorComponent = {
  attr: Attr4FfEnum;
  code: Code937Enum;
  detail: string;
};
export type AppointmentPackagesUpdateExpireCreditsAfterErrorComponent = {
  attr: AttrFc6Enum;
  code: Code937Enum;
  detail: string;
};
export type AppointmentPackagesUpdatePackagePriceErrorComponent = {
  attr: AttrD3FEnum;
  code: Code9BdEnum;
  detail: string;
};
export type AppointmentPackagesUpdateAvailableOnMobileAppErrorComponent = {
  attr: AttrF0DEnum;
  code: Code023Enum;
  detail: string;
};
export type AppointmentPackagesUpdateDescriptionErrorComponent = {
  attr: Attr50BEnum;
  code: Code0E6Enum;
  detail: string;
};
export type AppointmentPackagesUpdatePackageTypeErrorComponent = {
  attr: Attr928Enum;
  code: Code6C0Enum;
  detail: string;
};
export type AppointmentPackagesUpdateError =
  | ({
      attr: "non_field_errors";
    } & AppointmentPackagesUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & AppointmentPackagesUpdateNameErrorComponent)
  | ({
      attr: "credits";
    } & AppointmentPackagesUpdateCreditsErrorComponent)
  | ({
      attr: "expire_credits_after";
    } & AppointmentPackagesUpdateExpireCreditsAfterErrorComponent)
  | ({
      attr: "package_price";
    } & AppointmentPackagesUpdatePackagePriceErrorComponent)
  | ({
      attr: "available_on_mobile_app";
    } & AppointmentPackagesUpdateAvailableOnMobileAppErrorComponent)
  | ({
      attr: "description";
    } & AppointmentPackagesUpdateDescriptionErrorComponent)
  | ({
      attr: "package_type";
    } & AppointmentPackagesUpdatePackageTypeErrorComponent);
export type AppointmentPackagesUpdateValidationError = {
  type: TypeA2CEnum;
  errors: AppointmentPackagesUpdateError[];
};
export type AppointmentPackagesUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & AppointmentPackagesUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type AppointmentPackagesPartialUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type AppointmentPackagesPartialUpdateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code6AeEnum;
  detail: string;
};
export type AppointmentPackagesPartialUpdateCreditsErrorComponent = {
  attr: Attr4FfEnum;
  code: Code937Enum;
  detail: string;
};
export type AppointmentPackagesPartialUpdateExpireCreditsAfterErrorComponent = {
  attr: AttrFc6Enum;
  code: Code937Enum;
  detail: string;
};
export type AppointmentPackagesPartialUpdatePackagePriceErrorComponent = {
  attr: AttrD3FEnum;
  code: Code9BdEnum;
  detail: string;
};
export type AppointmentPackagesPartialUpdateAvailableOnMobileAppErrorComponent =
  {
    attr: AttrF0DEnum;
    code: Code023Enum;
    detail: string;
  };
export type AppointmentPackagesPartialUpdateDescriptionErrorComponent = {
  attr: Attr50BEnum;
  code: Code0E6Enum;
  detail: string;
};
export type AppointmentPackagesPartialUpdatePackageTypeErrorComponent = {
  attr: Attr928Enum;
  code: Code6C0Enum;
  detail: string;
};
export type AppointmentPackagesPartialUpdateError =
  | ({
      attr: "non_field_errors";
    } & AppointmentPackagesPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & AppointmentPackagesPartialUpdateNameErrorComponent)
  | ({
      attr: "credits";
    } & AppointmentPackagesPartialUpdateCreditsErrorComponent)
  | ({
      attr: "expire_credits_after";
    } & AppointmentPackagesPartialUpdateExpireCreditsAfterErrorComponent)
  | ({
      attr: "package_price";
    } & AppointmentPackagesPartialUpdatePackagePriceErrorComponent)
  | ({
      attr: "available_on_mobile_app";
    } & AppointmentPackagesPartialUpdateAvailableOnMobileAppErrorComponent)
  | ({
      attr: "description";
    } & AppointmentPackagesPartialUpdateDescriptionErrorComponent)
  | ({
      attr: "package_type";
    } & AppointmentPackagesPartialUpdatePackageTypeErrorComponent);
export type AppointmentPackagesPartialUpdateValidationError = {
  type: TypeA2CEnum;
  errors: AppointmentPackagesPartialUpdateError[];
};
export type AppointmentPackagesPartialUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & AppointmentPackagesPartialUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PatchedAppointmentPackage = {
  name?: string;
  credits?: number;
  /** Number of days after which credits expire */
  expire_credits_after?: number;
  package_price?: string;
  available_on_mobile_app?: boolean;
  description?: string;
  package_type?: number;
};
export type PatchedAppointmentPackageRead = {
  id?: number;
  created_at?: string;
  updated_at?: string;
  name?: string;
  credits?: number;
  /** Number of days after which credits expire */
  expire_credits_after?: number;
  package_price_currency?: string;
  package_price?: string;
  available_on_mobile_app?: boolean;
  description?: string;
  package_type?: number;
};
export type AppointmentPackagesDestroyErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type AppointmentReservation = {
  start_date_time: string;
  start_time: string;
  user: number;
  credit_package: number;
  staff: number;
};
export type AppointmentReservationRead = {
  id: number;
  is_expired: string;
  status: string;
  appointment_type: string;
  status_display: string;
  created_at: string;
  updated_at: string;
  reserved_at: string;
  start_date_time: string;
  start_time: string;
  polymorphic_ctype: number | null;
  user: number;
  credit_package: number;
  staff: number;
};
export type PaginatedAppointmentReservationList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: AppointmentReservation[];
};
export type PaginatedAppointmentReservationListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: AppointmentReservationRead[];
};
export type Attr886Enum = "user";
export type AppointmentReservationsListUserErrorComponent = {
  attr: Attr886Enum;
  code: Code099Enum;
  detail: string;
};
export type AttrD4FEnum = "staff";
export type AppointmentReservationsListStaffErrorComponent = {
  attr: AttrD4FEnum;
  code: Code099Enum;
  detail: string;
};
export type Attr805Enum = "credit_package__appointment_type";
export type AppointmentReservationsListCreditPackageAppointmentTypeErrorComponent =
  {
    attr: Attr805Enum;
    code: Code099Enum;
    detail: string;
  };
export type Attr632Enum = "start_date_time";
export type AppointmentReservationsListStartDateTimeErrorComponent = {
  attr: Attr632Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AttrCf5Enum = "start_date_time__range";
export type AppointmentReservationsListStartDateTimeRangeErrorComponent = {
  attr: AttrCf5Enum;
  code: Code241Enum;
  detail: string;
};
export type AttrA16Enum = "status";
export type AppointmentReservationsListStatusErrorComponent = {
  attr: AttrA16Enum;
  code: Code099Enum;
  detail: string;
};
export type AppointmentReservationsListError =
  | ({
      attr: "user";
    } & AppointmentReservationsListUserErrorComponent)
  | ({
      attr: "staff";
    } & AppointmentReservationsListStaffErrorComponent)
  | ({
      attr: "credit_package__appointment_type";
    } & AppointmentReservationsListCreditPackageAppointmentTypeErrorComponent)
  | ({
      attr: "start_date_time";
    } & AppointmentReservationsListStartDateTimeErrorComponent)
  | ({
      attr: "start_date_time__range";
    } & AppointmentReservationsListStartDateTimeRangeErrorComponent)
  | ({
      attr: "status";
    } & AppointmentReservationsListStatusErrorComponent);
export type AppointmentReservationsListValidationError = {
  type: TypeA2CEnum;
  errors: AppointmentReservationsListError[];
};
export type AppointmentReservationsListErrorResponse400 =
  | ({
      type: "validation_error";
    } & AppointmentReservationsListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type AppointmentReservationsRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type AppointmentReservationsCancelReservationDestroyErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type AppointmentReservationsEditReservationCreateNonFieldErrorsErrorComponent =
  {
    attr: AttrD81Enum;
    code: Code023Enum;
    detail: string;
  };
export type AppointmentReservationsEditReservationCreateStaffErrorComponent = {
  attr: AttrD4FEnum;
  code: Code6C0Enum;
  detail: string;
};
export type Attr92BEnum = "start_date";
export type Code0DbEnum = "datetime" | "invalid" | "null" | "required";
export type AppointmentReservationsEditReservationCreateStartDateErrorComponent =
  {
    attr: Attr92BEnum;
    code: Code0DbEnum;
    detail: string;
  };
export type Attr2A0Enum = "start_time";
export type CodeF7EEnum = "invalid" | "null" | "required";
export type AppointmentReservationsEditReservationCreateStartTimeErrorComponent =
  {
    attr: Attr2A0Enum;
    code: CodeF7EEnum;
    detail: string;
  };
export type AppointmentReservationsEditReservationCreateError =
  | ({
      attr: "non_field_errors";
    } & AppointmentReservationsEditReservationCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "staff";
    } & AppointmentReservationsEditReservationCreateStaffErrorComponent)
  | ({
      attr: "start_date";
    } & AppointmentReservationsEditReservationCreateStartDateErrorComponent)
  | ({
      attr: "start_time";
    } & AppointmentReservationsEditReservationCreateStartTimeErrorComponent);
export type AppointmentReservationsEditReservationCreateValidationError = {
  type: TypeA2CEnum;
  errors: AppointmentReservationsEditReservationCreateError[];
};
export type AppointmentReservationsEditReservationCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & AppointmentReservationsEditReservationCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type EditReservation = {
  staff: number;
  start_date: string;
  start_time: string;
};
export type AvailableTimeSlot = {
  start_time: string;
  end_time: string;
  /** List of staff members available for the time slot. */
  staff_ids?: number[];
};
export type AppointmentReservationsAvailableTimesRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type AppointmentReservationsMyReservationsListUserErrorComponent = {
  attr: Attr886Enum;
  code: Code099Enum;
  detail: string;
};
export type AppointmentReservationsMyReservationsListStaffErrorComponent = {
  attr: AttrD4FEnum;
  code: Code099Enum;
  detail: string;
};
export type AppointmentReservationsMyReservationsListCreditPackageAppointmentTypeErrorComponent =
  {
    attr: Attr805Enum;
    code: Code099Enum;
    detail: string;
  };
export type AppointmentReservationsMyReservationsListStartDateTimeErrorComponent =
  {
    attr: Attr632Enum;
    code: CodeE7CEnum;
    detail: string;
  };
export type AppointmentReservationsMyReservationsListStartDateTimeRangeErrorComponent =
  {
    attr: AttrCf5Enum;
    code: Code241Enum;
    detail: string;
  };
export type AppointmentReservationsMyReservationsListStatusErrorComponent = {
  attr: AttrA16Enum;
  code: Code099Enum;
  detail: string;
};
export type AppointmentReservationsMyReservationsListError =
  | ({
      attr: "user";
    } & AppointmentReservationsMyReservationsListUserErrorComponent)
  | ({
      attr: "staff";
    } & AppointmentReservationsMyReservationsListStaffErrorComponent)
  | ({
      attr: "credit_package__appointment_type";
    } & AppointmentReservationsMyReservationsListCreditPackageAppointmentTypeErrorComponent)
  | ({
      attr: "start_date_time";
    } & AppointmentReservationsMyReservationsListStartDateTimeErrorComponent)
  | ({
      attr: "start_date_time__range";
    } & AppointmentReservationsMyReservationsListStartDateTimeRangeErrorComponent)
  | ({
      attr: "status";
    } & AppointmentReservationsMyReservationsListStatusErrorComponent);
export type AppointmentReservationsMyReservationsListValidationError = {
  type: TypeA2CEnum;
  errors: AppointmentReservationsMyReservationsListError[];
};
export type AppointmentReservationsMyReservationsListErrorResponse400 =
  | ({
      type: "validation_error";
    } & AppointmentReservationsMyReservationsListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type ReserveAppointment = {
  credit_package: number;
  start_time: string;
  start_date: string;
  staff_member?: number;
};
export type AppointmentReservationsReserveAppointmentCreateNonFieldErrorsErrorComponent =
  {
    attr: AttrD81Enum;
    code: Code023Enum;
    detail: string;
  };
export type AppointmentReservationsReserveAppointmentCreateCreditPackageErrorComponentAttrEnum =
  "credit_package";
export type AppointmentReservationsReserveAppointmentCreateCreditPackageErrorComponent =
  {
    attr: AppointmentReservationsReserveAppointmentCreateCreditPackageErrorComponentAttrEnum;
    code: Code6C0Enum;
    detail: string;
  };
export type AppointmentReservationsReserveAppointmentCreateStartTimeErrorComponent =
  {
    attr: Attr2A0Enum;
    code: CodeF7EEnum;
    detail: string;
  };
export type AppointmentReservationsReserveAppointmentCreateStartDateErrorComponent =
  {
    attr: Attr92BEnum;
    code: Code0DbEnum;
    detail: string;
  };
export type AppointmentReservationsReserveAppointmentCreateStaffMemberErrorComponentAttrEnum =
  "staff_member";
export type CodeC2FEnum = "does_not_exist" | "incorrect_type" | "null";
export type AppointmentReservationsReserveAppointmentCreateStaffMemberErrorComponent =
  {
    attr: AppointmentReservationsReserveAppointmentCreateStaffMemberErrorComponentAttrEnum;
    code: CodeC2FEnum;
    detail: string;
  };
export type AppointmentReservationsReserveAppointmentCreateError =
  | ({
      attr: "non_field_errors";
    } & AppointmentReservationsReserveAppointmentCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "credit_package";
    } & AppointmentReservationsReserveAppointmentCreateCreditPackageErrorComponent)
  | ({
      attr: "start_time";
    } & AppointmentReservationsReserveAppointmentCreateStartTimeErrorComponent)
  | ({
      attr: "start_date";
    } & AppointmentReservationsReserveAppointmentCreateStartDateErrorComponent)
  | ({
      attr: "staff_member";
    } & AppointmentReservationsReserveAppointmentCreateStaffMemberErrorComponent);
export type AppointmentReservationsReserveAppointmentCreateValidationError = {
  type: TypeA2CEnum;
  errors: AppointmentReservationsReserveAppointmentCreateError[];
};
export type AppointmentReservationsReserveAppointmentCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & AppointmentReservationsReserveAppointmentCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type ReservationCloseEnum =
  | 0
  | 15
  | 30
  | 45
  | 60
  | 120
  | 180
  | 240
  | 360
  | 480
  | 600
  | 720
  | 1440
  | 2880
  | 4320
  | 5760
  | 7200
  | 8640
  | 10080
  | 20160
  | 30240
  | 40320;
export type BufferBeforeAppointmentEnum = 0 | 5 | 10 | 15 | 30 | 45 | 60;
export type BufferAfterAppointmentEnum = 0 | 5 | 10 | 15 | 30 | 45 | 60;
export type TimeSlotGranularityEnum = 5 | 10 | 15 | 30 | 45 | 60 | 90;
export type AppointmentType = {
  name: string;
  color: string;
  /** Duration in minutes */
  duration: number;
  monday_start_time?: string | null;
  monday_end_time?: string | null;
  tuesday_start_time?: string | null;
  tuesday_end_time?: string | null;
  wednesday_start_time?: string | null;
  wednesday_end_time?: string | null;
  thursday_start_time?: string | null;
  thursday_end_time?: string | null;
  friday_start_time?: string | null;
  friday_end_time?: string | null;
  saturday_start_time?: string | null;
  saturday_end_time?: string | null;
  sunday_start_time?: string | null;
  sunday_end_time?: string | null;
  /** Minimum booking notice in minutes
    
    * `0` - At start time
    * `15` - 15 minutes before start time
    * `30` - 30 minutes before start time
    * `45` - 45 minutes before start time
    * `60` - 1 hour before start time
    * `120` - 2 hours before start time
    * `180` - 3 hours before start time
    * `240` - 4 hours before start time
    * `360` - 6 hours before start time
    * `480` - 8 hours before start time
    * `600` - 10 hours before start time
    * `720` - 12 hours before start time
    * `1440` - 1 day before start time
    * `2880` - 2 days before start time
    * `4320` - 3 days before start time
    * `5760` - 4 days before start time
    * `7200` - 5 days before start time
    * `8640` - 6 days before start time
    * `10080` - 1 week before start time
    * `20160` - 2 weeks before start time
    * `30240` - 3 weeks before start time
    * `40320` - 4 weeks before start time */
  minimum_booking_notice: ReservationCloseEnum;
  /** Buffer time before appointment in minutes
    
    * `0` - No buffer
    * `5` - 5 min
    * `10` - 10 min
    * `15` - 15 min
    * `30` - 30 min
    * `45` - 45 min
    * `60` - 60 min */
  buffer_before_appointment?: BufferBeforeAppointmentEnum;
  /** Buffer time after appointment in minutes
    
    * `0` - No buffer
    * `5` - 5 min
    * `10` - 10 min
    * `15` - 15 min
    * `30` - 30 min
    * `45` - 45 min
    * `60` - 60 min */
  buffer_after_appointment: BufferAfterAppointmentEnum;
  /** Time slot granularity in minutes
    
    * `5` - 5 min
    * `10` - 10 min
    * `15` - 15 min
    * `30` - 30 min
    * `45` - 45 min
    * `60` - 60 min
    * `90` - 90 min */
  time_slot_granularity?: TimeSlotGranularityEnum;
  /** Minimum notice required to keep the session in minutes
    
    * `0` - At start time
    * `15` - 15 minutes before start time
    * `30` - 30 minutes before start time
    * `45` - 45 minutes before start time
    * `60` - 1 hour before start time
    * `120` - 2 hours before start time
    * `180` - 3 hours before start time
    * `240` - 4 hours before start time
    * `360` - 6 hours before start time
    * `480` - 8 hours before start time
    * `600` - 10 hours before start time
    * `720` - 12 hours before start time
    * `1440` - 1 day before start time
    * `2880` - 2 days before start time
    * `4320` - 3 days before start time
    * `5760` - 4 days before start time
    * `7200` - 5 days before start time
    * `8640` - 6 days before start time
    * `10080` - 1 week before start time
    * `20160` - 2 weeks before start time
    * `30240` - 3 weeks before start time
    * `40320` - 4 weeks before start time */
  late_cancel: ReservationCloseEnum;
  image?: string | null;
  staff: number[];
};
export type AppointmentTypeRead = {
  id: number;
  late_cancel_display: string;
  created_at: string;
  updated_at: string;
  name: string;
  color: string;
  /** Duration in minutes */
  duration: number;
  monday_start_time?: string | null;
  monday_end_time?: string | null;
  tuesday_start_time?: string | null;
  tuesday_end_time?: string | null;
  wednesday_start_time?: string | null;
  wednesday_end_time?: string | null;
  thursday_start_time?: string | null;
  thursday_end_time?: string | null;
  friday_start_time?: string | null;
  friday_end_time?: string | null;
  saturday_start_time?: string | null;
  saturday_end_time?: string | null;
  sunday_start_time?: string | null;
  sunday_end_time?: string | null;
  /** Minimum booking notice in minutes
    
    * `0` - At start time
    * `15` - 15 minutes before start time
    * `30` - 30 minutes before start time
    * `45` - 45 minutes before start time
    * `60` - 1 hour before start time
    * `120` - 2 hours before start time
    * `180` - 3 hours before start time
    * `240` - 4 hours before start time
    * `360` - 6 hours before start time
    * `480` - 8 hours before start time
    * `600` - 10 hours before start time
    * `720` - 12 hours before start time
    * `1440` - 1 day before start time
    * `2880` - 2 days before start time
    * `4320` - 3 days before start time
    * `5760` - 4 days before start time
    * `7200` - 5 days before start time
    * `8640` - 6 days before start time
    * `10080` - 1 week before start time
    * `20160` - 2 weeks before start time
    * `30240` - 3 weeks before start time
    * `40320` - 4 weeks before start time */
  minimum_booking_notice: ReservationCloseEnum;
  /** Buffer time before appointment in minutes
    
    * `0` - No buffer
    * `5` - 5 min
    * `10` - 10 min
    * `15` - 15 min
    * `30` - 30 min
    * `45` - 45 min
    * `60` - 60 min */
  buffer_before_appointment?: BufferBeforeAppointmentEnum;
  /** Buffer time after appointment in minutes
    
    * `0` - No buffer
    * `5` - 5 min
    * `10` - 10 min
    * `15` - 15 min
    * `30` - 30 min
    * `45` - 45 min
    * `60` - 60 min */
  buffer_after_appointment: BufferAfterAppointmentEnum;
  /** Time slot granularity in minutes
    
    * `5` - 5 min
    * `10` - 10 min
    * `15` - 15 min
    * `30` - 30 min
    * `45` - 45 min
    * `60` - 60 min
    * `90` - 90 min */
  time_slot_granularity?: TimeSlotGranularityEnum;
  /** Minimum notice required to keep the session in minutes
    
    * `0` - At start time
    * `15` - 15 minutes before start time
    * `30` - 30 minutes before start time
    * `45` - 45 minutes before start time
    * `60` - 1 hour before start time
    * `120` - 2 hours before start time
    * `180` - 3 hours before start time
    * `240` - 4 hours before start time
    * `360` - 6 hours before start time
    * `480` - 8 hours before start time
    * `600` - 10 hours before start time
    * `720` - 12 hours before start time
    * `1440` - 1 day before start time
    * `2880` - 2 days before start time
    * `4320` - 3 days before start time
    * `5760` - 4 days before start time
    * `7200` - 5 days before start time
    * `8640` - 6 days before start time
    * `10080` - 1 week before start time
    * `20160` - 2 weeks before start time
    * `30240` - 3 weeks before start time
    * `40320` - 4 weeks before start time */
  late_cancel: ReservationCloseEnum;
  image?: string | null;
  staff: number[];
};
export type PaginatedAppointmentTypeList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: AppointmentType[];
};
export type PaginatedAppointmentTypeListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: AppointmentTypeRead[];
};
export type AppointmentTypesListIdInErrorComponent = {
  attr: Attr12BEnum;
  code: Code90FEnum;
  detail: string;
};
export type AppointmentTypesListError = {
  attr: "id__in";
} & AppointmentTypesListIdInErrorComponent;
export type AppointmentTypesListValidationError = {
  type: TypeA2CEnum;
  errors: AppointmentTypesListError[];
};
export type AppointmentTypesListErrorResponse400 =
  | ({
      type: "validation_error";
    } & AppointmentTypesListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type AppointmentTypesCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type AppointmentTypesCreateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code6AeEnum;
  detail: string;
};
export type Attr5C1Enum = "color";
export type CodeAc9Enum =
  | "blank"
  | "invalid"
  | "invalid_color"
  | "max_length"
  | "null"
  | "null_characters_not_allowed"
  | "required"
  | "surrogate_characters_not_allowed";
export type AppointmentTypesCreateColorErrorComponent = {
  attr: Attr5C1Enum;
  code: CodeAc9Enum;
  detail: string;
};
export type AttrFd6Enum = "duration";
export type AppointmentTypesCreateDurationErrorComponent = {
  attr: AttrFd6Enum;
  code: Code937Enum;
  detail: string;
};
export type Attr5C6Enum = "monday_start_time";
export type AppointmentTypesCreateMondayStartTimeErrorComponent = {
  attr: Attr5C6Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type Attr347Enum = "monday_end_time";
export type AppointmentTypesCreateMondayEndTimeErrorComponent = {
  attr: Attr347Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type Attr984Enum = "tuesday_start_time";
export type AppointmentTypesCreateTuesdayStartTimeErrorComponent = {
  attr: Attr984Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type Attr447Enum = "tuesday_end_time";
export type AppointmentTypesCreateTuesdayEndTimeErrorComponent = {
  attr: Attr447Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type Attr2F9Enum = "wednesday_start_time";
export type AppointmentTypesCreateWednesdayStartTimeErrorComponent = {
  attr: Attr2F9Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type Attr002Enum = "wednesday_end_time";
export type AppointmentTypesCreateWednesdayEndTimeErrorComponent = {
  attr: Attr002Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AttrD52Enum = "thursday_start_time";
export type AppointmentTypesCreateThursdayStartTimeErrorComponent = {
  attr: AttrD52Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AttrC03Enum = "thursday_end_time";
export type AppointmentTypesCreateThursdayEndTimeErrorComponent = {
  attr: AttrC03Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AttrD4AEnum = "friday_start_time";
export type AppointmentTypesCreateFridayStartTimeErrorComponent = {
  attr: AttrD4AEnum;
  code: CodeE7CEnum;
  detail: string;
};
export type AttrF96Enum = "friday_end_time";
export type AppointmentTypesCreateFridayEndTimeErrorComponent = {
  attr: AttrF96Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type Attr58AEnum = "saturday_start_time";
export type AppointmentTypesCreateSaturdayStartTimeErrorComponent = {
  attr: Attr58AEnum;
  code: CodeE7CEnum;
  detail: string;
};
export type Attr5A3Enum = "saturday_end_time";
export type AppointmentTypesCreateSaturdayEndTimeErrorComponent = {
  attr: Attr5A3Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type Attr7F9Enum = "sunday_start_time";
export type AppointmentTypesCreateSundayStartTimeErrorComponent = {
  attr: Attr7F9Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AttrF9DEnum = "sunday_end_time";
export type AppointmentTypesCreateSundayEndTimeErrorComponent = {
  attr: AttrF9DEnum;
  code: CodeE7CEnum;
  detail: string;
};
export type Attr7FcEnum = "minimum_booking_notice";
export type Code546Enum =
  | "invalid_choice"
  | "max_value"
  | "min_value"
  | "null"
  | "required";
export type AppointmentTypesCreateMinimumBookingNoticeErrorComponent = {
  attr: Attr7FcEnum;
  code: Code546Enum;
  detail: string;
};
export type Attr562Enum = "buffer_before_appointment";
export type Code729Enum = "invalid_choice" | "max_value" | "min_value" | "null";
export type AppointmentTypesCreateBufferBeforeAppointmentErrorComponent = {
  attr: Attr562Enum;
  code: Code729Enum;
  detail: string;
};
export type Attr5C5Enum = "buffer_after_appointment";
export type AppointmentTypesCreateBufferAfterAppointmentErrorComponent = {
  attr: Attr5C5Enum;
  code: Code546Enum;
  detail: string;
};
export type Attr677Enum = "time_slot_granularity";
export type AppointmentTypesCreateTimeSlotGranularityErrorComponent = {
  attr: Attr677Enum;
  code: Code729Enum;
  detail: string;
};
export type Attr2C0Enum = "late_cancel";
export type AppointmentTypesCreateLateCancelErrorComponent = {
  attr: Attr2C0Enum;
  code: Code546Enum;
  detail: string;
};
export type Attr470Enum = "image";
export type Code40EEnum =
  | "empty"
  | "invalid"
  | "invalid_image"
  | "max_length"
  | "no_name";
export type AppointmentTypesCreateImageErrorComponent = {
  attr: Attr470Enum;
  code: Code40EEnum;
  detail: string;
};
export type Code70AEnum =
  | "does_not_exist"
  | "empty"
  | "incorrect_type"
  | "not_a_list"
  | "null"
  | "required";
export type AppointmentTypesCreateStaffErrorComponent = {
  attr: AttrD4FEnum;
  code: Code70AEnum;
  detail: string;
};
export type AppointmentTypesCreateError =
  | ({
      attr: "non_field_errors";
    } & AppointmentTypesCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & AppointmentTypesCreateNameErrorComponent)
  | ({
      attr: "color";
    } & AppointmentTypesCreateColorErrorComponent)
  | ({
      attr: "duration";
    } & AppointmentTypesCreateDurationErrorComponent)
  | ({
      attr: "monday_start_time";
    } & AppointmentTypesCreateMondayStartTimeErrorComponent)
  | ({
      attr: "monday_end_time";
    } & AppointmentTypesCreateMondayEndTimeErrorComponent)
  | ({
      attr: "tuesday_start_time";
    } & AppointmentTypesCreateTuesdayStartTimeErrorComponent)
  | ({
      attr: "tuesday_end_time";
    } & AppointmentTypesCreateTuesdayEndTimeErrorComponent)
  | ({
      attr: "wednesday_start_time";
    } & AppointmentTypesCreateWednesdayStartTimeErrorComponent)
  | ({
      attr: "wednesday_end_time";
    } & AppointmentTypesCreateWednesdayEndTimeErrorComponent)
  | ({
      attr: "thursday_start_time";
    } & AppointmentTypesCreateThursdayStartTimeErrorComponent)
  | ({
      attr: "thursday_end_time";
    } & AppointmentTypesCreateThursdayEndTimeErrorComponent)
  | ({
      attr: "friday_start_time";
    } & AppointmentTypesCreateFridayStartTimeErrorComponent)
  | ({
      attr: "friday_end_time";
    } & AppointmentTypesCreateFridayEndTimeErrorComponent)
  | ({
      attr: "saturday_start_time";
    } & AppointmentTypesCreateSaturdayStartTimeErrorComponent)
  | ({
      attr: "saturday_end_time";
    } & AppointmentTypesCreateSaturdayEndTimeErrorComponent)
  | ({
      attr: "sunday_start_time";
    } & AppointmentTypesCreateSundayStartTimeErrorComponent)
  | ({
      attr: "sunday_end_time";
    } & AppointmentTypesCreateSundayEndTimeErrorComponent)
  | ({
      attr: "minimum_booking_notice";
    } & AppointmentTypesCreateMinimumBookingNoticeErrorComponent)
  | ({
      attr: "buffer_before_appointment";
    } & AppointmentTypesCreateBufferBeforeAppointmentErrorComponent)
  | ({
      attr: "buffer_after_appointment";
    } & AppointmentTypesCreateBufferAfterAppointmentErrorComponent)
  | ({
      attr: "time_slot_granularity";
    } & AppointmentTypesCreateTimeSlotGranularityErrorComponent)
  | ({
      attr: "late_cancel";
    } & AppointmentTypesCreateLateCancelErrorComponent)
  | ({
      attr: "image";
    } & AppointmentTypesCreateImageErrorComponent)
  | ({
      attr: "staff";
    } & AppointmentTypesCreateStaffErrorComponent);
export type AppointmentTypesCreateValidationError = {
  type: TypeA2CEnum;
  errors: AppointmentTypesCreateError[];
};
export type AppointmentTypesCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & AppointmentTypesCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type AppointmentTypesRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type AppointmentTypesUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type AppointmentTypesUpdateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code6AeEnum;
  detail: string;
};
export type AppointmentTypesUpdateColorErrorComponent = {
  attr: Attr5C1Enum;
  code: CodeAc9Enum;
  detail: string;
};
export type AppointmentTypesUpdateDurationErrorComponent = {
  attr: AttrFd6Enum;
  code: Code937Enum;
  detail: string;
};
export type AppointmentTypesUpdateMondayStartTimeErrorComponent = {
  attr: Attr5C6Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AppointmentTypesUpdateMondayEndTimeErrorComponent = {
  attr: Attr347Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AppointmentTypesUpdateTuesdayStartTimeErrorComponent = {
  attr: Attr984Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AppointmentTypesUpdateTuesdayEndTimeErrorComponent = {
  attr: Attr447Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AppointmentTypesUpdateWednesdayStartTimeErrorComponent = {
  attr: Attr2F9Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AppointmentTypesUpdateWednesdayEndTimeErrorComponent = {
  attr: Attr002Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AppointmentTypesUpdateThursdayStartTimeErrorComponent = {
  attr: AttrD52Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AppointmentTypesUpdateThursdayEndTimeErrorComponent = {
  attr: AttrC03Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AppointmentTypesUpdateFridayStartTimeErrorComponent = {
  attr: AttrD4AEnum;
  code: CodeE7CEnum;
  detail: string;
};
export type AppointmentTypesUpdateFridayEndTimeErrorComponent = {
  attr: AttrF96Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AppointmentTypesUpdateSaturdayStartTimeErrorComponent = {
  attr: Attr58AEnum;
  code: CodeE7CEnum;
  detail: string;
};
export type AppointmentTypesUpdateSaturdayEndTimeErrorComponent = {
  attr: Attr5A3Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AppointmentTypesUpdateSundayStartTimeErrorComponent = {
  attr: Attr7F9Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AppointmentTypesUpdateSundayEndTimeErrorComponent = {
  attr: AttrF9DEnum;
  code: CodeE7CEnum;
  detail: string;
};
export type AppointmentTypesUpdateMinimumBookingNoticeErrorComponent = {
  attr: Attr7FcEnum;
  code: Code546Enum;
  detail: string;
};
export type AppointmentTypesUpdateBufferBeforeAppointmentErrorComponent = {
  attr: Attr562Enum;
  code: Code729Enum;
  detail: string;
};
export type AppointmentTypesUpdateBufferAfterAppointmentErrorComponent = {
  attr: Attr5C5Enum;
  code: Code546Enum;
  detail: string;
};
export type AppointmentTypesUpdateTimeSlotGranularityErrorComponent = {
  attr: Attr677Enum;
  code: Code729Enum;
  detail: string;
};
export type AppointmentTypesUpdateLateCancelErrorComponent = {
  attr: Attr2C0Enum;
  code: Code546Enum;
  detail: string;
};
export type AppointmentTypesUpdateImageErrorComponent = {
  attr: Attr470Enum;
  code: Code40EEnum;
  detail: string;
};
export type AppointmentTypesUpdateStaffErrorComponent = {
  attr: AttrD4FEnum;
  code: Code70AEnum;
  detail: string;
};
export type AppointmentTypesUpdateError =
  | ({
      attr: "non_field_errors";
    } & AppointmentTypesUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & AppointmentTypesUpdateNameErrorComponent)
  | ({
      attr: "color";
    } & AppointmentTypesUpdateColorErrorComponent)
  | ({
      attr: "duration";
    } & AppointmentTypesUpdateDurationErrorComponent)
  | ({
      attr: "monday_start_time";
    } & AppointmentTypesUpdateMondayStartTimeErrorComponent)
  | ({
      attr: "monday_end_time";
    } & AppointmentTypesUpdateMondayEndTimeErrorComponent)
  | ({
      attr: "tuesday_start_time";
    } & AppointmentTypesUpdateTuesdayStartTimeErrorComponent)
  | ({
      attr: "tuesday_end_time";
    } & AppointmentTypesUpdateTuesdayEndTimeErrorComponent)
  | ({
      attr: "wednesday_start_time";
    } & AppointmentTypesUpdateWednesdayStartTimeErrorComponent)
  | ({
      attr: "wednesday_end_time";
    } & AppointmentTypesUpdateWednesdayEndTimeErrorComponent)
  | ({
      attr: "thursday_start_time";
    } & AppointmentTypesUpdateThursdayStartTimeErrorComponent)
  | ({
      attr: "thursday_end_time";
    } & AppointmentTypesUpdateThursdayEndTimeErrorComponent)
  | ({
      attr: "friday_start_time";
    } & AppointmentTypesUpdateFridayStartTimeErrorComponent)
  | ({
      attr: "friday_end_time";
    } & AppointmentTypesUpdateFridayEndTimeErrorComponent)
  | ({
      attr: "saturday_start_time";
    } & AppointmentTypesUpdateSaturdayStartTimeErrorComponent)
  | ({
      attr: "saturday_end_time";
    } & AppointmentTypesUpdateSaturdayEndTimeErrorComponent)
  | ({
      attr: "sunday_start_time";
    } & AppointmentTypesUpdateSundayStartTimeErrorComponent)
  | ({
      attr: "sunday_end_time";
    } & AppointmentTypesUpdateSundayEndTimeErrorComponent)
  | ({
      attr: "minimum_booking_notice";
    } & AppointmentTypesUpdateMinimumBookingNoticeErrorComponent)
  | ({
      attr: "buffer_before_appointment";
    } & AppointmentTypesUpdateBufferBeforeAppointmentErrorComponent)
  | ({
      attr: "buffer_after_appointment";
    } & AppointmentTypesUpdateBufferAfterAppointmentErrorComponent)
  | ({
      attr: "time_slot_granularity";
    } & AppointmentTypesUpdateTimeSlotGranularityErrorComponent)
  | ({
      attr: "late_cancel";
    } & AppointmentTypesUpdateLateCancelErrorComponent)
  | ({
      attr: "image";
    } & AppointmentTypesUpdateImageErrorComponent)
  | ({
      attr: "staff";
    } & AppointmentTypesUpdateStaffErrorComponent);
export type AppointmentTypesUpdateValidationError = {
  type: TypeA2CEnum;
  errors: AppointmentTypesUpdateError[];
};
export type AppointmentTypesUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & AppointmentTypesUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type AppointmentTypesPartialUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type AppointmentTypesPartialUpdateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code6AeEnum;
  detail: string;
};
export type AppointmentTypesPartialUpdateColorErrorComponent = {
  attr: Attr5C1Enum;
  code: CodeAc9Enum;
  detail: string;
};
export type AppointmentTypesPartialUpdateDurationErrorComponent = {
  attr: AttrFd6Enum;
  code: Code937Enum;
  detail: string;
};
export type AppointmentTypesPartialUpdateMondayStartTimeErrorComponent = {
  attr: Attr5C6Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AppointmentTypesPartialUpdateMondayEndTimeErrorComponent = {
  attr: Attr347Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AppointmentTypesPartialUpdateTuesdayStartTimeErrorComponent = {
  attr: Attr984Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AppointmentTypesPartialUpdateTuesdayEndTimeErrorComponent = {
  attr: Attr447Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AppointmentTypesPartialUpdateWednesdayStartTimeErrorComponent = {
  attr: Attr2F9Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AppointmentTypesPartialUpdateWednesdayEndTimeErrorComponent = {
  attr: Attr002Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AppointmentTypesPartialUpdateThursdayStartTimeErrorComponent = {
  attr: AttrD52Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AppointmentTypesPartialUpdateThursdayEndTimeErrorComponent = {
  attr: AttrC03Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AppointmentTypesPartialUpdateFridayStartTimeErrorComponent = {
  attr: AttrD4AEnum;
  code: CodeE7CEnum;
  detail: string;
};
export type AppointmentTypesPartialUpdateFridayEndTimeErrorComponent = {
  attr: AttrF96Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AppointmentTypesPartialUpdateSaturdayStartTimeErrorComponent = {
  attr: Attr58AEnum;
  code: CodeE7CEnum;
  detail: string;
};
export type AppointmentTypesPartialUpdateSaturdayEndTimeErrorComponent = {
  attr: Attr5A3Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AppointmentTypesPartialUpdateSundayStartTimeErrorComponent = {
  attr: Attr7F9Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AppointmentTypesPartialUpdateSundayEndTimeErrorComponent = {
  attr: AttrF9DEnum;
  code: CodeE7CEnum;
  detail: string;
};
export type AppointmentTypesPartialUpdateMinimumBookingNoticeErrorComponent = {
  attr: Attr7FcEnum;
  code: Code546Enum;
  detail: string;
};
export type AppointmentTypesPartialUpdateBufferBeforeAppointmentErrorComponent =
  {
    attr: Attr562Enum;
    code: Code729Enum;
    detail: string;
  };
export type AppointmentTypesPartialUpdateBufferAfterAppointmentErrorComponent =
  {
    attr: Attr5C5Enum;
    code: Code546Enum;
    detail: string;
  };
export type AppointmentTypesPartialUpdateTimeSlotGranularityErrorComponent = {
  attr: Attr677Enum;
  code: Code729Enum;
  detail: string;
};
export type AppointmentTypesPartialUpdateLateCancelErrorComponent = {
  attr: Attr2C0Enum;
  code: Code546Enum;
  detail: string;
};
export type AppointmentTypesPartialUpdateImageErrorComponent = {
  attr: Attr470Enum;
  code: Code40EEnum;
  detail: string;
};
export type AppointmentTypesPartialUpdateStaffErrorComponent = {
  attr: AttrD4FEnum;
  code: Code70AEnum;
  detail: string;
};
export type AppointmentTypesPartialUpdateError =
  | ({
      attr: "non_field_errors";
    } & AppointmentTypesPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & AppointmentTypesPartialUpdateNameErrorComponent)
  | ({
      attr: "color";
    } & AppointmentTypesPartialUpdateColorErrorComponent)
  | ({
      attr: "duration";
    } & AppointmentTypesPartialUpdateDurationErrorComponent)
  | ({
      attr: "monday_start_time";
    } & AppointmentTypesPartialUpdateMondayStartTimeErrorComponent)
  | ({
      attr: "monday_end_time";
    } & AppointmentTypesPartialUpdateMondayEndTimeErrorComponent)
  | ({
      attr: "tuesday_start_time";
    } & AppointmentTypesPartialUpdateTuesdayStartTimeErrorComponent)
  | ({
      attr: "tuesday_end_time";
    } & AppointmentTypesPartialUpdateTuesdayEndTimeErrorComponent)
  | ({
      attr: "wednesday_start_time";
    } & AppointmentTypesPartialUpdateWednesdayStartTimeErrorComponent)
  | ({
      attr: "wednesday_end_time";
    } & AppointmentTypesPartialUpdateWednesdayEndTimeErrorComponent)
  | ({
      attr: "thursday_start_time";
    } & AppointmentTypesPartialUpdateThursdayStartTimeErrorComponent)
  | ({
      attr: "thursday_end_time";
    } & AppointmentTypesPartialUpdateThursdayEndTimeErrorComponent)
  | ({
      attr: "friday_start_time";
    } & AppointmentTypesPartialUpdateFridayStartTimeErrorComponent)
  | ({
      attr: "friday_end_time";
    } & AppointmentTypesPartialUpdateFridayEndTimeErrorComponent)
  | ({
      attr: "saturday_start_time";
    } & AppointmentTypesPartialUpdateSaturdayStartTimeErrorComponent)
  | ({
      attr: "saturday_end_time";
    } & AppointmentTypesPartialUpdateSaturdayEndTimeErrorComponent)
  | ({
      attr: "sunday_start_time";
    } & AppointmentTypesPartialUpdateSundayStartTimeErrorComponent)
  | ({
      attr: "sunday_end_time";
    } & AppointmentTypesPartialUpdateSundayEndTimeErrorComponent)
  | ({
      attr: "minimum_booking_notice";
    } & AppointmentTypesPartialUpdateMinimumBookingNoticeErrorComponent)
  | ({
      attr: "buffer_before_appointment";
    } & AppointmentTypesPartialUpdateBufferBeforeAppointmentErrorComponent)
  | ({
      attr: "buffer_after_appointment";
    } & AppointmentTypesPartialUpdateBufferAfterAppointmentErrorComponent)
  | ({
      attr: "time_slot_granularity";
    } & AppointmentTypesPartialUpdateTimeSlotGranularityErrorComponent)
  | ({
      attr: "late_cancel";
    } & AppointmentTypesPartialUpdateLateCancelErrorComponent)
  | ({
      attr: "image";
    } & AppointmentTypesPartialUpdateImageErrorComponent)
  | ({
      attr: "staff";
    } & AppointmentTypesPartialUpdateStaffErrorComponent);
export type AppointmentTypesPartialUpdateValidationError = {
  type: TypeA2CEnum;
  errors: AppointmentTypesPartialUpdateError[];
};
export type AppointmentTypesPartialUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & AppointmentTypesPartialUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PatchedAppointmentType = {
  name?: string;
  color?: string;
  /** Duration in minutes */
  duration?: number;
  monday_start_time?: string | null;
  monday_end_time?: string | null;
  tuesday_start_time?: string | null;
  tuesday_end_time?: string | null;
  wednesday_start_time?: string | null;
  wednesday_end_time?: string | null;
  thursday_start_time?: string | null;
  thursday_end_time?: string | null;
  friday_start_time?: string | null;
  friday_end_time?: string | null;
  saturday_start_time?: string | null;
  saturday_end_time?: string | null;
  sunday_start_time?: string | null;
  sunday_end_time?: string | null;
  /** Minimum booking notice in minutes
    
    * `0` - At start time
    * `15` - 15 minutes before start time
    * `30` - 30 minutes before start time
    * `45` - 45 minutes before start time
    * `60` - 1 hour before start time
    * `120` - 2 hours before start time
    * `180` - 3 hours before start time
    * `240` - 4 hours before start time
    * `360` - 6 hours before start time
    * `480` - 8 hours before start time
    * `600` - 10 hours before start time
    * `720` - 12 hours before start time
    * `1440` - 1 day before start time
    * `2880` - 2 days before start time
    * `4320` - 3 days before start time
    * `5760` - 4 days before start time
    * `7200` - 5 days before start time
    * `8640` - 6 days before start time
    * `10080` - 1 week before start time
    * `20160` - 2 weeks before start time
    * `30240` - 3 weeks before start time
    * `40320` - 4 weeks before start time */
  minimum_booking_notice?: ReservationCloseEnum;
  /** Buffer time before appointment in minutes
    
    * `0` - No buffer
    * `5` - 5 min
    * `10` - 10 min
    * `15` - 15 min
    * `30` - 30 min
    * `45` - 45 min
    * `60` - 60 min */
  buffer_before_appointment?: BufferBeforeAppointmentEnum;
  /** Buffer time after appointment in minutes
    
    * `0` - No buffer
    * `5` - 5 min
    * `10` - 10 min
    * `15` - 15 min
    * `30` - 30 min
    * `45` - 45 min
    * `60` - 60 min */
  buffer_after_appointment?: BufferAfterAppointmentEnum;
  /** Time slot granularity in minutes
    
    * `5` - 5 min
    * `10` - 10 min
    * `15` - 15 min
    * `30` - 30 min
    * `45` - 45 min
    * `60` - 60 min
    * `90` - 90 min */
  time_slot_granularity?: TimeSlotGranularityEnum;
  /** Minimum notice required to keep the session in minutes
    
    * `0` - At start time
    * `15` - 15 minutes before start time
    * `30` - 30 minutes before start time
    * `45` - 45 minutes before start time
    * `60` - 1 hour before start time
    * `120` - 2 hours before start time
    * `180` - 3 hours before start time
    * `240` - 4 hours before start time
    * `360` - 6 hours before start time
    * `480` - 8 hours before start time
    * `600` - 10 hours before start time
    * `720` - 12 hours before start time
    * `1440` - 1 day before start time
    * `2880` - 2 days before start time
    * `4320` - 3 days before start time
    * `5760` - 4 days before start time
    * `7200` - 5 days before start time
    * `8640` - 6 days before start time
    * `10080` - 1 week before start time
    * `20160` - 2 weeks before start time
    * `30240` - 3 weeks before start time
    * `40320` - 4 weeks before start time */
  late_cancel?: ReservationCloseEnum;
  image?: string | null;
  staff?: number[];
};
export type PatchedAppointmentTypeRead = {
  id?: number;
  late_cancel_display?: string;
  created_at?: string;
  updated_at?: string;
  name?: string;
  color?: string;
  /** Duration in minutes */
  duration?: number;
  monday_start_time?: string | null;
  monday_end_time?: string | null;
  tuesday_start_time?: string | null;
  tuesday_end_time?: string | null;
  wednesday_start_time?: string | null;
  wednesday_end_time?: string | null;
  thursday_start_time?: string | null;
  thursday_end_time?: string | null;
  friday_start_time?: string | null;
  friday_end_time?: string | null;
  saturday_start_time?: string | null;
  saturday_end_time?: string | null;
  sunday_start_time?: string | null;
  sunday_end_time?: string | null;
  /** Minimum booking notice in minutes
    
    * `0` - At start time
    * `15` - 15 minutes before start time
    * `30` - 30 minutes before start time
    * `45` - 45 minutes before start time
    * `60` - 1 hour before start time
    * `120` - 2 hours before start time
    * `180` - 3 hours before start time
    * `240` - 4 hours before start time
    * `360` - 6 hours before start time
    * `480` - 8 hours before start time
    * `600` - 10 hours before start time
    * `720` - 12 hours before start time
    * `1440` - 1 day before start time
    * `2880` - 2 days before start time
    * `4320` - 3 days before start time
    * `5760` - 4 days before start time
    * `7200` - 5 days before start time
    * `8640` - 6 days before start time
    * `10080` - 1 week before start time
    * `20160` - 2 weeks before start time
    * `30240` - 3 weeks before start time
    * `40320` - 4 weeks before start time */
  minimum_booking_notice?: ReservationCloseEnum;
  /** Buffer time before appointment in minutes
    
    * `0` - No buffer
    * `5` - 5 min
    * `10` - 10 min
    * `15` - 15 min
    * `30` - 30 min
    * `45` - 45 min
    * `60` - 60 min */
  buffer_before_appointment?: BufferBeforeAppointmentEnum;
  /** Buffer time after appointment in minutes
    
    * `0` - No buffer
    * `5` - 5 min
    * `10` - 10 min
    * `15` - 15 min
    * `30` - 30 min
    * `45` - 45 min
    * `60` - 60 min */
  buffer_after_appointment?: BufferAfterAppointmentEnum;
  /** Time slot granularity in minutes
    
    * `5` - 5 min
    * `10` - 10 min
    * `15` - 15 min
    * `30` - 30 min
    * `45` - 45 min
    * `60` - 60 min
    * `90` - 90 min */
  time_slot_granularity?: TimeSlotGranularityEnum;
  /** Minimum notice required to keep the session in minutes
    
    * `0` - At start time
    * `15` - 15 minutes before start time
    * `30` - 30 minutes before start time
    * `45` - 45 minutes before start time
    * `60` - 1 hour before start time
    * `120` - 2 hours before start time
    * `180` - 3 hours before start time
    * `240` - 4 hours before start time
    * `360` - 6 hours before start time
    * `480` - 8 hours before start time
    * `600` - 10 hours before start time
    * `720` - 12 hours before start time
    * `1440` - 1 day before start time
    * `2880` - 2 days before start time
    * `4320` - 3 days before start time
    * `5760` - 4 days before start time
    * `7200` - 5 days before start time
    * `8640` - 6 days before start time
    * `10080` - 1 week before start time
    * `20160` - 2 weeks before start time
    * `30240` - 3 weeks before start time
    * `40320` - 4 weeks before start time */
  late_cancel?: ReservationCloseEnum;
  image?: string | null;
  staff?: number[];
};
export type AppointmentTypesDestroyErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type AppointmentTypesStaffListIdInErrorComponent = {
  attr: Attr12BEnum;
  code: Code90FEnum;
  detail: string;
};
export type AppointmentTypesStaffListError = {
  attr: "id__in";
} & AppointmentTypesStaffListIdInErrorComponent;
export type AppointmentTypesStaffListValidationError = {
  type: TypeA2CEnum;
  errors: AppointmentTypesStaffListError[];
};
export type AppointmentTypesStaffListErrorResponse400 =
  | ({
      type: "validation_error";
    } & AppointmentTypesStaffListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type Attendance = {
  check_out?: string | null;
  user: number;
  employee: number;
};
export type AttendanceRead = {
  id: number;
  created_at: string;
  updated_at: string;
  check_in: string;
  check_out?: string | null;
  polymorphic_ctype: number | null;
  user: number;
  employee: number;
};
export type AttendanceTyped = {
  resourcetype: string;
} & Attendance;
export type AttendanceTypedRead = {
  resourcetype: string;
} & AttendanceRead;
export type UserMembershipAttendance = {
  check_out?: string | null;
  user: number;
  employee: number;
  user_membership: number;
};
export type UserMembershipAttendanceRead = {
  id: number;
  created_at: string;
  updated_at: string;
  check_in: string;
  check_out?: string | null;
  polymorphic_ctype: number | null;
  user: number;
  employee: number;
  user_membership: number;
};
export type UserMembershipAttendanceTyped = {
  resourcetype: string;
} & UserMembershipAttendance;
export type UserMembershipAttendanceTypedRead = {
  resourcetype: string;
} & UserMembershipAttendanceRead;
export type ReservationAttendance = {
  check_out?: string | null;
  user: number;
  employee: number;
  reservation: number;
};
export type ReservationAttendanceRead = {
  id: number;
  created_at: string;
  updated_at: string;
  check_in: string;
  check_out?: string | null;
  polymorphic_ctype: number | null;
  user: number;
  employee: number;
  reservation: number;
};
export type ReservationAttendanceTyped = {
  resourcetype: string;
} & ReservationAttendance;
export type ReservationAttendanceTypedRead = {
  resourcetype: string;
} & ReservationAttendanceRead;
export type AttendancePolymorphic =
  | ({
      resourcetype: "Attendance";
    } & AttendanceTyped)
  | ({
      resourcetype: "UserMembershipAttendance";
    } & UserMembershipAttendanceTyped)
  | ({
      resourcetype: "ReservationAttendance";
    } & ReservationAttendanceTyped);
export type AttendancePolymorphicRead =
  | ({
      resourcetype: "Attendance";
    } & AttendanceTypedRead)
  | ({
      resourcetype: "UserMembershipAttendance";
    } & UserMembershipAttendanceTypedRead)
  | ({
      resourcetype: "ReservationAttendance";
    } & ReservationAttendanceTypedRead);
export type PaginatedAttendancePolymorphicList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: AttendancePolymorphic[];
};
export type PaginatedAttendancePolymorphicListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: AttendancePolymorphicRead[];
};
export type AttendancesListUserErrorComponent = {
  attr: Attr886Enum;
  code: Code099Enum;
  detail: string;
};
export type AttrFf7Enum = "employee";
export type AttendancesListEmployeeErrorComponent = {
  attr: AttrFf7Enum;
  code: Code099Enum;
  detail: string;
};
export type Attr6D2Enum = "check_in";
export type AttendancesListCheckInErrorComponent = {
  attr: Attr6D2Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type Attr7AdEnum = "check_in__range";
export type AttendancesListCheckInRangeErrorComponent = {
  attr: Attr7AdEnum;
  code: Code241Enum;
  detail: string;
};
export type Attr6E1Enum = "check_out";
export type AttendancesListCheckOutErrorComponent = {
  attr: Attr6E1Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AttrC6EEnum = "check_out__range";
export type AttendancesListCheckOutRangeErrorComponent = {
  attr: AttrC6EEnum;
  code: Code241Enum;
  detail: string;
};
export type Attr7DfEnum = "check_in_date";
export type AttendancesListCheckInDateErrorComponent = {
  attr: Attr7DfEnum;
  code: CodeE7CEnum;
  detail: string;
};
export type Attr854Enum = "check_in_date_range";
export type AttendancesListCheckInDateRangeErrorComponent = {
  attr: Attr854Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AttrFaeEnum = "membership_plan__in";
export type AttendancesListMembershipPlanInErrorComponent = {
  attr: AttrFaeEnum;
  code: Code90FEnum;
  detail: string;
};
export type AttendancesListError =
  | ({
      attr: "user";
    } & AttendancesListUserErrorComponent)
  | ({
      attr: "employee";
    } & AttendancesListEmployeeErrorComponent)
  | ({
      attr: "check_in";
    } & AttendancesListCheckInErrorComponent)
  | ({
      attr: "check_in__range";
    } & AttendancesListCheckInRangeErrorComponent)
  | ({
      attr: "check_out";
    } & AttendancesListCheckOutErrorComponent)
  | ({
      attr: "check_out__range";
    } & AttendancesListCheckOutRangeErrorComponent)
  | ({
      attr: "check_in_date";
    } & AttendancesListCheckInDateErrorComponent)
  | ({
      attr: "check_in_date_range";
    } & AttendancesListCheckInDateRangeErrorComponent)
  | ({
      attr: "membership_plan__in";
    } & AttendancesListMembershipPlanInErrorComponent);
export type AttendancesListValidationError = {
  type: TypeA2CEnum;
  errors: AttendancesListError[];
};
export type AttendancesListErrorResponse400 =
  | ({
      type: "validation_error";
    } & AttendancesListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type AttendancesRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type AggregatedUserVisits = {
  data: {
    [key: string]: number;
  };
  percentage_change: number | null;
};
export type AttendancesAggregatedUserVisitsRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type AggregatedUserVisitsList = {
  period: string;
  total: number;
};
export type PaginatedAggregatedUserVisitsListList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: AggregatedUserVisitsList[];
};
export type AttendancesAggregatedUserVisitsListListErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type AttendancesMyAttendancesListUserErrorComponent = {
  attr: Attr886Enum;
  code: Code099Enum;
  detail: string;
};
export type AttendancesMyAttendancesListEmployeeErrorComponent = {
  attr: AttrFf7Enum;
  code: Code099Enum;
  detail: string;
};
export type AttendancesMyAttendancesListCheckInErrorComponent = {
  attr: Attr6D2Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AttendancesMyAttendancesListCheckInRangeErrorComponent = {
  attr: Attr7AdEnum;
  code: Code241Enum;
  detail: string;
};
export type AttendancesMyAttendancesListCheckOutErrorComponent = {
  attr: Attr6E1Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AttendancesMyAttendancesListCheckOutRangeErrorComponent = {
  attr: AttrC6EEnum;
  code: Code241Enum;
  detail: string;
};
export type AttendancesMyAttendancesListCheckInDateErrorComponent = {
  attr: Attr7DfEnum;
  code: CodeE7CEnum;
  detail: string;
};
export type AttendancesMyAttendancesListCheckInDateRangeErrorComponent = {
  attr: Attr854Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type AttendancesMyAttendancesListMembershipPlanInErrorComponent = {
  attr: AttrFaeEnum;
  code: Code90FEnum;
  detail: string;
};
export type AttendancesMyAttendancesListError =
  | ({
      attr: "user";
    } & AttendancesMyAttendancesListUserErrorComponent)
  | ({
      attr: "employee";
    } & AttendancesMyAttendancesListEmployeeErrorComponent)
  | ({
      attr: "check_in";
    } & AttendancesMyAttendancesListCheckInErrorComponent)
  | ({
      attr: "check_in__range";
    } & AttendancesMyAttendancesListCheckInRangeErrorComponent)
  | ({
      attr: "check_out";
    } & AttendancesMyAttendancesListCheckOutErrorComponent)
  | ({
      attr: "check_out__range";
    } & AttendancesMyAttendancesListCheckOutRangeErrorComponent)
  | ({
      attr: "check_in_date";
    } & AttendancesMyAttendancesListCheckInDateErrorComponent)
  | ({
      attr: "check_in_date_range";
    } & AttendancesMyAttendancesListCheckInDateRangeErrorComponent)
  | ({
      attr: "membership_plan__in";
    } & AttendancesMyAttendancesListMembershipPlanInErrorComponent);
export type AttendancesMyAttendancesListValidationError = {
  type: TypeA2CEnum;
  errors: AttendancesMyAttendancesListError[];
};
export type AttendancesMyAttendancesListErrorResponse400 =
  | ({
      type: "validation_error";
    } & AttendancesMyAttendancesListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type AttendancesPerformCheckInCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type Attr7DeEnum = "user_membership";
export type AttendancesPerformCheckInCreateUserMembershipErrorComponent = {
  attr: Attr7DeEnum;
  code: Code6C0Enum;
  detail: string;
};
export type AttendancesPerformCheckInCreateError =
  | ({
      attr: "non_field_errors";
    } & AttendancesPerformCheckInCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "user_membership";
    } & AttendancesPerformCheckInCreateUserMembershipErrorComponent);
export type AttendancesPerformCheckInCreateValidationError = {
  type: TypeA2CEnum;
  errors: AttendancesPerformCheckInCreateError[];
};
export type AttendancesPerformCheckInCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & AttendancesPerformCheckInCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PerformCheckIn = {
  user_membership: number;
};
export type PerformCheckInWithReservation = {
  reservation: number;
};
export type AttendancesPerformCheckInWithReservationCreateNonFieldErrorsErrorComponent =
  {
    attr: AttrD81Enum;
    code: Code023Enum;
    detail: string;
  };
export type AttrC70Enum = "reservation";
export type AttendancesPerformCheckInWithReservationCreateReservationErrorComponent =
  {
    attr: AttrC70Enum;
    code: Code6C0Enum;
    detail: string;
  };
export type AttendancesPerformCheckInWithReservationCreateError =
  | ({
      attr: "non_field_errors";
    } & AttendancesPerformCheckInWithReservationCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "reservation";
    } & AttendancesPerformCheckInWithReservationCreateReservationErrorComponent);
export type AttendancesPerformCheckInWithReservationCreateValidationError = {
  type: TypeA2CEnum;
  errors: AttendancesPerformCheckInWithReservationCreateError[];
};
export type AttendancesPerformCheckInWithReservationCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & AttendancesPerformCheckInWithReservationCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type AttendancesPerformCheckOutCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type AttendancesPerformCheckOutCreateUserErrorComponent = {
  attr: Attr886Enum;
  code: Code6C0Enum;
  detail: string;
};
export type AttendancesPerformCheckOutCreateError =
  | ({
      attr: "non_field_errors";
    } & AttendancesPerformCheckOutCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "user";
    } & AttendancesPerformCheckOutCreateUserErrorComponent);
export type AttendancesPerformCheckOutCreateValidationError = {
  type: TypeA2CEnum;
  errors: AttendancesPerformCheckOutCreateError[];
};
export type AttendancesPerformCheckOutCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & AttendancesPerformCheckOutCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PerformCheckOut = {
  user: number;
};
export type PerformCheckOutWithReservation = {
  reservation: number;
};
export type AttendancesPerformCheckOutWithReservationCreateNonFieldErrorsErrorComponent =
  {
    attr: AttrD81Enum;
    code: Code023Enum;
    detail: string;
  };
export type AttendancesPerformCheckOutWithReservationCreateReservationErrorComponent =
  {
    attr: AttrC70Enum;
    code: Code6C0Enum;
    detail: string;
  };
export type AttendancesPerformCheckOutWithReservationCreateError =
  | ({
      attr: "non_field_errors";
    } & AttendancesPerformCheckOutWithReservationCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "reservation";
    } & AttendancesPerformCheckOutWithReservationCreateReservationErrorComponent);
export type AttendancesPerformCheckOutWithReservationCreateValidationError = {
  type: TypeA2CEnum;
  errors: AttendancesPerformCheckOutWithReservationCreateError[];
};
export type AttendancesPerformCheckOutWithReservationCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & AttendancesPerformCheckOutWithReservationCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type AuthToken = {};
export type AuthTokenRead = {
  token: string;
};
export type AuthTokenWrite = {
  username: string;
  password: string;
};
export type AuthTokenCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type AuthTokenCreateUsernameErrorComponentAttrEnum = "username";
export type CodeA3CEnum =
  | "blank"
  | "invalid"
  | "null"
  | "null_characters_not_allowed"
  | "required"
  | "surrogate_characters_not_allowed";
export type AuthTokenCreateUsernameErrorComponent = {
  attr: AuthTokenCreateUsernameErrorComponentAttrEnum;
  code: CodeA3CEnum;
  detail: string;
};
export type Attr080Enum = "password";
export type AuthTokenCreatePasswordErrorComponent = {
  attr: Attr080Enum;
  code: CodeA3CEnum;
  detail: string;
};
export type AuthTokenCreateError =
  | ({
      attr: "non_field_errors";
    } & AuthTokenCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "username";
    } & AuthTokenCreateUsernameErrorComponent)
  | ({
      attr: "password";
    } & AuthTokenCreatePasswordErrorComponent);
export type AuthTokenCreateValidationError = {
  type: TypeA2CEnum;
  errors: AuthTokenCreateError[];
};
export type AuthTokenCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & AuthTokenCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type AuthDeleteTenantCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type AuthDeleteTenantCreatePasswordErrorComponent = {
  attr: Attr080Enum;
  code: CodeA3CEnum;
  detail: string;
};
export type AuthDeleteTenantCreateError =
  | ({
      attr: "non_field_errors";
    } & AuthDeleteTenantCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "password";
    } & AuthDeleteTenantCreatePasswordErrorComponent);
export type AuthDeleteTenantCreateValidationError = {
  type: TypeA2CEnum;
  errors: AuthDeleteTenantCreateError[];
};
export type AuthDeleteTenantCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & AuthDeleteTenantCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type TenantDelete = {};
export type TenantDeleteWrite = {
  password: string;
};
export type TokenObtainPair = {};
export type TokenObtainPairRead = {
  access: string;
  refresh: string;
};
export type TokenObtainPairWrite = {
  email: string;
  password: string;
};
export type AuthLoginCreateNonFieldErrorsErrorComponentCodeEnum =
  | "invalid"
  | "no_active_account"
  | "null";
export type AuthLoginCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: AuthLoginCreateNonFieldErrorsErrorComponentCodeEnum;
  detail: string;
};
export type Attr307Enum = "email";
export type AuthLoginCreateEmailErrorComponent = {
  attr: Attr307Enum;
  code: CodeA3CEnum;
  detail: string;
};
export type AuthLoginCreatePasswordErrorComponent = {
  attr: Attr080Enum;
  code: CodeA3CEnum;
  detail: string;
};
export type AuthLoginCreateError =
  | ({
      attr: "non_field_errors";
    } & AuthLoginCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "email";
    } & AuthLoginCreateEmailErrorComponent)
  | ({
      attr: "password";
    } & AuthLoginCreatePasswordErrorComponent);
export type AuthLoginCreateValidationError = {
  type: TypeA2CEnum;
  errors: AuthLoginCreateError[];
};
export type AuthLoginCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & AuthLoginCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type RestAuthDetail = {};
export type RestAuthDetailRead = {
  detail: string;
};
export type AuthPasswordChangeCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type Attr70EEnum = "new_password1";
export type Code238Enum =
  | "blank"
  | "invalid"
  | "max_length"
  | "null"
  | "null_characters_not_allowed"
  | "required"
  | "surrogate_characters_not_allowed";
export type AuthPasswordChangeCreateNewPassword1ErrorComponent = {
  attr: Attr70EEnum;
  code: Code238Enum;
  detail: string;
};
export type AttrC17Enum = "new_password2";
export type AuthPasswordChangeCreateNewPassword2ErrorComponent = {
  attr: AttrC17Enum;
  code: Code238Enum;
  detail: string;
};
export type AuthPasswordChangeCreateError =
  | ({
      attr: "non_field_errors";
    } & AuthPasswordChangeCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "new_password1";
    } & AuthPasswordChangeCreateNewPassword1ErrorComponent)
  | ({
      attr: "new_password2";
    } & AuthPasswordChangeCreateNewPassword2ErrorComponent);
export type AuthPasswordChangeCreateValidationError = {
  type: TypeA2CEnum;
  errors: AuthPasswordChangeCreateError[];
};
export type AuthPasswordChangeCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & AuthPasswordChangeCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PasswordChange = {
  new_password1: string;
  new_password2: string;
};
export type AuthPasswordResetCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type AuthPasswordResetCreateEmailErrorComponent = {
  attr: Attr307Enum;
  code: CodeA3CEnum;
  detail: string;
};
export type AuthPasswordResetCreateError =
  | ({
      attr: "non_field_errors";
    } & AuthPasswordResetCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "email";
    } & AuthPasswordResetCreateEmailErrorComponent);
export type AuthPasswordResetCreateValidationError = {
  type: TypeA2CEnum;
  errors: AuthPasswordResetCreateError[];
};
export type AuthPasswordResetCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & AuthPasswordResetCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PasswordReset = {
  email: string;
};
export type AuthPasswordResetConfirmCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type AuthPasswordResetConfirmCreateNewPassword1ErrorComponent = {
  attr: Attr70EEnum;
  code: Code238Enum;
  detail: string;
};
export type AuthPasswordResetConfirmCreateNewPassword2ErrorComponent = {
  attr: AttrC17Enum;
  code: Code238Enum;
  detail: string;
};
export type AuthPasswordResetConfirmCreateUidErrorComponentAttrEnum = "uid";
export type AuthPasswordResetConfirmCreateUidErrorComponent = {
  attr: AuthPasswordResetConfirmCreateUidErrorComponentAttrEnum;
  code: CodeA3CEnum;
  detail: string;
};
export type Attr961Enum = "token";
export type AuthPasswordResetConfirmCreateTokenErrorComponent = {
  attr: Attr961Enum;
  code: CodeA3CEnum;
  detail: string;
};
export type AuthPasswordResetConfirmCreateError =
  | ({
      attr: "non_field_errors";
    } & AuthPasswordResetConfirmCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "new_password1";
    } & AuthPasswordResetConfirmCreateNewPassword1ErrorComponent)
  | ({
      attr: "new_password2";
    } & AuthPasswordResetConfirmCreateNewPassword2ErrorComponent)
  | ({
      attr: "uid";
    } & AuthPasswordResetConfirmCreateUidErrorComponent)
  | ({
      attr: "token";
    } & AuthPasswordResetConfirmCreateTokenErrorComponent);
export type AuthPasswordResetConfirmCreateValidationError = {
  type: TypeA2CEnum;
  errors: AuthPasswordResetConfirmCreateError[];
};
export type AuthPasswordResetConfirmCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & AuthPasswordResetConfirmCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PasswordResetConfirm = {
  new_password1: string;
  new_password2: string;
  uid: string;
  token: string;
};
export type PrimaryBusinessTypeEnum =
  | "Bootcamp (Outdoor)"
  | "Bootcamp (Studio)"
  | "Boxing"
  | "CrossFit"
  | "Health Club"
  | "Martial Arts"
  | "Personal Training"
  | "Pilates"
  | "Spin"
  | "Strength & Conditioning"
  | "Yoga"
  | "Other";
export type BusinessSettings = {
  company_name: string;
  company_website?: string;
  customer_service_email: string;
  customer_service_phone?: string;
  address_1?: string;
  address_2?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  primary_business_type?: PrimaryBusinessTypeEnum;
  coach_label?: string;
  customer_label?: string;
  business_license?: string;
  /** Enter a hex color, e.g., #RRGGBB or #RGB. */
  main_color?: string;
  facebook_page?: string;
  facebook_private_group?: string;
  twitter_handle?: string;
  instagram_username?: string;
  auto_close_invoices?: boolean;
  retry_failed_invoices?: number;
  tax_number?: string;
  logo?: string | null;
};
export type TimezoneEnum =
  | "Africa/Abidjan"
  | "Africa/Accra"
  | "Africa/Addis_Ababa"
  | "Africa/Algiers"
  | "Africa/Asmara"
  | "Africa/Asmera"
  | "Africa/Bamako"
  | "Africa/Bangui"
  | "Africa/Banjul"
  | "Africa/Bissau"
  | "Africa/Blantyre"
  | "Africa/Brazzaville"
  | "Africa/Bujumbura"
  | "Africa/Cairo"
  | "Africa/Casablanca"
  | "Africa/Ceuta"
  | "Africa/Conakry"
  | "Africa/Dakar"
  | "Africa/Dar_es_Salaam"
  | "Africa/Djibouti"
  | "Africa/Douala"
  | "Africa/El_Aaiun"
  | "Africa/Freetown"
  | "Africa/Gaborone"
  | "Africa/Harare"
  | "Africa/Johannesburg"
  | "Africa/Juba"
  | "Africa/Kampala"
  | "Africa/Khartoum"
  | "Africa/Kigali"
  | "Africa/Kinshasa"
  | "Africa/Lagos"
  | "Africa/Libreville"
  | "Africa/Lome"
  | "Africa/Luanda"
  | "Africa/Lubumbashi"
  | "Africa/Lusaka"
  | "Africa/Malabo"
  | "Africa/Maputo"
  | "Africa/Maseru"
  | "Africa/Mbabane"
  | "Africa/Mogadishu"
  | "Africa/Monrovia"
  | "Africa/Nairobi"
  | "Africa/Ndjamena"
  | "Africa/Niamey"
  | "Africa/Nouakchott"
  | "Africa/Ouagadougou"
  | "Africa/Porto-Novo"
  | "Africa/Sao_Tome"
  | "Africa/Timbuktu"
  | "Africa/Tripoli"
  | "Africa/Tunis"
  | "Africa/Windhoek"
  | "America/Adak"
  | "America/Anchorage"
  | "America/Anguilla"
  | "America/Antigua"
  | "America/Araguaina"
  | "America/Argentina/Buenos_Aires"
  | "America/Argentina/Catamarca"
  | "America/Argentina/ComodRivadavia"
  | "America/Argentina/Cordoba"
  | "America/Argentina/Jujuy"
  | "America/Argentina/La_Rioja"
  | "America/Argentina/Mendoza"
  | "America/Argentina/Rio_Gallegos"
  | "America/Argentina/Salta"
  | "America/Argentina/San_Juan"
  | "America/Argentina/San_Luis"
  | "America/Argentina/Tucuman"
  | "America/Argentina/Ushuaia"
  | "America/Aruba"
  | "America/Asuncion"
  | "America/Atikokan"
  | "America/Atka"
  | "America/Bahia"
  | "America/Bahia_Banderas"
  | "America/Barbados"
  | "America/Belem"
  | "America/Belize"
  | "America/Blanc-Sablon"
  | "America/Boa_Vista"
  | "America/Bogota"
  | "America/Boise"
  | "America/Buenos_Aires"
  | "America/Cambridge_Bay"
  | "America/Campo_Grande"
  | "America/Cancun"
  | "America/Caracas"
  | "America/Catamarca"
  | "America/Cayenne"
  | "America/Cayman"
  | "America/Chicago"
  | "America/Chihuahua"
  | "America/Ciudad_Juarez"
  | "America/Coral_Harbour"
  | "America/Cordoba"
  | "America/Costa_Rica"
  | "America/Creston"
  | "America/Cuiaba"
  | "America/Curacao"
  | "America/Danmarkshavn"
  | "America/Dawson"
  | "America/Dawson_Creek"
  | "America/Denver"
  | "America/Detroit"
  | "America/Dominica"
  | "America/Edmonton"
  | "America/Eirunepe"
  | "America/El_Salvador"
  | "America/Ensenada"
  | "America/Fort_Nelson"
  | "America/Fort_Wayne"
  | "America/Fortaleza"
  | "America/Glace_Bay"
  | "America/Godthab"
  | "America/Goose_Bay"
  | "America/Grand_Turk"
  | "America/Grenada"
  | "America/Guadeloupe"
  | "America/Guatemala"
  | "America/Guayaquil"
  | "America/Guyana"
  | "America/Halifax"
  | "America/Havana"
  | "America/Hermosillo"
  | "America/Indiana/Indianapolis"
  | "America/Indiana/Knox"
  | "America/Indiana/Marengo"
  | "America/Indiana/Petersburg"
  | "America/Indiana/Tell_City"
  | "America/Indiana/Vevay"
  | "America/Indiana/Vincennes"
  | "America/Indiana/Winamac"
  | "America/Indianapolis"
  | "America/Inuvik"
  | "America/Iqaluit"
  | "America/Jamaica"
  | "America/Jujuy"
  | "America/Juneau"
  | "America/Kentucky/Louisville"
  | "America/Kentucky/Monticello"
  | "America/Knox_IN"
  | "America/Kralendijk"
  | "America/La_Paz"
  | "America/Lima"
  | "America/Los_Angeles"
  | "America/Louisville"
  | "America/Lower_Princes"
  | "America/Maceio"
  | "America/Managua"
  | "America/Manaus"
  | "America/Marigot"
  | "America/Martinique"
  | "America/Matamoros"
  | "America/Mazatlan"
  | "America/Mendoza"
  | "America/Menominee"
  | "America/Merida"
  | "America/Metlakatla"
  | "America/Mexico_City"
  | "America/Miquelon"
  | "America/Moncton"
  | "America/Monterrey"
  | "America/Montevideo"
  | "America/Montreal"
  | "America/Montserrat"
  | "America/Nassau"
  | "America/New_York"
  | "America/Nipigon"
  | "America/Nome"
  | "America/Noronha"
  | "America/North_Dakota/Beulah"
  | "America/North_Dakota/Center"
  | "America/North_Dakota/New_Salem"
  | "America/Nuuk"
  | "America/Ojinaga"
  | "America/Panama"
  | "America/Pangnirtung"
  | "America/Paramaribo"
  | "America/Phoenix"
  | "America/Port-au-Prince"
  | "America/Port_of_Spain"
  | "America/Porto_Acre"
  | "America/Porto_Velho"
  | "America/Puerto_Rico"
  | "America/Punta_Arenas"
  | "America/Rainy_River"
  | "America/Rankin_Inlet"
  | "America/Recife"
  | "America/Regina"
  | "America/Resolute"
  | "America/Rio_Branco"
  | "America/Rosario"
  | "America/Santa_Isabel"
  | "America/Santarem"
  | "America/Santiago"
  | "America/Santo_Domingo"
  | "America/Sao_Paulo"
  | "America/Scoresbysund"
  | "America/Shiprock"
  | "America/Sitka"
  | "America/St_Barthelemy"
  | "America/St_Johns"
  | "America/St_Kitts"
  | "America/St_Lucia"
  | "America/St_Thomas"
  | "America/St_Vincent"
  | "America/Swift_Current"
  | "America/Tegucigalpa"
  | "America/Thule"
  | "America/Thunder_Bay"
  | "America/Tijuana"
  | "America/Toronto"
  | "America/Tortola"
  | "America/Vancouver"
  | "America/Virgin"
  | "America/Whitehorse"
  | "America/Winnipeg"
  | "America/Yakutat"
  | "America/Yellowknife"
  | "Antarctica/Casey"
  | "Antarctica/Davis"
  | "Antarctica/DumontDUrville"
  | "Antarctica/Macquarie"
  | "Antarctica/Mawson"
  | "Antarctica/McMurdo"
  | "Antarctica/Palmer"
  | "Antarctica/Rothera"
  | "Antarctica/South_Pole"
  | "Antarctica/Syowa"
  | "Antarctica/Troll"
  | "Antarctica/Vostok"
  | "Arctic/Longyearbyen"
  | "Asia/Aden"
  | "Asia/Almaty"
  | "Asia/Amman"
  | "Asia/Anadyr"
  | "Asia/Aqtau"
  | "Asia/Aqtobe"
  | "Asia/Ashgabat"
  | "Asia/Ashkhabad"
  | "Asia/Atyrau"
  | "Asia/Baghdad"
  | "Asia/Bahrain"
  | "Asia/Baku"
  | "Asia/Bangkok"
  | "Asia/Barnaul"
  | "Asia/Beirut"
  | "Asia/Bishkek"
  | "Asia/Brunei"
  | "Asia/Calcutta"
  | "Asia/Chita"
  | "Asia/Choibalsan"
  | "Asia/Chongqing"
  | "Asia/Chungking"
  | "Asia/Colombo"
  | "Asia/Dacca"
  | "Asia/Damascus"
  | "Asia/Dhaka"
  | "Asia/Dili"
  | "Asia/Dubai"
  | "Asia/Dushanbe"
  | "Asia/Famagusta"
  | "Asia/Gaza"
  | "Asia/Harbin"
  | "Asia/Hebron"
  | "Asia/Ho_Chi_Minh"
  | "Asia/Hong_Kong"
  | "Asia/Hovd"
  | "Asia/Irkutsk"
  | "Asia/Istanbul"
  | "Asia/Jakarta"
  | "Asia/Jayapura"
  | "Asia/Jerusalem"
  | "Asia/Kabul"
  | "Asia/Kamchatka"
  | "Asia/Karachi"
  | "Asia/Kashgar"
  | "Asia/Kathmandu"
  | "Asia/Katmandu"
  | "Asia/Khandyga"
  | "Asia/Kolkata"
  | "Asia/Krasnoyarsk"
  | "Asia/Kuala_Lumpur"
  | "Asia/Kuching"
  | "Asia/Kuwait"
  | "Asia/Macao"
  | "Asia/Macau"
  | "Asia/Magadan"
  | "Asia/Makassar"
  | "Asia/Manila"
  | "Asia/Muscat"
  | "Asia/Nicosia"
  | "Asia/Novokuznetsk"
  | "Asia/Novosibirsk"
  | "Asia/Omsk"
  | "Asia/Oral"
  | "Asia/Phnom_Penh"
  | "Asia/Pontianak"
  | "Asia/Pyongyang"
  | "Asia/Qatar"
  | "Asia/Qostanay"
  | "Asia/Qyzylorda"
  | "Asia/Rangoon"
  | "Asia/Riyadh"
  | "Asia/Saigon"
  | "Asia/Sakhalin"
  | "Asia/Samarkand"
  | "Asia/Seoul"
  | "Asia/Shanghai"
  | "Asia/Singapore"
  | "Asia/Srednekolymsk"
  | "Asia/Taipei"
  | "Asia/Tashkent"
  | "Asia/Tbilisi"
  | "Asia/Tehran"
  | "Asia/Tel_Aviv"
  | "Asia/Thimbu"
  | "Asia/Thimphu"
  | "Asia/Tokyo"
  | "Asia/Tomsk"
  | "Asia/Ujung_Pandang"
  | "Asia/Ulaanbaatar"
  | "Asia/Ulan_Bator"
  | "Asia/Urumqi"
  | "Asia/Ust-Nera"
  | "Asia/Vientiane"
  | "Asia/Vladivostok"
  | "Asia/Yakutsk"
  | "Asia/Yangon"
  | "Asia/Yekaterinburg"
  | "Asia/Yerevan"
  | "Atlantic/Azores"
  | "Atlantic/Bermuda"
  | "Atlantic/Canary"
  | "Atlantic/Cape_Verde"
  | "Atlantic/Faeroe"
  | "Atlantic/Faroe"
  | "Atlantic/Jan_Mayen"
  | "Atlantic/Madeira"
  | "Atlantic/Reykjavik"
  | "Atlantic/South_Georgia"
  | "Atlantic/St_Helena"
  | "Atlantic/Stanley"
  | "Australia/ACT"
  | "Australia/Adelaide"
  | "Australia/Brisbane"
  | "Australia/Broken_Hill"
  | "Australia/Canberra"
  | "Australia/Currie"
  | "Australia/Darwin"
  | "Australia/Eucla"
  | "Australia/Hobart"
  | "Australia/LHI"
  | "Australia/Lindeman"
  | "Australia/Lord_Howe"
  | "Australia/Melbourne"
  | "Australia/NSW"
  | "Australia/North"
  | "Australia/Perth"
  | "Australia/Queensland"
  | "Australia/South"
  | "Australia/Sydney"
  | "Australia/Tasmania"
  | "Australia/Victoria"
  | "Australia/West"
  | "Australia/Yancowinna"
  | "Brazil/Acre"
  | "Brazil/DeNoronha"
  | "Brazil/East"
  | "Brazil/West"
  | "CET"
  | "CST6CDT"
  | "Canada/Atlantic"
  | "Canada/Central"
  | "Canada/Eastern"
  | "Canada/Mountain"
  | "Canada/Newfoundland"
  | "Canada/Pacific"
  | "Canada/Saskatchewan"
  | "Canada/Yukon"
  | "Chile/Continental"
  | "Chile/EasterIsland"
  | "Cuba"
  | "EET"
  | "EST"
  | "EST5EDT"
  | "Egypt"
  | "Eire"
  | "Etc/GMT"
  | "Etc/GMT+0"
  | "Etc/GMT+1"
  | "Etc/GMT+10"
  | "Etc/GMT+11"
  | "Etc/GMT+12"
  | "Etc/GMT+2"
  | "Etc/GMT+3"
  | "Etc/GMT+4"
  | "Etc/GMT+5"
  | "Etc/GMT+6"
  | "Etc/GMT+7"
  | "Etc/GMT+8"
  | "Etc/GMT+9"
  | "Etc/GMT-0"
  | "Etc/GMT-1"
  | "Etc/GMT-10"
  | "Etc/GMT-11"
  | "Etc/GMT-12"
  | "Etc/GMT-13"
  | "Etc/GMT-14"
  | "Etc/GMT-2"
  | "Etc/GMT-3"
  | "Etc/GMT-4"
  | "Etc/GMT-5"
  | "Etc/GMT-6"
  | "Etc/GMT-7"
  | "Etc/GMT-8"
  | "Etc/GMT-9"
  | "Etc/GMT0"
  | "Etc/Greenwich"
  | "Etc/UCT"
  | "Etc/UTC"
  | "Etc/Universal"
  | "Etc/Zulu"
  | "Europe/Amsterdam"
  | "Europe/Andorra"
  | "Europe/Astrakhan"
  | "Europe/Athens"
  | "Europe/Belfast"
  | "Europe/Belgrade"
  | "Europe/Berlin"
  | "Europe/Bratislava"
  | "Europe/Brussels"
  | "Europe/Bucharest"
  | "Europe/Budapest"
  | "Europe/Busingen"
  | "Europe/Chisinau"
  | "Europe/Copenhagen"
  | "Europe/Dublin"
  | "Europe/Gibraltar"
  | "Europe/Guernsey"
  | "Europe/Helsinki"
  | "Europe/Isle_of_Man"
  | "Europe/Istanbul"
  | "Europe/Jersey"
  | "Europe/Kaliningrad"
  | "Europe/Kiev"
  | "Europe/Kirov"
  | "Europe/Kyiv"
  | "Europe/Lisbon"
  | "Europe/Ljubljana"
  | "Europe/London"
  | "Europe/Luxembourg"
  | "Europe/Madrid"
  | "Europe/Malta"
  | "Europe/Mariehamn"
  | "Europe/Minsk"
  | "Europe/Monaco"
  | "Europe/Moscow"
  | "Europe/Nicosia"
  | "Europe/Oslo"
  | "Europe/Paris"
  | "Europe/Podgorica"
  | "Europe/Prague"
  | "Europe/Riga"
  | "Europe/Rome"
  | "Europe/Samara"
  | "Europe/San_Marino"
  | "Europe/Sarajevo"
  | "Europe/Saratov"
  | "Europe/Simferopol"
  | "Europe/Skopje"
  | "Europe/Sofia"
  | "Europe/Stockholm"
  | "Europe/Tallinn"
  | "Europe/Tirane"
  | "Europe/Tiraspol"
  | "Europe/Ulyanovsk"
  | "Europe/Uzhgorod"
  | "Europe/Vaduz"
  | "Europe/Vatican"
  | "Europe/Vienna"
  | "Europe/Vilnius"
  | "Europe/Volgograd"
  | "Europe/Warsaw"
  | "Europe/Zagreb"
  | "Europe/Zaporozhye"
  | "Europe/Zurich"
  | "GB"
  | "GB-Eire"
  | "GMT"
  | "GMT+0"
  | "GMT-0"
  | "GMT0"
  | "Greenwich"
  | "HST"
  | "Hongkong"
  | "Iceland"
  | "Indian/Antananarivo"
  | "Indian/Chagos"
  | "Indian/Christmas"
  | "Indian/Cocos"
  | "Indian/Comoro"
  | "Indian/Kerguelen"
  | "Indian/Mahe"
  | "Indian/Maldives"
  | "Indian/Mauritius"
  | "Indian/Mayotte"
  | "Indian/Reunion"
  | "Iran"
  | "Israel"
  | "Jamaica"
  | "Japan"
  | "Kwajalein"
  | "Libya"
  | "MET"
  | "MST"
  | "MST7MDT"
  | "Mexico/BajaNorte"
  | "Mexico/BajaSur"
  | "Mexico/General"
  | "NZ"
  | "NZ-CHAT"
  | "Navajo"
  | "PRC"
  | "PST8PDT"
  | "Pacific/Apia"
  | "Pacific/Auckland"
  | "Pacific/Bougainville"
  | "Pacific/Chatham"
  | "Pacific/Chuuk"
  | "Pacific/Easter"
  | "Pacific/Efate"
  | "Pacific/Enderbury"
  | "Pacific/Fakaofo"
  | "Pacific/Fiji"
  | "Pacific/Funafuti"
  | "Pacific/Galapagos"
  | "Pacific/Gambier"
  | "Pacific/Guadalcanal"
  | "Pacific/Guam"
  | "Pacific/Honolulu"
  | "Pacific/Johnston"
  | "Pacific/Kanton"
  | "Pacific/Kiritimati"
  | "Pacific/Kosrae"
  | "Pacific/Kwajalein"
  | "Pacific/Majuro"
  | "Pacific/Marquesas"
  | "Pacific/Midway"
  | "Pacific/Nauru"
  | "Pacific/Niue"
  | "Pacific/Norfolk"
  | "Pacific/Noumea"
  | "Pacific/Pago_Pago"
  | "Pacific/Palau"
  | "Pacific/Pitcairn"
  | "Pacific/Pohnpei"
  | "Pacific/Ponape"
  | "Pacific/Port_Moresby"
  | "Pacific/Rarotonga"
  | "Pacific/Saipan"
  | "Pacific/Samoa"
  | "Pacific/Tahiti"
  | "Pacific/Tarawa"
  | "Pacific/Tongatapu"
  | "Pacific/Truk"
  | "Pacific/Wake"
  | "Pacific/Wallis"
  | "Pacific/Yap"
  | "Poland"
  | "Portugal"
  | "ROC"
  | "ROK"
  | "Singapore"
  | "Turkey"
  | "UCT"
  | "US/Alaska"
  | "US/Aleutian"
  | "US/Arizona"
  | "US/Central"
  | "US/East-Indiana"
  | "US/Eastern"
  | "US/Hawaii"
  | "US/Indiana-Starke"
  | "US/Michigan"
  | "US/Mountain"
  | "US/Pacific"
  | "US/Samoa"
  | "UTC"
  | "Universal"
  | "W-SU"
  | "WET"
  | "Zulu";
export type CurrencyEnum = "EUR" | "USD" | "BAM";
export type BusinessSettingsRead = {
  id: number;
  country_display: string;
  created_at: string;
  updated_at: string;
  company_name: string;
  company_website?: string;
  customer_service_email: string;
  customer_service_phone?: string;
  address_1?: string;
  address_2?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  country: CountryEnum;
  primary_business_type?: PrimaryBusinessTypeEnum;
  coach_label?: string;
  customer_label?: string;
  business_license?: string;
  /** Enter a hex color, e.g., #RRGGBB or #RGB. */
  main_color?: string;
  timezone: TimezoneEnum;
  facebook_page?: string;
  facebook_private_group?: string;
  twitter_handle?: string;
  instagram_username?: string;
  currency: CurrencyEnum;
  auto_close_invoices?: boolean;
  retry_failed_invoices?: number;
  tax_number?: string;
  logo?: string | null;
};
export type BusinessSettingsRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type BusinessSettingsUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type AttrBf9Enum = "company_name";
export type BusinessSettingsUpdateCompanyNameErrorComponent = {
  attr: AttrBf9Enum;
  code: Code238Enum;
  detail: string;
};
export type Attr927Enum = "company_website";
export type CodeF87Enum =
  | "invalid"
  | "max_length"
  | "null"
  | "null_characters_not_allowed"
  | "surrogate_characters_not_allowed";
export type BusinessSettingsUpdateCompanyWebsiteErrorComponent = {
  attr: Attr927Enum;
  code: CodeF87Enum;
  detail: string;
};
export type AttrE69Enum = "customer_service_email";
export type BusinessSettingsUpdateCustomerServiceEmailErrorComponent = {
  attr: AttrE69Enum;
  code: Code238Enum;
  detail: string;
};
export type Attr730Enum = "customer_service_phone";
export type BusinessSettingsUpdateCustomerServicePhoneErrorComponent = {
  attr: Attr730Enum;
  code: CodeF87Enum;
  detail: string;
};
export type Attr150Enum = "address_1";
export type BusinessSettingsUpdateAddress1ErrorComponent = {
  attr: Attr150Enum;
  code: CodeF87Enum;
  detail: string;
};
export type Attr006Enum = "address_2";
export type BusinessSettingsUpdateAddress2ErrorComponent = {
  attr: Attr006Enum;
  code: CodeF87Enum;
  detail: string;
};
export type Attr001Enum = "city";
export type BusinessSettingsUpdateCityErrorComponent = {
  attr: Attr001Enum;
  code: CodeF87Enum;
  detail: string;
};
export type AttrAbdEnum = "state";
export type BusinessSettingsUpdateStateErrorComponent = {
  attr: AttrAbdEnum;
  code: CodeF87Enum;
  detail: string;
};
export type Attr1DeEnum = "postal_code";
export type BusinessSettingsUpdatePostalCodeErrorComponent = {
  attr: Attr1DeEnum;
  code: CodeF87Enum;
  detail: string;
};
export type Attr14BEnum = "primary_business_type";
export type Code3F2Enum = "invalid_choice" | "null";
export type BusinessSettingsUpdatePrimaryBusinessTypeErrorComponent = {
  attr: Attr14BEnum;
  code: Code3F2Enum;
  detail: string;
};
export type Attr832Enum = "coach_label";
export type Code593Enum =
  | "blank"
  | "invalid"
  | "max_length"
  | "null"
  | "null_characters_not_allowed"
  | "surrogate_characters_not_allowed";
export type BusinessSettingsUpdateCoachLabelErrorComponent = {
  attr: Attr832Enum;
  code: Code593Enum;
  detail: string;
};
export type Attr39FEnum = "customer_label";
export type BusinessSettingsUpdateCustomerLabelErrorComponent = {
  attr: Attr39FEnum;
  code: Code593Enum;
  detail: string;
};
export type AttrDcdEnum = "business_license";
export type BusinessSettingsUpdateBusinessLicenseErrorComponent = {
  attr: AttrDcdEnum;
  code: CodeF87Enum;
  detail: string;
};
export type Attr656Enum = "main_color";
export type Code81DEnum =
  | "invalid"
  | "invalid_hex_color"
  | "max_length"
  | "null"
  | "null_characters_not_allowed"
  | "surrogate_characters_not_allowed";
export type BusinessSettingsUpdateMainColorErrorComponent = {
  attr: Attr656Enum;
  code: Code81DEnum;
  detail: string;
};
export type AttrE41Enum = "facebook_page";
export type BusinessSettingsUpdateFacebookPageErrorComponent = {
  attr: AttrE41Enum;
  code: CodeF87Enum;
  detail: string;
};
export type Attr62BEnum = "facebook_private_group";
export type BusinessSettingsUpdateFacebookPrivateGroupErrorComponent = {
  attr: Attr62BEnum;
  code: CodeF87Enum;
  detail: string;
};
export type Attr91CEnum = "twitter_handle";
export type BusinessSettingsUpdateTwitterHandleErrorComponent = {
  attr: Attr91CEnum;
  code: CodeF87Enum;
  detail: string;
};
export type Attr980Enum = "instagram_username";
export type BusinessSettingsUpdateInstagramUsernameErrorComponent = {
  attr: Attr980Enum;
  code: CodeF87Enum;
  detail: string;
};
export type Attr3C2Enum = "auto_close_invoices";
export type BusinessSettingsUpdateAutoCloseInvoicesErrorComponent = {
  attr: Attr3C2Enum;
  code: Code023Enum;
  detail: string;
};
export type Attr39AEnum = "retry_failed_invoices";
export type Code9E1Enum =
  | "invalid"
  | "max_string_length"
  | "max_value"
  | "min_value"
  | "null";
export type BusinessSettingsUpdateRetryFailedInvoicesErrorComponent = {
  attr: Attr39AEnum;
  code: Code9E1Enum;
  detail: string;
};
export type AttrE23Enum = "tax_number";
export type BusinessSettingsUpdateTaxNumberErrorComponent = {
  attr: AttrE23Enum;
  code: CodeF87Enum;
  detail: string;
};
export type Attr021Enum = "logo";
export type BusinessSettingsUpdateLogoErrorComponent = {
  attr: Attr021Enum;
  code: Code40EEnum;
  detail: string;
};
export type BusinessSettingsUpdateError =
  | ({
      attr: "non_field_errors";
    } & BusinessSettingsUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "company_name";
    } & BusinessSettingsUpdateCompanyNameErrorComponent)
  | ({
      attr: "company_website";
    } & BusinessSettingsUpdateCompanyWebsiteErrorComponent)
  | ({
      attr: "customer_service_email";
    } & BusinessSettingsUpdateCustomerServiceEmailErrorComponent)
  | ({
      attr: "customer_service_phone";
    } & BusinessSettingsUpdateCustomerServicePhoneErrorComponent)
  | ({
      attr: "address_1";
    } & BusinessSettingsUpdateAddress1ErrorComponent)
  | ({
      attr: "address_2";
    } & BusinessSettingsUpdateAddress2ErrorComponent)
  | ({
      attr: "city";
    } & BusinessSettingsUpdateCityErrorComponent)
  | ({
      attr: "state";
    } & BusinessSettingsUpdateStateErrorComponent)
  | ({
      attr: "postal_code";
    } & BusinessSettingsUpdatePostalCodeErrorComponent)
  | ({
      attr: "primary_business_type";
    } & BusinessSettingsUpdatePrimaryBusinessTypeErrorComponent)
  | ({
      attr: "coach_label";
    } & BusinessSettingsUpdateCoachLabelErrorComponent)
  | ({
      attr: "customer_label";
    } & BusinessSettingsUpdateCustomerLabelErrorComponent)
  | ({
      attr: "business_license";
    } & BusinessSettingsUpdateBusinessLicenseErrorComponent)
  | ({
      attr: "main_color";
    } & BusinessSettingsUpdateMainColorErrorComponent)
  | ({
      attr: "facebook_page";
    } & BusinessSettingsUpdateFacebookPageErrorComponent)
  | ({
      attr: "facebook_private_group";
    } & BusinessSettingsUpdateFacebookPrivateGroupErrorComponent)
  | ({
      attr: "twitter_handle";
    } & BusinessSettingsUpdateTwitterHandleErrorComponent)
  | ({
      attr: "instagram_username";
    } & BusinessSettingsUpdateInstagramUsernameErrorComponent)
  | ({
      attr: "auto_close_invoices";
    } & BusinessSettingsUpdateAutoCloseInvoicesErrorComponent)
  | ({
      attr: "retry_failed_invoices";
    } & BusinessSettingsUpdateRetryFailedInvoicesErrorComponent)
  | ({
      attr: "tax_number";
    } & BusinessSettingsUpdateTaxNumberErrorComponent)
  | ({
      attr: "logo";
    } & BusinessSettingsUpdateLogoErrorComponent);
export type BusinessSettingsUpdateValidationError = {
  type: TypeA2CEnum;
  errors: BusinessSettingsUpdateError[];
};
export type BusinessSettingsUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & BusinessSettingsUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type BusinessSettingsPartialUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type BusinessSettingsPartialUpdateCompanyNameErrorComponent = {
  attr: AttrBf9Enum;
  code: Code238Enum;
  detail: string;
};
export type BusinessSettingsPartialUpdateCompanyWebsiteErrorComponent = {
  attr: Attr927Enum;
  code: CodeF87Enum;
  detail: string;
};
export type BusinessSettingsPartialUpdateCustomerServiceEmailErrorComponent = {
  attr: AttrE69Enum;
  code: Code238Enum;
  detail: string;
};
export type BusinessSettingsPartialUpdateCustomerServicePhoneErrorComponent = {
  attr: Attr730Enum;
  code: CodeF87Enum;
  detail: string;
};
export type BusinessSettingsPartialUpdateAddress1ErrorComponent = {
  attr: Attr150Enum;
  code: CodeF87Enum;
  detail: string;
};
export type BusinessSettingsPartialUpdateAddress2ErrorComponent = {
  attr: Attr006Enum;
  code: CodeF87Enum;
  detail: string;
};
export type BusinessSettingsPartialUpdateCityErrorComponent = {
  attr: Attr001Enum;
  code: CodeF87Enum;
  detail: string;
};
export type BusinessSettingsPartialUpdateStateErrorComponent = {
  attr: AttrAbdEnum;
  code: CodeF87Enum;
  detail: string;
};
export type BusinessSettingsPartialUpdatePostalCodeErrorComponent = {
  attr: Attr1DeEnum;
  code: CodeF87Enum;
  detail: string;
};
export type BusinessSettingsPartialUpdatePrimaryBusinessTypeErrorComponent = {
  attr: Attr14BEnum;
  code: Code3F2Enum;
  detail: string;
};
export type BusinessSettingsPartialUpdateCoachLabelErrorComponent = {
  attr: Attr832Enum;
  code: Code593Enum;
  detail: string;
};
export type BusinessSettingsPartialUpdateCustomerLabelErrorComponent = {
  attr: Attr39FEnum;
  code: Code593Enum;
  detail: string;
};
export type BusinessSettingsPartialUpdateBusinessLicenseErrorComponent = {
  attr: AttrDcdEnum;
  code: CodeF87Enum;
  detail: string;
};
export type BusinessSettingsPartialUpdateMainColorErrorComponent = {
  attr: Attr656Enum;
  code: Code81DEnum;
  detail: string;
};
export type BusinessSettingsPartialUpdateFacebookPageErrorComponent = {
  attr: AttrE41Enum;
  code: CodeF87Enum;
  detail: string;
};
export type BusinessSettingsPartialUpdateFacebookPrivateGroupErrorComponent = {
  attr: Attr62BEnum;
  code: CodeF87Enum;
  detail: string;
};
export type BusinessSettingsPartialUpdateTwitterHandleErrorComponent = {
  attr: Attr91CEnum;
  code: CodeF87Enum;
  detail: string;
};
export type BusinessSettingsPartialUpdateInstagramUsernameErrorComponent = {
  attr: Attr980Enum;
  code: CodeF87Enum;
  detail: string;
};
export type BusinessSettingsPartialUpdateAutoCloseInvoicesErrorComponent = {
  attr: Attr3C2Enum;
  code: Code023Enum;
  detail: string;
};
export type BusinessSettingsPartialUpdateRetryFailedInvoicesErrorComponent = {
  attr: Attr39AEnum;
  code: Code9E1Enum;
  detail: string;
};
export type BusinessSettingsPartialUpdateTaxNumberErrorComponent = {
  attr: AttrE23Enum;
  code: CodeF87Enum;
  detail: string;
};
export type BusinessSettingsPartialUpdateLogoErrorComponent = {
  attr: Attr021Enum;
  code: Code40EEnum;
  detail: string;
};
export type BusinessSettingsPartialUpdateError =
  | ({
      attr: "non_field_errors";
    } & BusinessSettingsPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "company_name";
    } & BusinessSettingsPartialUpdateCompanyNameErrorComponent)
  | ({
      attr: "company_website";
    } & BusinessSettingsPartialUpdateCompanyWebsiteErrorComponent)
  | ({
      attr: "customer_service_email";
    } & BusinessSettingsPartialUpdateCustomerServiceEmailErrorComponent)
  | ({
      attr: "customer_service_phone";
    } & BusinessSettingsPartialUpdateCustomerServicePhoneErrorComponent)
  | ({
      attr: "address_1";
    } & BusinessSettingsPartialUpdateAddress1ErrorComponent)
  | ({
      attr: "address_2";
    } & BusinessSettingsPartialUpdateAddress2ErrorComponent)
  | ({
      attr: "city";
    } & BusinessSettingsPartialUpdateCityErrorComponent)
  | ({
      attr: "state";
    } & BusinessSettingsPartialUpdateStateErrorComponent)
  | ({
      attr: "postal_code";
    } & BusinessSettingsPartialUpdatePostalCodeErrorComponent)
  | ({
      attr: "primary_business_type";
    } & BusinessSettingsPartialUpdatePrimaryBusinessTypeErrorComponent)
  | ({
      attr: "coach_label";
    } & BusinessSettingsPartialUpdateCoachLabelErrorComponent)
  | ({
      attr: "customer_label";
    } & BusinessSettingsPartialUpdateCustomerLabelErrorComponent)
  | ({
      attr: "business_license";
    } & BusinessSettingsPartialUpdateBusinessLicenseErrorComponent)
  | ({
      attr: "main_color";
    } & BusinessSettingsPartialUpdateMainColorErrorComponent)
  | ({
      attr: "facebook_page";
    } & BusinessSettingsPartialUpdateFacebookPageErrorComponent)
  | ({
      attr: "facebook_private_group";
    } & BusinessSettingsPartialUpdateFacebookPrivateGroupErrorComponent)
  | ({
      attr: "twitter_handle";
    } & BusinessSettingsPartialUpdateTwitterHandleErrorComponent)
  | ({
      attr: "instagram_username";
    } & BusinessSettingsPartialUpdateInstagramUsernameErrorComponent)
  | ({
      attr: "auto_close_invoices";
    } & BusinessSettingsPartialUpdateAutoCloseInvoicesErrorComponent)
  | ({
      attr: "retry_failed_invoices";
    } & BusinessSettingsPartialUpdateRetryFailedInvoicesErrorComponent)
  | ({
      attr: "tax_number";
    } & BusinessSettingsPartialUpdateTaxNumberErrorComponent)
  | ({
      attr: "logo";
    } & BusinessSettingsPartialUpdateLogoErrorComponent);
export type BusinessSettingsPartialUpdateValidationError = {
  type: TypeA2CEnum;
  errors: BusinessSettingsPartialUpdateError[];
};
export type BusinessSettingsPartialUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & BusinessSettingsPartialUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PatchedBusinessSettings = {
  company_name?: string;
  company_website?: string;
  customer_service_email?: string;
  customer_service_phone?: string;
  address_1?: string;
  address_2?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  primary_business_type?: PrimaryBusinessTypeEnum;
  coach_label?: string;
  customer_label?: string;
  business_license?: string;
  /** Enter a hex color, e.g., #RRGGBB or #RGB. */
  main_color?: string;
  facebook_page?: string;
  facebook_private_group?: string;
  twitter_handle?: string;
  instagram_username?: string;
  auto_close_invoices?: boolean;
  retry_failed_invoices?: number;
  tax_number?: string;
  logo?: string | null;
};
export type PatchedBusinessSettingsRead = {
  id?: number;
  country_display?: string;
  created_at?: string;
  updated_at?: string;
  company_name?: string;
  company_website?: string;
  customer_service_email?: string;
  customer_service_phone?: string;
  address_1?: string;
  address_2?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  country?: CountryEnum;
  primary_business_type?: PrimaryBusinessTypeEnum;
  coach_label?: string;
  customer_label?: string;
  business_license?: string;
  /** Enter a hex color, e.g., #RRGGBB or #RGB. */
  main_color?: string;
  timezone?: TimezoneEnum;
  facebook_page?: string;
  facebook_private_group?: string;
  twitter_handle?: string;
  instagram_username?: string;
  currency?: CurrencyEnum;
  auto_close_invoices?: boolean;
  retry_failed_invoices?: number;
  tax_number?: string;
  logo?: string | null;
};
export type BusinessSettingsHistorical = {
  primary_business_type: string;
  timezone: string;
  currency: string;
  id?: number;
  company_name: string;
  company_website?: string;
  customer_service_email: string;
  customer_service_phone?: string;
  address_1?: string;
  address_2?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  country: CountryEnum;
  coach_label?: string;
  customer_label?: string;
  business_license?: string;
  /** Enter a hex color, e.g., #RRGGBB or #RGB. */
  main_color?: string;
  facebook_page?: string;
  facebook_private_group?: string;
  twitter_handle?: string;
  instagram_username?: string;
  auto_close_invoices?: boolean;
  retry_failed_invoices?: number;
  tax_number?: string;
  logo?: string | null;
  history_date: string;
  history_change_reason?: string | null;
  history_type: HistoryTypeEnum;
  history_user?: number | null;
};
export type BusinessSettingsHistoricalRead = {
  history_id: number;
  primary_business_type: string;
  timezone: string;
  currency: string;
  id?: number;
  company_name: string;
  company_website?: string;
  customer_service_email: string;
  customer_service_phone?: string;
  address_1?: string;
  address_2?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  country: CountryEnum;
  coach_label?: string;
  customer_label?: string;
  business_license?: string;
  /** Enter a hex color, e.g., #RRGGBB or #RGB. */
  main_color?: string;
  facebook_page?: string;
  facebook_private_group?: string;
  twitter_handle?: string;
  instagram_username?: string;
  auto_close_invoices?: boolean;
  retry_failed_invoices?: number;
  tax_number?: string;
  logo?: string | null;
  history_date: string;
  history_change_reason?: string | null;
  history_type: HistoryTypeEnum;
  history_user?: number | null;
};
export type PaginatedBusinessSettingsHistoricalList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: BusinessSettingsHistorical[];
};
export type PaginatedBusinessSettingsHistoricalListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: BusinessSettingsHistoricalRead[];
};
export type BusinessSettingsHistoryListErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type BusinessSettingsHistoryRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type CalendarRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type ClassReservation = {
  start_date_time: string;
  user: number;
  class_instance: number;
  user_membership: number;
};
export type ClassReservationRead = {
  id: number;
  is_expired: string;
  status: string;
  status_display: string;
  created_at: string;
  updated_at: string;
  reserved_at: string;
  start_date_time: string;
  polymorphic_ctype: number | null;
  user: number;
  class_instance: number;
  user_membership: number;
};
export type PaginatedClassReservationList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: ClassReservation[];
};
export type PaginatedClassReservationListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: ClassReservationRead[];
};
export type ClassReservationsListUserErrorComponent = {
  attr: Attr886Enum;
  code: Code099Enum;
  detail: string;
};
export type AttrF7BEnum = "class_instance";
export type ClassReservationsListClassInstanceErrorComponent = {
  attr: AttrF7BEnum;
  code: Code099Enum;
  detail: string;
};
export type ClassReservationsListStartDateTimeErrorComponent = {
  attr: Attr632Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type ClassReservationsListStartDateTimeRangeErrorComponent = {
  attr: AttrCf5Enum;
  code: Code241Enum;
  detail: string;
};
export type ClassReservationsListStatusErrorComponent = {
  attr: AttrA16Enum;
  code: Code099Enum;
  detail: string;
};
export type ClassReservationsListError =
  | ({
      attr: "user";
    } & ClassReservationsListUserErrorComponent)
  | ({
      attr: "class_instance";
    } & ClassReservationsListClassInstanceErrorComponent)
  | ({
      attr: "start_date_time";
    } & ClassReservationsListStartDateTimeErrorComponent)
  | ({
      attr: "start_date_time__range";
    } & ClassReservationsListStartDateTimeRangeErrorComponent)
  | ({
      attr: "status";
    } & ClassReservationsListStatusErrorComponent);
export type ClassReservationsListValidationError = {
  type: TypeA2CEnum;
  errors: ClassReservationsListError[];
};
export type ClassReservationsListErrorResponse400 =
  | ({
      type: "validation_error";
    } & ClassReservationsListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type ClassReservationsRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type ClassReservationsCancelReservationDestroyErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type ClassReservationsReserveClassCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type ClassReservationsReserveClassCreateClassInstanceErrorComponent = {
  attr: AttrF7BEnum;
  code: Code6C0Enum;
  detail: string;
};
export type ClassReservationsReserveClassCreateUserMembershipErrorComponent = {
  attr: Attr7DeEnum;
  code: Code6C0Enum;
  detail: string;
};
export type ClassReservationsReserveClassCreateError =
  | ({
      attr: "non_field_errors";
    } & ClassReservationsReserveClassCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "class_instance";
    } & ClassReservationsReserveClassCreateClassInstanceErrorComponent)
  | ({
      attr: "user_membership";
    } & ClassReservationsReserveClassCreateUserMembershipErrorComponent);
export type ClassReservationsReserveClassCreateValidationError = {
  type: TypeA2CEnum;
  errors: ClassReservationsReserveClassCreateError[];
};
export type ClassReservationsReserveClassCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & ClassReservationsReserveClassCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type ReserveClass = {
  class_instance: number;
  user_membership: number;
};
export type ReservationOpenEnum =
  | 0
  | 15
  | 30
  | 45
  | 60
  | 120
  | 180
  | 240
  | 360
  | 480
  | 600
  | 720
  | 1440
  | 2880
  | 4320
  | 5760
  | 7200
  | 8640
  | 10080
  | 20160
  | 30240
  | 40320;
export type ClassType = {
  name: string;
  color: string;
  /** If enabled, classes of this type will NOT count against limited plans or punchcards */
  is_free_session?: boolean;
  description?: string | null;
  /** Duration in minutes */
  duration: number;
  reservation_open?: ReservationOpenEnum;
  reservation_close?: ReservationCloseEnum;
  limit_attendance?: boolean;
  attendance_limit?: number | null;
  late_cancel?: ReservationCloseEnum;
  image?: string | null;
};
export type ClassTypeRead = {
  id: number;
  late_cancel_display: string;
  created_at: string;
  updated_at: string;
  name: string;
  color: string;
  /** If enabled, classes of this type will NOT count against limited plans or punchcards */
  is_free_session?: boolean;
  description?: string | null;
  /** Duration in minutes */
  duration: number;
  reservation_open?: ReservationOpenEnum;
  reservation_close?: ReservationCloseEnum;
  limit_attendance?: boolean;
  attendance_limit?: number | null;
  late_cancel?: ReservationCloseEnum;
  image?: string | null;
};
export type PaginatedClassTypeList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: ClassType[];
};
export type PaginatedClassTypeListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: ClassTypeRead[];
};
export type ClassTypesListIdInErrorComponent = {
  attr: Attr12BEnum;
  code: Code90FEnum;
  detail: string;
};
export type ClassTypesListError = {
  attr: "id__in";
} & ClassTypesListIdInErrorComponent;
export type ClassTypesListValidationError = {
  type: TypeA2CEnum;
  errors: ClassTypesListError[];
};
export type ClassTypesListErrorResponse400 =
  | ({
      type: "validation_error";
    } & ClassTypesListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type ClassTypesCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type ClassTypesCreateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code6AeEnum;
  detail: string;
};
export type ClassTypesCreateColorErrorComponent = {
  attr: Attr5C1Enum;
  code: CodeAc9Enum;
  detail: string;
};
export type Attr3C4Enum = "is_free_session";
export type ClassTypesCreateIsFreeSessionErrorComponent = {
  attr: Attr3C4Enum;
  code: Code023Enum;
  detail: string;
};
export type CodeE4EEnum =
  | "invalid"
  | "null_characters_not_allowed"
  | "surrogate_characters_not_allowed";
export type ClassTypesCreateDescriptionErrorComponent = {
  attr: Attr50BEnum;
  code: CodeE4EEnum;
  detail: string;
};
export type ClassTypesCreateDurationErrorComponent = {
  attr: AttrFd6Enum;
  code: Code937Enum;
  detail: string;
};
export type AttrF2CEnum = "reservation_open";
export type ClassTypesCreateReservationOpenErrorComponent = {
  attr: AttrF2CEnum;
  code: Code729Enum;
  detail: string;
};
export type Attr59BEnum = "reservation_close";
export type ClassTypesCreateReservationCloseErrorComponent = {
  attr: Attr59BEnum;
  code: Code729Enum;
  detail: string;
};
export type Attr518Enum = "limit_attendance";
export type ClassTypesCreateLimitAttendanceErrorComponent = {
  attr: Attr518Enum;
  code: Code023Enum;
  detail: string;
};
export type Attr181Enum = "attendance_limit";
export type CodeB7BEnum =
  | "invalid"
  | "max_string_length"
  | "max_value"
  | "min_value";
export type ClassTypesCreateAttendanceLimitErrorComponent = {
  attr: Attr181Enum;
  code: CodeB7BEnum;
  detail: string;
};
export type ClassTypesCreateLateCancelErrorComponent = {
  attr: Attr2C0Enum;
  code: Code729Enum;
  detail: string;
};
export type ClassTypesCreateImageErrorComponent = {
  attr: Attr470Enum;
  code: Code40EEnum;
  detail: string;
};
export type ClassTypesCreateError =
  | ({
      attr: "non_field_errors";
    } & ClassTypesCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & ClassTypesCreateNameErrorComponent)
  | ({
      attr: "color";
    } & ClassTypesCreateColorErrorComponent)
  | ({
      attr: "is_free_session";
    } & ClassTypesCreateIsFreeSessionErrorComponent)
  | ({
      attr: "description";
    } & ClassTypesCreateDescriptionErrorComponent)
  | ({
      attr: "duration";
    } & ClassTypesCreateDurationErrorComponent)
  | ({
      attr: "reservation_open";
    } & ClassTypesCreateReservationOpenErrorComponent)
  | ({
      attr: "reservation_close";
    } & ClassTypesCreateReservationCloseErrorComponent)
  | ({
      attr: "limit_attendance";
    } & ClassTypesCreateLimitAttendanceErrorComponent)
  | ({
      attr: "attendance_limit";
    } & ClassTypesCreateAttendanceLimitErrorComponent)
  | ({
      attr: "late_cancel";
    } & ClassTypesCreateLateCancelErrorComponent)
  | ({
      attr: "image";
    } & ClassTypesCreateImageErrorComponent);
export type ClassTypesCreateValidationError = {
  type: TypeA2CEnum;
  errors: ClassTypesCreateError[];
};
export type ClassTypesCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & ClassTypesCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type ClassTypesRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type ClassTypesUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type ClassTypesUpdateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code6AeEnum;
  detail: string;
};
export type ClassTypesUpdateColorErrorComponent = {
  attr: Attr5C1Enum;
  code: CodeAc9Enum;
  detail: string;
};
export type ClassTypesUpdateIsFreeSessionErrorComponent = {
  attr: Attr3C4Enum;
  code: Code023Enum;
  detail: string;
};
export type ClassTypesUpdateDescriptionErrorComponent = {
  attr: Attr50BEnum;
  code: CodeE4EEnum;
  detail: string;
};
export type ClassTypesUpdateDurationErrorComponent = {
  attr: AttrFd6Enum;
  code: Code937Enum;
  detail: string;
};
export type ClassTypesUpdateReservationOpenErrorComponent = {
  attr: AttrF2CEnum;
  code: Code729Enum;
  detail: string;
};
export type ClassTypesUpdateReservationCloseErrorComponent = {
  attr: Attr59BEnum;
  code: Code729Enum;
  detail: string;
};
export type ClassTypesUpdateLimitAttendanceErrorComponent = {
  attr: Attr518Enum;
  code: Code023Enum;
  detail: string;
};
export type ClassTypesUpdateAttendanceLimitErrorComponent = {
  attr: Attr181Enum;
  code: CodeB7BEnum;
  detail: string;
};
export type ClassTypesUpdateLateCancelErrorComponent = {
  attr: Attr2C0Enum;
  code: Code729Enum;
  detail: string;
};
export type ClassTypesUpdateImageErrorComponent = {
  attr: Attr470Enum;
  code: Code40EEnum;
  detail: string;
};
export type ClassTypesUpdateError =
  | ({
      attr: "non_field_errors";
    } & ClassTypesUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & ClassTypesUpdateNameErrorComponent)
  | ({
      attr: "color";
    } & ClassTypesUpdateColorErrorComponent)
  | ({
      attr: "is_free_session";
    } & ClassTypesUpdateIsFreeSessionErrorComponent)
  | ({
      attr: "description";
    } & ClassTypesUpdateDescriptionErrorComponent)
  | ({
      attr: "duration";
    } & ClassTypesUpdateDurationErrorComponent)
  | ({
      attr: "reservation_open";
    } & ClassTypesUpdateReservationOpenErrorComponent)
  | ({
      attr: "reservation_close";
    } & ClassTypesUpdateReservationCloseErrorComponent)
  | ({
      attr: "limit_attendance";
    } & ClassTypesUpdateLimitAttendanceErrorComponent)
  | ({
      attr: "attendance_limit";
    } & ClassTypesUpdateAttendanceLimitErrorComponent)
  | ({
      attr: "late_cancel";
    } & ClassTypesUpdateLateCancelErrorComponent)
  | ({
      attr: "image";
    } & ClassTypesUpdateImageErrorComponent);
export type ClassTypesUpdateValidationError = {
  type: TypeA2CEnum;
  errors: ClassTypesUpdateError[];
};
export type ClassTypesUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & ClassTypesUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type ClassTypesPartialUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type ClassTypesPartialUpdateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code6AeEnum;
  detail: string;
};
export type ClassTypesPartialUpdateColorErrorComponent = {
  attr: Attr5C1Enum;
  code: CodeAc9Enum;
  detail: string;
};
export type ClassTypesPartialUpdateIsFreeSessionErrorComponent = {
  attr: Attr3C4Enum;
  code: Code023Enum;
  detail: string;
};
export type ClassTypesPartialUpdateDescriptionErrorComponent = {
  attr: Attr50BEnum;
  code: CodeE4EEnum;
  detail: string;
};
export type ClassTypesPartialUpdateDurationErrorComponent = {
  attr: AttrFd6Enum;
  code: Code937Enum;
  detail: string;
};
export type ClassTypesPartialUpdateReservationOpenErrorComponent = {
  attr: AttrF2CEnum;
  code: Code729Enum;
  detail: string;
};
export type ClassTypesPartialUpdateReservationCloseErrorComponent = {
  attr: Attr59BEnum;
  code: Code729Enum;
  detail: string;
};
export type ClassTypesPartialUpdateLimitAttendanceErrorComponent = {
  attr: Attr518Enum;
  code: Code023Enum;
  detail: string;
};
export type ClassTypesPartialUpdateAttendanceLimitErrorComponent = {
  attr: Attr181Enum;
  code: CodeB7BEnum;
  detail: string;
};
export type ClassTypesPartialUpdateLateCancelErrorComponent = {
  attr: Attr2C0Enum;
  code: Code729Enum;
  detail: string;
};
export type ClassTypesPartialUpdateImageErrorComponent = {
  attr: Attr470Enum;
  code: Code40EEnum;
  detail: string;
};
export type ClassTypesPartialUpdateError =
  | ({
      attr: "non_field_errors";
    } & ClassTypesPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & ClassTypesPartialUpdateNameErrorComponent)
  | ({
      attr: "color";
    } & ClassTypesPartialUpdateColorErrorComponent)
  | ({
      attr: "is_free_session";
    } & ClassTypesPartialUpdateIsFreeSessionErrorComponent)
  | ({
      attr: "description";
    } & ClassTypesPartialUpdateDescriptionErrorComponent)
  | ({
      attr: "duration";
    } & ClassTypesPartialUpdateDurationErrorComponent)
  | ({
      attr: "reservation_open";
    } & ClassTypesPartialUpdateReservationOpenErrorComponent)
  | ({
      attr: "reservation_close";
    } & ClassTypesPartialUpdateReservationCloseErrorComponent)
  | ({
      attr: "limit_attendance";
    } & ClassTypesPartialUpdateLimitAttendanceErrorComponent)
  | ({
      attr: "attendance_limit";
    } & ClassTypesPartialUpdateAttendanceLimitErrorComponent)
  | ({
      attr: "late_cancel";
    } & ClassTypesPartialUpdateLateCancelErrorComponent)
  | ({
      attr: "image";
    } & ClassTypesPartialUpdateImageErrorComponent);
export type ClassTypesPartialUpdateValidationError = {
  type: TypeA2CEnum;
  errors: ClassTypesPartialUpdateError[];
};
export type ClassTypesPartialUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & ClassTypesPartialUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PatchedClassType = {
  name?: string;
  color?: string;
  /** If enabled, classes of this type will NOT count against limited plans or punchcards */
  is_free_session?: boolean;
  description?: string | null;
  /** Duration in minutes */
  duration?: number;
  reservation_open?: ReservationOpenEnum;
  reservation_close?: ReservationCloseEnum;
  limit_attendance?: boolean;
  attendance_limit?: number | null;
  late_cancel?: ReservationCloseEnum;
  image?: string | null;
};
export type PatchedClassTypeRead = {
  id?: number;
  late_cancel_display?: string;
  created_at?: string;
  updated_at?: string;
  name?: string;
  color?: string;
  /** If enabled, classes of this type will NOT count against limited plans or punchcards */
  is_free_session?: boolean;
  description?: string | null;
  /** Duration in minutes */
  duration?: number;
  reservation_open?: ReservationOpenEnum;
  reservation_close?: ReservationCloseEnum;
  limit_attendance?: boolean;
  attendance_limit?: number | null;
  late_cancel?: ReservationCloseEnum;
  image?: string | null;
};
export type ClassTypesDestroyErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type Class = {
  date: string;
  start_time: string;
  end_time: string;
  coach?: number | null;
};
export type ClassRead = {
  id: number;
  spots_left: number | null;
  spots_taken: number;
  status: string;
  reservation: string;
  created_at: string;
  updated_at: string;
  date: string;
  start_time: string;
  end_time: string;
  class_type: number;
  coach?: number | null;
};
export type PaginatedClassList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Class[];
};
export type PaginatedClassListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: ClassRead[];
};
export type Attr37EEnum = "date";
export type ClassesListDateErrorComponent = {
  attr: Attr37EEnum;
  code: CodeE7CEnum;
  detail: string;
};
export type Attr8C0Enum = "date__range";
export type ClassesListDateRangeErrorComponent = {
  attr: Attr8C0Enum;
  code: Code241Enum;
  detail: string;
};
export type AttrB5AEnum = "class_type";
export type ClassesListClassTypeErrorComponent = {
  attr: AttrB5AEnum;
  code: Code099Enum;
  detail: string;
};
export type Attr724Enum = "class_type__in";
export type ClassesListClassTypeInErrorComponent = {
  attr: Attr724Enum;
  code: Code099Enum;
  detail: string;
};
export type Attr7D0Enum = "coach";
export type ClassesListCoachErrorComponent = {
  attr: Attr7D0Enum;
  code: Code099Enum;
  detail: string;
};
export type Attr6BcEnum = "coach__in";
export type ClassesListCoachInErrorComponent = {
  attr: Attr6BcEnum;
  code: Code099Enum;
  detail: string;
};
export type ClassesListError =
  | ({
      attr: "date";
    } & ClassesListDateErrorComponent)
  | ({
      attr: "date__range";
    } & ClassesListDateRangeErrorComponent)
  | ({
      attr: "class_type";
    } & ClassesListClassTypeErrorComponent)
  | ({
      attr: "class_type__in";
    } & ClassesListClassTypeInErrorComponent)
  | ({
      attr: "coach";
    } & ClassesListCoachErrorComponent)
  | ({
      attr: "coach__in";
    } & ClassesListCoachInErrorComponent);
export type ClassesListValidationError = {
  type: TypeA2CEnum;
  errors: ClassesListError[];
};
export type ClassesListErrorResponse400 =
  | ({
      type: "validation_error";
    } & ClassesListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type ClassesRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type ClassesUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type ClassesUpdateDateErrorComponent = {
  attr: Attr37EEnum;
  code: Code0DbEnum;
  detail: string;
};
export type ClassesUpdateStartTimeErrorComponent = {
  attr: Attr2A0Enum;
  code: CodeF7EEnum;
  detail: string;
};
export type AttrFc2Enum = "end_time";
export type ClassesUpdateEndTimeErrorComponent = {
  attr: AttrFc2Enum;
  code: CodeF7EEnum;
  detail: string;
};
export type Code948Enum = "does_not_exist" | "incorrect_type";
export type ClassesUpdateCoachErrorComponent = {
  attr: Attr7D0Enum;
  code: Code948Enum;
  detail: string;
};
export type ClassesUpdateError =
  | ({
      attr: "non_field_errors";
    } & ClassesUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "date";
    } & ClassesUpdateDateErrorComponent)
  | ({
      attr: "start_time";
    } & ClassesUpdateStartTimeErrorComponent)
  | ({
      attr: "end_time";
    } & ClassesUpdateEndTimeErrorComponent)
  | ({
      attr: "coach";
    } & ClassesUpdateCoachErrorComponent);
export type ClassesUpdateValidationError = {
  type: TypeA2CEnum;
  errors: ClassesUpdateError[];
};
export type ClassesUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & ClassesUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type ClassesPartialUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type ClassesPartialUpdateDateErrorComponent = {
  attr: Attr37EEnum;
  code: Code0DbEnum;
  detail: string;
};
export type ClassesPartialUpdateStartTimeErrorComponent = {
  attr: Attr2A0Enum;
  code: CodeF7EEnum;
  detail: string;
};
export type ClassesPartialUpdateEndTimeErrorComponent = {
  attr: AttrFc2Enum;
  code: CodeF7EEnum;
  detail: string;
};
export type ClassesPartialUpdateCoachErrorComponent = {
  attr: Attr7D0Enum;
  code: Code948Enum;
  detail: string;
};
export type ClassesPartialUpdateError =
  | ({
      attr: "non_field_errors";
    } & ClassesPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "date";
    } & ClassesPartialUpdateDateErrorComponent)
  | ({
      attr: "start_time";
    } & ClassesPartialUpdateStartTimeErrorComponent)
  | ({
      attr: "end_time";
    } & ClassesPartialUpdateEndTimeErrorComponent)
  | ({
      attr: "coach";
    } & ClassesPartialUpdateCoachErrorComponent);
export type ClassesPartialUpdateValidationError = {
  type: TypeA2CEnum;
  errors: ClassesPartialUpdateError[];
};
export type ClassesPartialUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & ClassesPartialUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PatchedClass = {
  date?: string;
  start_time?: string;
  end_time?: string;
  coach?: number | null;
};
export type PatchedClassRead = {
  id?: number;
  spots_left?: number | null;
  spots_taken?: number;
  status?: string;
  reservation?: string;
  created_at?: string;
  updated_at?: string;
  date?: string;
  start_time?: string;
  end_time?: string;
  class_type?: number;
  coach?: number | null;
};
export type ClassesDestroyErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type ClassesAttendeesListDateErrorComponent = {
  attr: Attr37EEnum;
  code: CodeE7CEnum;
  detail: string;
};
export type ClassesAttendeesListDateRangeErrorComponent = {
  attr: Attr8C0Enum;
  code: Code241Enum;
  detail: string;
};
export type ClassesAttendeesListClassTypeErrorComponent = {
  attr: AttrB5AEnum;
  code: Code099Enum;
  detail: string;
};
export type ClassesAttendeesListClassTypeInErrorComponent = {
  attr: Attr724Enum;
  code: Code099Enum;
  detail: string;
};
export type ClassesAttendeesListCoachErrorComponent = {
  attr: Attr7D0Enum;
  code: Code099Enum;
  detail: string;
};
export type ClassesAttendeesListCoachInErrorComponent = {
  attr: Attr6BcEnum;
  code: Code099Enum;
  detail: string;
};
export type ClassesAttendeesListError =
  | ({
      attr: "date";
    } & ClassesAttendeesListDateErrorComponent)
  | ({
      attr: "date__range";
    } & ClassesAttendeesListDateRangeErrorComponent)
  | ({
      attr: "class_type";
    } & ClassesAttendeesListClassTypeErrorComponent)
  | ({
      attr: "class_type__in";
    } & ClassesAttendeesListClassTypeInErrorComponent)
  | ({
      attr: "coach";
    } & ClassesAttendeesListCoachErrorComponent)
  | ({
      attr: "coach__in";
    } & ClassesAttendeesListCoachInErrorComponent);
export type ClassesAttendeesListValidationError = {
  type: TypeA2CEnum;
  errors: ClassesAttendeesListError[];
};
export type ClassesAttendeesListErrorResponse400 =
  | ({
      type: "validation_error";
    } & ClassesAttendeesListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type ClassesCreateClassesCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type ClassesCreateClassesCreateClassTypeErrorComponent = {
  attr: AttrB5AEnum;
  code: Code6C0Enum;
  detail: string;
};
export type ClassesCreateClassesCreateStartDateErrorComponent = {
  attr: Attr92BEnum;
  code: Code0DbEnum;
  detail: string;
};
export type Attr637Enum = "end_date";
export type CodeCeaEnum = "datetime" | "invalid";
export type ClassesCreateClassesCreateEndDateErrorComponent = {
  attr: Attr637Enum;
  code: CodeCeaEnum;
  detail: string;
};
export type ClassesCreateClassesCreateRepeatsErrorComponentAttrEnum = "repeats";
export type Code4EfEnum = "invalid_choice" | "null" | "required";
export type ClassesCreateClassesCreateRepeatsErrorComponent = {
  attr: ClassesCreateClassesCreateRepeatsErrorComponentAttrEnum;
  code: Code4EfEnum;
  detail: string;
};
export type ClassesCreateClassesCreateEndConditionErrorComponentAttrEnum =
  "end_condition";
export type ClassesCreateClassesCreateEndConditionErrorComponent = {
  attr: ClassesCreateClassesCreateEndConditionErrorComponentAttrEnum;
  code: Code099Enum;
  detail: string;
};
export type ClassesCreateClassesCreateOccurrencesErrorComponentAttrEnum =
  "occurrences";
export type ClassesCreateClassesCreateOccurrencesErrorComponentCodeEnum =
  | "invalid"
  | "max_string_length"
  | "min_value";
export type ClassesCreateClassesCreateOccurrencesErrorComponent = {
  attr: ClassesCreateClassesCreateOccurrencesErrorComponentAttrEnum;
  code: ClassesCreateClassesCreateOccurrencesErrorComponentCodeEnum;
  detail: string;
};
export type ClassesCreateClassesCreateRepeatsOnMondayErrorComponentAttrEnum =
  "repeats_on_monday";
export type ClassesCreateClassesCreateRepeatsOnMondayErrorComponent = {
  attr: ClassesCreateClassesCreateRepeatsOnMondayErrorComponentAttrEnum;
  code: Code023Enum;
  detail: string;
};
export type ClassesCreateClassesCreateRepeatsOnTuesdayErrorComponentAttrEnum =
  "repeats_on_tuesday";
export type ClassesCreateClassesCreateRepeatsOnTuesdayErrorComponent = {
  attr: ClassesCreateClassesCreateRepeatsOnTuesdayErrorComponentAttrEnum;
  code: Code023Enum;
  detail: string;
};
export type ClassesCreateClassesCreateRepeatsOnWednesdayErrorComponentAttrEnum =
  "repeats_on_wednesday";
export type ClassesCreateClassesCreateRepeatsOnWednesdayErrorComponent = {
  attr: ClassesCreateClassesCreateRepeatsOnWednesdayErrorComponentAttrEnum;
  code: Code023Enum;
  detail: string;
};
export type ClassesCreateClassesCreateRepeatsOnThursdayErrorComponentAttrEnum =
  "repeats_on_thursday";
export type ClassesCreateClassesCreateRepeatsOnThursdayErrorComponent = {
  attr: ClassesCreateClassesCreateRepeatsOnThursdayErrorComponentAttrEnum;
  code: Code023Enum;
  detail: string;
};
export type ClassesCreateClassesCreateRepeatsOnFridayErrorComponentAttrEnum =
  "repeats_on_friday";
export type ClassesCreateClassesCreateRepeatsOnFridayErrorComponent = {
  attr: ClassesCreateClassesCreateRepeatsOnFridayErrorComponentAttrEnum;
  code: Code023Enum;
  detail: string;
};
export type ClassesCreateClassesCreateRepeatsOnSaturdayErrorComponentAttrEnum =
  "repeats_on_saturday";
export type ClassesCreateClassesCreateRepeatsOnSaturdayErrorComponent = {
  attr: ClassesCreateClassesCreateRepeatsOnSaturdayErrorComponentAttrEnum;
  code: Code023Enum;
  detail: string;
};
export type ClassesCreateClassesCreateRepeatsOnSundayErrorComponentAttrEnum =
  "repeats_on_sunday";
export type ClassesCreateClassesCreateRepeatsOnSundayErrorComponent = {
  attr: ClassesCreateClassesCreateRepeatsOnSundayErrorComponentAttrEnum;
  code: Code023Enum;
  detail: string;
};
export type ClassesCreateClassesCreateStartTimeErrorComponent = {
  attr: Attr2A0Enum;
  code: CodeF7EEnum;
  detail: string;
};
export type ClassesCreateClassesCreateCoachErrorComponent = {
  attr: Attr7D0Enum;
  code: CodeC2FEnum;
  detail: string;
};
export type ClassesCreateClassesCreateError =
  | ({
      attr: "non_field_errors";
    } & ClassesCreateClassesCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "class_type";
    } & ClassesCreateClassesCreateClassTypeErrorComponent)
  | ({
      attr: "start_date";
    } & ClassesCreateClassesCreateStartDateErrorComponent)
  | ({
      attr: "end_date";
    } & ClassesCreateClassesCreateEndDateErrorComponent)
  | ({
      attr: "repeats";
    } & ClassesCreateClassesCreateRepeatsErrorComponent)
  | ({
      attr: "end_condition";
    } & ClassesCreateClassesCreateEndConditionErrorComponent)
  | ({
      attr: "occurrences";
    } & ClassesCreateClassesCreateOccurrencesErrorComponent)
  | ({
      attr: "repeats_on_monday";
    } & ClassesCreateClassesCreateRepeatsOnMondayErrorComponent)
  | ({
      attr: "repeats_on_tuesday";
    } & ClassesCreateClassesCreateRepeatsOnTuesdayErrorComponent)
  | ({
      attr: "repeats_on_wednesday";
    } & ClassesCreateClassesCreateRepeatsOnWednesdayErrorComponent)
  | ({
      attr: "repeats_on_thursday";
    } & ClassesCreateClassesCreateRepeatsOnThursdayErrorComponent)
  | ({
      attr: "repeats_on_friday";
    } & ClassesCreateClassesCreateRepeatsOnFridayErrorComponent)
  | ({
      attr: "repeats_on_saturday";
    } & ClassesCreateClassesCreateRepeatsOnSaturdayErrorComponent)
  | ({
      attr: "repeats_on_sunday";
    } & ClassesCreateClassesCreateRepeatsOnSundayErrorComponent)
  | ({
      attr: "start_time";
    } & ClassesCreateClassesCreateStartTimeErrorComponent)
  | ({
      attr: "coach";
    } & ClassesCreateClassesCreateCoachErrorComponent);
export type ClassesCreateClassesCreateValidationError = {
  type: TypeA2CEnum;
  errors: ClassesCreateClassesCreateError[];
};
export type ClassesCreateClassesCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & ClassesCreateClassesCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type RepeatsEnum =
  | "Every week"
  | "Every 2 weeks"
  | "Every 3 weeks"
  | "Every 4 weeks"
  | "Does not repeat";
export type EndConditionEnum = "On a date" | "After";
export type NullEnum = null;
export type CreateClass = {
  class_type: number;
  start_date: string;
  end_date?: string | null;
  repeats: RepeatsEnum;
  end_condition?: (EndConditionEnum | NullEnum) | null;
  occurrences?: number | null;
  repeats_on_monday?: boolean;
  repeats_on_tuesday?: boolean;
  repeats_on_wednesday?: boolean;
  repeats_on_thursday?: boolean;
  repeats_on_friday?: boolean;
  repeats_on_saturday?: boolean;
  repeats_on_sunday?: boolean;
  start_time: string;
  coach?: number;
};
export type CommunicationHistoryTypeEnum = "email";
export type CommunicationHistory = {
  type: CommunicationHistoryTypeEnum;
  subject: string;
  content: string;
  user: number;
};
export type CommunicationHistoryRead = {
  id: number;
  type_display: string;
  created_at: string;
  updated_at: string;
  date: string;
  type: CommunicationHistoryTypeEnum;
  subject: string;
  content: string;
  user: number;
};
export type PaginatedCommunicationHistoryList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: CommunicationHistory[];
};
export type PaginatedCommunicationHistoryListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: CommunicationHistoryRead[];
};
export type CommunicationHistoryListUserErrorComponent = {
  attr: Attr886Enum;
  code: Code099Enum;
  detail: string;
};
export type CommunicationHistoryListError = {
  attr: "user";
} & CommunicationHistoryListUserErrorComponent;
export type CommunicationHistoryListValidationError = {
  type: TypeA2CEnum;
  errors: CommunicationHistoryListError[];
};
export type CommunicationHistoryListErrorResponse400 =
  | ({
      type: "validation_error";
    } & CommunicationHistoryListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type CommunicationHistoryRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type CommunicationHistoryMarkAsReadCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type AttrE62Enum = "type";
export type CommunicationHistoryMarkAsReadCreateTypeErrorComponent = {
  attr: AttrE62Enum;
  code: Code4EfEnum;
  detail: string;
};
export type AttrFefEnum = "subject";
export type CommunicationHistoryMarkAsReadCreateSubjectErrorComponent = {
  attr: AttrFefEnum;
  code: Code238Enum;
  detail: string;
};
export type Attr0E2Enum = "content";
export type CommunicationHistoryMarkAsReadCreateContentErrorComponent = {
  attr: Attr0E2Enum;
  code: CodeA3CEnum;
  detail: string;
};
export type CommunicationHistoryMarkAsReadCreateUserErrorComponent = {
  attr: Attr886Enum;
  code: Code6C0Enum;
  detail: string;
};
export type CommunicationHistoryMarkAsReadCreateError =
  | ({
      attr: "non_field_errors";
    } & CommunicationHistoryMarkAsReadCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "type";
    } & CommunicationHistoryMarkAsReadCreateTypeErrorComponent)
  | ({
      attr: "subject";
    } & CommunicationHistoryMarkAsReadCreateSubjectErrorComponent)
  | ({
      attr: "content";
    } & CommunicationHistoryMarkAsReadCreateContentErrorComponent)
  | ({
      attr: "user";
    } & CommunicationHistoryMarkAsReadCreateUserErrorComponent);
export type CommunicationHistoryMarkAsReadCreateValidationError = {
  type: TypeA2CEnum;
  errors: CommunicationHistoryMarkAsReadCreateError[];
};
export type CommunicationHistoryMarkAsReadCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & CommunicationHistoryMarkAsReadCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type CommunicationHistoryMyCommunicationHistoryListUserErrorComponent = {
  attr: Attr886Enum;
  code: Code099Enum;
  detail: string;
};
export type CommunicationHistoryMyCommunicationHistoryListError = {
  attr: "user";
} & CommunicationHistoryMyCommunicationHistoryListUserErrorComponent;
export type CommunicationHistoryMyCommunicationHistoryListValidationError = {
  type: TypeA2CEnum;
  errors: CommunicationHistoryMyCommunicationHistoryListError[];
};
export type CommunicationHistoryMyCommunicationHistoryListErrorResponse400 =
  | ({
      type: "validation_error";
    } & CommunicationHistoryMyCommunicationHistoryListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type Client = {
  name: string;
  email: string;
};
export type ClientRead = {
  name: string;
  email: string;
  domain: string;
  stripe_customer: string | null;
  stripe_account: string | null;
  logo: string;
};
export type MembersCountEnum =
  | "50"
  | "100"
  | "150"
  | "200"
  | "300"
  | "400"
  | "500"
  | "unlimited";
export type PaymentIntervalEnum = "month" | "year";
export type ClientWrite = {
  name: string;
  domain_url: string;
  email: string;
  password: string;
  admin_name: string;
  country: CountryEnum;
  timezone: TimezoneEnum;
  members_count: MembersCountEnum;
  payment_interval: PaymentIntervalEnum;
};
export type ConnectionTestRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type Country = {
  code: string;
  name: string;
};
export type CountriesRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type CreditPackage = {
  credits: number;
  expiration_date_time: string;
  appointment_type: number;
  user: number;
};
export type CreditPackageRead = {
  id: number;
  is_active: boolean;
  created_at: string;
  updated_at: string;
  credits: number;
  expiration_date_time: string;
  appointment_type: number;
  created_by: number;
  invoice: string | null;
  user: number;
};
export type PaginatedCreditPackageList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: CreditPackage[];
};
export type PaginatedCreditPackageListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: CreditPackageRead[];
};
export type Attr44AEnum = "appointment_type";
export type CreditPackagesListAppointmentTypeErrorComponent = {
  attr: Attr44AEnum;
  code: Code099Enum;
  detail: string;
};
export type CreditPackagesListUserErrorComponent = {
  attr: Attr886Enum;
  code: Code099Enum;
  detail: string;
};
export type CreditPackagesListError =
  | ({
      attr: "appointment_type";
    } & CreditPackagesListAppointmentTypeErrorComponent)
  | ({
      attr: "user";
    } & CreditPackagesListUserErrorComponent);
export type CreditPackagesListValidationError = {
  type: TypeA2CEnum;
  errors: CreditPackagesListError[];
};
export type CreditPackagesListErrorResponse400 =
  | ({
      type: "validation_error";
    } & CreditPackagesListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type CreditPackagesRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type CheckoutSessionResponse = {
  checkout_url: string;
};
export type CreditPackagesAddByAppointmentPackageCardCreateNonFieldErrorsErrorComponent =
  {
    attr: AttrD81Enum;
    code: Code023Enum;
    detail: string;
  };
export type CreditPackagesAddByAppointmentPackageCardCreateAppointmentTypeErrorComponent =
  {
    attr: Attr44AEnum;
    code: Code6C0Enum;
    detail: string;
  };
export type Attr332Enum = "appointment_package";
export type CreditPackagesAddByAppointmentPackageCardCreateAppointmentPackageErrorComponent =
  {
    attr: Attr332Enum;
    code: Code6C0Enum;
    detail: string;
  };
export type CreditPackagesAddByAppointmentPackageCardCreateUserErrorComponent =
  {
    attr: Attr886Enum;
    code: Code6C0Enum;
    detail: string;
  };
export type Attr513Enum = "discount";
export type CreditPackagesAddByAppointmentPackageCardCreateDiscountErrorComponent =
  {
    attr: Attr513Enum;
    code: Code948Enum;
    detail: string;
  };
export type Attr9E6Enum = "payment_received";
export type CreditPackagesAddByAppointmentPackageCardCreatePaymentReceivedErrorComponent =
  {
    attr: Attr9E6Enum;
    code: Code023Enum;
    detail: string;
  };
export type CreditPackagesAddByAppointmentPackageCardCreateError =
  | ({
      attr: "non_field_errors";
    } & CreditPackagesAddByAppointmentPackageCardCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "appointment_type";
    } & CreditPackagesAddByAppointmentPackageCardCreateAppointmentTypeErrorComponent)
  | ({
      attr: "appointment_package";
    } & CreditPackagesAddByAppointmentPackageCardCreateAppointmentPackageErrorComponent)
  | ({
      attr: "user";
    } & CreditPackagesAddByAppointmentPackageCardCreateUserErrorComponent)
  | ({
      attr: "discount";
    } & CreditPackagesAddByAppointmentPackageCardCreateDiscountErrorComponent)
  | ({
      attr: "payment_received";
    } & CreditPackagesAddByAppointmentPackageCardCreatePaymentReceivedErrorComponent);
export type CreditPackagesAddByAppointmentPackageCardCreateValidationError = {
  type: TypeA2CEnum;
  errors: CreditPackagesAddByAppointmentPackageCardCreateError[];
};
export type CreditPackagesAddByAppointmentPackageCardCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & CreditPackagesAddByAppointmentPackageCardCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type AddCreditsByAppointmentPackage = {
  appointment_type: number;
  appointment_package: number;
  user: number;
  discount?: number | null;
  payment_received?: boolean;
};
export type CreditPackagesAddByAppointmentPackageCashCreateNonFieldErrorsErrorComponent =
  {
    attr: AttrD81Enum;
    code: Code023Enum;
    detail: string;
  };
export type CreditPackagesAddByAppointmentPackageCashCreateAppointmentTypeErrorComponent =
  {
    attr: Attr44AEnum;
    code: Code6C0Enum;
    detail: string;
  };
export type CreditPackagesAddByAppointmentPackageCashCreateAppointmentPackageErrorComponent =
  {
    attr: Attr332Enum;
    code: Code6C0Enum;
    detail: string;
  };
export type CreditPackagesAddByAppointmentPackageCashCreateUserErrorComponent =
  {
    attr: Attr886Enum;
    code: Code6C0Enum;
    detail: string;
  };
export type CreditPackagesAddByAppointmentPackageCashCreateDiscountErrorComponent =
  {
    attr: Attr513Enum;
    code: Code948Enum;
    detail: string;
  };
export type CreditPackagesAddByAppointmentPackageCashCreatePaymentReceivedErrorComponent =
  {
    attr: Attr9E6Enum;
    code: Code023Enum;
    detail: string;
  };
export type CreditPackagesAddByAppointmentPackageCashCreateError =
  | ({
      attr: "non_field_errors";
    } & CreditPackagesAddByAppointmentPackageCashCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "appointment_type";
    } & CreditPackagesAddByAppointmentPackageCashCreateAppointmentTypeErrorComponent)
  | ({
      attr: "appointment_package";
    } & CreditPackagesAddByAppointmentPackageCashCreateAppointmentPackageErrorComponent)
  | ({
      attr: "user";
    } & CreditPackagesAddByAppointmentPackageCashCreateUserErrorComponent)
  | ({
      attr: "discount";
    } & CreditPackagesAddByAppointmentPackageCashCreateDiscountErrorComponent)
  | ({
      attr: "payment_received";
    } & CreditPackagesAddByAppointmentPackageCashCreatePaymentReceivedErrorComponent);
export type CreditPackagesAddByAppointmentPackageCashCreateValidationError = {
  type: TypeA2CEnum;
  errors: CreditPackagesAddByAppointmentPackageCashCreateError[];
};
export type CreditPackagesAddByAppointmentPackageCashCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & CreditPackagesAddByAppointmentPackageCashCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type CreditPackagesAddCardCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type CreditPackagesAddCardCreateAppointmentTypeErrorComponent = {
  attr: Attr44AEnum;
  code: Code6C0Enum;
  detail: string;
};
export type CreditPackagesAddCardCreateUserErrorComponent = {
  attr: Attr886Enum;
  code: Code6C0Enum;
  detail: string;
};
export type Code2EcEnum =
  | "invalid"
  | "max_string_length"
  | "min_value"
  | "null"
  | "required";
export type CreditPackagesAddCardCreateExpireCreditsAfterErrorComponent = {
  attr: AttrFc6Enum;
  code: Code2EcEnum;
  detail: string;
};
export type CreditPackagesAddCardCreateCreditsErrorComponent = {
  attr: Attr4FfEnum;
  code: Code2EcEnum;
  detail: string;
};
export type Code820Enum =
  | "invalid"
  | "max_decimal_places"
  | "max_digits"
  | "max_string_length"
  | "max_whole_digits"
  | "null"
  | "required";
export type CreditPackagesAddCardCreatePackagePriceErrorComponent = {
  attr: AttrD3FEnum;
  code: Code820Enum;
  detail: string;
};
export type CreditPackagesAddCardCreateDiscountErrorComponent = {
  attr: Attr513Enum;
  code: Code948Enum;
  detail: string;
};
export type CreditPackagesAddCardCreatePaymentReceivedErrorComponent = {
  attr: Attr9E6Enum;
  code: Code023Enum;
  detail: string;
};
export type CreditPackagesAddCardCreateError =
  | ({
      attr: "non_field_errors";
    } & CreditPackagesAddCardCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "appointment_type";
    } & CreditPackagesAddCardCreateAppointmentTypeErrorComponent)
  | ({
      attr: "user";
    } & CreditPackagesAddCardCreateUserErrorComponent)
  | ({
      attr: "expire_credits_after";
    } & CreditPackagesAddCardCreateExpireCreditsAfterErrorComponent)
  | ({
      attr: "credits";
    } & CreditPackagesAddCardCreateCreditsErrorComponent)
  | ({
      attr: "package_price";
    } & CreditPackagesAddCardCreatePackagePriceErrorComponent)
  | ({
      attr: "discount";
    } & CreditPackagesAddCardCreateDiscountErrorComponent)
  | ({
      attr: "payment_received";
    } & CreditPackagesAddCardCreatePaymentReceivedErrorComponent);
export type CreditPackagesAddCardCreateValidationError = {
  type: TypeA2CEnum;
  errors: CreditPackagesAddCardCreateError[];
};
export type CreditPackagesAddCardCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & CreditPackagesAddCardCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type AddCredits = {
  appointment_type: number;
  user: number;
  expire_credits_after: number;
  credits: number;
  package_price: string;
  discount?: number | null;
  payment_received?: boolean;
};
export type CreditPackagesAddCashCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type CreditPackagesAddCashCreateAppointmentTypeErrorComponent = {
  attr: Attr44AEnum;
  code: Code6C0Enum;
  detail: string;
};
export type CreditPackagesAddCashCreateUserErrorComponent = {
  attr: Attr886Enum;
  code: Code6C0Enum;
  detail: string;
};
export type CreditPackagesAddCashCreateExpireCreditsAfterErrorComponent = {
  attr: AttrFc6Enum;
  code: Code2EcEnum;
  detail: string;
};
export type CreditPackagesAddCashCreateCreditsErrorComponent = {
  attr: Attr4FfEnum;
  code: Code2EcEnum;
  detail: string;
};
export type CreditPackagesAddCashCreatePackagePriceErrorComponent = {
  attr: AttrD3FEnum;
  code: Code820Enum;
  detail: string;
};
export type CreditPackagesAddCashCreateDiscountErrorComponent = {
  attr: Attr513Enum;
  code: Code948Enum;
  detail: string;
};
export type CreditPackagesAddCashCreatePaymentReceivedErrorComponent = {
  attr: Attr9E6Enum;
  code: Code023Enum;
  detail: string;
};
export type CreditPackagesAddCashCreateError =
  | ({
      attr: "non_field_errors";
    } & CreditPackagesAddCashCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "appointment_type";
    } & CreditPackagesAddCashCreateAppointmentTypeErrorComponent)
  | ({
      attr: "user";
    } & CreditPackagesAddCashCreateUserErrorComponent)
  | ({
      attr: "expire_credits_after";
    } & CreditPackagesAddCashCreateExpireCreditsAfterErrorComponent)
  | ({
      attr: "credits";
    } & CreditPackagesAddCashCreateCreditsErrorComponent)
  | ({
      attr: "package_price";
    } & CreditPackagesAddCashCreatePackagePriceErrorComponent)
  | ({
      attr: "discount";
    } & CreditPackagesAddCashCreateDiscountErrorComponent)
  | ({
      attr: "payment_received";
    } & CreditPackagesAddCashCreatePaymentReceivedErrorComponent);
export type CreditPackagesAddCashCreateValidationError = {
  type: TypeA2CEnum;
  errors: CreditPackagesAddCashCreateError[];
};
export type CreditPackagesAddCashCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & CreditPackagesAddCashCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PaymentIntentResponse = {
  payment_intent_client_secret: string;
  ephemeral_key_secret: string;
};
export type CreditPackagesBuyByAppointmentPackageCreateNonFieldErrorsErrorComponent =
  {
    attr: AttrD81Enum;
    code: Code023Enum;
    detail: string;
  };
export type CreditPackagesBuyByAppointmentPackageCreateAppointmentPackageErrorComponent =
  {
    attr: Attr332Enum;
    code: Code6C0Enum;
    detail: string;
  };
export type AttrCa4Enum = "discount_code";
export type Code23DEnum =
  | "blank"
  | "invalid"
  | "null_characters_not_allowed"
  | "surrogate_characters_not_allowed";
export type CreditPackagesBuyByAppointmentPackageCreateDiscountCodeErrorComponent =
  {
    attr: AttrCa4Enum;
    code: Code23DEnum;
    detail: string;
  };
export type CreditPackagesBuyByAppointmentPackageCreateError =
  | ({
      attr: "non_field_errors";
    } & CreditPackagesBuyByAppointmentPackageCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "appointment_package";
    } & CreditPackagesBuyByAppointmentPackageCreateAppointmentPackageErrorComponent)
  | ({
      attr: "discount_code";
    } & CreditPackagesBuyByAppointmentPackageCreateDiscountCodeErrorComponent);
export type CreditPackagesBuyByAppointmentPackageCreateValidationError = {
  type: TypeA2CEnum;
  errors: CreditPackagesBuyByAppointmentPackageCreateError[];
};
export type CreditPackagesBuyByAppointmentPackageCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & CreditPackagesBuyByAppointmentPackageCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type BuyCreditsByAppointmentPackage = {
  appointment_package: number;
  discount_code?: string | null;
};
export type CreditPackagesMyCreditPackagesListAppointmentTypeErrorComponent = {
  attr: Attr44AEnum;
  code: Code099Enum;
  detail: string;
};
export type CreditPackagesMyCreditPackagesListUserErrorComponent = {
  attr: Attr886Enum;
  code: Code099Enum;
  detail: string;
};
export type CreditPackagesMyCreditPackagesListError =
  | ({
      attr: "appointment_type";
    } & CreditPackagesMyCreditPackagesListAppointmentTypeErrorComponent)
  | ({
      attr: "user";
    } & CreditPackagesMyCreditPackagesListUserErrorComponent);
export type CreditPackagesMyCreditPackagesListValidationError = {
  type: TypeA2CEnum;
  errors: CreditPackagesMyCreditPackagesListError[];
};
export type CreditPackagesMyCreditPackagesListErrorResponse400 =
  | ({
      type: "validation_error";
    } & CreditPackagesMyCreditPackagesListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type RemainingCreditsResponse = {
  remaining_credits: number;
};
export type CreditPackagesRemainingCreditsRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type Currency = {
  currency_code: string;
  currency: string;
};
export type CurrenciesRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type DigitalDocumentVersion = {
  title: string;
  version: number;
  content: string;
  document: number;
};
export type DigitalDocumentVersionRead = {
  id: number;
  document_name: string;
  updated_at: string;
  title: string;
  version: number;
  content: string;
  created_at: string;
  document: number;
};
export type PaginatedDigitalDocumentVersionList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: DigitalDocumentVersion[];
};
export type PaginatedDigitalDocumentVersionListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: DigitalDocumentVersionRead[];
};
export type DigitalDocumentVersionsListErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type DigitalDocumentVersionsRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type AttachToEnum = "person" | "plan" | "appointment";
export type DigitalDocument = {
  is_active?: boolean;
  title: string;
  content: string;
  attach_to?: AttachToEnum;
  version?: number;
  plans?: number[];
  discounts?: number[];
  appointment_types?: number[];
};
export type DigitalDocumentRead = {
  id: number;
  attach_to_display: string;
  created_at: string;
  updated_at: string;
  is_active?: boolean;
  title: string;
  content: string;
  attach_to?: AttachToEnum;
  version?: number;
  plans?: number[];
  discounts?: number[];
  appointment_types?: number[];
};
export type PaginatedDigitalDocumentList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: DigitalDocument[];
};
export type PaginatedDigitalDocumentListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: DigitalDocumentRead[];
};
export type AttrCc4Enum = "title";
export type DigitalDocumentsListTitleErrorComponent = {
  attr: AttrCc4Enum;
  code: Code0E9Enum;
  detail: string;
};
export type AttrA56Enum = "attach_to";
export type DigitalDocumentsListAttachToErrorComponent = {
  attr: AttrA56Enum;
  code: Code099Enum;
  detail: string;
};
export type DigitalDocumentsListError =
  | ({
      attr: "title";
    } & DigitalDocumentsListTitleErrorComponent)
  | ({
      attr: "attach_to";
    } & DigitalDocumentsListAttachToErrorComponent);
export type DigitalDocumentsListValidationError = {
  type: TypeA2CEnum;
  errors: DigitalDocumentsListError[];
};
export type DigitalDocumentsListErrorResponse400 =
  | ({
      type: "validation_error";
    } & DigitalDocumentsListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type DigitalDocumentsCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type AttrDddEnum = "is_active";
export type DigitalDocumentsCreateIsActiveErrorComponent = {
  attr: AttrDddEnum;
  code: Code023Enum;
  detail: string;
};
export type CodeD7AEnum =
  | "blank"
  | "invalid"
  | "max_length"
  | "min_length"
  | "null"
  | "null_characters_not_allowed"
  | "required"
  | "surrogate_characters_not_allowed";
export type DigitalDocumentsCreateTitleErrorComponent = {
  attr: AttrCc4Enum;
  code: CodeD7AEnum;
  detail: string;
};
export type DigitalDocumentsCreateContentErrorComponent = {
  attr: Attr0E2Enum;
  code: CodeA3CEnum;
  detail: string;
};
export type DigitalDocumentsCreateAttachToErrorComponent = {
  attr: AttrA56Enum;
  code: Code3F2Enum;
  detail: string;
};
export type AttrBa9Enum = "version";
export type DigitalDocumentsCreateVersionErrorComponent = {
  attr: AttrBa9Enum;
  code: Code9E1Enum;
  detail: string;
};
export type Attr512Enum = "plans";
export type Code2DeEnum =
  | "does_not_exist"
  | "incorrect_type"
  | "not_a_list"
  | "null";
export type DigitalDocumentsCreatePlansErrorComponent = {
  attr: Attr512Enum;
  code: Code2DeEnum;
  detail: string;
};
export type Attr295Enum = "discounts";
export type DigitalDocumentsCreateDiscountsErrorComponent = {
  attr: Attr295Enum;
  code: Code2DeEnum;
  detail: string;
};
export type AttrFf2Enum = "appointment_types";
export type DigitalDocumentsCreateAppointmentTypesErrorComponent = {
  attr: AttrFf2Enum;
  code: Code2DeEnum;
  detail: string;
};
export type DigitalDocumentsCreateError =
  | ({
      attr: "non_field_errors";
    } & DigitalDocumentsCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "is_active";
    } & DigitalDocumentsCreateIsActiveErrorComponent)
  | ({
      attr: "title";
    } & DigitalDocumentsCreateTitleErrorComponent)
  | ({
      attr: "content";
    } & DigitalDocumentsCreateContentErrorComponent)
  | ({
      attr: "attach_to";
    } & DigitalDocumentsCreateAttachToErrorComponent)
  | ({
      attr: "version";
    } & DigitalDocumentsCreateVersionErrorComponent)
  | ({
      attr: "plans";
    } & DigitalDocumentsCreatePlansErrorComponent)
  | ({
      attr: "discounts";
    } & DigitalDocumentsCreateDiscountsErrorComponent)
  | ({
      attr: "appointment_types";
    } & DigitalDocumentsCreateAppointmentTypesErrorComponent);
export type DigitalDocumentsCreateValidationError = {
  type: TypeA2CEnum;
  errors: DigitalDocumentsCreateError[];
};
export type DigitalDocumentsCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & DigitalDocumentsCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type DigitalDocumentsRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type DigitalDocumentsUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type DigitalDocumentsUpdateIsActiveErrorComponent = {
  attr: AttrDddEnum;
  code: Code023Enum;
  detail: string;
};
export type DigitalDocumentsUpdateTitleErrorComponent = {
  attr: AttrCc4Enum;
  code: CodeD7AEnum;
  detail: string;
};
export type DigitalDocumentsUpdateContentErrorComponent = {
  attr: Attr0E2Enum;
  code: CodeA3CEnum;
  detail: string;
};
export type DigitalDocumentsUpdateAttachToErrorComponent = {
  attr: AttrA56Enum;
  code: Code3F2Enum;
  detail: string;
};
export type DigitalDocumentsUpdateVersionErrorComponent = {
  attr: AttrBa9Enum;
  code: Code9E1Enum;
  detail: string;
};
export type DigitalDocumentsUpdatePlansErrorComponent = {
  attr: Attr512Enum;
  code: Code2DeEnum;
  detail: string;
};
export type DigitalDocumentsUpdateDiscountsErrorComponent = {
  attr: Attr295Enum;
  code: Code2DeEnum;
  detail: string;
};
export type DigitalDocumentsUpdateAppointmentTypesErrorComponent = {
  attr: AttrFf2Enum;
  code: Code2DeEnum;
  detail: string;
};
export type DigitalDocumentsUpdateError =
  | ({
      attr: "non_field_errors";
    } & DigitalDocumentsUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "is_active";
    } & DigitalDocumentsUpdateIsActiveErrorComponent)
  | ({
      attr: "title";
    } & DigitalDocumentsUpdateTitleErrorComponent)
  | ({
      attr: "content";
    } & DigitalDocumentsUpdateContentErrorComponent)
  | ({
      attr: "attach_to";
    } & DigitalDocumentsUpdateAttachToErrorComponent)
  | ({
      attr: "version";
    } & DigitalDocumentsUpdateVersionErrorComponent)
  | ({
      attr: "plans";
    } & DigitalDocumentsUpdatePlansErrorComponent)
  | ({
      attr: "discounts";
    } & DigitalDocumentsUpdateDiscountsErrorComponent)
  | ({
      attr: "appointment_types";
    } & DigitalDocumentsUpdateAppointmentTypesErrorComponent);
export type DigitalDocumentsUpdateValidationError = {
  type: TypeA2CEnum;
  errors: DigitalDocumentsUpdateError[];
};
export type DigitalDocumentsUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & DigitalDocumentsUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type DigitalDocumentsPartialUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type DigitalDocumentsPartialUpdateIsActiveErrorComponent = {
  attr: AttrDddEnum;
  code: Code023Enum;
  detail: string;
};
export type DigitalDocumentsPartialUpdateTitleErrorComponent = {
  attr: AttrCc4Enum;
  code: CodeD7AEnum;
  detail: string;
};
export type DigitalDocumentsPartialUpdateContentErrorComponent = {
  attr: Attr0E2Enum;
  code: CodeA3CEnum;
  detail: string;
};
export type DigitalDocumentsPartialUpdateAttachToErrorComponent = {
  attr: AttrA56Enum;
  code: Code3F2Enum;
  detail: string;
};
export type DigitalDocumentsPartialUpdateVersionErrorComponent = {
  attr: AttrBa9Enum;
  code: Code9E1Enum;
  detail: string;
};
export type DigitalDocumentsPartialUpdatePlansErrorComponent = {
  attr: Attr512Enum;
  code: Code2DeEnum;
  detail: string;
};
export type DigitalDocumentsPartialUpdateDiscountsErrorComponent = {
  attr: Attr295Enum;
  code: Code2DeEnum;
  detail: string;
};
export type DigitalDocumentsPartialUpdateAppointmentTypesErrorComponent = {
  attr: AttrFf2Enum;
  code: Code2DeEnum;
  detail: string;
};
export type DigitalDocumentsPartialUpdateError =
  | ({
      attr: "non_field_errors";
    } & DigitalDocumentsPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "is_active";
    } & DigitalDocumentsPartialUpdateIsActiveErrorComponent)
  | ({
      attr: "title";
    } & DigitalDocumentsPartialUpdateTitleErrorComponent)
  | ({
      attr: "content";
    } & DigitalDocumentsPartialUpdateContentErrorComponent)
  | ({
      attr: "attach_to";
    } & DigitalDocumentsPartialUpdateAttachToErrorComponent)
  | ({
      attr: "version";
    } & DigitalDocumentsPartialUpdateVersionErrorComponent)
  | ({
      attr: "plans";
    } & DigitalDocumentsPartialUpdatePlansErrorComponent)
  | ({
      attr: "discounts";
    } & DigitalDocumentsPartialUpdateDiscountsErrorComponent)
  | ({
      attr: "appointment_types";
    } & DigitalDocumentsPartialUpdateAppointmentTypesErrorComponent);
export type DigitalDocumentsPartialUpdateValidationError = {
  type: TypeA2CEnum;
  errors: DigitalDocumentsPartialUpdateError[];
};
export type DigitalDocumentsPartialUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & DigitalDocumentsPartialUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PatchedDigitalDocument = {
  is_active?: boolean;
  title?: string;
  content?: string;
  attach_to?: AttachToEnum;
  version?: number;
  plans?: number[];
  discounts?: number[];
  appointment_types?: number[];
};
export type PatchedDigitalDocumentRead = {
  id?: number;
  attach_to_display?: string;
  created_at?: string;
  updated_at?: string;
  is_active?: boolean;
  title?: string;
  content?: string;
  attach_to?: AttachToEnum;
  version?: number;
  plans?: number[];
  discounts?: number[];
  appointment_types?: number[];
};
export type DigitalDocumentsDestroyErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type Historical = {
  id?: number;
  is_active?: boolean;
  title: string;
  content: string;
  attach_to?: AttachToEnum;
  version?: number;
  history_date: string;
  history_change_reason?: string | null;
  history_type: HistoryTypeEnum;
  history_user?: number | null;
};
export type HistoricalRead = {
  history_id: number;
  id?: number;
  is_active?: boolean;
  title: string;
  content: string;
  attach_to?: AttachToEnum;
  version?: number;
  history_date: string;
  history_change_reason?: string | null;
  history_type: HistoryTypeEnum;
  history_user?: number | null;
};
export type PaginatedHistoricalList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Historical[];
};
export type PaginatedHistoricalListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: HistoricalRead[];
};
export type DigitalDocumentsHistoryListErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type DigitalDocumentsPublishPartialUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type DigitalDocumentsPublishPartialUpdateIsActiveErrorComponent = {
  attr: AttrDddEnum;
  code: Code023Enum;
  detail: string;
};
export type DigitalDocumentsPublishPartialUpdateTitleErrorComponent = {
  attr: AttrCc4Enum;
  code: CodeD7AEnum;
  detail: string;
};
export type DigitalDocumentsPublishPartialUpdateContentErrorComponent = {
  attr: Attr0E2Enum;
  code: CodeA3CEnum;
  detail: string;
};
export type DigitalDocumentsPublishPartialUpdateAttachToErrorComponent = {
  attr: AttrA56Enum;
  code: Code3F2Enum;
  detail: string;
};
export type DigitalDocumentsPublishPartialUpdateVersionErrorComponent = {
  attr: AttrBa9Enum;
  code: Code9E1Enum;
  detail: string;
};
export type DigitalDocumentsPublishPartialUpdatePlansErrorComponent = {
  attr: Attr512Enum;
  code: Code2DeEnum;
  detail: string;
};
export type DigitalDocumentsPublishPartialUpdateDiscountsErrorComponent = {
  attr: Attr295Enum;
  code: Code2DeEnum;
  detail: string;
};
export type DigitalDocumentsPublishPartialUpdateAppointmentTypesErrorComponent =
  {
    attr: AttrFf2Enum;
    code: Code2DeEnum;
    detail: string;
  };
export type DigitalDocumentsPublishPartialUpdateError =
  | ({
      attr: "non_field_errors";
    } & DigitalDocumentsPublishPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "is_active";
    } & DigitalDocumentsPublishPartialUpdateIsActiveErrorComponent)
  | ({
      attr: "title";
    } & DigitalDocumentsPublishPartialUpdateTitleErrorComponent)
  | ({
      attr: "content";
    } & DigitalDocumentsPublishPartialUpdateContentErrorComponent)
  | ({
      attr: "attach_to";
    } & DigitalDocumentsPublishPartialUpdateAttachToErrorComponent)
  | ({
      attr: "version";
    } & DigitalDocumentsPublishPartialUpdateVersionErrorComponent)
  | ({
      attr: "plans";
    } & DigitalDocumentsPublishPartialUpdatePlansErrorComponent)
  | ({
      attr: "discounts";
    } & DigitalDocumentsPublishPartialUpdateDiscountsErrorComponent)
  | ({
      attr: "appointment_types";
    } & DigitalDocumentsPublishPartialUpdateAppointmentTypesErrorComponent);
export type DigitalDocumentsPublishPartialUpdateValidationError = {
  type: TypeA2CEnum;
  errors: DigitalDocumentsPublishPartialUpdateError[];
};
export type DigitalDocumentsPublishPartialUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & DigitalDocumentsPublishPartialUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type DigitalDocumentsRequiredSignaturesListErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type DigitalDocumentAllowedVariable = {
  variable: string;
  description: string;
};
export type DigitalDocumentsAllowedVariablesRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type DigitalDocumentsRequiredRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type UserDocumentSignature = {
  signature_image?: string | null;
  signed_content: string;
  user_name: string;
  user: number;
  document_version: number;
};
export type UserDocumentSignatureRead = {
  id: number;
  created_at: string;
  updated_at: string;
  signature_image?: string | null;
  signed_at: string;
  signed_content: string;
  user_name: string;
  user: number;
  document_version: number;
};
export type DigitalDocumentsSignCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type AttrC4DEnum = "document";
export type DigitalDocumentsSignCreateDocumentErrorComponent = {
  attr: AttrC4DEnum;
  code: Code6C0Enum;
  detail: string;
};
export type Attr5A9Enum = "signature_image";
export type Code80AEnum =
  | "empty"
  | "invalid"
  | "invalid_image"
  | "no_name"
  | "null"
  | "required";
export type DigitalDocumentsSignCreateSignatureImageErrorComponent = {
  attr: Attr5A9Enum;
  code: Code80AEnum;
  detail: string;
};
export type DigitalDocumentsSignCreateError =
  | ({
      attr: "non_field_errors";
    } & DigitalDocumentsSignCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "document";
    } & DigitalDocumentsSignCreateDocumentErrorComponent)
  | ({
      attr: "signature_image";
    } & DigitalDocumentsSignCreateSignatureImageErrorComponent);
export type DigitalDocumentsSignCreateValidationError = {
  type: TypeA2CEnum;
  errors: DigitalDocumentsSignCreateError[];
};
export type DigitalDocumentsSignCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & DigitalDocumentsSignCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type SignDocument = {
  document: number;
  signature_image: string;
};
export type ValidityEnum = "all" | "specific";
export type Discount = {
  name: string;
  amount: string;
  is_percentage?: boolean;
  description?: string;
  start_date?: string;
  end_date?: string | null;
  is_active?: boolean;
  discount_code: string;
  validity?: ValidityEnum;
  limit_total_uses?: boolean;
  total_uses?: number | null;
  limit_uses_per_customer?: boolean;
  uses_per_customer?: number | null;
  applicable_to_products?: boolean;
  applicable_to_memberships?: boolean;
  applicable_to_appointments?: boolean;
  products?: number[];
  membership_plans?: number[];
  appointment_types?: number[];
};
export type DiscountRead = {
  id: number;
  status: string;
  created_at: string;
  updated_at: string;
  name: string;
  amount: string;
  is_percentage?: boolean;
  description?: string;
  start_date?: string;
  end_date?: string | null;
  is_active?: boolean;
  discount_code: string;
  validity?: ValidityEnum;
  limit_total_uses?: boolean;
  total_uses?: number | null;
  limit_uses_per_customer?: boolean;
  uses_per_customer?: number | null;
  applicable_to_products?: boolean;
  applicable_to_memberships?: boolean;
  applicable_to_appointments?: boolean;
  products?: number[];
  membership_plans?: number[];
  appointment_types?: number[];
};
export type PaginatedDiscountList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Discount[];
};
export type PaginatedDiscountListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: DiscountRead[];
};
export type DiscountsListNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code0E9Enum;
  detail: string;
};
export type DiscountsListError = {
  attr: "name";
} & DiscountsListNameErrorComponent;
export type DiscountsListValidationError = {
  type: TypeA2CEnum;
  errors: DiscountsListError[];
};
export type DiscountsListErrorResponse400 =
  | ({
      type: "validation_error";
    } & DiscountsListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type DiscountsCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type DiscountsCreateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code238Enum;
  detail: string;
};
export type Attr18FEnum = "amount";
export type CodeC22Enum =
  | "invalid"
  | "max_decimal_places"
  | "max_digits"
  | "max_string_length"
  | "max_whole_digits"
  | "min_value"
  | "null"
  | "required";
export type DiscountsCreateAmountErrorComponent = {
  attr: Attr18FEnum;
  code: CodeC22Enum;
  detail: string;
};
export type Attr5A2Enum = "is_percentage";
export type DiscountsCreateIsPercentageErrorComponent = {
  attr: Attr5A2Enum;
  code: Code023Enum;
  detail: string;
};
export type DiscountsCreateDescriptionErrorComponent = {
  attr: Attr50BEnum;
  code: Code0E6Enum;
  detail: string;
};
export type Code5E5Enum = "datetime" | "invalid" | "null";
export type DiscountsCreateStartDateErrorComponent = {
  attr: Attr92BEnum;
  code: Code5E5Enum;
  detail: string;
};
export type DiscountsCreateEndDateErrorComponent = {
  attr: Attr637Enum;
  code: CodeCeaEnum;
  detail: string;
};
export type DiscountsCreateIsActiveErrorComponent = {
  attr: AttrDddEnum;
  code: Code023Enum;
  detail: string;
};
export type DiscountsCreateDiscountCodeErrorComponent = {
  attr: AttrCa4Enum;
  code: Code6AeEnum;
  detail: string;
};
export type Attr0AaEnum = "validity";
export type DiscountsCreateValidityErrorComponent = {
  attr: Attr0AaEnum;
  code: Code3F2Enum;
  detail: string;
};
export type Attr860Enum = "limit_total_uses";
export type DiscountsCreateLimitTotalUsesErrorComponent = {
  attr: Attr860Enum;
  code: Code023Enum;
  detail: string;
};
export type Attr0DaEnum = "total_uses";
export type DiscountsCreateTotalUsesErrorComponent = {
  attr: Attr0DaEnum;
  code: CodeB7BEnum;
  detail: string;
};
export type AttrFe0Enum = "limit_uses_per_customer";
export type DiscountsCreateLimitUsesPerCustomerErrorComponent = {
  attr: AttrFe0Enum;
  code: Code023Enum;
  detail: string;
};
export type Attr6A5Enum = "uses_per_customer";
export type DiscountsCreateUsesPerCustomerErrorComponent = {
  attr: Attr6A5Enum;
  code: CodeB7BEnum;
  detail: string;
};
export type AttrB51Enum = "applicable_to_products";
export type DiscountsCreateApplicableToProductsErrorComponent = {
  attr: AttrB51Enum;
  code: Code023Enum;
  detail: string;
};
export type Attr62EEnum = "applicable_to_memberships";
export type DiscountsCreateApplicableToMembershipsErrorComponent = {
  attr: Attr62EEnum;
  code: Code023Enum;
  detail: string;
};
export type Attr209Enum = "applicable_to_appointments";
export type DiscountsCreateApplicableToAppointmentsErrorComponent = {
  attr: Attr209Enum;
  code: Code023Enum;
  detail: string;
};
export type AttrA7EEnum = "products";
export type DiscountsCreateProductsErrorComponent = {
  attr: AttrA7EEnum;
  code: Code2DeEnum;
  detail: string;
};
export type AttrF8EEnum = "membership_plans";
export type DiscountsCreateMembershipPlansErrorComponent = {
  attr: AttrF8EEnum;
  code: Code2DeEnum;
  detail: string;
};
export type DiscountsCreateAppointmentTypesErrorComponent = {
  attr: AttrFf2Enum;
  code: Code2DeEnum;
  detail: string;
};
export type DiscountsCreateError =
  | ({
      attr: "non_field_errors";
    } & DiscountsCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & DiscountsCreateNameErrorComponent)
  | ({
      attr: "amount";
    } & DiscountsCreateAmountErrorComponent)
  | ({
      attr: "is_percentage";
    } & DiscountsCreateIsPercentageErrorComponent)
  | ({
      attr: "description";
    } & DiscountsCreateDescriptionErrorComponent)
  | ({
      attr: "start_date";
    } & DiscountsCreateStartDateErrorComponent)
  | ({
      attr: "end_date";
    } & DiscountsCreateEndDateErrorComponent)
  | ({
      attr: "is_active";
    } & DiscountsCreateIsActiveErrorComponent)
  | ({
      attr: "discount_code";
    } & DiscountsCreateDiscountCodeErrorComponent)
  | ({
      attr: "validity";
    } & DiscountsCreateValidityErrorComponent)
  | ({
      attr: "limit_total_uses";
    } & DiscountsCreateLimitTotalUsesErrorComponent)
  | ({
      attr: "total_uses";
    } & DiscountsCreateTotalUsesErrorComponent)
  | ({
      attr: "limit_uses_per_customer";
    } & DiscountsCreateLimitUsesPerCustomerErrorComponent)
  | ({
      attr: "uses_per_customer";
    } & DiscountsCreateUsesPerCustomerErrorComponent)
  | ({
      attr: "applicable_to_products";
    } & DiscountsCreateApplicableToProductsErrorComponent)
  | ({
      attr: "applicable_to_memberships";
    } & DiscountsCreateApplicableToMembershipsErrorComponent)
  | ({
      attr: "applicable_to_appointments";
    } & DiscountsCreateApplicableToAppointmentsErrorComponent)
  | ({
      attr: "products";
    } & DiscountsCreateProductsErrorComponent)
  | ({
      attr: "membership_plans";
    } & DiscountsCreateMembershipPlansErrorComponent)
  | ({
      attr: "appointment_types";
    } & DiscountsCreateAppointmentTypesErrorComponent);
export type DiscountsCreateValidationError = {
  type: TypeA2CEnum;
  errors: DiscountsCreateError[];
};
export type DiscountsCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & DiscountsCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type DiscountsRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type DiscountsUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type DiscountsUpdateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code238Enum;
  detail: string;
};
export type DiscountsUpdateAmountErrorComponent = {
  attr: Attr18FEnum;
  code: CodeC22Enum;
  detail: string;
};
export type DiscountsUpdateIsPercentageErrorComponent = {
  attr: Attr5A2Enum;
  code: Code023Enum;
  detail: string;
};
export type DiscountsUpdateDescriptionErrorComponent = {
  attr: Attr50BEnum;
  code: Code0E6Enum;
  detail: string;
};
export type DiscountsUpdateStartDateErrorComponent = {
  attr: Attr92BEnum;
  code: Code5E5Enum;
  detail: string;
};
export type DiscountsUpdateEndDateErrorComponent = {
  attr: Attr637Enum;
  code: CodeCeaEnum;
  detail: string;
};
export type DiscountsUpdateIsActiveErrorComponent = {
  attr: AttrDddEnum;
  code: Code023Enum;
  detail: string;
};
export type DiscountsUpdateDiscountCodeErrorComponent = {
  attr: AttrCa4Enum;
  code: Code6AeEnum;
  detail: string;
};
export type DiscountsUpdateValidityErrorComponent = {
  attr: Attr0AaEnum;
  code: Code3F2Enum;
  detail: string;
};
export type DiscountsUpdateLimitTotalUsesErrorComponent = {
  attr: Attr860Enum;
  code: Code023Enum;
  detail: string;
};
export type DiscountsUpdateTotalUsesErrorComponent = {
  attr: Attr0DaEnum;
  code: CodeB7BEnum;
  detail: string;
};
export type DiscountsUpdateLimitUsesPerCustomerErrorComponent = {
  attr: AttrFe0Enum;
  code: Code023Enum;
  detail: string;
};
export type DiscountsUpdateUsesPerCustomerErrorComponent = {
  attr: Attr6A5Enum;
  code: CodeB7BEnum;
  detail: string;
};
export type DiscountsUpdateApplicableToProductsErrorComponent = {
  attr: AttrB51Enum;
  code: Code023Enum;
  detail: string;
};
export type DiscountsUpdateApplicableToMembershipsErrorComponent = {
  attr: Attr62EEnum;
  code: Code023Enum;
  detail: string;
};
export type DiscountsUpdateApplicableToAppointmentsErrorComponent = {
  attr: Attr209Enum;
  code: Code023Enum;
  detail: string;
};
export type DiscountsUpdateProductsErrorComponent = {
  attr: AttrA7EEnum;
  code: Code2DeEnum;
  detail: string;
};
export type DiscountsUpdateMembershipPlansErrorComponent = {
  attr: AttrF8EEnum;
  code: Code2DeEnum;
  detail: string;
};
export type DiscountsUpdateAppointmentTypesErrorComponent = {
  attr: AttrFf2Enum;
  code: Code2DeEnum;
  detail: string;
};
export type DiscountsUpdateError =
  | ({
      attr: "non_field_errors";
    } & DiscountsUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & DiscountsUpdateNameErrorComponent)
  | ({
      attr: "amount";
    } & DiscountsUpdateAmountErrorComponent)
  | ({
      attr: "is_percentage";
    } & DiscountsUpdateIsPercentageErrorComponent)
  | ({
      attr: "description";
    } & DiscountsUpdateDescriptionErrorComponent)
  | ({
      attr: "start_date";
    } & DiscountsUpdateStartDateErrorComponent)
  | ({
      attr: "end_date";
    } & DiscountsUpdateEndDateErrorComponent)
  | ({
      attr: "is_active";
    } & DiscountsUpdateIsActiveErrorComponent)
  | ({
      attr: "discount_code";
    } & DiscountsUpdateDiscountCodeErrorComponent)
  | ({
      attr: "validity";
    } & DiscountsUpdateValidityErrorComponent)
  | ({
      attr: "limit_total_uses";
    } & DiscountsUpdateLimitTotalUsesErrorComponent)
  | ({
      attr: "total_uses";
    } & DiscountsUpdateTotalUsesErrorComponent)
  | ({
      attr: "limit_uses_per_customer";
    } & DiscountsUpdateLimitUsesPerCustomerErrorComponent)
  | ({
      attr: "uses_per_customer";
    } & DiscountsUpdateUsesPerCustomerErrorComponent)
  | ({
      attr: "applicable_to_products";
    } & DiscountsUpdateApplicableToProductsErrorComponent)
  | ({
      attr: "applicable_to_memberships";
    } & DiscountsUpdateApplicableToMembershipsErrorComponent)
  | ({
      attr: "applicable_to_appointments";
    } & DiscountsUpdateApplicableToAppointmentsErrorComponent)
  | ({
      attr: "products";
    } & DiscountsUpdateProductsErrorComponent)
  | ({
      attr: "membership_plans";
    } & DiscountsUpdateMembershipPlansErrorComponent)
  | ({
      attr: "appointment_types";
    } & DiscountsUpdateAppointmentTypesErrorComponent);
export type DiscountsUpdateValidationError = {
  type: TypeA2CEnum;
  errors: DiscountsUpdateError[];
};
export type DiscountsUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & DiscountsUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type DiscountsPartialUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type DiscountsPartialUpdateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code238Enum;
  detail: string;
};
export type DiscountsPartialUpdateAmountErrorComponent = {
  attr: Attr18FEnum;
  code: CodeC22Enum;
  detail: string;
};
export type DiscountsPartialUpdateIsPercentageErrorComponent = {
  attr: Attr5A2Enum;
  code: Code023Enum;
  detail: string;
};
export type DiscountsPartialUpdateDescriptionErrorComponent = {
  attr: Attr50BEnum;
  code: Code0E6Enum;
  detail: string;
};
export type DiscountsPartialUpdateStartDateErrorComponent = {
  attr: Attr92BEnum;
  code: Code5E5Enum;
  detail: string;
};
export type DiscountsPartialUpdateEndDateErrorComponent = {
  attr: Attr637Enum;
  code: CodeCeaEnum;
  detail: string;
};
export type DiscountsPartialUpdateIsActiveErrorComponent = {
  attr: AttrDddEnum;
  code: Code023Enum;
  detail: string;
};
export type DiscountsPartialUpdateDiscountCodeErrorComponent = {
  attr: AttrCa4Enum;
  code: Code6AeEnum;
  detail: string;
};
export type DiscountsPartialUpdateValidityErrorComponent = {
  attr: Attr0AaEnum;
  code: Code3F2Enum;
  detail: string;
};
export type DiscountsPartialUpdateLimitTotalUsesErrorComponent = {
  attr: Attr860Enum;
  code: Code023Enum;
  detail: string;
};
export type DiscountsPartialUpdateTotalUsesErrorComponent = {
  attr: Attr0DaEnum;
  code: CodeB7BEnum;
  detail: string;
};
export type DiscountsPartialUpdateLimitUsesPerCustomerErrorComponent = {
  attr: AttrFe0Enum;
  code: Code023Enum;
  detail: string;
};
export type DiscountsPartialUpdateUsesPerCustomerErrorComponent = {
  attr: Attr6A5Enum;
  code: CodeB7BEnum;
  detail: string;
};
export type DiscountsPartialUpdateApplicableToProductsErrorComponent = {
  attr: AttrB51Enum;
  code: Code023Enum;
  detail: string;
};
export type DiscountsPartialUpdateApplicableToMembershipsErrorComponent = {
  attr: Attr62EEnum;
  code: Code023Enum;
  detail: string;
};
export type DiscountsPartialUpdateApplicableToAppointmentsErrorComponent = {
  attr: Attr209Enum;
  code: Code023Enum;
  detail: string;
};
export type DiscountsPartialUpdateProductsErrorComponent = {
  attr: AttrA7EEnum;
  code: Code2DeEnum;
  detail: string;
};
export type DiscountsPartialUpdateMembershipPlansErrorComponent = {
  attr: AttrF8EEnum;
  code: Code2DeEnum;
  detail: string;
};
export type DiscountsPartialUpdateAppointmentTypesErrorComponent = {
  attr: AttrFf2Enum;
  code: Code2DeEnum;
  detail: string;
};
export type DiscountsPartialUpdateError =
  | ({
      attr: "non_field_errors";
    } & DiscountsPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & DiscountsPartialUpdateNameErrorComponent)
  | ({
      attr: "amount";
    } & DiscountsPartialUpdateAmountErrorComponent)
  | ({
      attr: "is_percentage";
    } & DiscountsPartialUpdateIsPercentageErrorComponent)
  | ({
      attr: "description";
    } & DiscountsPartialUpdateDescriptionErrorComponent)
  | ({
      attr: "start_date";
    } & DiscountsPartialUpdateStartDateErrorComponent)
  | ({
      attr: "end_date";
    } & DiscountsPartialUpdateEndDateErrorComponent)
  | ({
      attr: "is_active";
    } & DiscountsPartialUpdateIsActiveErrorComponent)
  | ({
      attr: "discount_code";
    } & DiscountsPartialUpdateDiscountCodeErrorComponent)
  | ({
      attr: "validity";
    } & DiscountsPartialUpdateValidityErrorComponent)
  | ({
      attr: "limit_total_uses";
    } & DiscountsPartialUpdateLimitTotalUsesErrorComponent)
  | ({
      attr: "total_uses";
    } & DiscountsPartialUpdateTotalUsesErrorComponent)
  | ({
      attr: "limit_uses_per_customer";
    } & DiscountsPartialUpdateLimitUsesPerCustomerErrorComponent)
  | ({
      attr: "uses_per_customer";
    } & DiscountsPartialUpdateUsesPerCustomerErrorComponent)
  | ({
      attr: "applicable_to_products";
    } & DiscountsPartialUpdateApplicableToProductsErrorComponent)
  | ({
      attr: "applicable_to_memberships";
    } & DiscountsPartialUpdateApplicableToMembershipsErrorComponent)
  | ({
      attr: "applicable_to_appointments";
    } & DiscountsPartialUpdateApplicableToAppointmentsErrorComponent)
  | ({
      attr: "products";
    } & DiscountsPartialUpdateProductsErrorComponent)
  | ({
      attr: "membership_plans";
    } & DiscountsPartialUpdateMembershipPlansErrorComponent)
  | ({
      attr: "appointment_types";
    } & DiscountsPartialUpdateAppointmentTypesErrorComponent);
export type DiscountsPartialUpdateValidationError = {
  type: TypeA2CEnum;
  errors: DiscountsPartialUpdateError[];
};
export type DiscountsPartialUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & DiscountsPartialUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PatchedDiscount = {
  name?: string;
  amount?: string;
  is_percentage?: boolean;
  description?: string;
  start_date?: string;
  end_date?: string | null;
  is_active?: boolean;
  discount_code?: string;
  validity?: ValidityEnum;
  limit_total_uses?: boolean;
  total_uses?: number | null;
  limit_uses_per_customer?: boolean;
  uses_per_customer?: number | null;
  applicable_to_products?: boolean;
  applicable_to_memberships?: boolean;
  applicable_to_appointments?: boolean;
  products?: number[];
  membership_plans?: number[];
  appointment_types?: number[];
};
export type PatchedDiscountRead = {
  id?: number;
  status?: string;
  created_at?: string;
  updated_at?: string;
  name?: string;
  amount?: string;
  is_percentage?: boolean;
  description?: string;
  start_date?: string;
  end_date?: string | null;
  is_active?: boolean;
  discount_code?: string;
  validity?: ValidityEnum;
  limit_total_uses?: boolean;
  total_uses?: number | null;
  limit_uses_per_customer?: boolean;
  uses_per_customer?: number | null;
  applicable_to_products?: boolean;
  applicable_to_memberships?: boolean;
  applicable_to_appointments?: boolean;
  products?: number[];
  membership_plans?: number[];
  appointment_types?: number[];
};
export type DiscountsDestroyErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type DiscountsHistoryListErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type EmailTrigger = {
  key: string;
  name: string;
  category: string;
  sent_to: string;
  explanation: string;
  is_active?: boolean;
};
export type EmailTriggerRead = {
  id: number;
  key: string;
  name: string;
  category: string;
  sent_to: string;
  explanation: string;
  is_active?: boolean;
};
export type PaginatedEmailTriggerList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: EmailTrigger[];
};
export type PaginatedEmailTriggerListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: EmailTriggerRead[];
};
export type EmailTriggersListErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type EmailTriggersRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type EmailTriggersUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type AttrFeeEnum = "key";
export type EmailTriggersUpdateKeyErrorComponent = {
  attr: AttrFeeEnum;
  code: Code6AeEnum;
  detail: string;
};
export type EmailTriggersUpdateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: CodeA3CEnum;
  detail: string;
};
export type Attr330Enum = "category";
export type EmailTriggersUpdateCategoryErrorComponent = {
  attr: Attr330Enum;
  code: CodeA3CEnum;
  detail: string;
};
export type AttrF44Enum = "sent_to";
export type EmailTriggersUpdateSentToErrorComponent = {
  attr: AttrF44Enum;
  code: CodeA3CEnum;
  detail: string;
};
export type AttrB47Enum = "explanation";
export type EmailTriggersUpdateExplanationErrorComponent = {
  attr: AttrB47Enum;
  code: CodeA3CEnum;
  detail: string;
};
export type EmailTriggersUpdateIsActiveErrorComponent = {
  attr: AttrDddEnum;
  code: Code023Enum;
  detail: string;
};
export type EmailTriggersUpdateError =
  | ({
      attr: "non_field_errors";
    } & EmailTriggersUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "key";
    } & EmailTriggersUpdateKeyErrorComponent)
  | ({
      attr: "name";
    } & EmailTriggersUpdateNameErrorComponent)
  | ({
      attr: "category";
    } & EmailTriggersUpdateCategoryErrorComponent)
  | ({
      attr: "sent_to";
    } & EmailTriggersUpdateSentToErrorComponent)
  | ({
      attr: "explanation";
    } & EmailTriggersUpdateExplanationErrorComponent)
  | ({
      attr: "is_active";
    } & EmailTriggersUpdateIsActiveErrorComponent);
export type EmailTriggersUpdateValidationError = {
  type: TypeA2CEnum;
  errors: EmailTriggersUpdateError[];
};
export type EmailTriggersUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & EmailTriggersUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type EmailTriggersPartialUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type EmailTriggersPartialUpdateKeyErrorComponent = {
  attr: AttrFeeEnum;
  code: Code6AeEnum;
  detail: string;
};
export type EmailTriggersPartialUpdateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: CodeA3CEnum;
  detail: string;
};
export type EmailTriggersPartialUpdateCategoryErrorComponent = {
  attr: Attr330Enum;
  code: CodeA3CEnum;
  detail: string;
};
export type EmailTriggersPartialUpdateSentToErrorComponent = {
  attr: AttrF44Enum;
  code: CodeA3CEnum;
  detail: string;
};
export type EmailTriggersPartialUpdateExplanationErrorComponent = {
  attr: AttrB47Enum;
  code: CodeA3CEnum;
  detail: string;
};
export type EmailTriggersPartialUpdateIsActiveErrorComponent = {
  attr: AttrDddEnum;
  code: Code023Enum;
  detail: string;
};
export type EmailTriggersPartialUpdateError =
  | ({
      attr: "non_field_errors";
    } & EmailTriggersPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "key";
    } & EmailTriggersPartialUpdateKeyErrorComponent)
  | ({
      attr: "name";
    } & EmailTriggersPartialUpdateNameErrorComponent)
  | ({
      attr: "category";
    } & EmailTriggersPartialUpdateCategoryErrorComponent)
  | ({
      attr: "sent_to";
    } & EmailTriggersPartialUpdateSentToErrorComponent)
  | ({
      attr: "explanation";
    } & EmailTriggersPartialUpdateExplanationErrorComponent)
  | ({
      attr: "is_active";
    } & EmailTriggersPartialUpdateIsActiveErrorComponent);
export type EmailTriggersPartialUpdateValidationError = {
  type: TypeA2CEnum;
  errors: EmailTriggersPartialUpdateError[];
};
export type EmailTriggersPartialUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & EmailTriggersPartialUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PatchedEmailTrigger = {
  key?: string;
  name?: string;
  category?: string;
  sent_to?: string;
  explanation?: string;
  is_active?: boolean;
};
export type PatchedEmailTriggerRead = {
  id?: number;
  key?: string;
  name?: string;
  category?: string;
  sent_to?: string;
  explanation?: string;
  is_active?: boolean;
};
export type EventReservation = {
  start_date_time: string;
  user: number;
  event_instance: number;
};
export type EventReservationRead = {
  id: number;
  is_expired: string;
  status: string;
  status_display: string;
  created_at: string;
  updated_at: string;
  reserved_at: string;
  start_date_time: string;
  polymorphic_ctype: number | null;
  user: number;
  event_instance: number;
};
export type PaginatedEventReservationList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: EventReservation[];
};
export type PaginatedEventReservationListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: EventReservationRead[];
};
export type EventReservationsListErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type EventReservationsRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type EventReservationsCancelReservationDestroyErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type EventReservationsMyReservationsListErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type Event = {
  name: string;
  start_date: string;
  start_time: string;
  end_time: string;
  is_all_day: boolean;
  price: string;
  reservation_open?: ReservationOpenEnum;
  reservation_close?: ReservationCloseEnum;
  limit_attendance?: boolean;
  attendance_limit?: number | null;
  description: string;
  staff?: number | null;
};
export type EventRead = {
  id: number;
  spots_left: string;
  spots_taken: string;
  status: string;
  reservation: string;
  created_at: string;
  updated_at: string;
  name: string;
  start_date: string;
  start_time: string;
  end_time: string;
  is_all_day: boolean;
  price_currency: string;
  price: string;
  reservation_open?: ReservationOpenEnum;
  reservation_close?: ReservationCloseEnum;
  limit_attendance?: boolean;
  attendance_limit?: number | null;
  description: string;
  staff?: number | null;
};
export type PaginatedEventList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Event[];
};
export type PaginatedEventListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: EventRead[];
};
export type EventsListStartDateErrorComponent = {
  attr: Attr92BEnum;
  code: CodeE7CEnum;
  detail: string;
};
export type Attr66EEnum = "start_date__range";
export type EventsListStartDateRangeErrorComponent = {
  attr: Attr66EEnum;
  code: Code241Enum;
  detail: string;
};
export type EventsListStaffErrorComponent = {
  attr: AttrD4FEnum;
  code: Code099Enum;
  detail: string;
};
export type AttrAfbEnum = "staff__in";
export type EventsListStaffInErrorComponent = {
  attr: AttrAfbEnum;
  code: Code099Enum;
  detail: string;
};
export type EventsListError =
  | ({
      attr: "start_date";
    } & EventsListStartDateErrorComponent)
  | ({
      attr: "start_date__range";
    } & EventsListStartDateRangeErrorComponent)
  | ({
      attr: "staff";
    } & EventsListStaffErrorComponent)
  | ({
      attr: "staff__in";
    } & EventsListStaffInErrorComponent);
export type EventsListValidationError = {
  type: TypeA2CEnum;
  errors: EventsListError[];
};
export type EventsListErrorResponse400 =
  | ({
      type: "validation_error";
    } & EventsListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type EventsCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type EventsCreateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code238Enum;
  detail: string;
};
export type EventsCreateStartDateErrorComponent = {
  attr: Attr92BEnum;
  code: Code0DbEnum;
  detail: string;
};
export type EventsCreateStartTimeErrorComponent = {
  attr: Attr2A0Enum;
  code: CodeF7EEnum;
  detail: string;
};
export type EventsCreateEndTimeErrorComponent = {
  attr: AttrFc2Enum;
  code: CodeF7EEnum;
  detail: string;
};
export type AttrF3CEnum = "is_all_day";
export type EventsCreateIsAllDayErrorComponent = {
  attr: AttrF3CEnum;
  code: CodeF7EEnum;
  detail: string;
};
export type AttrA2DEnum = "price";
export type EventsCreatePriceErrorComponent = {
  attr: AttrA2DEnum;
  code: Code9BdEnum;
  detail: string;
};
export type EventsCreateReservationOpenErrorComponent = {
  attr: AttrF2CEnum;
  code: Code729Enum;
  detail: string;
};
export type EventsCreateReservationCloseErrorComponent = {
  attr: Attr59BEnum;
  code: Code729Enum;
  detail: string;
};
export type EventsCreateLimitAttendanceErrorComponent = {
  attr: Attr518Enum;
  code: Code023Enum;
  detail: string;
};
export type EventsCreateAttendanceLimitErrorComponent = {
  attr: Attr181Enum;
  code: CodeB7BEnum;
  detail: string;
};
export type EventsCreateDescriptionErrorComponent = {
  attr: Attr50BEnum;
  code: CodeA3CEnum;
  detail: string;
};
export type EventsCreateStaffErrorComponent = {
  attr: AttrD4FEnum;
  code: Code948Enum;
  detail: string;
};
export type EventsCreateError =
  | ({
      attr: "non_field_errors";
    } & EventsCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & EventsCreateNameErrorComponent)
  | ({
      attr: "start_date";
    } & EventsCreateStartDateErrorComponent)
  | ({
      attr: "start_time";
    } & EventsCreateStartTimeErrorComponent)
  | ({
      attr: "end_time";
    } & EventsCreateEndTimeErrorComponent)
  | ({
      attr: "is_all_day";
    } & EventsCreateIsAllDayErrorComponent)
  | ({
      attr: "price";
    } & EventsCreatePriceErrorComponent)
  | ({
      attr: "reservation_open";
    } & EventsCreateReservationOpenErrorComponent)
  | ({
      attr: "reservation_close";
    } & EventsCreateReservationCloseErrorComponent)
  | ({
      attr: "limit_attendance";
    } & EventsCreateLimitAttendanceErrorComponent)
  | ({
      attr: "attendance_limit";
    } & EventsCreateAttendanceLimitErrorComponent)
  | ({
      attr: "description";
    } & EventsCreateDescriptionErrorComponent)
  | ({
      attr: "staff";
    } & EventsCreateStaffErrorComponent);
export type EventsCreateValidationError = {
  type: TypeA2CEnum;
  errors: EventsCreateError[];
};
export type EventsCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & EventsCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type EventsRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type EventsUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type EventsUpdateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code238Enum;
  detail: string;
};
export type EventsUpdateStartDateErrorComponent = {
  attr: Attr92BEnum;
  code: Code0DbEnum;
  detail: string;
};
export type EventsUpdateStartTimeErrorComponent = {
  attr: Attr2A0Enum;
  code: CodeF7EEnum;
  detail: string;
};
export type EventsUpdateEndTimeErrorComponent = {
  attr: AttrFc2Enum;
  code: CodeF7EEnum;
  detail: string;
};
export type EventsUpdateIsAllDayErrorComponent = {
  attr: AttrF3CEnum;
  code: CodeF7EEnum;
  detail: string;
};
export type EventsUpdatePriceErrorComponent = {
  attr: AttrA2DEnum;
  code: Code9BdEnum;
  detail: string;
};
export type EventsUpdateReservationOpenErrorComponent = {
  attr: AttrF2CEnum;
  code: Code729Enum;
  detail: string;
};
export type EventsUpdateReservationCloseErrorComponent = {
  attr: Attr59BEnum;
  code: Code729Enum;
  detail: string;
};
export type EventsUpdateLimitAttendanceErrorComponent = {
  attr: Attr518Enum;
  code: Code023Enum;
  detail: string;
};
export type EventsUpdateAttendanceLimitErrorComponent = {
  attr: Attr181Enum;
  code: CodeB7BEnum;
  detail: string;
};
export type EventsUpdateDescriptionErrorComponent = {
  attr: Attr50BEnum;
  code: CodeA3CEnum;
  detail: string;
};
export type EventsUpdateStaffErrorComponent = {
  attr: AttrD4FEnum;
  code: Code948Enum;
  detail: string;
};
export type EventsUpdateError =
  | ({
      attr: "non_field_errors";
    } & EventsUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & EventsUpdateNameErrorComponent)
  | ({
      attr: "start_date";
    } & EventsUpdateStartDateErrorComponent)
  | ({
      attr: "start_time";
    } & EventsUpdateStartTimeErrorComponent)
  | ({
      attr: "end_time";
    } & EventsUpdateEndTimeErrorComponent)
  | ({
      attr: "is_all_day";
    } & EventsUpdateIsAllDayErrorComponent)
  | ({
      attr: "price";
    } & EventsUpdatePriceErrorComponent)
  | ({
      attr: "reservation_open";
    } & EventsUpdateReservationOpenErrorComponent)
  | ({
      attr: "reservation_close";
    } & EventsUpdateReservationCloseErrorComponent)
  | ({
      attr: "limit_attendance";
    } & EventsUpdateLimitAttendanceErrorComponent)
  | ({
      attr: "attendance_limit";
    } & EventsUpdateAttendanceLimitErrorComponent)
  | ({
      attr: "description";
    } & EventsUpdateDescriptionErrorComponent)
  | ({
      attr: "staff";
    } & EventsUpdateStaffErrorComponent);
export type EventsUpdateValidationError = {
  type: TypeA2CEnum;
  errors: EventsUpdateError[];
};
export type EventsUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & EventsUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type EventsPartialUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type EventsPartialUpdateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code238Enum;
  detail: string;
};
export type EventsPartialUpdateStartDateErrorComponent = {
  attr: Attr92BEnum;
  code: Code0DbEnum;
  detail: string;
};
export type EventsPartialUpdateStartTimeErrorComponent = {
  attr: Attr2A0Enum;
  code: CodeF7EEnum;
  detail: string;
};
export type EventsPartialUpdateEndTimeErrorComponent = {
  attr: AttrFc2Enum;
  code: CodeF7EEnum;
  detail: string;
};
export type EventsPartialUpdateIsAllDayErrorComponent = {
  attr: AttrF3CEnum;
  code: CodeF7EEnum;
  detail: string;
};
export type EventsPartialUpdatePriceErrorComponent = {
  attr: AttrA2DEnum;
  code: Code9BdEnum;
  detail: string;
};
export type EventsPartialUpdateReservationOpenErrorComponent = {
  attr: AttrF2CEnum;
  code: Code729Enum;
  detail: string;
};
export type EventsPartialUpdateReservationCloseErrorComponent = {
  attr: Attr59BEnum;
  code: Code729Enum;
  detail: string;
};
export type EventsPartialUpdateLimitAttendanceErrorComponent = {
  attr: Attr518Enum;
  code: Code023Enum;
  detail: string;
};
export type EventsPartialUpdateAttendanceLimitErrorComponent = {
  attr: Attr181Enum;
  code: CodeB7BEnum;
  detail: string;
};
export type EventsPartialUpdateDescriptionErrorComponent = {
  attr: Attr50BEnum;
  code: CodeA3CEnum;
  detail: string;
};
export type EventsPartialUpdateStaffErrorComponent = {
  attr: AttrD4FEnum;
  code: Code948Enum;
  detail: string;
};
export type EventsPartialUpdateError =
  | ({
      attr: "non_field_errors";
    } & EventsPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & EventsPartialUpdateNameErrorComponent)
  | ({
      attr: "start_date";
    } & EventsPartialUpdateStartDateErrorComponent)
  | ({
      attr: "start_time";
    } & EventsPartialUpdateStartTimeErrorComponent)
  | ({
      attr: "end_time";
    } & EventsPartialUpdateEndTimeErrorComponent)
  | ({
      attr: "is_all_day";
    } & EventsPartialUpdateIsAllDayErrorComponent)
  | ({
      attr: "price";
    } & EventsPartialUpdatePriceErrorComponent)
  | ({
      attr: "reservation_open";
    } & EventsPartialUpdateReservationOpenErrorComponent)
  | ({
      attr: "reservation_close";
    } & EventsPartialUpdateReservationCloseErrorComponent)
  | ({
      attr: "limit_attendance";
    } & EventsPartialUpdateLimitAttendanceErrorComponent)
  | ({
      attr: "attendance_limit";
    } & EventsPartialUpdateAttendanceLimitErrorComponent)
  | ({
      attr: "description";
    } & EventsPartialUpdateDescriptionErrorComponent)
  | ({
      attr: "staff";
    } & EventsPartialUpdateStaffErrorComponent);
export type EventsPartialUpdateValidationError = {
  type: TypeA2CEnum;
  errors: EventsPartialUpdateError[];
};
export type EventsPartialUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & EventsPartialUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PatchedEvent = {
  name?: string;
  start_date?: string;
  start_time?: string;
  end_time?: string;
  is_all_day?: boolean;
  price?: string;
  reservation_open?: ReservationOpenEnum;
  reservation_close?: ReservationCloseEnum;
  limit_attendance?: boolean;
  attendance_limit?: number | null;
  description?: string;
  staff?: number | null;
};
export type PatchedEventRead = {
  id?: number;
  spots_left?: string;
  spots_taken?: string;
  status?: string;
  reservation?: string;
  created_at?: string;
  updated_at?: string;
  name?: string;
  start_date?: string;
  start_time?: string;
  end_time?: string;
  is_all_day?: boolean;
  price_currency?: string;
  price?: string;
  reservation_open?: ReservationOpenEnum;
  reservation_close?: ReservationCloseEnum;
  limit_attendance?: boolean;
  attendance_limit?: number | null;
  description?: string;
  staff?: number | null;
};
export type EventsDestroyErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type EventsAttendeesListStartDateErrorComponent = {
  attr: Attr92BEnum;
  code: CodeE7CEnum;
  detail: string;
};
export type EventsAttendeesListStartDateRangeErrorComponent = {
  attr: Attr66EEnum;
  code: Code241Enum;
  detail: string;
};
export type EventsAttendeesListStaffErrorComponent = {
  attr: AttrD4FEnum;
  code: Code099Enum;
  detail: string;
};
export type EventsAttendeesListStaffInErrorComponent = {
  attr: AttrAfbEnum;
  code: Code099Enum;
  detail: string;
};
export type EventsAttendeesListError =
  | ({
      attr: "start_date";
    } & EventsAttendeesListStartDateErrorComponent)
  | ({
      attr: "start_date__range";
    } & EventsAttendeesListStartDateRangeErrorComponent)
  | ({
      attr: "staff";
    } & EventsAttendeesListStaffErrorComponent)
  | ({
      attr: "staff__in";
    } & EventsAttendeesListStaffInErrorComponent);
export type EventsAttendeesListValidationError = {
  type: TypeA2CEnum;
  errors: EventsAttendeesListError[];
};
export type EventsAttendeesListErrorResponse400 =
  | ({
      type: "validation_error";
    } & EventsAttendeesListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type Group = {
  name: string;
  permissions?: number[];
};
export type GroupRead = {
  id: number;
  name: string;
  permissions?: number[];
};
export type PaginatedGroupList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Group[];
};
export type PaginatedGroupListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: GroupRead[];
};
export type GroupsListErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type GroupsRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type MembersListAttendanceCheckOutErrorComponent = {
  attr: AttrE49Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type MembersListAttendanceCheckOutRangeErrorComponent = {
  attr: Attr107Enum;
  code: Code241Enum;
  detail: string;
};
export type MembersListIdInErrorComponent = {
  attr: Attr12BEnum;
  code: Code90FEnum;
  detail: string;
};
export type MembersListMigrationStatusErrorComponent = {
  attr: Attr461Enum;
  code: Code099Enum;
  detail: string;
};
export type MembersListMigrationStatusInErrorComponent = {
  attr: Attr5B2Enum;
  code: Code0E9Enum;
  detail: string;
};
export type MembersListBirthdayMonthErrorComponent = {
  attr: Attr09EEnum;
  code: Code90FEnum;
  detail: string;
};
export type MembersListDaysUntilBirthdayErrorComponent = {
  attr: Attr69DEnum;
  code: Code90FEnum;
  detail: string;
};
export type MembersListError =
  | ({
      attr: "attendance__check_out";
    } & MembersListAttendanceCheckOutErrorComponent)
  | ({
      attr: "attendance__check_out__range";
    } & MembersListAttendanceCheckOutRangeErrorComponent)
  | ({
      attr: "id__in";
    } & MembersListIdInErrorComponent)
  | ({
      attr: "migration_status";
    } & MembersListMigrationStatusErrorComponent)
  | ({
      attr: "migration_status__in";
    } & MembersListMigrationStatusInErrorComponent)
  | ({
      attr: "birthday_month";
    } & MembersListBirthdayMonthErrorComponent)
  | ({
      attr: "days_until_birthday";
    } & MembersListDaysUntilBirthdayErrorComponent);
export type MembersListValidationError = {
  type: TypeA2CEnum;
  errors: MembersListError[];
};
export type MembersListErrorResponse400 =
  | ({
      type: "validation_error";
    } & MembersListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type MembersCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type MembersCreateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code238Enum;
  detail: string;
};
export type MembersCreateEmailErrorComponent = {
  attr: Attr307Enum;
  code: Code6AeEnum;
  detail: string;
};
export type AttrB8DEnum = "phone";
export type MembersCreatePhoneErrorComponent = {
  attr: AttrB8DEnum;
  code: CodeF87Enum;
  detail: string;
};
export type MembersCreateProfilePhotoErrorComponent = {
  attr: AttrC70Enum;
  code: Code40EEnum;
  detail: string;
};
export type Attr6F0Enum = "language";
export type MembersCreateLanguageErrorComponent = {
  attr: Attr6F0Enum;
  code: Code3F2Enum;
  detail: string;
};
export type AttrDe0Enum = "birthday";
export type MembersCreateBirthdayErrorComponent = {
  attr: AttrDe0Enum;
  code: Code0DbEnum;
  detail: string;
};
export type Attr35DEnum = "assigned_to";
export type MembersCreateAssignedToErrorComponent = {
  attr: Attr35DEnum;
  code: Code948Enum;
  detail: string;
};
export type Attr43DEnum = "referred_by";
export type MembersCreateReferredByErrorComponent = {
  attr: Attr43DEnum;
  code: Code3F2Enum;
  detail: string;
};
export type Attr1FbEnum = "referrer";
export type MembersCreateReferrerErrorComponent = {
  attr: Attr1FbEnum;
  code: Code948Enum;
  detail: string;
};
export type Attr8B7Enum = "street_address";
export type Code548Enum =
  | "invalid"
  | "max_length"
  | "null_characters_not_allowed"
  | "surrogate_characters_not_allowed";
export type MembersCreateStreetAddressErrorComponent = {
  attr: Attr8B7Enum;
  code: Code548Enum;
  detail: string;
};
export type MembersCreateCityErrorComponent = {
  attr: Attr001Enum;
  code: Code548Enum;
  detail: string;
};
export type MembersCreateStateErrorComponent = {
  attr: AttrAbdEnum;
  code: Code548Enum;
  detail: string;
};
export type MembersCreatePostalCodeErrorComponent = {
  attr: Attr1DeEnum;
  code: Code548Enum;
  detail: string;
};
export type Attr476Enum = "country";
export type MembersCreateCountryErrorComponent = {
  attr: Attr476Enum;
  code: Code4EfEnum;
  detail: string;
};
export type Attr1D4Enum = "guardian_name";
export type MembersCreateGuardianNameErrorComponent = {
  attr: Attr1D4Enum;
  code: Code548Enum;
  detail: string;
};
export type AttrA55Enum = "guardian_email";
export type MembersCreateGuardianEmailErrorComponent = {
  attr: AttrA55Enum;
  code: Code548Enum;
  detail: string;
};
export type Attr933Enum = "guardian_phone";
export type MembersCreateGuardianPhoneErrorComponent = {
  attr: Attr933Enum;
  code: CodeF87Enum;
  detail: string;
};
export type Attr5B3Enum = "groups";
export type MembersCreateGroupsErrorComponent = {
  attr: Attr5B3Enum;
  code: Code2DeEnum;
  detail: string;
};
export type MembersCreateError =
  | ({
      attr: "non_field_errors";
    } & MembersCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & MembersCreateNameErrorComponent)
  | ({
      attr: "email";
    } & MembersCreateEmailErrorComponent)
  | ({
      attr: "phone";
    } & MembersCreatePhoneErrorComponent)
  | ({
      attr: "profile_photo";
    } & MembersCreateProfilePhotoErrorComponent)
  | ({
      attr: "language";
    } & MembersCreateLanguageErrorComponent)
  | ({
      attr: "birthday";
    } & MembersCreateBirthdayErrorComponent)
  | ({
      attr: "assigned_to";
    } & MembersCreateAssignedToErrorComponent)
  | ({
      attr: "referred_by";
    } & MembersCreateReferredByErrorComponent)
  | ({
      attr: "referrer";
    } & MembersCreateReferrerErrorComponent)
  | ({
      attr: "street_address";
    } & MembersCreateStreetAddressErrorComponent)
  | ({
      attr: "city";
    } & MembersCreateCityErrorComponent)
  | ({
      attr: "state";
    } & MembersCreateStateErrorComponent)
  | ({
      attr: "postal_code";
    } & MembersCreatePostalCodeErrorComponent)
  | ({
      attr: "country";
    } & MembersCreateCountryErrorComponent)
  | ({
      attr: "guardian_name";
    } & MembersCreateGuardianNameErrorComponent)
  | ({
      attr: "guardian_email";
    } & MembersCreateGuardianEmailErrorComponent)
  | ({
      attr: "guardian_phone";
    } & MembersCreateGuardianPhoneErrorComponent)
  | ({
      attr: "groups";
    } & MembersCreateGroupsErrorComponent);
export type MembersCreateValidationError = {
  type: TypeA2CEnum;
  errors: MembersCreateError[];
};
export type MembersCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & MembersCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type MembersRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type MembersUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type MembersUpdateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code238Enum;
  detail: string;
};
export type MembersUpdateEmailErrorComponent = {
  attr: Attr307Enum;
  code: Code6AeEnum;
  detail: string;
};
export type MembersUpdatePhoneErrorComponent = {
  attr: AttrB8DEnum;
  code: CodeF87Enum;
  detail: string;
};
export type MembersUpdateProfilePhotoErrorComponent = {
  attr: AttrC70Enum;
  code: Code40EEnum;
  detail: string;
};
export type MembersUpdateLanguageErrorComponent = {
  attr: Attr6F0Enum;
  code: Code3F2Enum;
  detail: string;
};
export type MembersUpdateBirthdayErrorComponent = {
  attr: AttrDe0Enum;
  code: Code0DbEnum;
  detail: string;
};
export type MembersUpdateAssignedToErrorComponent = {
  attr: Attr35DEnum;
  code: Code948Enum;
  detail: string;
};
export type MembersUpdateReferredByErrorComponent = {
  attr: Attr43DEnum;
  code: Code3F2Enum;
  detail: string;
};
export type MembersUpdateReferrerErrorComponent = {
  attr: Attr1FbEnum;
  code: Code948Enum;
  detail: string;
};
export type MembersUpdateStreetAddressErrorComponent = {
  attr: Attr8B7Enum;
  code: Code548Enum;
  detail: string;
};
export type MembersUpdateCityErrorComponent = {
  attr: Attr001Enum;
  code: Code548Enum;
  detail: string;
};
export type MembersUpdateStateErrorComponent = {
  attr: AttrAbdEnum;
  code: Code548Enum;
  detail: string;
};
export type MembersUpdatePostalCodeErrorComponent = {
  attr: Attr1DeEnum;
  code: Code548Enum;
  detail: string;
};
export type MembersUpdateCountryErrorComponent = {
  attr: Attr476Enum;
  code: Code4EfEnum;
  detail: string;
};
export type MembersUpdateGuardianNameErrorComponent = {
  attr: Attr1D4Enum;
  code: Code548Enum;
  detail: string;
};
export type MembersUpdateGuardianEmailErrorComponent = {
  attr: AttrA55Enum;
  code: Code548Enum;
  detail: string;
};
export type MembersUpdateGuardianPhoneErrorComponent = {
  attr: Attr933Enum;
  code: CodeF87Enum;
  detail: string;
};
export type MembersUpdateGroupsErrorComponent = {
  attr: Attr5B3Enum;
  code: Code2DeEnum;
  detail: string;
};
export type MembersUpdateError =
  | ({
      attr: "non_field_errors";
    } & MembersUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & MembersUpdateNameErrorComponent)
  | ({
      attr: "email";
    } & MembersUpdateEmailErrorComponent)
  | ({
      attr: "phone";
    } & MembersUpdatePhoneErrorComponent)
  | ({
      attr: "profile_photo";
    } & MembersUpdateProfilePhotoErrorComponent)
  | ({
      attr: "language";
    } & MembersUpdateLanguageErrorComponent)
  | ({
      attr: "birthday";
    } & MembersUpdateBirthdayErrorComponent)
  | ({
      attr: "assigned_to";
    } & MembersUpdateAssignedToErrorComponent)
  | ({
      attr: "referred_by";
    } & MembersUpdateReferredByErrorComponent)
  | ({
      attr: "referrer";
    } & MembersUpdateReferrerErrorComponent)
  | ({
      attr: "street_address";
    } & MembersUpdateStreetAddressErrorComponent)
  | ({
      attr: "city";
    } & MembersUpdateCityErrorComponent)
  | ({
      attr: "state";
    } & MembersUpdateStateErrorComponent)
  | ({
      attr: "postal_code";
    } & MembersUpdatePostalCodeErrorComponent)
  | ({
      attr: "country";
    } & MembersUpdateCountryErrorComponent)
  | ({
      attr: "guardian_name";
    } & MembersUpdateGuardianNameErrorComponent)
  | ({
      attr: "guardian_email";
    } & MembersUpdateGuardianEmailErrorComponent)
  | ({
      attr: "guardian_phone";
    } & MembersUpdateGuardianPhoneErrorComponent)
  | ({
      attr: "groups";
    } & MembersUpdateGroupsErrorComponent);
export type MembersUpdateValidationError = {
  type: TypeA2CEnum;
  errors: MembersUpdateError[];
};
export type MembersUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & MembersUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type MembersPartialUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type MembersPartialUpdateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code238Enum;
  detail: string;
};
export type MembersPartialUpdateEmailErrorComponent = {
  attr: Attr307Enum;
  code: Code6AeEnum;
  detail: string;
};
export type MembersPartialUpdatePhoneErrorComponent = {
  attr: AttrB8DEnum;
  code: CodeF87Enum;
  detail: string;
};
export type MembersPartialUpdateProfilePhotoErrorComponent = {
  attr: AttrC70Enum;
  code: Code40EEnum;
  detail: string;
};
export type MembersPartialUpdateLanguageErrorComponent = {
  attr: Attr6F0Enum;
  code: Code3F2Enum;
  detail: string;
};
export type MembersPartialUpdateBirthdayErrorComponent = {
  attr: AttrDe0Enum;
  code: Code0DbEnum;
  detail: string;
};
export type MembersPartialUpdateAssignedToErrorComponent = {
  attr: Attr35DEnum;
  code: Code948Enum;
  detail: string;
};
export type MembersPartialUpdateReferredByErrorComponent = {
  attr: Attr43DEnum;
  code: Code3F2Enum;
  detail: string;
};
export type MembersPartialUpdateReferrerErrorComponent = {
  attr: Attr1FbEnum;
  code: Code948Enum;
  detail: string;
};
export type MembersPartialUpdateStreetAddressErrorComponent = {
  attr: Attr8B7Enum;
  code: Code548Enum;
  detail: string;
};
export type MembersPartialUpdateCityErrorComponent = {
  attr: Attr001Enum;
  code: Code548Enum;
  detail: string;
};
export type MembersPartialUpdateStateErrorComponent = {
  attr: AttrAbdEnum;
  code: Code548Enum;
  detail: string;
};
export type MembersPartialUpdatePostalCodeErrorComponent = {
  attr: Attr1DeEnum;
  code: Code548Enum;
  detail: string;
};
export type MembersPartialUpdateCountryErrorComponent = {
  attr: Attr476Enum;
  code: Code4EfEnum;
  detail: string;
};
export type MembersPartialUpdateGuardianNameErrorComponent = {
  attr: Attr1D4Enum;
  code: Code548Enum;
  detail: string;
};
export type MembersPartialUpdateGuardianEmailErrorComponent = {
  attr: AttrA55Enum;
  code: Code548Enum;
  detail: string;
};
export type MembersPartialUpdateGuardianPhoneErrorComponent = {
  attr: Attr933Enum;
  code: CodeF87Enum;
  detail: string;
};
export type MembersPartialUpdateGroupsErrorComponent = {
  attr: Attr5B3Enum;
  code: Code2DeEnum;
  detail: string;
};
export type MembersPartialUpdateError =
  | ({
      attr: "non_field_errors";
    } & MembersPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & MembersPartialUpdateNameErrorComponent)
  | ({
      attr: "email";
    } & MembersPartialUpdateEmailErrorComponent)
  | ({
      attr: "phone";
    } & MembersPartialUpdatePhoneErrorComponent)
  | ({
      attr: "profile_photo";
    } & MembersPartialUpdateProfilePhotoErrorComponent)
  | ({
      attr: "language";
    } & MembersPartialUpdateLanguageErrorComponent)
  | ({
      attr: "birthday";
    } & MembersPartialUpdateBirthdayErrorComponent)
  | ({
      attr: "assigned_to";
    } & MembersPartialUpdateAssignedToErrorComponent)
  | ({
      attr: "referred_by";
    } & MembersPartialUpdateReferredByErrorComponent)
  | ({
      attr: "referrer";
    } & MembersPartialUpdateReferrerErrorComponent)
  | ({
      attr: "street_address";
    } & MembersPartialUpdateStreetAddressErrorComponent)
  | ({
      attr: "city";
    } & MembersPartialUpdateCityErrorComponent)
  | ({
      attr: "state";
    } & MembersPartialUpdateStateErrorComponent)
  | ({
      attr: "postal_code";
    } & MembersPartialUpdatePostalCodeErrorComponent)
  | ({
      attr: "country";
    } & MembersPartialUpdateCountryErrorComponent)
  | ({
      attr: "guardian_name";
    } & MembersPartialUpdateGuardianNameErrorComponent)
  | ({
      attr: "guardian_email";
    } & MembersPartialUpdateGuardianEmailErrorComponent)
  | ({
      attr: "guardian_phone";
    } & MembersPartialUpdateGuardianPhoneErrorComponent)
  | ({
      attr: "groups";
    } & MembersPartialUpdateGroupsErrorComponent);
export type MembersPartialUpdateValidationError = {
  type: TypeA2CEnum;
  errors: MembersPartialUpdateError[];
};
export type MembersPartialUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & MembersPartialUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PatchedUser = {
  name?: string;
  email?: string;
  phone?: string;
  profile_photo?: string | null;
  language?: LanguageEnum;
  birthday?: string;
  assigned_to?: number | null;
  referred_by?: ReferredByEnum;
  referrer?: number | null;
  street_address?: string | null;
  city?: string | null;
  state?: string | null;
  postal_code?: string | null;
  country?: CountryEnum;
  guardian_name?: string | null;
  guardian_email?: string | null;
  guardian_phone?: string;
  groups?: number[];
};
export type PatchedUserRead = {
  id?: number;
  name?: string;
  email?: string;
  phone?: string;
  profile_photo?: string | null;
  language?: LanguageEnum;
  has_active_membership?: boolean;
  created_by?: number | null;
  is_in_frontdesk_mode?: boolean;
  pin_code?: string;
  stripe_customer_id?: string | null;
  birthday?: string;
  assigned_to?: number | null;
  referred_by?: ReferredByEnum;
  referrer?: number | null;
  street_address?: string | null;
  city?: string | null;
  state?: string | null;
  postal_code?: string | null;
  country?: CountryEnum;
  guardian_name?: string | null;
  guardian_email?: string | null;
  guardian_phone?: string;
  is_owner?: string;
  working_hours?: number;
  days_until_birthday?: number;
  role?: RoleEnum;
  is_instructor?: string;
  groups?: number[];
  migration_status?: MigrationStatusEnum;
};
export type MembersDestroyErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type MembersDocumentsRequiredListErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type MembersHistoryListErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type AggregatedMembersCounts = {
  data: {
    [key: string]: number;
  };
  percentage_change: number | null;
};
export type MembersAggregatedMembersCountsRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type AggregatedMembersCountsList = {
  period: string;
  count: number;
};
export type PaginatedAggregatedMembersCountsListList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: AggregatedMembersCountsList[];
};
export type MembersAggregatedMembersCountsListListErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type PaymentTypeEnum = "Cash" | "Card";
export type UserMembership = {
  start_date: string;
  payment_type?: PaymentTypeEnum;
  archive_after_expiration?: boolean;
  user: number;
  membership_plan: number;
  discount?: number | null;
};
export type UserMembershipRead = {
  id: number;
  is_active: boolean;
  membership_plan_name: string;
  last_bill_date: string;
  paid_until: string;
  latest_invoice: string;
  total_check_ins_allowed: string;
  remaining_check_ins: string;
  check_in_reset_date: string;
  created_at: string;
  updated_at: string;
  created_on: string;
  start_date: string;
  end_date: string | null;
  is_archived: boolean;
  payment_type?: PaymentTypeEnum;
  punchcard_uses_remaining: number | null;
  check_ins_count: number;
  archive_after_expiration?: boolean;
  next_bill_date: string | null;
  user: number;
  employee: number;
  membership_plan: number;
  discount?: number | null;
};
export type UserMembershipWrite = {
  payment_received?: boolean;
  start_date: string;
  payment_type?: PaymentTypeEnum;
  archive_after_expiration?: boolean;
  user: number;
  membership_plan: number;
  discount?: number | null;
};
export type MembersAssignMembershipCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type MembersAssignMembershipCreateUserErrorComponent = {
  attr: Attr886Enum;
  code: Code6C0Enum;
  detail: string;
};
export type Attr8A0Enum = "membership_plan";
export type MembersAssignMembershipCreateMembershipPlanErrorComponent = {
  attr: Attr8A0Enum;
  code: Code6C0Enum;
  detail: string;
};
export type MembersAssignMembershipCreateEndDateErrorComponent = {
  attr: Attr637Enum;
  code: Code5E5Enum;
  detail: string;
};
export type AttrCa2Enum = "next_bill_date";
export type MembersAssignMembershipCreateNextBillDateErrorComponent = {
  attr: AttrCa2Enum;
  code: Code5E5Enum;
  detail: string;
};
export type MembersAssignMembershipCreatePunchesRemainingErrorComponentAttrEnum =
  "punches_remaining";
export type MembersAssignMembershipCreatePunchesRemainingErrorComponentCodeEnum =
  "invalid" | "max_string_length" | "null";
export type MembersAssignMembershipCreatePunchesRemainingErrorComponent = {
  attr: MembersAssignMembershipCreatePunchesRemainingErrorComponentAttrEnum;
  code: MembersAssignMembershipCreatePunchesRemainingErrorComponentCodeEnum;
  detail: string;
};
export type MembersAssignMembershipCreatePunchcardExpiresErrorComponent = {
  attr: Attr4FfEnum;
  code: Code023Enum;
  detail: string;
};
export type MembersAssignMembershipCreateError =
  | ({
      attr: "non_field_errors";
    } & MembersAssignMembershipCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "user";
    } & MembersAssignMembershipCreateUserErrorComponent)
  | ({
      attr: "membership_plan";
    } & MembersAssignMembershipCreateMembershipPlanErrorComponent)
  | ({
      attr: "end_date";
    } & MembersAssignMembershipCreateEndDateErrorComponent)
  | ({
      attr: "next_bill_date";
    } & MembersAssignMembershipCreateNextBillDateErrorComponent)
  | ({
      attr: "punches_remaining";
    } & MembersAssignMembershipCreatePunchesRemainingErrorComponent)
  | ({
      attr: "punchcard_expires";
    } & MembersAssignMembershipCreatePunchcardExpiresErrorComponent);
export type MembersAssignMembershipCreateValidationError = {
  type: TypeA2CEnum;
  errors: MembersAssignMembershipCreateError[];
};
export type MembersAssignMembershipCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & MembersAssignMembershipCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type AssignMembership = {
  user: number;
  membership_plan: number;
  end_date?: string;
  next_bill_date?: string;
  punches_remaining?: number;
  punchcard_expires?: boolean;
};
export type MembersDownloadCsvRetrieveAttendanceCheckOutErrorComponent = {
  attr: AttrE49Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type MembersDownloadCsvRetrieveAttendanceCheckOutRangeErrorComponent = {
  attr: Attr107Enum;
  code: Code241Enum;
  detail: string;
};
export type MembersDownloadCsvRetrieveIdInErrorComponent = {
  attr: Attr12BEnum;
  code: Code90FEnum;
  detail: string;
};
export type MembersDownloadCsvRetrieveMigrationStatusErrorComponent = {
  attr: Attr461Enum;
  code: Code099Enum;
  detail: string;
};
export type MembersDownloadCsvRetrieveMigrationStatusInErrorComponent = {
  attr: Attr5B2Enum;
  code: Code0E9Enum;
  detail: string;
};
export type MembersDownloadCsvRetrieveBirthdayMonthErrorComponent = {
  attr: Attr09EEnum;
  code: Code90FEnum;
  detail: string;
};
export type MembersDownloadCsvRetrieveDaysUntilBirthdayErrorComponent = {
  attr: Attr69DEnum;
  code: Code90FEnum;
  detail: string;
};
export type MembersDownloadCsvRetrieveError =
  | ({
      attr: "attendance__check_out";
    } & MembersDownloadCsvRetrieveAttendanceCheckOutErrorComponent)
  | ({
      attr: "attendance__check_out__range";
    } & MembersDownloadCsvRetrieveAttendanceCheckOutRangeErrorComponent)
  | ({
      attr: "id__in";
    } & MembersDownloadCsvRetrieveIdInErrorComponent)
  | ({
      attr: "migration_status";
    } & MembersDownloadCsvRetrieveMigrationStatusErrorComponent)
  | ({
      attr: "migration_status__in";
    } & MembersDownloadCsvRetrieveMigrationStatusInErrorComponent)
  | ({
      attr: "birthday_month";
    } & MembersDownloadCsvRetrieveBirthdayMonthErrorComponent)
  | ({
      attr: "days_until_birthday";
    } & MembersDownloadCsvRetrieveDaysUntilBirthdayErrorComponent);
export type MembersDownloadCsvRetrieveValidationError = {
  type: TypeA2CEnum;
  errors: MembersDownloadCsvRetrieveError[];
};
export type MembersDownloadCsvRetrieveErrorResponse400 =
  | ({
      type: "validation_error";
    } & MembersDownloadCsvRetrieveValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type MembersDownloadTemplateRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type ImportUser = {
  name: string;
  email: string;
  birthday?: string | null;
  language?: LanguageEnum;
  street_address?: string | null;
  city?: string | null;
  state?: string | null;
  postal_code?: string | null;
  country: CountryEnum;
  phone?: string;
  guardian_name?: string | null;
  guardian_email?: string | null;
  guardian_phone?: string;
};
export type CodeB06Enum = "not_a_list" | "null";
export type MembersImportUsersCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: CodeB06Enum;
  detail: string;
};
export type MembersImportUsersCreateIndexNonFieldErrorsErrorComponentAttrEnum =
  "INDEX.non_field_errors";
export type MembersImportUsersCreateIndexNonFieldErrorsErrorComponent = {
  attr: MembersImportUsersCreateIndexNonFieldErrorsErrorComponentAttrEnum;
  code: CodeF7EEnum;
  detail: string;
};
export type MembersImportUsersCreateIndexNameErrorComponentAttrEnum =
  "INDEX.name";
export type MembersImportUsersCreateIndexNameErrorComponent = {
  attr: MembersImportUsersCreateIndexNameErrorComponentAttrEnum;
  code: Code238Enum;
  detail: string;
};
export type MembersImportUsersCreateIndexEmailErrorComponentAttrEnum =
  "INDEX.email";
export type MembersImportUsersCreateIndexEmailErrorComponent = {
  attr: MembersImportUsersCreateIndexEmailErrorComponentAttrEnum;
  code: Code6AeEnum;
  detail: string;
};
export type MembersImportUsersCreateIndexBirthdayErrorComponentAttrEnum =
  "INDEX.birthday";
export type MembersImportUsersCreateIndexBirthdayErrorComponent = {
  attr: MembersImportUsersCreateIndexBirthdayErrorComponentAttrEnum;
  code: CodeCeaEnum;
  detail: string;
};
export type MembersImportUsersCreateIndexLanguageErrorComponentAttrEnum =
  "INDEX.language";
export type MembersImportUsersCreateIndexLanguageErrorComponent = {
  attr: MembersImportUsersCreateIndexLanguageErrorComponentAttrEnum;
  code: Code3F2Enum;
  detail: string;
};
export type MembersImportUsersCreateIndexStreetAddressErrorComponentAttrEnum =
  "INDEX.street_address";
export type MembersImportUsersCreateIndexStreetAddressErrorComponent = {
  attr: MembersImportUsersCreateIndexStreetAddressErrorComponentAttrEnum;
  code: Code548Enum;
  detail: string;
};
export type MembersImportUsersCreateIndexCityErrorComponentAttrEnum =
  "INDEX.city";
export type MembersImportUsersCreateIndexCityErrorComponent = {
  attr: MembersImportUsersCreateIndexCityErrorComponentAttrEnum;
  code: Code548Enum;
  detail: string;
};
export type MembersImportUsersCreateIndexStateErrorComponentAttrEnum =
  "INDEX.state";
export type MembersImportUsersCreateIndexStateErrorComponent = {
  attr: MembersImportUsersCreateIndexStateErrorComponentAttrEnum;
  code: Code548Enum;
  detail: string;
};
export type MembersImportUsersCreateIndexPostalCodeErrorComponentAttrEnum =
  "INDEX.postal_code";
export type MembersImportUsersCreateIndexPostalCodeErrorComponent = {
  attr: MembersImportUsersCreateIndexPostalCodeErrorComponentAttrEnum;
  code: Code548Enum;
  detail: string;
};
export type MembersImportUsersCreateIndexCountryErrorComponentAttrEnum =
  "INDEX.country";
export type MembersImportUsersCreateIndexCountryErrorComponent = {
  attr: MembersImportUsersCreateIndexCountryErrorComponentAttrEnum;
  code: Code4EfEnum;
  detail: string;
};
export type MembersImportUsersCreateIndexPhoneErrorComponentAttrEnum =
  "INDEX.phone";
export type MembersImportUsersCreateIndexPhoneErrorComponent = {
  attr: MembersImportUsersCreateIndexPhoneErrorComponentAttrEnum;
  code: CodeF87Enum;
  detail: string;
};
export type MembersImportUsersCreateIndexGuardianNameErrorComponentAttrEnum =
  "INDEX.guardian_name";
export type MembersImportUsersCreateIndexGuardianNameErrorComponent = {
  attr: MembersImportUsersCreateIndexGuardianNameErrorComponentAttrEnum;
  code: Code548Enum;
  detail: string;
};
export type MembersImportUsersCreateIndexGuardianEmailErrorComponentAttrEnum =
  "INDEX.guardian_email";
export type MembersImportUsersCreateIndexGuardianEmailErrorComponent = {
  attr: MembersImportUsersCreateIndexGuardianEmailErrorComponentAttrEnum;
  code: Code548Enum;
  detail: string;
};
export type MembersImportUsersCreateIndexGuardianPhoneErrorComponentAttrEnum =
  "INDEX.guardian_phone";
export type MembersImportUsersCreateIndexGuardianPhoneErrorComponent = {
  attr: MembersImportUsersCreateIndexGuardianPhoneErrorComponentAttrEnum;
  code: CodeF87Enum;
  detail: string;
};
export type MembersImportUsersCreateError =
  | ({
      attr: "non_field_errors";
    } & MembersImportUsersCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "INDEX.non_field_errors";
    } & MembersImportUsersCreateIndexNonFieldErrorsErrorComponent)
  | ({
      attr: "INDEX.name";
    } & MembersImportUsersCreateIndexNameErrorComponent)
  | ({
      attr: "INDEX.email";
    } & MembersImportUsersCreateIndexEmailErrorComponent)
  | ({
      attr: "INDEX.birthday";
    } & MembersImportUsersCreateIndexBirthdayErrorComponent)
  | ({
      attr: "INDEX.language";
    } & MembersImportUsersCreateIndexLanguageErrorComponent)
  | ({
      attr: "INDEX.street_address";
    } & MembersImportUsersCreateIndexStreetAddressErrorComponent)
  | ({
      attr: "INDEX.city";
    } & MembersImportUsersCreateIndexCityErrorComponent)
  | ({
      attr: "INDEX.state";
    } & MembersImportUsersCreateIndexStateErrorComponent)
  | ({
      attr: "INDEX.postal_code";
    } & MembersImportUsersCreateIndexPostalCodeErrorComponent)
  | ({
      attr: "INDEX.country";
    } & MembersImportUsersCreateIndexCountryErrorComponent)
  | ({
      attr: "INDEX.phone";
    } & MembersImportUsersCreateIndexPhoneErrorComponent)
  | ({
      attr: "INDEX.guardian_name";
    } & MembersImportUsersCreateIndexGuardianNameErrorComponent)
  | ({
      attr: "INDEX.guardian_email";
    } & MembersImportUsersCreateIndexGuardianEmailErrorComponent)
  | ({
      attr: "INDEX.guardian_phone";
    } & MembersImportUsersCreateIndexGuardianPhoneErrorComponent);
export type MembersImportUsersCreateValidationError = {
  type: TypeA2CEnum;
  errors: MembersImportUsersCreateError[];
};
export type MembersImportUsersCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & MembersImportUsersCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type NotificationPreference = {
  class_cancellation?: boolean;
  class_reschedule?: boolean;
  reminders?: boolean;
};
export type MembersMyNotificationPreferencesRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type MembersMyNotificationPreferencesPartialUpdateNonFieldErrorsErrorComponent =
  {
    attr: AttrD81Enum;
    code: Code023Enum;
    detail: string;
  };
export type MembersMyNotificationPreferencesPartialUpdateClassCancellationErrorComponentAttrEnum =
  "class_cancellation";
export type MembersMyNotificationPreferencesPartialUpdateClassCancellationErrorComponent =
  {
    attr: MembersMyNotificationPreferencesPartialUpdateClassCancellationErrorComponentAttrEnum;
    code: Code023Enum;
    detail: string;
  };
export type MembersMyNotificationPreferencesPartialUpdateClassRescheduleErrorComponentAttrEnum =
  "class_reschedule";
export type MembersMyNotificationPreferencesPartialUpdateClassRescheduleErrorComponent =
  {
    attr: MembersMyNotificationPreferencesPartialUpdateClassRescheduleErrorComponentAttrEnum;
    code: Code023Enum;
    detail: string;
  };
export type MembersMyNotificationPreferencesPartialUpdateRemindersErrorComponentAttrEnum =
  "reminders";
export type MembersMyNotificationPreferencesPartialUpdateRemindersErrorComponent =
  {
    attr: MembersMyNotificationPreferencesPartialUpdateRemindersErrorComponentAttrEnum;
    code: Code023Enum;
    detail: string;
  };
export type MembersMyNotificationPreferencesPartialUpdateError =
  | ({
      attr: "non_field_errors";
    } & MembersMyNotificationPreferencesPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "class_cancellation";
    } & MembersMyNotificationPreferencesPartialUpdateClassCancellationErrorComponent)
  | ({
      attr: "class_reschedule";
    } & MembersMyNotificationPreferencesPartialUpdateClassRescheduleErrorComponent)
  | ({
      attr: "reminders";
    } & MembersMyNotificationPreferencesPartialUpdateRemindersErrorComponent);
export type MembersMyNotificationPreferencesPartialUpdateValidationError = {
  type: TypeA2CEnum;
  errors: MembersMyNotificationPreferencesPartialUpdateError[];
};
export type MembersMyNotificationPreferencesPartialUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & MembersMyNotificationPreferencesPartialUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PatchedNotificationPreference = {
  class_cancellation?: boolean;
  class_reschedule?: boolean;
  reminders?: boolean;
};
export type MembersReferralSourcesPercentageRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type MembersSendEmailToAllCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type MembersSendEmailToAllCreateSubjectErrorComponent = {
  attr: AttrFefEnum;
  code: Code238Enum;
  detail: string;
};
export type AttrF8CEnum = "message";
export type MembersSendEmailToAllCreateMessageErrorComponent = {
  attr: AttrF8CEnum;
  code: CodeA3CEnum;
  detail: string;
};
export type MembersSendEmailToAllCreateError =
  | ({
      attr: "non_field_errors";
    } & MembersSendEmailToAllCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "subject";
    } & MembersSendEmailToAllCreateSubjectErrorComponent)
  | ({
      attr: "message";
    } & MembersSendEmailToAllCreateMessageErrorComponent);
export type MembersSendEmailToAllCreateValidationError = {
  type: TypeA2CEnum;
  errors: MembersSendEmailToAllCreateError[];
};
export type MembersSendEmailToAllCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & MembersSendEmailToAllCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type EmailBroadcast = {
  subject: string;
  message: string;
};
export type MembersSendNotificationCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type MembersSendNotificationCreateMessageErrorComponent = {
  attr: AttrF8CEnum;
  code: Code238Enum;
  detail: string;
};
export type MembersSendNotificationCreateExtraErrorComponentAttrEnum = "extra";
export type MembersSendNotificationCreateExtraErrorComponent = {
  attr: MembersSendNotificationCreateExtraErrorComponentAttrEnum;
  code: Code023Enum;
  detail: string;
};
export type MembersSendNotificationCreateError =
  | ({
      attr: "non_field_errors";
    } & MembersSendNotificationCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "message";
    } & MembersSendNotificationCreateMessageErrorComponent)
  | ({
      attr: "extra";
    } & MembersSendNotificationCreateExtraErrorComponent);
export type MembersSendNotificationCreateValidationError = {
  type: TypeA2CEnum;
  errors: MembersSendNotificationCreateError[];
};
export type MembersSendNotificationCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & MembersSendNotificationCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type Notification = {
  message: string;
  extra?: any;
};
export type MembersSendWelcomeEmailCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type MembersSendWelcomeEmailCreateUsersErrorComponentAttrEnum = "users";
export type CodeD67Enum = "not_a_list" | "null" | "required";
export type MembersSendWelcomeEmailCreateUsersErrorComponent = {
  attr: MembersSendWelcomeEmailCreateUsersErrorComponentAttrEnum;
  code: CodeD67Enum;
  detail: string;
};
export type MembersSendWelcomeEmailCreateUsersIndexErrorComponentAttrEnum =
  "users.INDEX";
export type MembersSendWelcomeEmailCreateUsersIndexErrorComponent = {
  attr: MembersSendWelcomeEmailCreateUsersIndexErrorComponentAttrEnum;
  code: Code6C0Enum;
  detail: string;
};
export type MembersSendWelcomeEmailCreateError =
  | ({
      attr: "non_field_errors";
    } & MembersSendWelcomeEmailCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "users";
    } & MembersSendWelcomeEmailCreateUsersErrorComponent)
  | ({
      attr: "users.INDEX";
    } & MembersSendWelcomeEmailCreateUsersIndexErrorComponent);
export type MembersSendWelcomeEmailCreateValidationError = {
  type: TypeA2CEnum;
  errors: MembersSendWelcomeEmailCreateError[];
};
export type MembersSendWelcomeEmailCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & MembersSendWelcomeEmailCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type SendWelcomeEmail = {
  users: number[];
};
export type PlanTypeEnum = "recurring" | "non_recurring" | "punchcard";
export type ExpirePunchcardInUnitEnum = "day" | "week" | "month" | "year";
export type ResetCheckInLimitOnEnum = "billing_period" | "weekly" | "monthly";
export type BlankEnum = "";
export type ClassTypeRestrictionsEnum = "all" | "certain";
export type MembershipPlan = {
  name: string;
  description: string;
  additional_information?: string | null;
  plan_type: PlanTypeEnum;
  setup_fee?: string | null;
  charge_amount: string;
  recurring_interval?: number | null;
  recurring_interval_type?: ExpirePunchcardInUnitEnum;
  recurred_forever?: boolean;
  number_of_occurrences?: number | null;
  non_recurring_plan_expiration_unit?: ExpirePunchcardInUnitEnum;
  non_recurring_plan_expire_in?: number;
  punchcard_expires?: boolean;
  expire_punchcard_in?: number | null;
  expire_punchcard_in_unit?: ExpirePunchcardInUnitEnum;
  /** Total number of check-ins for punchcard plans */
  total_punchcard_checkins?: number | null;
  allow_check_ins?: boolean;
  limit_check_ins?: boolean;
  reset_check_in_limit_on?:
    | (ResetCheckInLimitOnEnum | BlankEnum | NullEnum)
    | null;
  check_in_restrictions?: number | null;
  class_type_restrictions?: ClassTypeRestrictionsEnum;
  allow_open_gym_check_ins?: boolean;
  generate_landing_page?: boolean;
  allow_class_booking?: boolean;
  confirmation_message?: string | null;
  show_on_member_app?: boolean;
  show_on_staff_app?: boolean;
  limit_quantity?: boolean;
  quantity_available?: number | null;
  trial_membership?: boolean;
  is_archived?: boolean;
  one_per_customer?: boolean;
  valid_class_types?: number[];
};
export type MembershipPlanRead = {
  id: number;
  plan_type_display: string;
  number_of_user_memberships: string;
  number_of_active_user_memberships: string;
  can_be_deleted: string;
  created_at: string;
  updated_at: string;
  name: string;
  description: string;
  additional_information?: string | null;
  plan_type: PlanTypeEnum;
  setup_fee_currency: string | null;
  setup_fee?: string | null;
  charge_amount_currency: string;
  charge_amount: string;
  recurring_interval?: number | null;
  recurring_interval_type?: ExpirePunchcardInUnitEnum;
  recurred_forever?: boolean;
  number_of_occurrences?: number | null;
  non_recurring_plan_expiration_unit?: ExpirePunchcardInUnitEnum;
  non_recurring_plan_expire_in?: number;
  punchcard_expires?: boolean;
  expire_punchcard_in?: number | null;
  expire_punchcard_in_unit?: ExpirePunchcardInUnitEnum;
  /** Total number of check-ins for punchcard plans */
  total_punchcard_checkins?: number | null;
  allow_check_ins?: boolean;
  limit_check_ins?: boolean;
  reset_check_in_limit_on?:
    | (ResetCheckInLimitOnEnum | BlankEnum | NullEnum)
    | null;
  check_in_restrictions?: number | null;
  class_type_restrictions?: ClassTypeRestrictionsEnum;
  allow_open_gym_check_ins?: boolean;
  generate_landing_page?: boolean;
  allow_class_booking?: boolean;
  confirmation_message?: string | null;
  show_on_member_app?: boolean;
  show_on_staff_app?: boolean;
  limit_quantity?: boolean;
  quantity_available?: number | null;
  trial_membership?: boolean;
  is_archived?: boolean;
  one_per_customer?: boolean;
  valid_class_types?: number[];
};
export type PaginatedMembershipPlanList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: MembershipPlan[];
};
export type PaginatedMembershipPlanListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: MembershipPlanRead[];
};
export type MembershipPlansListIdInErrorComponent = {
  attr: Attr12BEnum;
  code: Code90FEnum;
  detail: string;
};
export type AttrDc6Enum = "plan_type";
export type MembershipPlansListPlanTypeErrorComponent = {
  attr: AttrDc6Enum;
  code: Code099Enum;
  detail: string;
};
export type MembershipPlansListError =
  | ({
      attr: "id__in";
    } & MembershipPlansListIdInErrorComponent)
  | ({
      attr: "plan_type";
    } & MembershipPlansListPlanTypeErrorComponent);
export type MembershipPlansListValidationError = {
  type: TypeA2CEnum;
  errors: MembershipPlansListError[];
};
export type MembershipPlansListErrorResponse400 =
  | ({
      type: "validation_error";
    } & MembershipPlansListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type MembershipPlansCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansCreateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code238Enum;
  detail: string;
};
export type MembershipPlansCreateDescriptionErrorComponent = {
  attr: Attr50BEnum;
  code: CodeA3CEnum;
  detail: string;
};
export type Attr8E6Enum = "additional_information";
export type MembershipPlansCreateAdditionalInformationErrorComponent = {
  attr: Attr8E6Enum;
  code: CodeE4EEnum;
  detail: string;
};
export type MembershipPlansCreatePlanTypeErrorComponent = {
  attr: AttrDc6Enum;
  code: Code4EfEnum;
  detail: string;
};
export type Attr884Enum = "setup_fee";
export type CodeA60Enum =
  | "invalid"
  | "invalid_currency"
  | "max_decimal_places"
  | "max_digits"
  | "max_string_length"
  | "max_whole_digits";
export type MembershipPlansCreateSetupFeeErrorComponent = {
  attr: Attr884Enum;
  code: CodeA60Enum;
  detail: string;
};
export type AttrDf4Enum = "charge_amount";
export type MembershipPlansCreateChargeAmountErrorComponent = {
  attr: AttrDf4Enum;
  code: Code9BdEnum;
  detail: string;
};
export type AttrB90Enum = "recurring_interval";
export type MembershipPlansCreateRecurringIntervalErrorComponent = {
  attr: AttrB90Enum;
  code: CodeB7BEnum;
  detail: string;
};
export type AttrD31Enum = "recurring_interval_type";
export type MembershipPlansCreateRecurringIntervalTypeErrorComponent = {
  attr: AttrD31Enum;
  code: Code3F2Enum;
  detail: string;
};
export type AttrE6AEnum = "recurred_forever";
export type MembershipPlansCreateRecurredForeverErrorComponent = {
  attr: AttrE6AEnum;
  code: Code023Enum;
  detail: string;
};
export type Attr039Enum = "number_of_occurrences";
export type MembershipPlansCreateNumberOfOccurrencesErrorComponent = {
  attr: Attr039Enum;
  code: CodeB7BEnum;
  detail: string;
};
export type AttrE1EEnum = "non_recurring_plan_expiration_unit";
export type MembershipPlansCreateNonRecurringPlanExpirationUnitErrorComponent =
  {
    attr: AttrE1EEnum;
    code: Code3F2Enum;
    detail: string;
  };
export type MembershipPlansCreateNonRecurringPlanExpireInErrorComponent = {
  attr: Attr1FbEnum;
  code: Code9E1Enum;
  detail: string;
};
export type MembershipPlansCreatePunchcardExpiresErrorComponent = {
  attr: Attr4FfEnum;
  code: Code023Enum;
  detail: string;
};
export type Attr405Enum = "expire_punchcard_in";
export type MembershipPlansCreateExpirePunchcardInErrorComponent = {
  attr: Attr405Enum;
  code: CodeB7BEnum;
  detail: string;
};
export type AttrC2FEnum = "expire_punchcard_in_unit";
export type MembershipPlansCreateExpirePunchcardInUnitErrorComponent = {
  attr: AttrC2FEnum;
  code: Code3F2Enum;
  detail: string;
};
export type Attr288Enum = "total_punchcard_checkins";
export type MembershipPlansCreateTotalPunchcardCheckinsErrorComponent = {
  attr: Attr288Enum;
  code: CodeB7BEnum;
  detail: string;
};
export type AttrDc7Enum = "allow_check_ins";
export type MembershipPlansCreateAllowCheckInsErrorComponent = {
  attr: AttrDc7Enum;
  code: Code023Enum;
  detail: string;
};
export type Attr930Enum = "limit_check_ins";
export type MembershipPlansCreateLimitCheckInsErrorComponent = {
  attr: Attr930Enum;
  code: Code023Enum;
  detail: string;
};
export type AttrF80Enum = "reset_check_in_limit_on";
export type MembershipPlansCreateResetCheckInLimitOnErrorComponent = {
  attr: AttrF80Enum;
  code: Code099Enum;
  detail: string;
};
export type AttrCd0Enum = "check_in_restrictions";
export type MembershipPlansCreateCheckInRestrictionsErrorComponent = {
  attr: AttrCd0Enum;
  code: CodeB7BEnum;
  detail: string;
};
export type AttrB74Enum = "class_type_restrictions";
export type MembershipPlansCreateClassTypeRestrictionsErrorComponent = {
  attr: AttrB74Enum;
  code: Code3F2Enum;
  detail: string;
};
export type Attr488Enum = "allow_open_gym_check_ins";
export type MembershipPlansCreateAllowOpenGymCheckInsErrorComponent = {
  attr: Attr488Enum;
  code: Code023Enum;
  detail: string;
};
export type Attr9EbEnum = "generate_landing_page";
export type MembershipPlansCreateGenerateLandingPageErrorComponent = {
  attr: Attr9EbEnum;
  code: Code023Enum;
  detail: string;
};
export type Attr144Enum = "allow_class_booking";
export type MembershipPlansCreateAllowClassBookingErrorComponent = {
  attr: Attr144Enum;
  code: Code023Enum;
  detail: string;
};
export type AttrE6CEnum = "confirmation_message";
export type MembershipPlansCreateConfirmationMessageErrorComponent = {
  attr: AttrE6CEnum;
  code: CodeE4EEnum;
  detail: string;
};
export type Attr820Enum = "show_on_member_app";
export type MembershipPlansCreateShowOnMemberAppErrorComponent = {
  attr: Attr820Enum;
  code: Code023Enum;
  detail: string;
};
export type Attr8BeEnum = "show_on_staff_app";
export type MembershipPlansCreateShowOnStaffAppErrorComponent = {
  attr: Attr8BeEnum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansCreateLimitQuantityErrorComponent = {
  attr: Attr980Enum;
  code: Code023Enum;
  detail: string;
};
export type AttrC0EEnum = "quantity_available";
export type MembershipPlansCreateQuantityAvailableErrorComponent = {
  attr: AttrC0EEnum;
  code: CodeB7BEnum;
  detail: string;
};
export type AttrA9FEnum = "trial_membership";
export type MembershipPlansCreateTrialMembershipErrorComponent = {
  attr: AttrA9FEnum;
  code: Code023Enum;
  detail: string;
};
export type Attr7BbEnum = "is_archived";
export type MembershipPlansCreateIsArchivedErrorComponent = {
  attr: Attr7BbEnum;
  code: Code023Enum;
  detail: string;
};
export type AttrF32Enum = "one_per_customer";
export type MembershipPlansCreateOnePerCustomerErrorComponent = {
  attr: AttrF32Enum;
  code: Code023Enum;
  detail: string;
};
export type Attr1B9Enum = "valid_class_types";
export type MembershipPlansCreateValidClassTypesErrorComponent = {
  attr: Attr1B9Enum;
  code: Code2DeEnum;
  detail: string;
};
export type MembershipPlansCreateError =
  | ({
      attr: "non_field_errors";
    } & MembershipPlansCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & MembershipPlansCreateNameErrorComponent)
  | ({
      attr: "description";
    } & MembershipPlansCreateDescriptionErrorComponent)
  | ({
      attr: "additional_information";
    } & MembershipPlansCreateAdditionalInformationErrorComponent)
  | ({
      attr: "plan_type";
    } & MembershipPlansCreatePlanTypeErrorComponent)
  | ({
      attr: "setup_fee";
    } & MembershipPlansCreateSetupFeeErrorComponent)
  | ({
      attr: "charge_amount";
    } & MembershipPlansCreateChargeAmountErrorComponent)
  | ({
      attr: "recurring_interval";
    } & MembershipPlansCreateRecurringIntervalErrorComponent)
  | ({
      attr: "recurring_interval_type";
    } & MembershipPlansCreateRecurringIntervalTypeErrorComponent)
  | ({
      attr: "recurred_forever";
    } & MembershipPlansCreateRecurredForeverErrorComponent)
  | ({
      attr: "number_of_occurrences";
    } & MembershipPlansCreateNumberOfOccurrencesErrorComponent)
  | ({
      attr: "non_recurring_plan_expiration_unit";
    } & MembershipPlansCreateNonRecurringPlanExpirationUnitErrorComponent)
  | ({
      attr: "non_recurring_plan_expire_in";
    } & MembershipPlansCreateNonRecurringPlanExpireInErrorComponent)
  | ({
      attr: "punchcard_expires";
    } & MembershipPlansCreatePunchcardExpiresErrorComponent)
  | ({
      attr: "expire_punchcard_in";
    } & MembershipPlansCreateExpirePunchcardInErrorComponent)
  | ({
      attr: "expire_punchcard_in_unit";
    } & MembershipPlansCreateExpirePunchcardInUnitErrorComponent)
  | ({
      attr: "total_punchcard_checkins";
    } & MembershipPlansCreateTotalPunchcardCheckinsErrorComponent)
  | ({
      attr: "allow_check_ins";
    } & MembershipPlansCreateAllowCheckInsErrorComponent)
  | ({
      attr: "limit_check_ins";
    } & MembershipPlansCreateLimitCheckInsErrorComponent)
  | ({
      attr: "reset_check_in_limit_on";
    } & MembershipPlansCreateResetCheckInLimitOnErrorComponent)
  | ({
      attr: "check_in_restrictions";
    } & MembershipPlansCreateCheckInRestrictionsErrorComponent)
  | ({
      attr: "class_type_restrictions";
    } & MembershipPlansCreateClassTypeRestrictionsErrorComponent)
  | ({
      attr: "allow_open_gym_check_ins";
    } & MembershipPlansCreateAllowOpenGymCheckInsErrorComponent)
  | ({
      attr: "generate_landing_page";
    } & MembershipPlansCreateGenerateLandingPageErrorComponent)
  | ({
      attr: "allow_class_booking";
    } & MembershipPlansCreateAllowClassBookingErrorComponent)
  | ({
      attr: "confirmation_message";
    } & MembershipPlansCreateConfirmationMessageErrorComponent)
  | ({
      attr: "show_on_member_app";
    } & MembershipPlansCreateShowOnMemberAppErrorComponent)
  | ({
      attr: "show_on_staff_app";
    } & MembershipPlansCreateShowOnStaffAppErrorComponent)
  | ({
      attr: "limit_quantity";
    } & MembershipPlansCreateLimitQuantityErrorComponent)
  | ({
      attr: "quantity_available";
    } & MembershipPlansCreateQuantityAvailableErrorComponent)
  | ({
      attr: "trial_membership";
    } & MembershipPlansCreateTrialMembershipErrorComponent)
  | ({
      attr: "is_archived";
    } & MembershipPlansCreateIsArchivedErrorComponent)
  | ({
      attr: "one_per_customer";
    } & MembershipPlansCreateOnePerCustomerErrorComponent)
  | ({
      attr: "valid_class_types";
    } & MembershipPlansCreateValidClassTypesErrorComponent);
export type MembershipPlansCreateValidationError = {
  type: TypeA2CEnum;
  errors: MembershipPlansCreateError[];
};
export type MembershipPlansCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & MembershipPlansCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type MembershipPlansRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type MembershipPlansUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansUpdateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code238Enum;
  detail: string;
};
export type MembershipPlansUpdateDescriptionErrorComponent = {
  attr: Attr50BEnum;
  code: CodeA3CEnum;
  detail: string;
};
export type MembershipPlansUpdateAdditionalInformationErrorComponent = {
  attr: Attr8E6Enum;
  code: CodeE4EEnum;
  detail: string;
};
export type MembershipPlansUpdatePlanTypeErrorComponent = {
  attr: AttrDc6Enum;
  code: Code4EfEnum;
  detail: string;
};
export type MembershipPlansUpdateSetupFeeErrorComponent = {
  attr: Attr884Enum;
  code: CodeA60Enum;
  detail: string;
};
export type MembershipPlansUpdateChargeAmountErrorComponent = {
  attr: AttrDf4Enum;
  code: Code9BdEnum;
  detail: string;
};
export type MembershipPlansUpdateRecurringIntervalErrorComponent = {
  attr: AttrB90Enum;
  code: CodeB7BEnum;
  detail: string;
};
export type MembershipPlansUpdateRecurringIntervalTypeErrorComponent = {
  attr: AttrD31Enum;
  code: Code3F2Enum;
  detail: string;
};
export type MembershipPlansUpdateRecurredForeverErrorComponent = {
  attr: AttrE6AEnum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansUpdateNumberOfOccurrencesErrorComponent = {
  attr: Attr039Enum;
  code: CodeB7BEnum;
  detail: string;
};
export type MembershipPlansUpdateNonRecurringPlanExpirationUnitErrorComponent =
  {
    attr: AttrE1EEnum;
    code: Code3F2Enum;
    detail: string;
  };
export type MembershipPlansUpdateNonRecurringPlanExpireInErrorComponent = {
  attr: Attr1FbEnum;
  code: Code9E1Enum;
  detail: string;
};
export type MembershipPlansUpdatePunchcardExpiresErrorComponent = {
  attr: Attr4FfEnum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansUpdateExpirePunchcardInErrorComponent = {
  attr: Attr405Enum;
  code: CodeB7BEnum;
  detail: string;
};
export type MembershipPlansUpdateExpirePunchcardInUnitErrorComponent = {
  attr: AttrC2FEnum;
  code: Code3F2Enum;
  detail: string;
};
export type MembershipPlansUpdateTotalPunchcardCheckinsErrorComponent = {
  attr: Attr288Enum;
  code: CodeB7BEnum;
  detail: string;
};
export type MembershipPlansUpdateAllowCheckInsErrorComponent = {
  attr: AttrDc7Enum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansUpdateLimitCheckInsErrorComponent = {
  attr: Attr930Enum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansUpdateResetCheckInLimitOnErrorComponent = {
  attr: AttrF80Enum;
  code: Code099Enum;
  detail: string;
};
export type MembershipPlansUpdateCheckInRestrictionsErrorComponent = {
  attr: AttrCd0Enum;
  code: CodeB7BEnum;
  detail: string;
};
export type MembershipPlansUpdateClassTypeRestrictionsErrorComponent = {
  attr: AttrB74Enum;
  code: Code3F2Enum;
  detail: string;
};
export type MembershipPlansUpdateAllowOpenGymCheckInsErrorComponent = {
  attr: Attr488Enum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansUpdateGenerateLandingPageErrorComponent = {
  attr: Attr9EbEnum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansUpdateAllowClassBookingErrorComponent = {
  attr: Attr144Enum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansUpdateConfirmationMessageErrorComponent = {
  attr: AttrE6CEnum;
  code: CodeE4EEnum;
  detail: string;
};
export type MembershipPlansUpdateShowOnMemberAppErrorComponent = {
  attr: Attr820Enum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansUpdateShowOnStaffAppErrorComponent = {
  attr: Attr8BeEnum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansUpdateLimitQuantityErrorComponent = {
  attr: Attr980Enum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansUpdateQuantityAvailableErrorComponent = {
  attr: AttrC0EEnum;
  code: CodeB7BEnum;
  detail: string;
};
export type MembershipPlansUpdateTrialMembershipErrorComponent = {
  attr: AttrA9FEnum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansUpdateIsArchivedErrorComponent = {
  attr: Attr7BbEnum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansUpdateOnePerCustomerErrorComponent = {
  attr: AttrF32Enum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansUpdateValidClassTypesErrorComponent = {
  attr: Attr1B9Enum;
  code: Code2DeEnum;
  detail: string;
};
export type MembershipPlansUpdateError =
  | ({
      attr: "non_field_errors";
    } & MembershipPlansUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & MembershipPlansUpdateNameErrorComponent)
  | ({
      attr: "description";
    } & MembershipPlansUpdateDescriptionErrorComponent)
  | ({
      attr: "additional_information";
    } & MembershipPlansUpdateAdditionalInformationErrorComponent)
  | ({
      attr: "plan_type";
    } & MembershipPlansUpdatePlanTypeErrorComponent)
  | ({
      attr: "setup_fee";
    } & MembershipPlansUpdateSetupFeeErrorComponent)
  | ({
      attr: "charge_amount";
    } & MembershipPlansUpdateChargeAmountErrorComponent)
  | ({
      attr: "recurring_interval";
    } & MembershipPlansUpdateRecurringIntervalErrorComponent)
  | ({
      attr: "recurring_interval_type";
    } & MembershipPlansUpdateRecurringIntervalTypeErrorComponent)
  | ({
      attr: "recurred_forever";
    } & MembershipPlansUpdateRecurredForeverErrorComponent)
  | ({
      attr: "number_of_occurrences";
    } & MembershipPlansUpdateNumberOfOccurrencesErrorComponent)
  | ({
      attr: "non_recurring_plan_expiration_unit";
    } & MembershipPlansUpdateNonRecurringPlanExpirationUnitErrorComponent)
  | ({
      attr: "non_recurring_plan_expire_in";
    } & MembershipPlansUpdateNonRecurringPlanExpireInErrorComponent)
  | ({
      attr: "punchcard_expires";
    } & MembershipPlansUpdatePunchcardExpiresErrorComponent)
  | ({
      attr: "expire_punchcard_in";
    } & MembershipPlansUpdateExpirePunchcardInErrorComponent)
  | ({
      attr: "expire_punchcard_in_unit";
    } & MembershipPlansUpdateExpirePunchcardInUnitErrorComponent)
  | ({
      attr: "total_punchcard_checkins";
    } & MembershipPlansUpdateTotalPunchcardCheckinsErrorComponent)
  | ({
      attr: "allow_check_ins";
    } & MembershipPlansUpdateAllowCheckInsErrorComponent)
  | ({
      attr: "limit_check_ins";
    } & MembershipPlansUpdateLimitCheckInsErrorComponent)
  | ({
      attr: "reset_check_in_limit_on";
    } & MembershipPlansUpdateResetCheckInLimitOnErrorComponent)
  | ({
      attr: "check_in_restrictions";
    } & MembershipPlansUpdateCheckInRestrictionsErrorComponent)
  | ({
      attr: "class_type_restrictions";
    } & MembershipPlansUpdateClassTypeRestrictionsErrorComponent)
  | ({
      attr: "allow_open_gym_check_ins";
    } & MembershipPlansUpdateAllowOpenGymCheckInsErrorComponent)
  | ({
      attr: "generate_landing_page";
    } & MembershipPlansUpdateGenerateLandingPageErrorComponent)
  | ({
      attr: "allow_class_booking";
    } & MembershipPlansUpdateAllowClassBookingErrorComponent)
  | ({
      attr: "confirmation_message";
    } & MembershipPlansUpdateConfirmationMessageErrorComponent)
  | ({
      attr: "show_on_member_app";
    } & MembershipPlansUpdateShowOnMemberAppErrorComponent)
  | ({
      attr: "show_on_staff_app";
    } & MembershipPlansUpdateShowOnStaffAppErrorComponent)
  | ({
      attr: "limit_quantity";
    } & MembershipPlansUpdateLimitQuantityErrorComponent)
  | ({
      attr: "quantity_available";
    } & MembershipPlansUpdateQuantityAvailableErrorComponent)
  | ({
      attr: "trial_membership";
    } & MembershipPlansUpdateTrialMembershipErrorComponent)
  | ({
      attr: "is_archived";
    } & MembershipPlansUpdateIsArchivedErrorComponent)
  | ({
      attr: "one_per_customer";
    } & MembershipPlansUpdateOnePerCustomerErrorComponent)
  | ({
      attr: "valid_class_types";
    } & MembershipPlansUpdateValidClassTypesErrorComponent);
export type MembershipPlansUpdateValidationError = {
  type: TypeA2CEnum;
  errors: MembershipPlansUpdateError[];
};
export type MembershipPlansUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & MembershipPlansUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type MembershipPlansPartialUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansPartialUpdateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code238Enum;
  detail: string;
};
export type MembershipPlansPartialUpdateDescriptionErrorComponent = {
  attr: Attr50BEnum;
  code: CodeA3CEnum;
  detail: string;
};
export type MembershipPlansPartialUpdateAdditionalInformationErrorComponent = {
  attr: Attr8E6Enum;
  code: CodeE4EEnum;
  detail: string;
};
export type MembershipPlansPartialUpdatePlanTypeErrorComponent = {
  attr: AttrDc6Enum;
  code: Code4EfEnum;
  detail: string;
};
export type MembershipPlansPartialUpdateSetupFeeErrorComponent = {
  attr: Attr884Enum;
  code: CodeA60Enum;
  detail: string;
};
export type MembershipPlansPartialUpdateChargeAmountErrorComponent = {
  attr: AttrDf4Enum;
  code: Code9BdEnum;
  detail: string;
};
export type MembershipPlansPartialUpdateRecurringIntervalErrorComponent = {
  attr: AttrB90Enum;
  code: CodeB7BEnum;
  detail: string;
};
export type MembershipPlansPartialUpdateRecurringIntervalTypeErrorComponent = {
  attr: AttrD31Enum;
  code: Code3F2Enum;
  detail: string;
};
export type MembershipPlansPartialUpdateRecurredForeverErrorComponent = {
  attr: AttrE6AEnum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansPartialUpdateNumberOfOccurrencesErrorComponent = {
  attr: Attr039Enum;
  code: CodeB7BEnum;
  detail: string;
};
export type MembershipPlansPartialUpdateNonRecurringPlanExpirationUnitErrorComponent =
  {
    attr: AttrE1EEnum;
    code: Code3F2Enum;
    detail: string;
  };
export type MembershipPlansPartialUpdateNonRecurringPlanExpireInErrorComponent =
  {
    attr: Attr1FbEnum;
    code: Code9E1Enum;
    detail: string;
  };
export type MembershipPlansPartialUpdatePunchcardExpiresErrorComponent = {
  attr: Attr4FfEnum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansPartialUpdateExpirePunchcardInErrorComponent = {
  attr: Attr405Enum;
  code: CodeB7BEnum;
  detail: string;
};
export type MembershipPlansPartialUpdateExpirePunchcardInUnitErrorComponent = {
  attr: AttrC2FEnum;
  code: Code3F2Enum;
  detail: string;
};
export type MembershipPlansPartialUpdateTotalPunchcardCheckinsErrorComponent = {
  attr: Attr288Enum;
  code: CodeB7BEnum;
  detail: string;
};
export type MembershipPlansPartialUpdateAllowCheckInsErrorComponent = {
  attr: AttrDc7Enum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansPartialUpdateLimitCheckInsErrorComponent = {
  attr: Attr930Enum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansPartialUpdateResetCheckInLimitOnErrorComponent = {
  attr: AttrF80Enum;
  code: Code099Enum;
  detail: string;
};
export type MembershipPlansPartialUpdateCheckInRestrictionsErrorComponent = {
  attr: AttrCd0Enum;
  code: CodeB7BEnum;
  detail: string;
};
export type MembershipPlansPartialUpdateClassTypeRestrictionsErrorComponent = {
  attr: AttrB74Enum;
  code: Code3F2Enum;
  detail: string;
};
export type MembershipPlansPartialUpdateAllowOpenGymCheckInsErrorComponent = {
  attr: Attr488Enum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansPartialUpdateGenerateLandingPageErrorComponent = {
  attr: Attr9EbEnum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansPartialUpdateAllowClassBookingErrorComponent = {
  attr: Attr144Enum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansPartialUpdateConfirmationMessageErrorComponent = {
  attr: AttrE6CEnum;
  code: CodeE4EEnum;
  detail: string;
};
export type MembershipPlansPartialUpdateShowOnMemberAppErrorComponent = {
  attr: Attr820Enum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansPartialUpdateShowOnStaffAppErrorComponent = {
  attr: Attr8BeEnum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansPartialUpdateLimitQuantityErrorComponent = {
  attr: Attr980Enum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansPartialUpdateQuantityAvailableErrorComponent = {
  attr: AttrC0EEnum;
  code: CodeB7BEnum;
  detail: string;
};
export type MembershipPlansPartialUpdateTrialMembershipErrorComponent = {
  attr: AttrA9FEnum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansPartialUpdateIsArchivedErrorComponent = {
  attr: Attr7BbEnum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansPartialUpdateOnePerCustomerErrorComponent = {
  attr: AttrF32Enum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansPartialUpdateValidClassTypesErrorComponent = {
  attr: Attr1B9Enum;
  code: Code2DeEnum;
  detail: string;
};
export type MembershipPlansPartialUpdateError =
  | ({
      attr: "non_field_errors";
    } & MembershipPlansPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & MembershipPlansPartialUpdateNameErrorComponent)
  | ({
      attr: "description";
    } & MembershipPlansPartialUpdateDescriptionErrorComponent)
  | ({
      attr: "additional_information";
    } & MembershipPlansPartialUpdateAdditionalInformationErrorComponent)
  | ({
      attr: "plan_type";
    } & MembershipPlansPartialUpdatePlanTypeErrorComponent)
  | ({
      attr: "setup_fee";
    } & MembershipPlansPartialUpdateSetupFeeErrorComponent)
  | ({
      attr: "charge_amount";
    } & MembershipPlansPartialUpdateChargeAmountErrorComponent)
  | ({
      attr: "recurring_interval";
    } & MembershipPlansPartialUpdateRecurringIntervalErrorComponent)
  | ({
      attr: "recurring_interval_type";
    } & MembershipPlansPartialUpdateRecurringIntervalTypeErrorComponent)
  | ({
      attr: "recurred_forever";
    } & MembershipPlansPartialUpdateRecurredForeverErrorComponent)
  | ({
      attr: "number_of_occurrences";
    } & MembershipPlansPartialUpdateNumberOfOccurrencesErrorComponent)
  | ({
      attr: "non_recurring_plan_expiration_unit";
    } & MembershipPlansPartialUpdateNonRecurringPlanExpirationUnitErrorComponent)
  | ({
      attr: "non_recurring_plan_expire_in";
    } & MembershipPlansPartialUpdateNonRecurringPlanExpireInErrorComponent)
  | ({
      attr: "punchcard_expires";
    } & MembershipPlansPartialUpdatePunchcardExpiresErrorComponent)
  | ({
      attr: "expire_punchcard_in";
    } & MembershipPlansPartialUpdateExpirePunchcardInErrorComponent)
  | ({
      attr: "expire_punchcard_in_unit";
    } & MembershipPlansPartialUpdateExpirePunchcardInUnitErrorComponent)
  | ({
      attr: "total_punchcard_checkins";
    } & MembershipPlansPartialUpdateTotalPunchcardCheckinsErrorComponent)
  | ({
      attr: "allow_check_ins";
    } & MembershipPlansPartialUpdateAllowCheckInsErrorComponent)
  | ({
      attr: "limit_check_ins";
    } & MembershipPlansPartialUpdateLimitCheckInsErrorComponent)
  | ({
      attr: "reset_check_in_limit_on";
    } & MembershipPlansPartialUpdateResetCheckInLimitOnErrorComponent)
  | ({
      attr: "check_in_restrictions";
    } & MembershipPlansPartialUpdateCheckInRestrictionsErrorComponent)
  | ({
      attr: "class_type_restrictions";
    } & MembershipPlansPartialUpdateClassTypeRestrictionsErrorComponent)
  | ({
      attr: "allow_open_gym_check_ins";
    } & MembershipPlansPartialUpdateAllowOpenGymCheckInsErrorComponent)
  | ({
      attr: "generate_landing_page";
    } & MembershipPlansPartialUpdateGenerateLandingPageErrorComponent)
  | ({
      attr: "allow_class_booking";
    } & MembershipPlansPartialUpdateAllowClassBookingErrorComponent)
  | ({
      attr: "confirmation_message";
    } & MembershipPlansPartialUpdateConfirmationMessageErrorComponent)
  | ({
      attr: "show_on_member_app";
    } & MembershipPlansPartialUpdateShowOnMemberAppErrorComponent)
  | ({
      attr: "show_on_staff_app";
    } & MembershipPlansPartialUpdateShowOnStaffAppErrorComponent)
  | ({
      attr: "limit_quantity";
    } & MembershipPlansPartialUpdateLimitQuantityErrorComponent)
  | ({
      attr: "quantity_available";
    } & MembershipPlansPartialUpdateQuantityAvailableErrorComponent)
  | ({
      attr: "trial_membership";
    } & MembershipPlansPartialUpdateTrialMembershipErrorComponent)
  | ({
      attr: "is_archived";
    } & MembershipPlansPartialUpdateIsArchivedErrorComponent)
  | ({
      attr: "one_per_customer";
    } & MembershipPlansPartialUpdateOnePerCustomerErrorComponent)
  | ({
      attr: "valid_class_types";
    } & MembershipPlansPartialUpdateValidClassTypesErrorComponent);
export type MembershipPlansPartialUpdateValidationError = {
  type: TypeA2CEnum;
  errors: MembershipPlansPartialUpdateError[];
};
export type MembershipPlansPartialUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & MembershipPlansPartialUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PatchedMembershipPlan = {
  name?: string;
  description?: string;
  additional_information?: string | null;
  plan_type?: PlanTypeEnum;
  setup_fee?: string | null;
  charge_amount?: string;
  recurring_interval?: number | null;
  recurring_interval_type?: ExpirePunchcardInUnitEnum;
  recurred_forever?: boolean;
  number_of_occurrences?: number | null;
  non_recurring_plan_expiration_unit?: ExpirePunchcardInUnitEnum;
  non_recurring_plan_expire_in?: number;
  punchcard_expires?: boolean;
  expire_punchcard_in?: number | null;
  expire_punchcard_in_unit?: ExpirePunchcardInUnitEnum;
  /** Total number of check-ins for punchcard plans */
  total_punchcard_checkins?: number | null;
  allow_check_ins?: boolean;
  limit_check_ins?: boolean;
  reset_check_in_limit_on?:
    | (ResetCheckInLimitOnEnum | BlankEnum | NullEnum)
    | null;
  check_in_restrictions?: number | null;
  class_type_restrictions?: ClassTypeRestrictionsEnum;
  allow_open_gym_check_ins?: boolean;
  generate_landing_page?: boolean;
  allow_class_booking?: boolean;
  confirmation_message?: string | null;
  show_on_member_app?: boolean;
  show_on_staff_app?: boolean;
  limit_quantity?: boolean;
  quantity_available?: number | null;
  trial_membership?: boolean;
  is_archived?: boolean;
  one_per_customer?: boolean;
  valid_class_types?: number[];
};
export type PatchedMembershipPlanRead = {
  id?: number;
  plan_type_display?: string;
  number_of_user_memberships?: string;
  number_of_active_user_memberships?: string;
  can_be_deleted?: string;
  created_at?: string;
  updated_at?: string;
  name?: string;
  description?: string;
  additional_information?: string | null;
  plan_type?: PlanTypeEnum;
  setup_fee_currency?: string | null;
  setup_fee?: string | null;
  charge_amount_currency?: string;
  charge_amount?: string;
  recurring_interval?: number | null;
  recurring_interval_type?: ExpirePunchcardInUnitEnum;
  recurred_forever?: boolean;
  number_of_occurrences?: number | null;
  non_recurring_plan_expiration_unit?: ExpirePunchcardInUnitEnum;
  non_recurring_plan_expire_in?: number;
  punchcard_expires?: boolean;
  expire_punchcard_in?: number | null;
  expire_punchcard_in_unit?: ExpirePunchcardInUnitEnum;
  /** Total number of check-ins for punchcard plans */
  total_punchcard_checkins?: number | null;
  allow_check_ins?: boolean;
  limit_check_ins?: boolean;
  reset_check_in_limit_on?:
    | (ResetCheckInLimitOnEnum | BlankEnum | NullEnum)
    | null;
  check_in_restrictions?: number | null;
  class_type_restrictions?: ClassTypeRestrictionsEnum;
  allow_open_gym_check_ins?: boolean;
  generate_landing_page?: boolean;
  allow_class_booking?: boolean;
  confirmation_message?: string | null;
  show_on_member_app?: boolean;
  show_on_staff_app?: boolean;
  limit_quantity?: boolean;
  quantity_available?: number | null;
  trial_membership?: boolean;
  is_archived?: boolean;
  one_per_customer?: boolean;
  valid_class_types?: number[];
};
export type MembershipPlansDestroyErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type MembershipPlansArchiveRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type MembershipPlansDownloadMembersCsvRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type HistoricalMembershipPlan = {
  plan_type: string;
  recurring_interval_type: string;
  non_recurring_plan_expiration_unit: string;
  expire_punchcard_in_unit: string;
  reset_check_in_limit_on: string;
  id?: number;
  name: string;
  description: string;
  additional_information?: string | null;
  setup_fee?: string | null;
  charge_amount: string;
  recurring_interval?: number | null;
  recurred_forever?: boolean;
  number_of_occurrences?: number | null;
  non_recurring_plan_expire_in?: number;
  punchcard_expires?: boolean;
  expire_punchcard_in?: number | null;
  /** Total number of check-ins for punchcard plans */
  total_punchcard_checkins?: number | null;
  allow_check_ins?: boolean;
  limit_check_ins?: boolean;
  check_in_restrictions?: number | null;
  class_type_restrictions?: ClassTypeRestrictionsEnum;
  allow_open_gym_check_ins?: boolean;
  generate_landing_page?: boolean;
  allow_class_booking?: boolean;
  confirmation_message?: string | null;
  show_on_member_app?: boolean;
  show_on_staff_app?: boolean;
  limit_quantity?: boolean;
  quantity_available?: number | null;
  trial_membership?: boolean;
  is_archived?: boolean;
  one_per_customer?: boolean;
  history_date: string;
  history_change_reason?: string | null;
  history_type: HistoryTypeEnum;
  history_user?: number | null;
};
export type HistoricalMembershipPlanRead = {
  history_id: number;
  plan_type: string;
  recurring_interval_type: string;
  non_recurring_plan_expiration_unit: string;
  expire_punchcard_in_unit: string;
  reset_check_in_limit_on: string;
  id?: number;
  name: string;
  description: string;
  additional_information?: string | null;
  setup_fee_currency: string | null;
  setup_fee?: string | null;
  charge_amount_currency: string;
  charge_amount: string;
  recurring_interval?: number | null;
  recurred_forever?: boolean;
  number_of_occurrences?: number | null;
  non_recurring_plan_expire_in?: number;
  punchcard_expires?: boolean;
  expire_punchcard_in?: number | null;
  /** Total number of check-ins for punchcard plans */
  total_punchcard_checkins?: number | null;
  allow_check_ins?: boolean;
  limit_check_ins?: boolean;
  check_in_restrictions?: number | null;
  class_type_restrictions?: ClassTypeRestrictionsEnum;
  allow_open_gym_check_ins?: boolean;
  generate_landing_page?: boolean;
  allow_class_booking?: boolean;
  confirmation_message?: string | null;
  show_on_member_app?: boolean;
  show_on_staff_app?: boolean;
  limit_quantity?: boolean;
  quantity_available?: number | null;
  trial_membership?: boolean;
  is_archived?: boolean;
  one_per_customer?: boolean;
  history_date: string;
  history_change_reason?: string | null;
  history_type: HistoryTypeEnum;
  history_user?: number | null;
};
export type PaginatedHistoricalMembershipPlanList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: HistoricalMembershipPlan[];
};
export type PaginatedHistoricalMembershipPlanListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: HistoricalMembershipPlanRead[];
};
export type MembershipPlansHistoryListErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type MembershipPlansSendEmailToAllCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type MembershipPlansSendEmailToAllCreateSubjectErrorComponent = {
  attr: AttrFefEnum;
  code: Code238Enum;
  detail: string;
};
export type MembershipPlansSendEmailToAllCreateMessageErrorComponent = {
  attr: AttrF8CEnum;
  code: CodeA3CEnum;
  detail: string;
};
export type MembershipPlansSendEmailToAllCreateError =
  | ({
      attr: "non_field_errors";
    } & MembershipPlansSendEmailToAllCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "subject";
    } & MembershipPlansSendEmailToAllCreateSubjectErrorComponent)
  | ({
      attr: "message";
    } & MembershipPlansSendEmailToAllCreateMessageErrorComponent);
export type MembershipPlansSendEmailToAllCreateValidationError = {
  type: TypeA2CEnum;
  errors: MembershipPlansSendEmailToAllCreateError[];
};
export type MembershipPlansSendEmailToAllCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & MembershipPlansSendEmailToAllCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type MembershipPlansUnarchiveRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type MembershipPlansArchivedListIdInErrorComponent = {
  attr: Attr12BEnum;
  code: Code90FEnum;
  detail: string;
};
export type MembershipPlansArchivedListPlanTypeErrorComponent = {
  attr: AttrDc6Enum;
  code: Code099Enum;
  detail: string;
};
export type MembershipPlansArchivedListError =
  | ({
      attr: "id__in";
    } & MembershipPlansArchivedListIdInErrorComponent)
  | ({
      attr: "plan_type";
    } & MembershipPlansArchivedListPlanTypeErrorComponent);
export type MembershipPlansArchivedListValidationError = {
  type: TypeA2CEnum;
  errors: MembershipPlansArchivedListError[];
};
export type MembershipPlansArchivedListErrorResponse400 =
  | ({
      type: "validation_error";
    } & MembershipPlansArchivedListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type Address = {
  street?: string | null;
  city?: string | null;
  state?: string | null;
  postal_code?: string | null;
  country: CountryEnum;
  invoice: string;
};
export type AddressRead = {
  id: number;
  country_display: string;
  street?: string | null;
  city?: string | null;
  state?: string | null;
  postal_code?: string | null;
  country: CountryEnum;
  invoice: string;
};
export type PaymentsAddressRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type AggregatedRevenueSource = {
  membership_revenue: number;
  product_revenue: number;
  credit_revenue: number;
};
export type AggregatedRevenue = {
  data: {
    [key: string]: AggregatedRevenueSource;
  };
  percentage_change: number | null;
};
export type PaymentsAggregatedRevenueRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type AggregatedRevenueList = {
  period: string;
  membership_revenue: string;
  product_revenue: string;
  credit_revenue: string;
  total_revenue: string;
};
export type PaginatedAggregatedRevenueListList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: AggregatedRevenueList[];
};
export type PaymentsAggregatedRevenueListListErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type FeeTypeEnum = "percent" | "flat";
export type InvoiceItemTax = {
  tax_name: string;
  tax_rate: string;
  fee_type?: FeeTypeEnum;
  amount: string;
  invoice_item: string;
  tax?: number | null;
};
export type InvoiceItemTaxRead = {
  id: number;
  tax_name: string;
  tax_rate: string;
  fee_type?: FeeTypeEnum;
  amount_currency: string;
  amount: string;
  invoice_item: string;
  tax?: number | null;
};
export type PaginatedInvoiceItemTaxList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: InvoiceItemTax[];
};
export type PaginatedInvoiceItemTaxListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: InvoiceItemTaxRead[];
};
export type PaymentsInvoiceItemTaxesListIdErrorComponentAttrEnum = "id";
export type PaymentsInvoiceItemTaxesListIdErrorComponent = {
  attr: PaymentsInvoiceItemTaxesListIdErrorComponentAttrEnum;
  code: Code90FEnum;
  detail: string;
};
export type PaymentsInvoiceItemTaxesListIdInErrorComponent = {
  attr: Attr12BEnum;
  code: Code90FEnum;
  detail: string;
};
export type PaymentsInvoiceItemTaxesListError =
  | ({
      attr: "id";
    } & PaymentsInvoiceItemTaxesListIdErrorComponent)
  | ({
      attr: "id__in";
    } & PaymentsInvoiceItemTaxesListIdInErrorComponent);
export type PaymentsInvoiceItemTaxesListValidationError = {
  type: TypeA2CEnum;
  errors: PaymentsInvoiceItemTaxesListError[];
};
export type PaymentsInvoiceItemTaxesListErrorResponse400 =
  | ({
      type: "validation_error";
    } & PaymentsInvoiceItemTaxesListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PaymentsInvoiceItemTaxesRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type InvoiceItem = {
  invoice: string;
  quantity: number;
};
export type InvoiceItemRead = {
  id: string;
  invoice: string;
  performed_by: number;
  quantity: number;
  unit_price: string;
  original_price: string;
  original_price_currency: string;
  line_total: string;
  line_total_currency: string;
  name: string;
  unit_price_currency: string;
  discount_amount: string;
  total_discount: string;
  total_discount_currency: string;
  total_original_amount: string;
  total_original_amount_currency: string;
  taxes: number[];
  total_tax_amount: string;
  total_tax_amount_currency: string;
};
export type InvoiceItemTyped = {
  resourcetype: string;
} & InvoiceItem;
export type InvoiceItemTypedRead = {
  resourcetype: string;
} & InvoiceItemRead;
export type ProductInvoiceItem = {
  invoice: string;
  quantity: number;
  product_option: number;
  discount?: number | null;
};
export type ProductInvoiceItemRead = {
  id: string;
  invoice: string;
  performed_by: number;
  quantity: number;
  unit_price: string;
  original_price: string;
  original_price_currency: string;
  line_total: string;
  line_total_currency: string;
  product_option: number;
  name: string;
  unit_price_currency: string;
  discount?: number | null;
  discount_name: string | null;
  discount_amount: string;
  total_discount: string;
  total_discount_currency: string;
  total_original_amount: string;
  total_original_amount_currency: string;
  taxes: number[];
  total_tax_amount: string;
  total_tax_amount_currency: string;
};
export type ProductInvoiceItemTyped = {
  resourcetype: string;
} & ProductInvoiceItem;
export type ProductInvoiceItemTypedRead = {
  resourcetype: string;
} & ProductInvoiceItemRead;
export type UserMembershipInvoiceItem = {
  invoice: string;
  quantity: number;
};
export type UserMembershipInvoiceItemRead = {
  id: string;
  invoice: string;
  performed_by: number;
  quantity: number;
  unit_price: string;
  original_price: string;
  original_price_currency: string;
  line_total: string;
  line_total_currency: string;
  name: string;
  unit_price_currency: string;
  user_membership: number;
  discount_name: string | null;
  discount_amount: string;
  total_discount: string;
  total_discount_currency: string;
  total_original_amount: string;
  total_original_amount_currency: string;
  taxes: number[];
  total_tax_amount: string;
  total_tax_amount_currency: string;
};
export type UserMembershipInvoiceItemTyped = {
  resourcetype: string;
} & UserMembershipInvoiceItem;
export type UserMembershipInvoiceItemTypedRead = {
  resourcetype: string;
} & UserMembershipInvoiceItemRead;
export type AppointmentInvoiceItem = {
  invoice: string;
  quantity: number;
};
export type AppointmentInvoiceItemRead = {
  id: string;
  invoice: string;
  performed_by: number;
  quantity: number;
  unit_price: string;
  original_price: string;
  original_price_currency: string;
  line_total: string;
  line_total_currency: string;
  name: string;
  unit_price_currency: string;
  credit_package: number;
  discount_name: string | null;
  discount_amount: string;
  total_discount: string;
  total_discount_currency: string;
  total_original_amount: string;
  total_original_amount_currency: string;
  taxes: number[];
  total_tax_amount: string;
  total_tax_amount_currency: string;
};
export type AppointmentInvoiceItemTyped = {
  resourcetype: string;
} & AppointmentInvoiceItem;
export type AppointmentInvoiceItemTypedRead = {
  resourcetype: string;
} & AppointmentInvoiceItemRead;
export type InvoiceItemPolymorphic =
  | ({
      resourcetype: "InvoiceItem";
    } & InvoiceItemTyped)
  | ({
      resourcetype: "ProductInvoiceItem";
    } & ProductInvoiceItemTyped)
  | ({
      resourcetype: "UserMembershipInvoiceItem";
    } & UserMembershipInvoiceItemTyped)
  | ({
      resourcetype: "AppointmentInvoiceItem";
    } & AppointmentInvoiceItemTyped);
export type InvoiceItemPolymorphicRead =
  | ({
      resourcetype: "InvoiceItem";
    } & InvoiceItemTypedRead)
  | ({
      resourcetype: "ProductInvoiceItem";
    } & ProductInvoiceItemTypedRead)
  | ({
      resourcetype: "UserMembershipInvoiceItem";
    } & UserMembershipInvoiceItemTypedRead)
  | ({
      resourcetype: "AppointmentInvoiceItem";
    } & AppointmentInvoiceItemTypedRead);
export type PaginatedInvoiceItemPolymorphicList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: InvoiceItemPolymorphic[];
};
export type PaginatedInvoiceItemPolymorphicListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: InvoiceItemPolymorphicRead[];
};
export type AttrF4DEnum = "invoice";
export type PaymentsInvoiceItemsListInvoiceErrorComponent = {
  attr: AttrF4DEnum;
  code: Code099Enum;
  detail: string;
};
export type PaymentsInvoiceItemsListError = {
  attr: "invoice";
} & PaymentsInvoiceItemsListInvoiceErrorComponent;
export type PaymentsInvoiceItemsListValidationError = {
  type: TypeA2CEnum;
  errors: PaymentsInvoiceItemsListError[];
};
export type PaymentsInvoiceItemsListErrorResponse400 =
  | ({
      type: "validation_error";
    } & PaymentsInvoiceItemsListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PaymentsInvoiceItemsCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type PaymentsInvoiceItemsCreateError = {
  attr: "non_field_errors";
} & PaymentsInvoiceItemsCreateNonFieldErrorsErrorComponent;
export type PaymentsInvoiceItemsCreateValidationError = {
  type: TypeA2CEnum;
  errors: PaymentsInvoiceItemsCreateError[];
};
export type PaymentsInvoiceItemsCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & PaymentsInvoiceItemsCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PaymentsInvoiceItemsRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type PaymentsInvoiceItemsDestroyErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type Invoice = {
  payment_intent_id?: string | null;
  customer?: number | null;
};
export type InvoiceRead = {
  id: string;
  total_paid: string;
  total_amount: string;
  total_discounts: string;
  payments: string;
  refunds: string;
  total_paid_currency: string;
  total_amount_currency: string;
  total_discounts_currency: string;
  payments_currency: string;
  refunds_currency: string;
  shipping_address: number;
  total_original_amount: string;
  total_original_amount_currency: string;
  total_tax_amount: string;
  total_tax_amount_currency: string;
  created_at: string;
  updated_at: string;
  customer_name: string;
  performed_by_name: string;
  is_paid: boolean;
  paid_at: string | null;
  payment_type: (PaymentTypeEnum | NullEnum) | null;
  is_forgiven: boolean;
  is_refunded: boolean;
  payment_intent_id?: string | null;
  customer?: number | null;
  performed_by: number | null;
};
export type PaginatedInvoiceList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Invoice[];
};
export type PaginatedInvoiceListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: InvoiceRead[];
};
export type Attr17CEnum = "customer";
export type PaymentsInvoicesListCustomerErrorComponent = {
  attr: Attr17CEnum;
  code: Code099Enum;
  detail: string;
};
export type AttrC82Enum = "membership_discount";
export type PaymentsInvoicesListMembershipDiscountErrorComponent = {
  attr: AttrC82Enum;
  code: Code90FEnum;
  detail: string;
};
export type PaymentsInvoicesListDiscountErrorComponent = {
  attr: Attr513Enum;
  code: Code90FEnum;
  detail: string;
};
export type Attr7B9Enum = "appointment_discount";
export type PaymentsInvoicesListAppointmentDiscountErrorComponent = {
  attr: Attr7B9Enum;
  code: Code90FEnum;
  detail: string;
};
export type AttrA8AEnum = "product_discount";
export type PaymentsInvoicesListProductDiscountErrorComponent = {
  attr: AttrA8AEnum;
  code: Code90FEnum;
  detail: string;
};
export type PaymentsInvoicesListError =
  | ({
      attr: "customer";
    } & PaymentsInvoicesListCustomerErrorComponent)
  | ({
      attr: "membership_discount";
    } & PaymentsInvoicesListMembershipDiscountErrorComponent)
  | ({
      attr: "discount";
    } & PaymentsInvoicesListDiscountErrorComponent)
  | ({
      attr: "appointment_discount";
    } & PaymentsInvoicesListAppointmentDiscountErrorComponent)
  | ({
      attr: "product_discount";
    } & PaymentsInvoicesListProductDiscountErrorComponent);
export type PaymentsInvoicesListValidationError = {
  type: TypeA2CEnum;
  errors: PaymentsInvoicesListError[];
};
export type PaymentsInvoicesListErrorResponse400 =
  | ({
      type: "validation_error";
    } & PaymentsInvoicesListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PaymentsInvoicesCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type Attr700Enum = "payment_intent_id";
export type PaymentsInvoicesCreatePaymentIntentIdErrorComponent = {
  attr: Attr700Enum;
  code: Code548Enum;
  detail: string;
};
export type PaymentsInvoicesCreateCustomerErrorComponent = {
  attr: Attr17CEnum;
  code: Code948Enum;
  detail: string;
};
export type PaymentsInvoicesCreateError =
  | ({
      attr: "non_field_errors";
    } & PaymentsInvoicesCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "payment_intent_id";
    } & PaymentsInvoicesCreatePaymentIntentIdErrorComponent)
  | ({
      attr: "customer";
    } & PaymentsInvoicesCreateCustomerErrorComponent);
export type PaymentsInvoicesCreateValidationError = {
  type: TypeA2CEnum;
  errors: PaymentsInvoicesCreateError[];
};
export type PaymentsInvoicesCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & PaymentsInvoicesCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PaymentsInvoicesRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type PaymentsInvoicesDestroyErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type PaymentsInvoicesForgiveInvoicePartialUpdateNonFieldErrorsErrorComponent =
  {
    attr: AttrD81Enum;
    code: Code023Enum;
    detail: string;
  };
export type PaymentsInvoicesForgiveInvoicePartialUpdatePaymentIntentIdErrorComponent =
  {
    attr: Attr700Enum;
    code: Code548Enum;
    detail: string;
  };
export type PaymentsInvoicesForgiveInvoicePartialUpdateCustomerErrorComponent =
  {
    attr: Attr17CEnum;
    code: Code948Enum;
    detail: string;
  };
export type PaymentsInvoicesForgiveInvoicePartialUpdateError =
  | ({
      attr: "non_field_errors";
    } & PaymentsInvoicesForgiveInvoicePartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "payment_intent_id";
    } & PaymentsInvoicesForgiveInvoicePartialUpdatePaymentIntentIdErrorComponent)
  | ({
      attr: "customer";
    } & PaymentsInvoicesForgiveInvoicePartialUpdateCustomerErrorComponent);
export type PaymentsInvoicesForgiveInvoicePartialUpdateValidationError = {
  type: TypeA2CEnum;
  errors: PaymentsInvoicesForgiveInvoicePartialUpdateError[];
};
export type PaymentsInvoicesForgiveInvoicePartialUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & PaymentsInvoicesForgiveInvoicePartialUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PatchedInvoice = {
  payment_intent_id?: string | null;
  customer?: number | null;
};
export type PatchedInvoiceRead = {
  id?: string;
  total_paid?: string;
  total_amount?: string;
  total_discounts?: string;
  payments?: string;
  refunds?: string;
  total_paid_currency?: string;
  total_amount_currency?: string;
  total_discounts_currency?: string;
  payments_currency?: string;
  refunds_currency?: string;
  shipping_address?: number;
  total_original_amount?: string;
  total_original_amount_currency?: string;
  total_tax_amount?: string;
  total_tax_amount_currency?: string;
  created_at?: string;
  updated_at?: string;
  customer_name?: string;
  performed_by_name?: string;
  is_paid?: boolean;
  paid_at?: string | null;
  payment_type?: (PaymentTypeEnum | NullEnum) | null;
  is_forgiven?: boolean;
  is_refunded?: boolean;
  payment_intent_id?: string | null;
  customer?: number | null;
  performed_by?: number | null;
};
export type PaymentsInvoicesGeneratePdfRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type PaymentsInvoicesHistoryListErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type MethodEnum = "Cash" | "Card";
export type Payment = {
  amount: string;
  method: MethodEnum;
};
export type PaymentsInvoicesPayCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type PaymentsInvoicesPayCreateAmountErrorComponent = {
  attr: Attr18FEnum;
  code: CodeC22Enum;
  detail: string;
};
export type Attr7E4Enum = "method";
export type PaymentsInvoicesPayCreateMethodErrorComponent = {
  attr: Attr7E4Enum;
  code: Code4EfEnum;
  detail: string;
};
export type PaymentsInvoicesPayCreateError =
  | ({
      attr: "non_field_errors";
    } & PaymentsInvoicesPayCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "amount";
    } & PaymentsInvoicesPayCreateAmountErrorComponent)
  | ({
      attr: "method";
    } & PaymentsInvoicesPayCreateMethodErrorComponent);
export type PaymentsInvoicesPayCreateValidationError = {
  type: TypeA2CEnum;
  errors: PaymentsInvoicesPayCreateError[];
};
export type PaymentsInvoicesPayCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & PaymentsInvoicesPayCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PaymentsInvoicesRefundCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type PaymentsInvoicesRefundCreateAmountErrorComponent = {
  attr: Attr18FEnum;
  code: CodeC22Enum;
  detail: string;
};
export type PaymentsInvoicesRefundCreateMethodErrorComponent = {
  attr: Attr7E4Enum;
  code: Code4EfEnum;
  detail: string;
};
export type PaymentsInvoicesRefundCreateError =
  | ({
      attr: "non_field_errors";
    } & PaymentsInvoicesRefundCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "amount";
    } & PaymentsInvoicesRefundCreateAmountErrorComponent)
  | ({
      attr: "method";
    } & PaymentsInvoicesRefundCreateMethodErrorComponent);
export type PaymentsInvoicesRefundCreateValidationError = {
  type: TypeA2CEnum;
  errors: PaymentsInvoicesRefundCreateError[];
};
export type PaymentsInvoicesRefundCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & PaymentsInvoicesRefundCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PaymentsInvoicesRefundCardCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type PaymentsInvoicesRefundCardCreatePaymentIntentIdErrorComponent = {
  attr: Attr700Enum;
  code: Code548Enum;
  detail: string;
};
export type PaymentsInvoicesRefundCardCreateCustomerErrorComponent = {
  attr: Attr17CEnum;
  code: Code948Enum;
  detail: string;
};
export type PaymentsInvoicesRefundCardCreateError =
  | ({
      attr: "non_field_errors";
    } & PaymentsInvoicesRefundCardCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "payment_intent_id";
    } & PaymentsInvoicesRefundCardCreatePaymentIntentIdErrorComponent)
  | ({
      attr: "customer";
    } & PaymentsInvoicesRefundCardCreateCustomerErrorComponent);
export type PaymentsInvoicesRefundCardCreateValidationError = {
  type: TypeA2CEnum;
  errors: PaymentsInvoicesRefundCardCreateError[];
};
export type PaymentsInvoicesRefundCardCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & PaymentsInvoicesRefundCardCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PaymentsInvoicesUpdateShippingAddressPartialUpdateNonFieldErrorsErrorComponent =
  {
    attr: AttrD81Enum;
    code: Code023Enum;
    detail: string;
  };
export type PaymentsInvoicesUpdateShippingAddressPartialUpdateStreetErrorComponentAttrEnum =
  "street";
export type PaymentsInvoicesUpdateShippingAddressPartialUpdateStreetErrorComponent =
  {
    attr: PaymentsInvoicesUpdateShippingAddressPartialUpdateStreetErrorComponentAttrEnum;
    code: Code548Enum;
    detail: string;
  };
export type PaymentsInvoicesUpdateShippingAddressPartialUpdateCityErrorComponent =
  {
    attr: Attr001Enum;
    code: Code548Enum;
    detail: string;
  };
export type PaymentsInvoicesUpdateShippingAddressPartialUpdateStateErrorComponent =
  {
    attr: AttrAbdEnum;
    code: Code548Enum;
    detail: string;
  };
export type PaymentsInvoicesUpdateShippingAddressPartialUpdatePostalCodeErrorComponent =
  {
    attr: Attr1DeEnum;
    code: Code548Enum;
    detail: string;
  };
export type PaymentsInvoicesUpdateShippingAddressPartialUpdateCountryErrorComponent =
  {
    attr: Attr476Enum;
    code: Code4EfEnum;
    detail: string;
  };
export type Code9DdEnum =
  | "does_not_exist"
  | "incorrect_type"
  | "null"
  | "required"
  | "unique";
export type PaymentsInvoicesUpdateShippingAddressPartialUpdateInvoiceErrorComponent =
  {
    attr: AttrF4DEnum;
    code: Code9DdEnum;
    detail: string;
  };
export type PaymentsInvoicesUpdateShippingAddressPartialUpdateError =
  | ({
      attr: "non_field_errors";
    } & PaymentsInvoicesUpdateShippingAddressPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "street";
    } & PaymentsInvoicesUpdateShippingAddressPartialUpdateStreetErrorComponent)
  | ({
      attr: "city";
    } & PaymentsInvoicesUpdateShippingAddressPartialUpdateCityErrorComponent)
  | ({
      attr: "state";
    } & PaymentsInvoicesUpdateShippingAddressPartialUpdateStateErrorComponent)
  | ({
      attr: "postal_code";
    } & PaymentsInvoicesUpdateShippingAddressPartialUpdatePostalCodeErrorComponent)
  | ({
      attr: "country";
    } & PaymentsInvoicesUpdateShippingAddressPartialUpdateCountryErrorComponent)
  | ({
      attr: "invoice";
    } & PaymentsInvoicesUpdateShippingAddressPartialUpdateInvoiceErrorComponent);
export type PaymentsInvoicesUpdateShippingAddressPartialUpdateValidationError =
  {
    type: TypeA2CEnum;
    errors: PaymentsInvoicesUpdateShippingAddressPartialUpdateError[];
  };
export type PaymentsInvoicesUpdateShippingAddressPartialUpdateErrorResponse400 =

    | ({
        type: "validation_error";
      } & PaymentsInvoicesUpdateShippingAddressPartialUpdateValidationError)
    | ({
        type: "client_error";
      } & ParseErrorResponse);
export type PatchedAddress = {
  street?: string | null;
  city?: string | null;
  state?: string | null;
  postal_code?: string | null;
  country?: CountryEnum;
  invoice?: string;
};
export type PatchedAddressRead = {
  id?: number;
  country_display?: string;
  street?: string | null;
  city?: string | null;
  state?: string | null;
  postal_code?: string | null;
  country?: CountryEnum;
  invoice?: string;
};
export type PaymentsInvoicesMyInvoicesListCustomerErrorComponent = {
  attr: Attr17CEnum;
  code: Code099Enum;
  detail: string;
};
export type PaymentsInvoicesMyInvoicesListMembershipDiscountErrorComponent = {
  attr: AttrC82Enum;
  code: Code90FEnum;
  detail: string;
};
export type PaymentsInvoicesMyInvoicesListDiscountErrorComponent = {
  attr: Attr513Enum;
  code: Code90FEnum;
  detail: string;
};
export type PaymentsInvoicesMyInvoicesListAppointmentDiscountErrorComponent = {
  attr: Attr7B9Enum;
  code: Code90FEnum;
  detail: string;
};
export type PaymentsInvoicesMyInvoicesListProductDiscountErrorComponent = {
  attr: AttrA8AEnum;
  code: Code90FEnum;
  detail: string;
};
export type PaymentsInvoicesMyInvoicesListError =
  | ({
      attr: "customer";
    } & PaymentsInvoicesMyInvoicesListCustomerErrorComponent)
  | ({
      attr: "membership_discount";
    } & PaymentsInvoicesMyInvoicesListMembershipDiscountErrorComponent)
  | ({
      attr: "discount";
    } & PaymentsInvoicesMyInvoicesListDiscountErrorComponent)
  | ({
      attr: "appointment_discount";
    } & PaymentsInvoicesMyInvoicesListAppointmentDiscountErrorComponent)
  | ({
      attr: "product_discount";
    } & PaymentsInvoicesMyInvoicesListProductDiscountErrorComponent);
export type PaymentsInvoicesMyInvoicesListValidationError = {
  type: TypeA2CEnum;
  errors: PaymentsInvoicesMyInvoicesListError[];
};
export type PaymentsInvoicesMyInvoicesListErrorResponse400 =
  | ({
      type: "validation_error";
    } & PaymentsInvoicesMyInvoicesListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PaginatedProductInvoiceItemList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: ProductInvoiceItem[];
};
export type PaginatedProductInvoiceItemListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: ProductInvoiceItemRead[];
};
export type PaymentsProductInvoiceItemsListInvoiceErrorComponent = {
  attr: AttrF4DEnum;
  code: Code099Enum;
  detail: string;
};
export type PaymentsProductInvoiceItemsListProductOptionProductErrorComponentAttrEnum =
  "product_option__product";
export type PaymentsProductInvoiceItemsListProductOptionProductErrorComponent =
  {
    attr: PaymentsProductInvoiceItemsListProductOptionProductErrorComponentAttrEnum;
    code: Code099Enum;
    detail: string;
  };
export type PaymentsProductInvoiceItemsListError =
  | ({
      attr: "invoice";
    } & PaymentsProductInvoiceItemsListInvoiceErrorComponent)
  | ({
      attr: "product_option__product";
    } & PaymentsProductInvoiceItemsListProductOptionProductErrorComponent);
export type PaymentsProductInvoiceItemsListValidationError = {
  type: TypeA2CEnum;
  errors: PaymentsProductInvoiceItemsListError[];
};
export type PaymentsProductInvoiceItemsListErrorResponse400 =
  | ({
      type: "validation_error";
    } & PaymentsProductInvoiceItemsListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PaymentsProductInvoiceItemsCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type PaymentsProductInvoiceItemsCreateInvoiceErrorComponent = {
  attr: AttrF4DEnum;
  code: Code6C0Enum;
  detail: string;
};
export type PaymentsProductInvoiceItemsCreateQuantityErrorComponentAttrEnum =
  "quantity";
export type PaymentsProductInvoiceItemsCreateQuantityErrorComponent = {
  attr: PaymentsProductInvoiceItemsCreateQuantityErrorComponentAttrEnum;
  code: Code937Enum;
  detail: string;
};
export type PaymentsProductInvoiceItemsCreateProductOptionErrorComponentAttrEnum =
  "product_option";
export type PaymentsProductInvoiceItemsCreateProductOptionErrorComponent = {
  attr: PaymentsProductInvoiceItemsCreateProductOptionErrorComponentAttrEnum;
  code: Code6C0Enum;
  detail: string;
};
export type PaymentsProductInvoiceItemsCreateDiscountErrorComponent = {
  attr: Attr513Enum;
  code: Code948Enum;
  detail: string;
};
export type PaymentsProductInvoiceItemsCreateError =
  | ({
      attr: "non_field_errors";
    } & PaymentsProductInvoiceItemsCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "invoice";
    } & PaymentsProductInvoiceItemsCreateInvoiceErrorComponent)
  | ({
      attr: "quantity";
    } & PaymentsProductInvoiceItemsCreateQuantityErrorComponent)
  | ({
      attr: "product_option";
    } & PaymentsProductInvoiceItemsCreateProductOptionErrorComponent)
  | ({
      attr: "discount";
    } & PaymentsProductInvoiceItemsCreateDiscountErrorComponent);
export type PaymentsProductInvoiceItemsCreateValidationError = {
  type: TypeA2CEnum;
  errors: PaymentsProductInvoiceItemsCreateError[];
};
export type PaymentsProductInvoiceItemsCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & PaymentsProductInvoiceItemsCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PaymentsProductInvoiceItemsRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type PaymentsProductInvoiceItemsDestroyErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type ProductRevenuePerInterval = {
  data: {
    [key: string]: string;
  };
  percentage_change: number | null;
};
export type PaymentsProductRevenuePerIntervalRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type ProductSalesCountPerInterval = {
  data: {
    [key: string]: number;
  };
  percentage_change: number | null;
};
export type PaymentsProductSalesCountPerIntervalRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type ActionEnum = "PAYMENT" | "REFUND";
export type SourceEnum = "membership" | "product" | "credit";
export type Transaction = {
  id?: string;
  amount: string;
  method?: MethodEnum;
  action?: ActionEnum;
  source: SourceEnum;
  invoice: string;
};
export type TransactionRead = {
  id?: string;
  method_display: string;
  action_display: string;
  source_display: string;
  created_at: string;
  updated_at: string;
  date_time: string;
  amount_currency: string;
  amount: string;
  method?: MethodEnum;
  action?: ActionEnum;
  source: SourceEnum;
  invoice: string;
  performed_by: number | null;
};
export type PaginatedTransactionList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Transaction[];
};
export type PaginatedTransactionListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: TransactionRead[];
};
export type AttrC3FEnum = "invoice__customer";
export type PaymentsTransactionsListInvoiceCustomerErrorComponent = {
  attr: AttrC3FEnum;
  code: Code099Enum;
  detail: string;
};
export type PaymentsTransactionsListInvoiceErrorComponent = {
  attr: AttrF4DEnum;
  code: Code099Enum;
  detail: string;
};
export type PaymentsTransactionsListDateTimeErrorComponent = {
  attr: Attr59BEnum;
  code: CodeE7CEnum;
  detail: string;
};
export type Attr25EEnum = "date_time__lt";
export type PaymentsTransactionsListDateTimeLtErrorComponent = {
  attr: Attr25EEnum;
  code: CodeE7CEnum;
  detail: string;
};
export type AttrE45Enum = "date_time__gt";
export type PaymentsTransactionsListDateTimeGtErrorComponent = {
  attr: AttrE45Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type Attr175Enum = "date_time__range";
export type PaymentsTransactionsListDateTimeRangeErrorComponent = {
  attr: Attr175Enum;
  code: Code241Enum;
  detail: string;
};
export type AttrFd8Enum = "date_time__date__range";
export type PaymentsTransactionsListDateTimeDateRangeErrorComponent = {
  attr: AttrFd8Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type PaymentsTransactionsListError =
  | ({
      attr: "invoice__customer";
    } & PaymentsTransactionsListInvoiceCustomerErrorComponent)
  | ({
      attr: "invoice";
    } & PaymentsTransactionsListInvoiceErrorComponent)
  | ({
      attr: "date_time";
    } & PaymentsTransactionsListDateTimeErrorComponent)
  | ({
      attr: "date_time__lt";
    } & PaymentsTransactionsListDateTimeLtErrorComponent)
  | ({
      attr: "date_time__gt";
    } & PaymentsTransactionsListDateTimeGtErrorComponent)
  | ({
      attr: "date_time__range";
    } & PaymentsTransactionsListDateTimeRangeErrorComponent)
  | ({
      attr: "date_time__date__range";
    } & PaymentsTransactionsListDateTimeDateRangeErrorComponent);
export type PaymentsTransactionsListValidationError = {
  type: TypeA2CEnum;
  errors: PaymentsTransactionsListError[];
};
export type PaymentsTransactionsListErrorResponse400 =
  | ({
      type: "validation_error";
    } & PaymentsTransactionsListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PaymentsTransactionsRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type PaymentsTransactionsMyTransactionsListInvoiceCustomerErrorComponent =
  {
    attr: AttrC3FEnum;
    code: Code099Enum;
    detail: string;
  };
export type PaymentsTransactionsMyTransactionsListInvoiceErrorComponent = {
  attr: AttrF4DEnum;
  code: Code099Enum;
  detail: string;
};
export type PaymentsTransactionsMyTransactionsListDateTimeErrorComponent = {
  attr: Attr59BEnum;
  code: CodeE7CEnum;
  detail: string;
};
export type PaymentsTransactionsMyTransactionsListDateTimeLtErrorComponent = {
  attr: Attr25EEnum;
  code: CodeE7CEnum;
  detail: string;
};
export type PaymentsTransactionsMyTransactionsListDateTimeGtErrorComponent = {
  attr: AttrE45Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type PaymentsTransactionsMyTransactionsListDateTimeRangeErrorComponent =
  {
    attr: Attr175Enum;
    code: Code241Enum;
    detail: string;
  };
export type PaymentsTransactionsMyTransactionsListDateTimeDateRangeErrorComponent =
  {
    attr: AttrFd8Enum;
    code: CodeE7CEnum;
    detail: string;
  };
export type PaymentsTransactionsMyTransactionsListError =
  | ({
      attr: "invoice__customer";
    } & PaymentsTransactionsMyTransactionsListInvoiceCustomerErrorComponent)
  | ({
      attr: "invoice";
    } & PaymentsTransactionsMyTransactionsListInvoiceErrorComponent)
  | ({
      attr: "date_time";
    } & PaymentsTransactionsMyTransactionsListDateTimeErrorComponent)
  | ({
      attr: "date_time__lt";
    } & PaymentsTransactionsMyTransactionsListDateTimeLtErrorComponent)
  | ({
      attr: "date_time__gt";
    } & PaymentsTransactionsMyTransactionsListDateTimeGtErrorComponent)
  | ({
      attr: "date_time__range";
    } & PaymentsTransactionsMyTransactionsListDateTimeRangeErrorComponent)
  | ({
      attr: "date_time__date__range";
    } & PaymentsTransactionsMyTransactionsListDateTimeDateRangeErrorComponent);
export type PaymentsTransactionsMyTransactionsListValidationError = {
  type: TypeA2CEnum;
  errors: PaymentsTransactionsMyTransactionsListError[];
};
export type PaymentsTransactionsMyTransactionsListErrorResponse400 =
  | ({
      type: "validation_error";
    } & PaymentsTransactionsMyTransactionsListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type CategoryAmount = {
  category: string;
  total_amount: string;
  count: number;
};
export type MethodAmount = {
  method: string;
  total_amount: string;
  count: number;
};
export type TransactionReport = {
  source_data: CategoryAmount[];
  method_data: MethodAmount[];
  total_refunds: string;
  subtotal: string;
  net_total: string;
  currency: string;
};
export type PaymentsTransactionsReportRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type PolicyDocument = {
  title: string;
  content: string;
  is_active?: boolean;
};
export type PolicyDocumentRead = {
  id: number;
  created_at: string;
  updated_at: string;
  title: string;
  content: string;
  is_active?: boolean;
};
export type PaginatedPolicyDocumentList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: PolicyDocument[];
};
export type PaginatedPolicyDocumentListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: PolicyDocumentRead[];
};
export type PolicyDocumentsListTitleErrorComponent = {
  attr: AttrCc4Enum;
  code: Code0E9Enum;
  detail: string;
};
export type PolicyDocumentsListError = {
  attr: "title";
} & PolicyDocumentsListTitleErrorComponent;
export type PolicyDocumentsListValidationError = {
  type: TypeA2CEnum;
  errors: PolicyDocumentsListError[];
};
export type PolicyDocumentsListErrorResponse400 =
  | ({
      type: "validation_error";
    } & PolicyDocumentsListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PolicyDocumentsCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type PolicyDocumentsCreateTitleErrorComponent = {
  attr: AttrCc4Enum;
  code: Code6AeEnum;
  detail: string;
};
export type PolicyDocumentsCreateContentErrorComponent = {
  attr: Attr0E2Enum;
  code: CodeA3CEnum;
  detail: string;
};
export type PolicyDocumentsCreateIsActiveErrorComponent = {
  attr: AttrDddEnum;
  code: Code023Enum;
  detail: string;
};
export type PolicyDocumentsCreateError =
  | ({
      attr: "non_field_errors";
    } & PolicyDocumentsCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "title";
    } & PolicyDocumentsCreateTitleErrorComponent)
  | ({
      attr: "content";
    } & PolicyDocumentsCreateContentErrorComponent)
  | ({
      attr: "is_active";
    } & PolicyDocumentsCreateIsActiveErrorComponent);
export type PolicyDocumentsCreateValidationError = {
  type: TypeA2CEnum;
  errors: PolicyDocumentsCreateError[];
};
export type PolicyDocumentsCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & PolicyDocumentsCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PolicyDocumentsRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type PolicyDocumentsUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type PolicyDocumentsUpdateTitleErrorComponent = {
  attr: AttrCc4Enum;
  code: Code6AeEnum;
  detail: string;
};
export type PolicyDocumentsUpdateContentErrorComponent = {
  attr: Attr0E2Enum;
  code: CodeA3CEnum;
  detail: string;
};
export type PolicyDocumentsUpdateIsActiveErrorComponent = {
  attr: AttrDddEnum;
  code: Code023Enum;
  detail: string;
};
export type PolicyDocumentsUpdateError =
  | ({
      attr: "non_field_errors";
    } & PolicyDocumentsUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "title";
    } & PolicyDocumentsUpdateTitleErrorComponent)
  | ({
      attr: "content";
    } & PolicyDocumentsUpdateContentErrorComponent)
  | ({
      attr: "is_active";
    } & PolicyDocumentsUpdateIsActiveErrorComponent);
export type PolicyDocumentsUpdateValidationError = {
  type: TypeA2CEnum;
  errors: PolicyDocumentsUpdateError[];
};
export type PolicyDocumentsUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & PolicyDocumentsUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PolicyDocumentsPartialUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type PolicyDocumentsPartialUpdateTitleErrorComponent = {
  attr: AttrCc4Enum;
  code: Code6AeEnum;
  detail: string;
};
export type PolicyDocumentsPartialUpdateContentErrorComponent = {
  attr: Attr0E2Enum;
  code: CodeA3CEnum;
  detail: string;
};
export type PolicyDocumentsPartialUpdateIsActiveErrorComponent = {
  attr: AttrDddEnum;
  code: Code023Enum;
  detail: string;
};
export type PolicyDocumentsPartialUpdateError =
  | ({
      attr: "non_field_errors";
    } & PolicyDocumentsPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "title";
    } & PolicyDocumentsPartialUpdateTitleErrorComponent)
  | ({
      attr: "content";
    } & PolicyDocumentsPartialUpdateContentErrorComponent)
  | ({
      attr: "is_active";
    } & PolicyDocumentsPartialUpdateIsActiveErrorComponent);
export type PolicyDocumentsPartialUpdateValidationError = {
  type: TypeA2CEnum;
  errors: PolicyDocumentsPartialUpdateError[];
};
export type PolicyDocumentsPartialUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & PolicyDocumentsPartialUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PatchedPolicyDocument = {
  title?: string;
  content?: string;
  is_active?: boolean;
};
export type PatchedPolicyDocumentRead = {
  id?: number;
  created_at?: string;
  updated_at?: string;
  title?: string;
  content?: string;
  is_active?: boolean;
};
export type PolicyDocumentsDestroyErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type ProductCategory = {
  name: string;
};
export type ProductCategoryRead = {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
};
export type PaginatedProductCategoryList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: ProductCategory[];
};
export type PaginatedProductCategoryListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: ProductCategoryRead[];
};
export type ProductCategoriesListErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type ProductCategoriesCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type ProductCategoriesCreateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code238Enum;
  detail: string;
};
export type ProductCategoriesCreateError =
  | ({
      attr: "non_field_errors";
    } & ProductCategoriesCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & ProductCategoriesCreateNameErrorComponent);
export type ProductCategoriesCreateValidationError = {
  type: TypeA2CEnum;
  errors: ProductCategoriesCreateError[];
};
export type ProductCategoriesCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & ProductCategoriesCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type ProductCategoriesRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type ProductCategoriesUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type ProductCategoriesUpdateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code238Enum;
  detail: string;
};
export type ProductCategoriesUpdateError =
  | ({
      attr: "non_field_errors";
    } & ProductCategoriesUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & ProductCategoriesUpdateNameErrorComponent);
export type ProductCategoriesUpdateValidationError = {
  type: TypeA2CEnum;
  errors: ProductCategoriesUpdateError[];
};
export type ProductCategoriesUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & ProductCategoriesUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type ProductCategoriesPartialUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type ProductCategoriesPartialUpdateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code238Enum;
  detail: string;
};
export type ProductCategoriesPartialUpdateError =
  | ({
      attr: "non_field_errors";
    } & ProductCategoriesPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & ProductCategoriesPartialUpdateNameErrorComponent);
export type ProductCategoriesPartialUpdateValidationError = {
  type: TypeA2CEnum;
  errors: ProductCategoriesPartialUpdateError[];
};
export type ProductCategoriesPartialUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & ProductCategoriesPartialUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PatchedProductCategory = {
  name?: string;
};
export type PatchedProductCategoryRead = {
  id?: number;
  created_at?: string;
  updated_at?: string;
  name?: string;
};
export type ProductCategoriesDestroyErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type ProductImage = {
  image: string;
  product: number;
};
export type ProductImageRead = {
  id: number;
  created_at: string;
  updated_at: string;
  image: string;
  product: number;
};
export type PaginatedProductImageList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: ProductImage[];
};
export type PaginatedProductImageListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: ProductImageRead[];
};
export type Attr310Enum = "product";
export type ProductImagesListProductErrorComponent = {
  attr: Attr310Enum;
  code: Code099Enum;
  detail: string;
};
export type ProductImagesListError = {
  attr: "product";
} & ProductImagesListProductErrorComponent;
export type ProductImagesListValidationError = {
  type: TypeA2CEnum;
  errors: ProductImagesListError[];
};
export type ProductImagesListErrorResponse400 =
  | ({
      type: "validation_error";
    } & ProductImagesListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type ProductImagesCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type CodeDd8Enum =
  | "empty"
  | "invalid"
  | "invalid_image"
  | "max_length"
  | "no_name"
  | "null"
  | "required";
export type ProductImagesCreateImageErrorComponent = {
  attr: Attr470Enum;
  code: CodeDd8Enum;
  detail: string;
};
export type ProductImagesCreateProductErrorComponent = {
  attr: Attr310Enum;
  code: Code6C0Enum;
  detail: string;
};
export type ProductImagesCreateError =
  | ({
      attr: "non_field_errors";
    } & ProductImagesCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "image";
    } & ProductImagesCreateImageErrorComponent)
  | ({
      attr: "product";
    } & ProductImagesCreateProductErrorComponent);
export type ProductImagesCreateValidationError = {
  type: TypeA2CEnum;
  errors: ProductImagesCreateError[];
};
export type ProductImagesCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & ProductImagesCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type ProductImagesRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type ProductImagesUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type ProductImagesUpdateImageErrorComponent = {
  attr: Attr470Enum;
  code: CodeDd8Enum;
  detail: string;
};
export type ProductImagesUpdateProductErrorComponent = {
  attr: Attr310Enum;
  code: Code6C0Enum;
  detail: string;
};
export type ProductImagesUpdateError =
  | ({
      attr: "non_field_errors";
    } & ProductImagesUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "image";
    } & ProductImagesUpdateImageErrorComponent)
  | ({
      attr: "product";
    } & ProductImagesUpdateProductErrorComponent);
export type ProductImagesUpdateValidationError = {
  type: TypeA2CEnum;
  errors: ProductImagesUpdateError[];
};
export type ProductImagesUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & ProductImagesUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type ProductImagesPartialUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type ProductImagesPartialUpdateImageErrorComponent = {
  attr: Attr470Enum;
  code: CodeDd8Enum;
  detail: string;
};
export type ProductImagesPartialUpdateProductErrorComponent = {
  attr: Attr310Enum;
  code: Code6C0Enum;
  detail: string;
};
export type ProductImagesPartialUpdateError =
  | ({
      attr: "non_field_errors";
    } & ProductImagesPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "image";
    } & ProductImagesPartialUpdateImageErrorComponent)
  | ({
      attr: "product";
    } & ProductImagesPartialUpdateProductErrorComponent);
export type ProductImagesPartialUpdateValidationError = {
  type: TypeA2CEnum;
  errors: ProductImagesPartialUpdateError[];
};
export type ProductImagesPartialUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & ProductImagesPartialUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PatchedProductImage = {
  image?: string;
  product?: number;
};
export type PatchedProductImageRead = {
  id?: number;
  created_at?: string;
  updated_at?: string;
  image?: string;
  product?: number;
};
export type ProductImagesDestroyErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type ProductOption = {
  name: string;
  price: string;
  inventory?: number | null;
  display_order?: number;
  product: number;
};
export type ProductOptionRead = {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  price_currency: string;
  price: string;
  inventory?: number | null;
  display_order?: number;
  product: number;
};
export type PaginatedProductOptionList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: ProductOption[];
};
export type PaginatedProductOptionListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: ProductOptionRead[];
};
export type ProductOptionsListProductErrorComponent = {
  attr: Attr310Enum;
  code: Code099Enum;
  detail: string;
};
export type ProductOptionsListError = {
  attr: "product";
} & ProductOptionsListProductErrorComponent;
export type ProductOptionsListValidationError = {
  type: TypeA2CEnum;
  errors: ProductOptionsListError[];
};
export type ProductOptionsListErrorResponse400 =
  | ({
      type: "validation_error";
    } & ProductOptionsListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type ProductOptionsCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type ProductOptionsCreateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code238Enum;
  detail: string;
};
export type ProductOptionsCreatePriceErrorComponent = {
  attr: AttrA2DEnum;
  code: Code9BdEnum;
  detail: string;
};
export type Attr735Enum = "inventory";
export type ProductOptionsCreateInventoryErrorComponent = {
  attr: Attr735Enum;
  code: CodeB7BEnum;
  detail: string;
};
export type Attr217Enum = "display_order";
export type ProductOptionsCreateDisplayOrderErrorComponent = {
  attr: Attr217Enum;
  code: Code9E1Enum;
  detail: string;
};
export type ProductOptionsCreateProductErrorComponent = {
  attr: Attr310Enum;
  code: Code6C0Enum;
  detail: string;
};
export type ProductOptionsCreateError =
  | ({
      attr: "non_field_errors";
    } & ProductOptionsCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & ProductOptionsCreateNameErrorComponent)
  | ({
      attr: "price";
    } & ProductOptionsCreatePriceErrorComponent)
  | ({
      attr: "inventory";
    } & ProductOptionsCreateInventoryErrorComponent)
  | ({
      attr: "display_order";
    } & ProductOptionsCreateDisplayOrderErrorComponent)
  | ({
      attr: "product";
    } & ProductOptionsCreateProductErrorComponent);
export type ProductOptionsCreateValidationError = {
  type: TypeA2CEnum;
  errors: ProductOptionsCreateError[];
};
export type ProductOptionsCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & ProductOptionsCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type ProductOptionsRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type ProductOptionsUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type ProductOptionsUpdateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code238Enum;
  detail: string;
};
export type ProductOptionsUpdatePriceErrorComponent = {
  attr: AttrA2DEnum;
  code: Code9BdEnum;
  detail: string;
};
export type ProductOptionsUpdateInventoryErrorComponent = {
  attr: Attr735Enum;
  code: CodeB7BEnum;
  detail: string;
};
export type ProductOptionsUpdateDisplayOrderErrorComponent = {
  attr: Attr217Enum;
  code: Code9E1Enum;
  detail: string;
};
export type ProductOptionsUpdateProductErrorComponent = {
  attr: Attr310Enum;
  code: Code6C0Enum;
  detail: string;
};
export type ProductOptionsUpdateError =
  | ({
      attr: "non_field_errors";
    } & ProductOptionsUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & ProductOptionsUpdateNameErrorComponent)
  | ({
      attr: "price";
    } & ProductOptionsUpdatePriceErrorComponent)
  | ({
      attr: "inventory";
    } & ProductOptionsUpdateInventoryErrorComponent)
  | ({
      attr: "display_order";
    } & ProductOptionsUpdateDisplayOrderErrorComponent)
  | ({
      attr: "product";
    } & ProductOptionsUpdateProductErrorComponent);
export type ProductOptionsUpdateValidationError = {
  type: TypeA2CEnum;
  errors: ProductOptionsUpdateError[];
};
export type ProductOptionsUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & ProductOptionsUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type ProductOptionsPartialUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type ProductOptionsPartialUpdateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code238Enum;
  detail: string;
};
export type ProductOptionsPartialUpdatePriceErrorComponent = {
  attr: AttrA2DEnum;
  code: Code9BdEnum;
  detail: string;
};
export type ProductOptionsPartialUpdateInventoryErrorComponent = {
  attr: Attr735Enum;
  code: CodeB7BEnum;
  detail: string;
};
export type ProductOptionsPartialUpdateDisplayOrderErrorComponent = {
  attr: Attr217Enum;
  code: Code9E1Enum;
  detail: string;
};
export type ProductOptionsPartialUpdateProductErrorComponent = {
  attr: Attr310Enum;
  code: Code6C0Enum;
  detail: string;
};
export type ProductOptionsPartialUpdateError =
  | ({
      attr: "non_field_errors";
    } & ProductOptionsPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & ProductOptionsPartialUpdateNameErrorComponent)
  | ({
      attr: "price";
    } & ProductOptionsPartialUpdatePriceErrorComponent)
  | ({
      attr: "inventory";
    } & ProductOptionsPartialUpdateInventoryErrorComponent)
  | ({
      attr: "display_order";
    } & ProductOptionsPartialUpdateDisplayOrderErrorComponent)
  | ({
      attr: "product";
    } & ProductOptionsPartialUpdateProductErrorComponent);
export type ProductOptionsPartialUpdateValidationError = {
  type: TypeA2CEnum;
  errors: ProductOptionsPartialUpdateError[];
};
export type ProductOptionsPartialUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & ProductOptionsPartialUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PatchedProductOption = {
  name?: string;
  price?: string;
  inventory?: number | null;
  display_order?: number;
  product?: number;
};
export type PatchedProductOptionRead = {
  id?: number;
  created_at?: string;
  updated_at?: string;
  name?: string;
  price_currency?: string;
  price?: string;
  inventory?: number | null;
  display_order?: number;
  product?: number;
};
export type ProductOptionsDestroyErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type ProductOptionWithoutProduct = {
  name: string;
  price: string;
  inventory?: number | null;
  display_order?: number;
};
export type ProductOptionWithoutProductRead = {
  id: number;
  product: number;
  created_at: string;
  updated_at: string;
  name: string;
  price_currency: string;
  price: string;
  inventory?: number | null;
  display_order?: number;
};
export type StatusEnum = "active" | "draft";
export type Product = {
  options: ProductOptionWithoutProduct[];
  images?: ProductImage[];
  name: string;
  description?: string;
  image?: string | null;
  track_inventory?: boolean;
  available_for_pickup?: boolean;
  available_for_shipping?: boolean;
  status?: StatusEnum;
  redirect_url?: string | null;
  category?: number | null;
};
export type ProductRead = {
  id: number;
  options: ProductOptionWithoutProductRead[];
  images?: ProductImageRead[];
  total_sales: string;
  total_stock: string;
  created_at: string;
  updated_at: string;
  name: string;
  description?: string;
  image?: string | null;
  track_inventory?: boolean;
  available_for_pickup?: boolean;
  available_for_shipping?: boolean;
  status?: StatusEnum;
  redirect_url?: string | null;
  category?: number | null;
};
export type PaginatedProductList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Product[];
};
export type PaginatedProductListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: ProductRead[];
};
export type ProductsListStatusErrorComponent = {
  attr: AttrA16Enum;
  code: Code099Enum;
  detail: string;
};
export type ProductsListCategoryErrorComponent = {
  attr: Attr330Enum;
  code: Code099Enum;
  detail: string;
};
export type ProductsListIdInErrorComponent = {
  attr: Attr12BEnum;
  code: Code90FEnum;
  detail: string;
};
export type ProductsListError =
  | ({
      attr: "status";
    } & ProductsListStatusErrorComponent)
  | ({
      attr: "category";
    } & ProductsListCategoryErrorComponent)
  | ({
      attr: "id__in";
    } & ProductsListIdInErrorComponent);
export type ProductsListValidationError = {
  type: TypeA2CEnum;
  errors: ProductsListError[];
};
export type ProductsListErrorResponse400 =
  | ({
      type: "validation_error";
    } & ProductsListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type ProductsCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type AttrF95Enum = "options.non_field_errors";
export type ProductsCreateOptionsNonFieldErrorsErrorComponent = {
  attr: AttrF95Enum;
  code: CodeD67Enum;
  detail: string;
};
export type Attr3E0Enum = "options.INDEX.non_field_errors";
export type ProductsCreateOptionsIndexNonFieldErrorsErrorComponent = {
  attr: Attr3E0Enum;
  code: CodeF7EEnum;
  detail: string;
};
export type Attr291Enum = "options.INDEX.name";
export type ProductsCreateOptionsIndexNameErrorComponent = {
  attr: Attr291Enum;
  code: Code238Enum;
  detail: string;
};
export type AttrD64Enum = "options.INDEX.price";
export type ProductsCreateOptionsIndexPriceErrorComponent = {
  attr: AttrD64Enum;
  code: Code9BdEnum;
  detail: string;
};
export type Attr657Enum = "options.INDEX.inventory";
export type ProductsCreateOptionsIndexInventoryErrorComponent = {
  attr: Attr657Enum;
  code: CodeB7BEnum;
  detail: string;
};
export type Attr050Enum = "options.INDEX.display_order";
export type ProductsCreateOptionsIndexDisplayOrderErrorComponent = {
  attr: Attr050Enum;
  code: Code9E1Enum;
  detail: string;
};
export type Attr4F6Enum = "images.non_field_errors";
export type ProductsCreateImagesNonFieldErrorsErrorComponent = {
  attr: Attr4F6Enum;
  code: CodeB06Enum;
  detail: string;
};
export type Attr686Enum = "images.INDEX.non_field_errors";
export type ProductsCreateImagesIndexNonFieldErrorsErrorComponent = {
  attr: Attr686Enum;
  code: Code023Enum;
  detail: string;
};
export type AttrDc3Enum = "images.INDEX.image";
export type ProductsCreateImagesIndexImageErrorComponent = {
  attr: AttrDc3Enum;
  code: CodeDd8Enum;
  detail: string;
};
export type Attr587Enum = "images.INDEX.product";
export type ProductsCreateImagesIndexProductErrorComponent = {
  attr: Attr587Enum;
  code: Code6C0Enum;
  detail: string;
};
export type ProductsCreateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code238Enum;
  detail: string;
};
export type ProductsCreateDescriptionErrorComponent = {
  attr: Attr50BEnum;
  code: Code0E6Enum;
  detail: string;
};
export type ProductsCreateImageErrorComponent = {
  attr: Attr470Enum;
  code: Code40EEnum;
  detail: string;
};
export type Attr84CEnum = "track_inventory";
export type ProductsCreateTrackInventoryErrorComponent = {
  attr: Attr84CEnum;
  code: Code023Enum;
  detail: string;
};
export type AttrEb2Enum = "available_for_pickup";
export type ProductsCreateAvailableForPickupErrorComponent = {
  attr: AttrEb2Enum;
  code: Code023Enum;
  detail: string;
};
export type AttrB8AEnum = "available_for_shipping";
export type ProductsCreateAvailableForShippingErrorComponent = {
  attr: AttrB8AEnum;
  code: Code023Enum;
  detail: string;
};
export type ProductsCreateStatusErrorComponent = {
  attr: AttrA16Enum;
  code: Code3F2Enum;
  detail: string;
};
export type AttrFf4Enum = "redirect_url";
export type ProductsCreateRedirectUrlErrorComponent = {
  attr: AttrFf4Enum;
  code: Code548Enum;
  detail: string;
};
export type ProductsCreateCategoryErrorComponent = {
  attr: Attr330Enum;
  code: Code948Enum;
  detail: string;
};
export type ProductsCreateError =
  | ({
      attr: "non_field_errors";
    } & ProductsCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "options.non_field_errors";
    } & ProductsCreateOptionsNonFieldErrorsErrorComponent)
  | ({
      attr: "options.INDEX.non_field_errors";
    } & ProductsCreateOptionsIndexNonFieldErrorsErrorComponent)
  | ({
      attr: "options.INDEX.name";
    } & ProductsCreateOptionsIndexNameErrorComponent)
  | ({
      attr: "options.INDEX.price";
    } & ProductsCreateOptionsIndexPriceErrorComponent)
  | ({
      attr: "options.INDEX.inventory";
    } & ProductsCreateOptionsIndexInventoryErrorComponent)
  | ({
      attr: "options.INDEX.display_order";
    } & ProductsCreateOptionsIndexDisplayOrderErrorComponent)
  | ({
      attr: "images.non_field_errors";
    } & ProductsCreateImagesNonFieldErrorsErrorComponent)
  | ({
      attr: "images.INDEX.non_field_errors";
    } & ProductsCreateImagesIndexNonFieldErrorsErrorComponent)
  | ({
      attr: "images.INDEX.image";
    } & ProductsCreateImagesIndexImageErrorComponent)
  | ({
      attr: "images.INDEX.product";
    } & ProductsCreateImagesIndexProductErrorComponent)
  | ({
      attr: "name";
    } & ProductsCreateNameErrorComponent)
  | ({
      attr: "description";
    } & ProductsCreateDescriptionErrorComponent)
  | ({
      attr: "image";
    } & ProductsCreateImageErrorComponent)
  | ({
      attr: "track_inventory";
    } & ProductsCreateTrackInventoryErrorComponent)
  | ({
      attr: "available_for_pickup";
    } & ProductsCreateAvailableForPickupErrorComponent)
  | ({
      attr: "available_for_shipping";
    } & ProductsCreateAvailableForShippingErrorComponent)
  | ({
      attr: "status";
    } & ProductsCreateStatusErrorComponent)
  | ({
      attr: "redirect_url";
    } & ProductsCreateRedirectUrlErrorComponent)
  | ({
      attr: "category";
    } & ProductsCreateCategoryErrorComponent);
export type ProductsCreateValidationError = {
  type: TypeA2CEnum;
  errors: ProductsCreateError[];
};
export type ProductsCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & ProductsCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type ProductsRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type ProductsUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type ProductsUpdateOptionsNonFieldErrorsErrorComponent = {
  attr: AttrF95Enum;
  code: CodeD67Enum;
  detail: string;
};
export type ProductsUpdateOptionsIndexNonFieldErrorsErrorComponent = {
  attr: Attr3E0Enum;
  code: CodeF7EEnum;
  detail: string;
};
export type ProductsUpdateOptionsIndexNameErrorComponent = {
  attr: Attr291Enum;
  code: Code238Enum;
  detail: string;
};
export type ProductsUpdateOptionsIndexPriceErrorComponent = {
  attr: AttrD64Enum;
  code: Code9BdEnum;
  detail: string;
};
export type ProductsUpdateOptionsIndexInventoryErrorComponent = {
  attr: Attr657Enum;
  code: CodeB7BEnum;
  detail: string;
};
export type ProductsUpdateOptionsIndexDisplayOrderErrorComponent = {
  attr: Attr050Enum;
  code: Code9E1Enum;
  detail: string;
};
export type ProductsUpdateImagesNonFieldErrorsErrorComponent = {
  attr: Attr4F6Enum;
  code: CodeB06Enum;
  detail: string;
};
export type ProductsUpdateImagesIndexNonFieldErrorsErrorComponent = {
  attr: Attr686Enum;
  code: Code023Enum;
  detail: string;
};
export type ProductsUpdateImagesIndexImageErrorComponent = {
  attr: AttrDc3Enum;
  code: CodeDd8Enum;
  detail: string;
};
export type ProductsUpdateImagesIndexProductErrorComponent = {
  attr: Attr587Enum;
  code: Code6C0Enum;
  detail: string;
};
export type ProductsUpdateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code238Enum;
  detail: string;
};
export type ProductsUpdateDescriptionErrorComponent = {
  attr: Attr50BEnum;
  code: Code0E6Enum;
  detail: string;
};
export type ProductsUpdateImageErrorComponent = {
  attr: Attr470Enum;
  code: Code40EEnum;
  detail: string;
};
export type ProductsUpdateTrackInventoryErrorComponent = {
  attr: Attr84CEnum;
  code: Code023Enum;
  detail: string;
};
export type ProductsUpdateAvailableForPickupErrorComponent = {
  attr: AttrEb2Enum;
  code: Code023Enum;
  detail: string;
};
export type ProductsUpdateAvailableForShippingErrorComponent = {
  attr: AttrB8AEnum;
  code: Code023Enum;
  detail: string;
};
export type ProductsUpdateStatusErrorComponent = {
  attr: AttrA16Enum;
  code: Code3F2Enum;
  detail: string;
};
export type ProductsUpdateRedirectUrlErrorComponent = {
  attr: AttrFf4Enum;
  code: Code548Enum;
  detail: string;
};
export type ProductsUpdateCategoryErrorComponent = {
  attr: Attr330Enum;
  code: Code948Enum;
  detail: string;
};
export type ProductsUpdateError =
  | ({
      attr: "non_field_errors";
    } & ProductsUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "options.non_field_errors";
    } & ProductsUpdateOptionsNonFieldErrorsErrorComponent)
  | ({
      attr: "options.INDEX.non_field_errors";
    } & ProductsUpdateOptionsIndexNonFieldErrorsErrorComponent)
  | ({
      attr: "options.INDEX.name";
    } & ProductsUpdateOptionsIndexNameErrorComponent)
  | ({
      attr: "options.INDEX.price";
    } & ProductsUpdateOptionsIndexPriceErrorComponent)
  | ({
      attr: "options.INDEX.inventory";
    } & ProductsUpdateOptionsIndexInventoryErrorComponent)
  | ({
      attr: "options.INDEX.display_order";
    } & ProductsUpdateOptionsIndexDisplayOrderErrorComponent)
  | ({
      attr: "images.non_field_errors";
    } & ProductsUpdateImagesNonFieldErrorsErrorComponent)
  | ({
      attr: "images.INDEX.non_field_errors";
    } & ProductsUpdateImagesIndexNonFieldErrorsErrorComponent)
  | ({
      attr: "images.INDEX.image";
    } & ProductsUpdateImagesIndexImageErrorComponent)
  | ({
      attr: "images.INDEX.product";
    } & ProductsUpdateImagesIndexProductErrorComponent)
  | ({
      attr: "name";
    } & ProductsUpdateNameErrorComponent)
  | ({
      attr: "description";
    } & ProductsUpdateDescriptionErrorComponent)
  | ({
      attr: "image";
    } & ProductsUpdateImageErrorComponent)
  | ({
      attr: "track_inventory";
    } & ProductsUpdateTrackInventoryErrorComponent)
  | ({
      attr: "available_for_pickup";
    } & ProductsUpdateAvailableForPickupErrorComponent)
  | ({
      attr: "available_for_shipping";
    } & ProductsUpdateAvailableForShippingErrorComponent)
  | ({
      attr: "status";
    } & ProductsUpdateStatusErrorComponent)
  | ({
      attr: "redirect_url";
    } & ProductsUpdateRedirectUrlErrorComponent)
  | ({
      attr: "category";
    } & ProductsUpdateCategoryErrorComponent);
export type ProductsUpdateValidationError = {
  type: TypeA2CEnum;
  errors: ProductsUpdateError[];
};
export type ProductsUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & ProductsUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type ProductsPartialUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type ProductsPartialUpdateOptionsNonFieldErrorsErrorComponent = {
  attr: AttrF95Enum;
  code: CodeD67Enum;
  detail: string;
};
export type ProductsPartialUpdateOptionsIndexNonFieldErrorsErrorComponent = {
  attr: Attr3E0Enum;
  code: CodeF7EEnum;
  detail: string;
};
export type ProductsPartialUpdateOptionsIndexNameErrorComponent = {
  attr: Attr291Enum;
  code: Code238Enum;
  detail: string;
};
export type ProductsPartialUpdateOptionsIndexPriceErrorComponent = {
  attr: AttrD64Enum;
  code: Code9BdEnum;
  detail: string;
};
export type ProductsPartialUpdateOptionsIndexInventoryErrorComponent = {
  attr: Attr657Enum;
  code: CodeB7BEnum;
  detail: string;
};
export type ProductsPartialUpdateOptionsIndexDisplayOrderErrorComponent = {
  attr: Attr050Enum;
  code: Code9E1Enum;
  detail: string;
};
export type ProductsPartialUpdateImagesNonFieldErrorsErrorComponent = {
  attr: Attr4F6Enum;
  code: CodeB06Enum;
  detail: string;
};
export type ProductsPartialUpdateImagesIndexNonFieldErrorsErrorComponent = {
  attr: Attr686Enum;
  code: Code023Enum;
  detail: string;
};
export type ProductsPartialUpdateImagesIndexImageErrorComponent = {
  attr: AttrDc3Enum;
  code: CodeDd8Enum;
  detail: string;
};
export type ProductsPartialUpdateImagesIndexProductErrorComponent = {
  attr: Attr587Enum;
  code: Code6C0Enum;
  detail: string;
};
export type ProductsPartialUpdateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code238Enum;
  detail: string;
};
export type ProductsPartialUpdateDescriptionErrorComponent = {
  attr: Attr50BEnum;
  code: Code0E6Enum;
  detail: string;
};
export type ProductsPartialUpdateImageErrorComponent = {
  attr: Attr470Enum;
  code: Code40EEnum;
  detail: string;
};
export type ProductsPartialUpdateTrackInventoryErrorComponent = {
  attr: Attr84CEnum;
  code: Code023Enum;
  detail: string;
};
export type ProductsPartialUpdateAvailableForPickupErrorComponent = {
  attr: AttrEb2Enum;
  code: Code023Enum;
  detail: string;
};
export type ProductsPartialUpdateAvailableForShippingErrorComponent = {
  attr: AttrB8AEnum;
  code: Code023Enum;
  detail: string;
};
export type ProductsPartialUpdateStatusErrorComponent = {
  attr: AttrA16Enum;
  code: Code3F2Enum;
  detail: string;
};
export type ProductsPartialUpdateRedirectUrlErrorComponent = {
  attr: AttrFf4Enum;
  code: Code548Enum;
  detail: string;
};
export type ProductsPartialUpdateCategoryErrorComponent = {
  attr: Attr330Enum;
  code: Code948Enum;
  detail: string;
};
export type ProductsPartialUpdateError =
  | ({
      attr: "non_field_errors";
    } & ProductsPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "options.non_field_errors";
    } & ProductsPartialUpdateOptionsNonFieldErrorsErrorComponent)
  | ({
      attr: "options.INDEX.non_field_errors";
    } & ProductsPartialUpdateOptionsIndexNonFieldErrorsErrorComponent)
  | ({
      attr: "options.INDEX.name";
    } & ProductsPartialUpdateOptionsIndexNameErrorComponent)
  | ({
      attr: "options.INDEX.price";
    } & ProductsPartialUpdateOptionsIndexPriceErrorComponent)
  | ({
      attr: "options.INDEX.inventory";
    } & ProductsPartialUpdateOptionsIndexInventoryErrorComponent)
  | ({
      attr: "options.INDEX.display_order";
    } & ProductsPartialUpdateOptionsIndexDisplayOrderErrorComponent)
  | ({
      attr: "images.non_field_errors";
    } & ProductsPartialUpdateImagesNonFieldErrorsErrorComponent)
  | ({
      attr: "images.INDEX.non_field_errors";
    } & ProductsPartialUpdateImagesIndexNonFieldErrorsErrorComponent)
  | ({
      attr: "images.INDEX.image";
    } & ProductsPartialUpdateImagesIndexImageErrorComponent)
  | ({
      attr: "images.INDEX.product";
    } & ProductsPartialUpdateImagesIndexProductErrorComponent)
  | ({
      attr: "name";
    } & ProductsPartialUpdateNameErrorComponent)
  | ({
      attr: "description";
    } & ProductsPartialUpdateDescriptionErrorComponent)
  | ({
      attr: "image";
    } & ProductsPartialUpdateImageErrorComponent)
  | ({
      attr: "track_inventory";
    } & ProductsPartialUpdateTrackInventoryErrorComponent)
  | ({
      attr: "available_for_pickup";
    } & ProductsPartialUpdateAvailableForPickupErrorComponent)
  | ({
      attr: "available_for_shipping";
    } & ProductsPartialUpdateAvailableForShippingErrorComponent)
  | ({
      attr: "status";
    } & ProductsPartialUpdateStatusErrorComponent)
  | ({
      attr: "redirect_url";
    } & ProductsPartialUpdateRedirectUrlErrorComponent)
  | ({
      attr: "category";
    } & ProductsPartialUpdateCategoryErrorComponent);
export type ProductsPartialUpdateValidationError = {
  type: TypeA2CEnum;
  errors: ProductsPartialUpdateError[];
};
export type ProductsPartialUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & ProductsPartialUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PatchedProduct = {
  options?: ProductOptionWithoutProduct[];
  images?: ProductImage[];
  name?: string;
  description?: string;
  image?: string | null;
  track_inventory?: boolean;
  available_for_pickup?: boolean;
  available_for_shipping?: boolean;
  status?: StatusEnum;
  redirect_url?: string | null;
  category?: number | null;
};
export type PatchedProductRead = {
  id?: number;
  options?: ProductOptionWithoutProductRead[];
  images?: ProductImageRead[];
  total_sales?: string;
  total_stock?: string;
  created_at?: string;
  updated_at?: string;
  name?: string;
  description?: string;
  image?: string | null;
  track_inventory?: boolean;
  available_for_pickup?: boolean;
  available_for_shipping?: boolean;
  status?: StatusEnum;
  redirect_url?: string | null;
  category?: number | null;
};
export type ProductsDestroyErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type ProductsHistoryListErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type Reservation = {
  start_date_time: string;
  user: number;
};
export type ReservationRead = {
  id: number;
  is_expired: string;
  status: string;
  status_display: string;
  created_at: string;
  updated_at: string;
  reserved_at: string;
  start_date_time: string;
  polymorphic_ctype: number | null;
  user: number;
};
export type ReservationTyped = {
  resourcetype: string;
} & Reservation;
export type ReservationTypedRead = {
  resourcetype: string;
} & ReservationRead;
export type ClassReservationTyped = {
  resourcetype: string;
} & ClassReservation;
export type ClassReservationTypedRead = {
  resourcetype: string;
} & ClassReservationRead;
export type AppointmentReservationTyped = {
  resourcetype: string;
} & AppointmentReservation;
export type AppointmentReservationTypedRead = {
  resourcetype: string;
} & AppointmentReservationRead;
export type EventReservationTyped = {
  resourcetype: string;
} & EventReservation;
export type EventReservationTypedRead = {
  resourcetype: string;
} & EventReservationRead;
export type ReservationPolymorphic =
  | ({
      resourcetype: "Reservation";
    } & ReservationTyped)
  | ({
      resourcetype: "ClassReservation";
    } & ClassReservationTyped)
  | ({
      resourcetype: "AppointmentReservation";
    } & AppointmentReservationTyped)
  | ({
      resourcetype: "EventReservation";
    } & EventReservationTyped);
export type ReservationPolymorphicRead =
  | ({
      resourcetype: "Reservation";
    } & ReservationTypedRead)
  | ({
      resourcetype: "ClassReservation";
    } & ClassReservationTypedRead)
  | ({
      resourcetype: "AppointmentReservation";
    } & AppointmentReservationTypedRead)
  | ({
      resourcetype: "EventReservation";
    } & EventReservationTypedRead);
export type PaginatedReservationPolymorphicList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: ReservationPolymorphic[];
};
export type PaginatedReservationPolymorphicListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: ReservationPolymorphicRead[];
};
export type ReserveEventCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type ReserveEventCreateEventInstanceErrorComponentAttrEnum =
  "event_instance";
export type ReserveEventCreateEventInstanceErrorComponent = {
  attr: ReserveEventCreateEventInstanceErrorComponentAttrEnum;
  code: Code6C0Enum;
  detail: string;
};
export type ReserveEventCreateError =
  | ({
      attr: "non_field_errors";
    } & ReserveEventCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "event_instance";
    } & ReserveEventCreateEventInstanceErrorComponent);
export type ReserveEventCreateValidationError = {
  type: TypeA2CEnum;
  errors: ReserveEventCreateError[];
};
export type ReserveEventCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & ReserveEventCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type ReserveEvent = {
  event_instance: number;
};
export type SchemaRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type SettingsRegisterPushTokenCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type SettingsRegisterPushTokenCreateTokenErrorComponent = {
  attr: Attr961Enum;
  code: CodeA3CEnum;
  detail: string;
};
export type SettingsRegisterPushTokenCreateError =
  | ({
      attr: "non_field_errors";
    } & SettingsRegisterPushTokenCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "token";
    } & SettingsRegisterPushTokenCreateTokenErrorComponent);
export type SettingsRegisterPushTokenCreateValidationError = {
  type: TypeA2CEnum;
  errors: SettingsRegisterPushTokenCreateError[];
};
export type SettingsRegisterPushTokenCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & SettingsRegisterPushTokenCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type RegisterPushTokenParameters = {
  token: string;
};
export type StripePaymentsCheckoutSuccessCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type Attr509Enum = "session_id";
export type StripePaymentsCheckoutSuccessCreateSessionIdErrorComponent = {
  attr: Attr509Enum;
  code: CodeA3CEnum;
  detail: string;
};
export type StripePaymentsCheckoutSuccessCreateError =
  | ({
      attr: "non_field_errors";
    } & StripePaymentsCheckoutSuccessCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "session_id";
    } & StripePaymentsCheckoutSuccessCreateSessionIdErrorComponent);
export type StripePaymentsCheckoutSuccessCreateValidationError = {
  type: TypeA2CEnum;
  errors: StripePaymentsCheckoutSuccessCreateError[];
};
export type StripePaymentsCheckoutSuccessCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & StripePaymentsCheckoutSuccessCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type CheckoutSuccessCreate = {
  /** The ID of the plan for which to create a PaymentIntent. */
  session_id: string;
};
export type CreateCustomerPortalSession = {
  url: string;
};
export type StripePaymentsCreateCustomerPortalCreateErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type CreateFinegymCheckoutSessionResponse = {
  session_url: string;
};
export type StripePaymentsCreateFinegymCheckoutSessionCreateNonFieldErrorsErrorComponent =
  {
    attr: AttrD81Enum;
    code: Code023Enum;
    detail: string;
  };
export type StripePaymentsCreateFinegymCheckoutSessionCreatePriceIdErrorComponentAttrEnum =
  "price_id";
export type StripePaymentsCreateFinegymCheckoutSessionCreatePriceIdErrorComponent =
  {
    attr: StripePaymentsCreateFinegymCheckoutSessionCreatePriceIdErrorComponentAttrEnum;
    code: CodeA3CEnum;
    detail: string;
  };
export type StripePaymentsCreateFinegymCheckoutSessionCreateError =
  | ({
      attr: "non_field_errors";
    } & StripePaymentsCreateFinegymCheckoutSessionCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "price_id";
    } & StripePaymentsCreateFinegymCheckoutSessionCreatePriceIdErrorComponent);
export type StripePaymentsCreateFinegymCheckoutSessionCreateValidationError = {
  type: TypeA2CEnum;
  errors: StripePaymentsCreateFinegymCheckoutSessionCreateError[];
};
export type StripePaymentsCreateFinegymCheckoutSessionCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & StripePaymentsCreateFinegymCheckoutSessionCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type CreateFinegymCheckoutSession = {
  price_id: string;
};
export type CreateInvoiceCheckoutSessionResponse = {
  session_url: string;
};
export type StripePaymentsCreateInvoiceCheckoutSessionCreateNonFieldErrorsErrorComponent =
  {
    attr: AttrD81Enum;
    code: Code023Enum;
    detail: string;
  };
export type StripePaymentsCreateInvoiceCheckoutSessionCreateInvoiceErrorComponent =
  {
    attr: AttrF4DEnum;
    code: Code6C0Enum;
    detail: string;
  };
export type StripePaymentsCreateInvoiceCheckoutSessionCreateError =
  | ({
      attr: "non_field_errors";
    } & StripePaymentsCreateInvoiceCheckoutSessionCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "invoice";
    } & StripePaymentsCreateInvoiceCheckoutSessionCreateInvoiceErrorComponent);
export type StripePaymentsCreateInvoiceCheckoutSessionCreateValidationError = {
  type: TypeA2CEnum;
  errors: StripePaymentsCreateInvoiceCheckoutSessionCreateError[];
};
export type StripePaymentsCreateInvoiceCheckoutSessionCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & StripePaymentsCreateInvoiceCheckoutSessionCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type CreateInvoiceCheckoutSession = {
  invoice: string;
};
export type CreateInvoicePaymentIntentResponse = {
  payment_intent_client_secret: string;
  ephemeral_key_secret: string;
};
export type StripePaymentsCreateInvoicePaymentIntentCreateNonFieldErrorsErrorComponent =
  {
    attr: AttrD81Enum;
    code: Code023Enum;
    detail: string;
  };
export type StripePaymentsCreateInvoicePaymentIntentCreateInvoiceErrorComponent =
  {
    attr: AttrF4DEnum;
    code: Code6C0Enum;
    detail: string;
  };
export type StripePaymentsCreateInvoicePaymentIntentCreateError =
  | ({
      attr: "non_field_errors";
    } & StripePaymentsCreateInvoicePaymentIntentCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "invoice";
    } & StripePaymentsCreateInvoicePaymentIntentCreateInvoiceErrorComponent);
export type StripePaymentsCreateInvoicePaymentIntentCreateValidationError = {
  type: TypeA2CEnum;
  errors: StripePaymentsCreateInvoicePaymentIntentCreateError[];
};
export type StripePaymentsCreateInvoicePaymentIntentCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & StripePaymentsCreateInvoicePaymentIntentCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type CreateInvoicePaymentIntent = {
  invoice: string;
};
export type CreateStripeAccount = {
  url: string;
};
export type StripePaymentsCreateStripeAccountCreateNonFieldErrorsErrorComponent =
  {
    attr: AttrD81Enum;
    code: Code023Enum;
    detail: string;
  };
export type StripePaymentsCreateStripeAccountCreateTypeErrorComponent = {
  attr: AttrE62Enum;
  code: Code4EfEnum;
  detail: string;
};
export type StripePaymentsCreateStripeAccountCreateError =
  | ({
      attr: "non_field_errors";
    } & StripePaymentsCreateStripeAccountCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "type";
    } & StripePaymentsCreateStripeAccountCreateTypeErrorComponent);
export type StripePaymentsCreateStripeAccountCreateValidationError = {
  type: TypeA2CEnum;
  errors: StripePaymentsCreateStripeAccountCreateError[];
};
export type StripePaymentsCreateStripeAccountCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & StripePaymentsCreateStripeAccountCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type CreateStripeAccountTypeTypeEnum =
  | "account_onboarding"
  | "account_update";
export type CreateStripeAccountType = {
  type: CreateStripeAccountTypeTypeEnum;
};
export type BusinessProfile = {
  annual_revenue: string | null;
  estimated_worker_count: number | null;
  mcc: string | null;
  name: string | null;
  product_description: string | null;
  support_address: any | null;
  support_email: string | null;
  support_phone: string | null;
  support_url: string | null;
  url: string | null;
};
export type ExternalAccounts = {
  object: string;
  data: any[];
  has_more: boolean;
  total_count: number;
  url: string;
};
export type Requirements = {
  alternatives: any[];
  currently_due: string[];
  disabled_reason: string | null;
  errors: any[];
  eventually_due: string[];
  past_due: string[];
  pending_verification: any[];
};
export type Settings = {
  bacs_debit_payments: any;
  branding: any;
  card_issuing: any;
  card_payments: any;
  dashboard: any;
  invoices: any;
  payments: any;
  payouts: any;
  sepa_debit_payments: any;
};
export type TosAcceptance = {
  ip: string | null;
  user_agent: string | null;
};
export type StripeAccount = {
  id: string;
  object: string;
  business_profile: BusinessProfile;
  business_type: string | null;
  capabilities: any;
  charges_enabled: boolean;
  country: string;
  created: number;
  default_currency: string;
  details_submitted: boolean;
  email: string;
  external_accounts: ExternalAccounts;
  future_requirements: Requirements;
  metadata: any;
  payouts_enabled: boolean;
  requirements: Requirements;
  settings: Settings;
  tos_acceptance: TosAcceptance;
  type: string;
};
export type StripePaymentsCurrentAccountRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type StripeCustomer = {
  id: string;
  object: string;
  address: any | null;
  description: string | null;
  email: string | null;
  metadata: {
    [key: string]: string;
  };
  name: string | null;
  phone: string | null;
  shipping: any | null;
  balance: number;
  created: number;
  currency: string | null;
  default_source: string | null;
  delinquent: boolean | null;
  discount: any | null;
  invoice_prefix: string | null;
  invoice_settings: {
    [key: string]: any;
  };
  livemode: boolean;
  next_invoice_sequence: number | null;
  preferred_locales: string[] | null;
  tax_exempt: string | null;
  test_clock: string | null;
};
export type StripePaymentsCustomerRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type StripeInvoice = {
  id: string;
  auto_advance: boolean;
  charge: string | null;
  collection_method: string;
  currency: string;
  customer: string;
  description: string | null;
  hosted_invoice_url: string | null;
  lines: any;
  metadata: {
    [key: string]: string;
  } | null;
  payment_intent: string | null;
  period_end: number;
  period_start: number;
  status: string | null;
  subscription: string | null;
  total: number;
  amount_due: number;
  amount_paid: number;
  amount_remaining: number;
  created: number;
  customer_email: string | null;
  customer_name: string | null;
  invoice_pdf: string | null;
  livemode: boolean;
  paid: boolean;
  receipt_number: string | null;
  subtotal: number;
  tax: number | null;
  total_tax_amounts: any[] | null;
};
export type StripePaymentsInvoicesListErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type StripePaymentsInvoicesRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type StripeUpcomingInvoice = {
  charge: string | null;
  collection_method: string;
  currency: string;
  customer: string;
  description: string | null;
  hosted_invoice_url: string | null;
  lines: any;
  metadata: {
    [key: string]: string;
  } | null;
  payment_intent: string | null;
  period_end: number;
  period_start: number;
  status: string | null;
  subscription: string | null;
  total: number;
  amount_due: number;
  amount_paid: number;
  amount_remaining: number;
  created: number;
  customer_email: string | null;
  customer_name: string | null;
  invoice_pdf: string | null;
  livemode: boolean;
  paid: boolean;
  receipt_number: string | null;
  subtotal: number;
  tax: number | null;
  total_tax_amounts: any[] | null;
};
export type StripePaymentsInvoicesUpcomingRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type StripePlan = {
  id: string;
  object: string;
  active: boolean;
  aggregate_usage: string | null;
  amount: number;
  amount_decimal: string;
  billing_scheme: string;
  created: number;
  currency: string;
  interval: string;
  interval_count: number;
  livemode: boolean;
  metadata: {
    [key: string]: string;
  };
  nickname: string | null;
  product: string;
  tiers: any[] | null;
  tiers_mode: string | null;
  transform_usage: string | null;
  trial_period_days: number | null;
  usage_type: string;
};
export type StripePaymentsPlansListErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type StripePaymentsPlansRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type StripePrice = {
  id: string;
  object: string;
  active: boolean;
  billing_scheme: string;
  created: number;
  currency: string;
  metadata: {
    [key: string]: string;
  };
  product: string;
  unit_amount: number;
  unit_amount_decimal: string;
  recurring: any;
  tax_behavior: string;
  tiers: any[] | null;
  tiers_mode: string | null;
  transform_quantity: string | null;
  type: string;
};
export type StripePaymentsPricesListErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type StripePaymentsPricesRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type StripeProduct = {
  id: string;
  object: string;
  active: boolean;
  created: number;
  description: string | null;
  images: string[];
  name: string;
  default_price: string | null;
  livemode: boolean;
  metadata: {
    [key: string]: string;
  };
  package_dimensions: string | null;
  shippable: boolean | null;
  statement_descriptor: string | null;
  tax_code: string | null;
  unit_label: string | null;
  updated: number;
  url: string | null;
};
export type StripePaymentsProductsListErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type StripePaymentsProductsRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type Session = {
  id: string;
  object: string;
  client_secret: string;
  livemode: boolean;
  metadata: {
    [key: string]: any;
  };
};
export type StripePaymentsSessionRetrieveCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type StripePaymentsSessionRetrieveCreateSessionIdErrorComponent = {
  attr: Attr509Enum;
  code: CodeA3CEnum;
  detail: string;
};
export type StripePaymentsSessionRetrieveCreateError =
  | ({
      attr: "non_field_errors";
    } & StripePaymentsSessionRetrieveCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "session_id";
    } & StripePaymentsSessionRetrieveCreateSessionIdErrorComponent);
export type StripePaymentsSessionRetrieveCreateValidationError = {
  type: TypeA2CEnum;
  errors: StripePaymentsSessionRetrieveCreateError[];
};
export type StripePaymentsSessionRetrieveCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & StripePaymentsSessionRetrieveCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type SessionRetrieve = {
  /** The ID of the plan for which to create a PaymentIntent. */
  session_id: string;
};
export type StripeSubscriptionItem = {
  id: string;
  object: string;
  billing_thresholds: string | null;
  created: number;
  metadata: {
    [key: string]: string;
  };
  plan: any;
  price: any;
  quantity: number;
  subscription: string;
  tax_rates: string[];
};
export type StripeSubscription = {
  id: string;
  object: string;
  application: string | null;
  application_fee_percent: number | null;
  automatic_tax: any;
  billing_cycle_anchor: number;
  billing_thresholds: any | null;
  cancel_at: number | null;
  cancel_at_period_end: boolean;
  canceled_at: number | null;
  cancellation_details: any | null;
  collection_method: string;
  created: number;
  currency: string;
  current_period_end: number;
  current_period_start: number;
  customer: string;
  days_until_due: number | null;
  default_payment_method: string | null;
  default_source: string | null;
  default_tax_rates: any[] | null;
  description: string | null;
  discount: any | null;
  discounts: string[];
  ended_at: number | null;
  invoice_settings: any;
  items: StripeSubscriptionItem[];
  latest_invoice: string | null;
  livemode: boolean;
  metadata: {
    [key: string]: string;
  };
  next_pending_invoice_item_invoice: number | null;
  on_behalf_of: string | null;
  pause_collection: any | null;
  payment_settings: any | null;
  pending_invoice_item_interval: any | null;
  pending_setup_intent: string | null;
  pending_update: any | null;
  schedule: string | null;
  start_date: number;
  status: string;
  test_clock: string | null;
  transfer_data: any | null;
  trial_end: number | null;
  trial_settings: any | null;
  trial_start: number | null;
};
export type StripePaymentsSubscriptionRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type TaxRate = {
  name: string;
  fee_type?: FeeTypeEnum;
  rate: string;
  applicable_to_products?: boolean;
  applicable_to_memberships?: boolean;
  applicable_to_appointments?: boolean;
  is_archived?: boolean;
};
export type TaxRateRead = {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  fee_type?: FeeTypeEnum;
  rate: string;
  applicable_to_products?: boolean;
  applicable_to_memberships?: boolean;
  applicable_to_appointments?: boolean;
  is_archived?: boolean;
};
export type PaginatedTaxRateList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: TaxRate[];
};
export type PaginatedTaxRateListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: TaxRateRead[];
};
export type PatchedTaxRate = {
  name?: string;
  fee_type?: FeeTypeEnum;
  rate?: string;
  applicable_to_products?: boolean;
  applicable_to_memberships?: boolean;
  applicable_to_appointments?: boolean;
  is_archived?: boolean;
};
export type PatchedTaxRateRead = {
  id?: number;
  created_at?: string;
  updated_at?: string;
  name?: string;
  fee_type?: FeeTypeEnum;
  rate?: string;
  applicable_to_products?: boolean;
  applicable_to_memberships?: boolean;
  applicable_to_appointments?: boolean;
  is_archived?: boolean;
};
export type TimeZone = {
  code: string;
  name: string;
};
export type TimezonesRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type PaginatedUserDocumentSignatureList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: UserDocumentSignature[];
};
export type PaginatedUserDocumentSignatureListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: UserDocumentSignatureRead[];
};
export type UserDocumentSignaturesListUserErrorComponent = {
  attr: Attr886Enum;
  code: Code099Enum;
  detail: string;
};
export type UserDocumentSignaturesListDocumentVersionDocumentErrorComponentAttrEnum =
  "document_version__document";
export type UserDocumentSignaturesListDocumentVersionDocumentErrorComponent = {
  attr: UserDocumentSignaturesListDocumentVersionDocumentErrorComponentAttrEnum;
  code: Code099Enum;
  detail: string;
};
export type UserDocumentSignaturesListError =
  | ({
      attr: "user";
    } & UserDocumentSignaturesListUserErrorComponent)
  | ({
      attr: "document_version__document";
    } & UserDocumentSignaturesListDocumentVersionDocumentErrorComponent);
export type UserDocumentSignaturesListValidationError = {
  type: TypeA2CEnum;
  errors: UserDocumentSignaturesListError[];
};
export type UserDocumentSignaturesListErrorResponse400 =
  | ({
      type: "validation_error";
    } & UserDocumentSignaturesListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type UserDocumentSignaturesRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type UserDocumentSignaturesGenerateSignedDocumentRetrieveErrorResponse400 =
  {
    type: "client_error";
  } & ParseErrorResponse;
export type UserDocumentSignaturesAdminSignCreateNonFieldErrorsErrorComponent =
  {
    attr: AttrD81Enum;
    code: Code023Enum;
    detail: string;
  };
export type UserDocumentSignaturesAdminSignCreateDocumentErrorComponent = {
  attr: AttrC4DEnum;
  code: Code6C0Enum;
  detail: string;
};
export type UserDocumentSignaturesAdminSignCreateSignatureImageErrorComponent =
  {
    attr: Attr5A9Enum;
    code: Code80AEnum;
    detail: string;
  };
export type UserDocumentSignaturesAdminSignCreateUserErrorComponent = {
  attr: Attr886Enum;
  code: Code6C0Enum;
  detail: string;
};
export type UserDocumentSignaturesAdminSignCreateError =
  | ({
      attr: "non_field_errors";
    } & UserDocumentSignaturesAdminSignCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "document";
    } & UserDocumentSignaturesAdminSignCreateDocumentErrorComponent)
  | ({
      attr: "signature_image";
    } & UserDocumentSignaturesAdminSignCreateSignatureImageErrorComponent)
  | ({
      attr: "user";
    } & UserDocumentSignaturesAdminSignCreateUserErrorComponent);
export type UserDocumentSignaturesAdminSignCreateValidationError = {
  type: TypeA2CEnum;
  errors: UserDocumentSignaturesAdminSignCreateError[];
};
export type UserDocumentSignaturesAdminSignCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & UserDocumentSignaturesAdminSignCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type AdminSignDocument = {
  document: number;
  signature_image: string;
  user: number;
};
export type PaginatedUserMembershipAttendanceList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: UserMembershipAttendance[];
};
export type PaginatedUserMembershipAttendanceListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: UserMembershipAttendanceRead[];
};
export type UserMembershipAttendancesListUserErrorComponent = {
  attr: Attr886Enum;
  code: Code099Enum;
  detail: string;
};
export type UserMembershipAttendancesListUserMembershipErrorComponent = {
  attr: Attr7DeEnum;
  code: Code099Enum;
  detail: string;
};
export type UserMembershipAttendancesListCheckInErrorComponent = {
  attr: Attr6D2Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type UserMembershipAttendancesListCheckInRangeErrorComponent = {
  attr: Attr7AdEnum;
  code: Code241Enum;
  detail: string;
};
export type UserMembershipAttendancesListCheckOutErrorComponent = {
  attr: Attr6E1Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type UserMembershipAttendancesListCheckOutRangeErrorComponent = {
  attr: AttrC6EEnum;
  code: Code241Enum;
  detail: string;
};
export type UserMembershipAttendancesListCheckInDateErrorComponent = {
  attr: Attr7DfEnum;
  code: CodeE7CEnum;
  detail: string;
};
export type UserMembershipAttendancesListError =
  | ({
      attr: "user";
    } & UserMembershipAttendancesListUserErrorComponent)
  | ({
      attr: "user_membership";
    } & UserMembershipAttendancesListUserMembershipErrorComponent)
  | ({
      attr: "check_in";
    } & UserMembershipAttendancesListCheckInErrorComponent)
  | ({
      attr: "check_in__range";
    } & UserMembershipAttendancesListCheckInRangeErrorComponent)
  | ({
      attr: "check_out";
    } & UserMembershipAttendancesListCheckOutErrorComponent)
  | ({
      attr: "check_out__range";
    } & UserMembershipAttendancesListCheckOutRangeErrorComponent)
  | ({
      attr: "check_in_date";
    } & UserMembershipAttendancesListCheckInDateErrorComponent);
export type UserMembershipAttendancesListValidationError = {
  type: TypeA2CEnum;
  errors: UserMembershipAttendancesListError[];
};
export type UserMembershipAttendancesListErrorResponse400 =
  | ({
      type: "validation_error";
    } & UserMembershipAttendancesListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type UserMembershipAttendancesRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type PaginatedUserMembershipList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: UserMembership[];
};
export type PaginatedUserMembershipListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: UserMembershipRead[];
};
export type PaginatedUserMembershipListWrite = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: UserMembershipWrite[];
};
export type UserMembershipsListUserErrorComponent = {
  attr: Attr886Enum;
  code: Code099Enum;
  detail: string;
};
export type UserMembershipsListMembershipPlanErrorComponent = {
  attr: Attr8A0Enum;
  code: Code099Enum;
  detail: string;
};
export type UserMembershipsListDiscountErrorComponent = {
  attr: Attr513Enum;
  code: Code099Enum;
  detail: string;
};
export type UserMembershipsListError =
  | ({
      attr: "user";
    } & UserMembershipsListUserErrorComponent)
  | ({
      attr: "membership_plan";
    } & UserMembershipsListMembershipPlanErrorComponent)
  | ({
      attr: "discount";
    } & UserMembershipsListDiscountErrorComponent);
export type UserMembershipsListValidationError = {
  type: TypeA2CEnum;
  errors: UserMembershipsListError[];
};
export type UserMembershipsListErrorResponse400 =
  | ({
      type: "validation_error";
    } & UserMembershipsListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type UserMembershipsRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type UserMembershipsArchiveUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type UserMembershipsArchiveUpdateCancelImmediatelyErrorComponentAttrEnum =
  "cancel_immediately";
export type UserMembershipsArchiveUpdateCancelImmediatelyErrorComponent = {
  attr: UserMembershipsArchiveUpdateCancelImmediatelyErrorComponentAttrEnum;
  code: Code023Enum;
  detail: string;
};
export type UserMembershipsArchiveUpdateReasonErrorComponentAttrEnum = "reason";
export type UserMembershipsArchiveUpdateReasonErrorComponent = {
  attr: UserMembershipsArchiveUpdateReasonErrorComponentAttrEnum;
  code: Code0E6Enum;
  detail: string;
};
export type UserMembershipsArchiveUpdateSendEmailToMemberErrorComponentAttrEnum =
  "send_email_to_member";
export type UserMembershipsArchiveUpdateSendEmailToMemberErrorComponent = {
  attr: UserMembershipsArchiveUpdateSendEmailToMemberErrorComponentAttrEnum;
  code: Code023Enum;
  detail: string;
};
export type UserMembershipsArchiveUpdateError =
  | ({
      attr: "non_field_errors";
    } & UserMembershipsArchiveUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "cancel_immediately";
    } & UserMembershipsArchiveUpdateCancelImmediatelyErrorComponent)
  | ({
      attr: "reason";
    } & UserMembershipsArchiveUpdateReasonErrorComponent)
  | ({
      attr: "send_email_to_member";
    } & UserMembershipsArchiveUpdateSendEmailToMemberErrorComponent);
export type UserMembershipsArchiveUpdateValidationError = {
  type: TypeA2CEnum;
  errors: UserMembershipsArchiveUpdateError[];
};
export type UserMembershipsArchiveUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & UserMembershipsArchiveUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type ArchiveUserMembership = {
  cancel_immediately?: boolean;
  reason?: string;
  send_email_to_member?: boolean;
};
export type UserMembershipsChangeDiscountPartialUpdateNonFieldErrorsErrorComponent =
  {
    attr: AttrD81Enum;
    code: Code023Enum;
    detail: string;
  };
export type UserMembershipsChangeDiscountPartialUpdateDiscountErrorComponentCodeEnum =
  "does_not_exist" | "incorrect_type" | "required";
export type UserMembershipsChangeDiscountPartialUpdateDiscountErrorComponent = {
  attr: Attr513Enum;
  code: UserMembershipsChangeDiscountPartialUpdateDiscountErrorComponentCodeEnum;
  detail: string;
};
export type UserMembershipsChangeDiscountPartialUpdateError =
  | ({
      attr: "non_field_errors";
    } & UserMembershipsChangeDiscountPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "discount";
    } & UserMembershipsChangeDiscountPartialUpdateDiscountErrorComponent);
export type UserMembershipsChangeDiscountPartialUpdateValidationError = {
  type: TypeA2CEnum;
  errors: UserMembershipsChangeDiscountPartialUpdateError[];
};
export type UserMembershipsChangeDiscountPartialUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & UserMembershipsChangeDiscountPartialUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PatchedChangeDiscount = {
  discount?: number | null;
};
export type UserMembershipsChangeNextBillDatePartialUpdateNonFieldErrorsErrorComponent =
  {
    attr: AttrD81Enum;
    code: Code023Enum;
    detail: string;
  };
export type UserMembershipsChangeNextBillDatePartialUpdateNextBillDateErrorComponent =
  {
    attr: AttrCa2Enum;
    code: Code0DbEnum;
    detail: string;
  };
export type UserMembershipsChangeNextBillDatePartialUpdateError =
  | ({
      attr: "non_field_errors";
    } & UserMembershipsChangeNextBillDatePartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "next_bill_date";
    } & UserMembershipsChangeNextBillDatePartialUpdateNextBillDateErrorComponent);
export type UserMembershipsChangeNextBillDatePartialUpdateValidationError = {
  type: TypeA2CEnum;
  errors: UserMembershipsChangeNextBillDatePartialUpdateError[];
};
export type UserMembershipsChangeNextBillDatePartialUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & UserMembershipsChangeNextBillDatePartialUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PatchedChangeNextBillDate = {
  next_bill_date?: string;
};
export type UserMembershipsChangePaymentTypePartialUpdateNonFieldErrorsErrorComponent =
  {
    attr: AttrD81Enum;
    code: Code023Enum;
    detail: string;
  };
export type UserMembershipsChangePaymentTypePartialUpdatePaymentTypeErrorComponentAttrEnum =
  "payment_type";
export type UserMembershipsChangePaymentTypePartialUpdatePaymentTypeErrorComponent =
  {
    attr: UserMembershipsChangePaymentTypePartialUpdatePaymentTypeErrorComponentAttrEnum;
    code: Code4EfEnum;
    detail: string;
  };
export type UserMembershipsChangePaymentTypePartialUpdateError =
  | ({
      attr: "non_field_errors";
    } & UserMembershipsChangePaymentTypePartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "payment_type";
    } & UserMembershipsChangePaymentTypePartialUpdatePaymentTypeErrorComponent);
export type UserMembershipsChangePaymentTypePartialUpdateValidationError = {
  type: TypeA2CEnum;
  errors: UserMembershipsChangePaymentTypePartialUpdateError[];
};
export type UserMembershipsChangePaymentTypePartialUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & UserMembershipsChangePaymentTypePartialUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PatchedChangePaymentType = {
  payment_type?: PaymentTypeEnum;
};
export type UserMembershipsAddMembershipCardCreateNonFieldErrorsErrorComponent =
  {
    attr: AttrD81Enum;
    code: Code023Enum;
    detail: string;
  };
export type UserMembershipsAddMembershipCardCreateMembershipPlanErrorComponent =
  {
    attr: Attr8A0Enum;
    code: Code6C0Enum;
    detail: string;
  };
export type UserMembershipsAddMembershipCardCreateUserErrorComponent = {
  attr: Attr886Enum;
  code: Code6C0Enum;
  detail: string;
};
export type UserMembershipsAddMembershipCardCreateStartDateErrorComponent = {
  attr: Attr92BEnum;
  code: Code0DbEnum;
  detail: string;
};
export type UserMembershipsAddMembershipCardCreateDiscountErrorComponent = {
  attr: Attr513Enum;
  code: Code948Enum;
  detail: string;
};
export type UserMembershipsAddMembershipCardCreatePaymentReceivedErrorComponent =
  {
    attr: Attr9E6Enum;
    code: Code023Enum;
    detail: string;
  };
export type UserMembershipsAddMembershipCardCreateError =
  | ({
      attr: "non_field_errors";
    } & UserMembershipsAddMembershipCardCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "membership_plan";
    } & UserMembershipsAddMembershipCardCreateMembershipPlanErrorComponent)
  | ({
      attr: "user";
    } & UserMembershipsAddMembershipCardCreateUserErrorComponent)
  | ({
      attr: "start_date";
    } & UserMembershipsAddMembershipCardCreateStartDateErrorComponent)
  | ({
      attr: "discount";
    } & UserMembershipsAddMembershipCardCreateDiscountErrorComponent)
  | ({
      attr: "payment_received";
    } & UserMembershipsAddMembershipCardCreatePaymentReceivedErrorComponent);
export type UserMembershipsAddMembershipCardCreateValidationError = {
  type: TypeA2CEnum;
  errors: UserMembershipsAddMembershipCardCreateError[];
};
export type UserMembershipsAddMembershipCardCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & UserMembershipsAddMembershipCardCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type AddUserMembership = {
  membership_plan: number;
  user: number;
  start_date: string;
  discount?: number | null;
  payment_received?: boolean;
};
export type UserMembershipsAddMembershipCashCreateNonFieldErrorsErrorComponent =
  {
    attr: AttrD81Enum;
    code: Code023Enum;
    detail: string;
  };
export type UserMembershipsAddMembershipCashCreateMembershipPlanErrorComponent =
  {
    attr: Attr8A0Enum;
    code: Code6C0Enum;
    detail: string;
  };
export type UserMembershipsAddMembershipCashCreateUserErrorComponent = {
  attr: Attr886Enum;
  code: Code6C0Enum;
  detail: string;
};
export type UserMembershipsAddMembershipCashCreateStartDateErrorComponent = {
  attr: Attr92BEnum;
  code: Code0DbEnum;
  detail: string;
};
export type UserMembershipsAddMembershipCashCreateDiscountErrorComponent = {
  attr: Attr513Enum;
  code: Code948Enum;
  detail: string;
};
export type UserMembershipsAddMembershipCashCreatePaymentReceivedErrorComponent =
  {
    attr: Attr9E6Enum;
    code: Code023Enum;
    detail: string;
  };
export type UserMembershipsAddMembershipCashCreateError =
  | ({
      attr: "non_field_errors";
    } & UserMembershipsAddMembershipCashCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "membership_plan";
    } & UserMembershipsAddMembershipCashCreateMembershipPlanErrorComponent)
  | ({
      attr: "user";
    } & UserMembershipsAddMembershipCashCreateUserErrorComponent)
  | ({
      attr: "start_date";
    } & UserMembershipsAddMembershipCashCreateStartDateErrorComponent)
  | ({
      attr: "discount";
    } & UserMembershipsAddMembershipCashCreateDiscountErrorComponent)
  | ({
      attr: "payment_received";
    } & UserMembershipsAddMembershipCashCreatePaymentReceivedErrorComponent);
export type UserMembershipsAddMembershipCashCreateValidationError = {
  type: TypeA2CEnum;
  errors: UserMembershipsAddMembershipCashCreateError[];
};
export type UserMembershipsAddMembershipCashCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & UserMembershipsAddMembershipCashCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type BuyMembershipResponse = {
  payment_intent_client_secret: string;
  ephemeral_key_secret: string;
};
export type UserMembershipsBuyMembershipCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type UserMembershipsBuyMembershipCreateMembershipPlanErrorComponent = {
  attr: Attr8A0Enum;
  code: Code6C0Enum;
  detail: string;
};
export type UserMembershipsBuyMembershipCreateStartDateErrorComponent = {
  attr: Attr92BEnum;
  code: Code0DbEnum;
  detail: string;
};
export type UserMembershipsBuyMembershipCreateDiscountCodeErrorComponent = {
  attr: AttrCa4Enum;
  code: Code23DEnum;
  detail: string;
};
export type UserMembershipsBuyMembershipCreateError =
  | ({
      attr: "non_field_errors";
    } & UserMembershipsBuyMembershipCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "membership_plan";
    } & UserMembershipsBuyMembershipCreateMembershipPlanErrorComponent)
  | ({
      attr: "start_date";
    } & UserMembershipsBuyMembershipCreateStartDateErrorComponent)
  | ({
      attr: "discount_code";
    } & UserMembershipsBuyMembershipCreateDiscountCodeErrorComponent);
export type UserMembershipsBuyMembershipCreateValidationError = {
  type: TypeA2CEnum;
  errors: UserMembershipsBuyMembershipCreateError[];
};
export type UserMembershipsBuyMembershipCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & UserMembershipsBuyMembershipCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type BuyMembership = {
  membership_plan: number;
  start_date: string;
  discount_code?: string | null;
};
export type UserMembershipsMyMembershipsListUserErrorComponent = {
  attr: Attr886Enum;
  code: Code099Enum;
  detail: string;
};
export type UserMembershipsMyMembershipsListMembershipPlanErrorComponent = {
  attr: Attr8A0Enum;
  code: Code099Enum;
  detail: string;
};
export type UserMembershipsMyMembershipsListDiscountErrorComponent = {
  attr: Attr513Enum;
  code: Code099Enum;
  detail: string;
};
export type UserMembershipsMyMembershipsListError =
  | ({
      attr: "user";
    } & UserMembershipsMyMembershipsListUserErrorComponent)
  | ({
      attr: "membership_plan";
    } & UserMembershipsMyMembershipsListMembershipPlanErrorComponent)
  | ({
      attr: "discount";
    } & UserMembershipsMyMembershipsListDiscountErrorComponent);
export type UserMembershipsMyMembershipsListValidationError = {
  type: TypeA2CEnum;
  errors: UserMembershipsMyMembershipsListError[];
};
export type UserMembershipsMyMembershipsListErrorResponse400 =
  | ({
      type: "validation_error";
    } & UserMembershipsMyMembershipsListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type UserNoteTag = {
  name: string;
};
export type UserNoteTagRead = {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  created_by: number;
};
export type PaginatedUserNoteTagList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: UserNoteTag[];
};
export type PaginatedUserNoteTagListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: UserNoteTagRead[];
};
export type UserNoteTagsListIdInErrorComponent = {
  attr: Attr12BEnum;
  code: Code90FEnum;
  detail: string;
};
export type UserNoteTagsListError = {
  attr: "id__in";
} & UserNoteTagsListIdInErrorComponent;
export type UserNoteTagsListValidationError = {
  type: TypeA2CEnum;
  errors: UserNoteTagsListError[];
};
export type UserNoteTagsListErrorResponse400 =
  | ({
      type: "validation_error";
    } & UserNoteTagsListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type UserNoteTagsCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type UserNoteTagsCreateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code238Enum;
  detail: string;
};
export type UserNoteTagsCreateError =
  | ({
      attr: "non_field_errors";
    } & UserNoteTagsCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & UserNoteTagsCreateNameErrorComponent);
export type UserNoteTagsCreateValidationError = {
  type: TypeA2CEnum;
  errors: UserNoteTagsCreateError[];
};
export type UserNoteTagsCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & UserNoteTagsCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type UserNoteTagsRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type UserNoteTagsUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type UserNoteTagsUpdateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code238Enum;
  detail: string;
};
export type UserNoteTagsUpdateError =
  | ({
      attr: "non_field_errors";
    } & UserNoteTagsUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & UserNoteTagsUpdateNameErrorComponent);
export type UserNoteTagsUpdateValidationError = {
  type: TypeA2CEnum;
  errors: UserNoteTagsUpdateError[];
};
export type UserNoteTagsUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & UserNoteTagsUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type UserNoteTagsPartialUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type UserNoteTagsPartialUpdateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code238Enum;
  detail: string;
};
export type UserNoteTagsPartialUpdateError =
  | ({
      attr: "non_field_errors";
    } & UserNoteTagsPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & UserNoteTagsPartialUpdateNameErrorComponent);
export type UserNoteTagsPartialUpdateValidationError = {
  type: TypeA2CEnum;
  errors: UserNoteTagsPartialUpdateError[];
};
export type UserNoteTagsPartialUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & UserNoteTagsPartialUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PatchedUserNoteTag = {
  name?: string;
};
export type PatchedUserNoteTagRead = {
  id?: number;
  created_at?: string;
  updated_at?: string;
  name?: string;
  created_by?: number;
};
export type UserNoteTagsDestroyErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type ReminderTypeEnum = "ON_DATE" | "NEXT_CHECK_IN" | "NO_REMINDER";
export type UserNote = {
  note: string;
  reminder_type?: ReminderTypeEnum;
  reminder_date?: string | null;
  reminder_email?: boolean;
  reminder_slack?: boolean;
  reminder_sms?: boolean;
  cc_member?: boolean;
  user: number;
  tags?: number[];
};
export type UserNoteRead = {
  id: number;
  reminder_type_display: string;
  created_at: string;
  updated_at: string;
  note: string;
  reminder_type?: ReminderTypeEnum;
  reminder_date?: string | null;
  reminder_email?: boolean;
  reminder_slack?: boolean;
  reminder_sms?: boolean;
  cc_member?: boolean;
  reminder_sent: boolean;
  user: number;
  created_by: number;
  tags?: number[];
};
export type PaginatedUserNoteList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: UserNote[];
};
export type PaginatedUserNoteListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: UserNoteRead[];
};
export type UserNotesListUserErrorComponent = {
  attr: Attr886Enum;
  code: Code099Enum;
  detail: string;
};
export type UserNotesListError = {
  attr: "user";
} & UserNotesListUserErrorComponent;
export type UserNotesListValidationError = {
  type: TypeA2CEnum;
  errors: UserNotesListError[];
};
export type UserNotesListErrorResponse400 =
  | ({
      type: "validation_error";
    } & UserNotesListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type UserNotesCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type AttrB59Enum = "note";
export type UserNotesCreateNoteErrorComponent = {
  attr: AttrB59Enum;
  code: CodeA3CEnum;
  detail: string;
};
export type Attr167Enum = "reminder_type";
export type UserNotesCreateReminderTypeErrorComponent = {
  attr: Attr167Enum;
  code: Code3F2Enum;
  detail: string;
};
export type Attr136Enum = "reminder_date";
export type Code5A0Enum = "date" | "invalid" | "make_aware" | "overflow";
export type UserNotesCreateReminderDateErrorComponent = {
  attr: Attr136Enum;
  code: Code5A0Enum;
  detail: string;
};
export type Attr5C2Enum = "reminder_email";
export type UserNotesCreateReminderEmailErrorComponent = {
  attr: Attr5C2Enum;
  code: Code023Enum;
  detail: string;
};
export type Attr100Enum = "reminder_slack";
export type UserNotesCreateReminderSlackErrorComponent = {
  attr: Attr100Enum;
  code: Code023Enum;
  detail: string;
};
export type Attr212Enum = "reminder_sms";
export type UserNotesCreateReminderSmsErrorComponent = {
  attr: Attr212Enum;
  code: Code023Enum;
  detail: string;
};
export type AttrC53Enum = "cc_member";
export type UserNotesCreateCcMemberErrorComponent = {
  attr: AttrC53Enum;
  code: Code023Enum;
  detail: string;
};
export type UserNotesCreateUserErrorComponent = {
  attr: Attr886Enum;
  code: Code6C0Enum;
  detail: string;
};
export type AttrFe4Enum = "tags";
export type UserNotesCreateTagsErrorComponent = {
  attr: AttrFe4Enum;
  code: Code2DeEnum;
  detail: string;
};
export type UserNotesCreateError =
  | ({
      attr: "non_field_errors";
    } & UserNotesCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "note";
    } & UserNotesCreateNoteErrorComponent)
  | ({
      attr: "reminder_type";
    } & UserNotesCreateReminderTypeErrorComponent)
  | ({
      attr: "reminder_date";
    } & UserNotesCreateReminderDateErrorComponent)
  | ({
      attr: "reminder_email";
    } & UserNotesCreateReminderEmailErrorComponent)
  | ({
      attr: "reminder_slack";
    } & UserNotesCreateReminderSlackErrorComponent)
  | ({
      attr: "reminder_sms";
    } & UserNotesCreateReminderSmsErrorComponent)
  | ({
      attr: "cc_member";
    } & UserNotesCreateCcMemberErrorComponent)
  | ({
      attr: "user";
    } & UserNotesCreateUserErrorComponent)
  | ({
      attr: "tags";
    } & UserNotesCreateTagsErrorComponent);
export type UserNotesCreateValidationError = {
  type: TypeA2CEnum;
  errors: UserNotesCreateError[];
};
export type UserNotesCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & UserNotesCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type UserNotesRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type UserNotesUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type UserNotesUpdateNoteErrorComponent = {
  attr: AttrB59Enum;
  code: CodeA3CEnum;
  detail: string;
};
export type UserNotesUpdateReminderTypeErrorComponent = {
  attr: Attr167Enum;
  code: Code3F2Enum;
  detail: string;
};
export type UserNotesUpdateReminderDateErrorComponent = {
  attr: Attr136Enum;
  code: Code5A0Enum;
  detail: string;
};
export type UserNotesUpdateReminderEmailErrorComponent = {
  attr: Attr5C2Enum;
  code: Code023Enum;
  detail: string;
};
export type UserNotesUpdateReminderSlackErrorComponent = {
  attr: Attr100Enum;
  code: Code023Enum;
  detail: string;
};
export type UserNotesUpdateReminderSmsErrorComponent = {
  attr: Attr212Enum;
  code: Code023Enum;
  detail: string;
};
export type UserNotesUpdateCcMemberErrorComponent = {
  attr: AttrC53Enum;
  code: Code023Enum;
  detail: string;
};
export type UserNotesUpdateUserErrorComponent = {
  attr: Attr886Enum;
  code: Code6C0Enum;
  detail: string;
};
export type UserNotesUpdateTagsErrorComponent = {
  attr: AttrFe4Enum;
  code: Code2DeEnum;
  detail: string;
};
export type UserNotesUpdateError =
  | ({
      attr: "non_field_errors";
    } & UserNotesUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "note";
    } & UserNotesUpdateNoteErrorComponent)
  | ({
      attr: "reminder_type";
    } & UserNotesUpdateReminderTypeErrorComponent)
  | ({
      attr: "reminder_date";
    } & UserNotesUpdateReminderDateErrorComponent)
  | ({
      attr: "reminder_email";
    } & UserNotesUpdateReminderEmailErrorComponent)
  | ({
      attr: "reminder_slack";
    } & UserNotesUpdateReminderSlackErrorComponent)
  | ({
      attr: "reminder_sms";
    } & UserNotesUpdateReminderSmsErrorComponent)
  | ({
      attr: "cc_member";
    } & UserNotesUpdateCcMemberErrorComponent)
  | ({
      attr: "user";
    } & UserNotesUpdateUserErrorComponent)
  | ({
      attr: "tags";
    } & UserNotesUpdateTagsErrorComponent);
export type UserNotesUpdateValidationError = {
  type: TypeA2CEnum;
  errors: UserNotesUpdateError[];
};
export type UserNotesUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & UserNotesUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type UserNotesPartialUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type UserNotesPartialUpdateNoteErrorComponent = {
  attr: AttrB59Enum;
  code: CodeA3CEnum;
  detail: string;
};
export type UserNotesPartialUpdateReminderTypeErrorComponent = {
  attr: Attr167Enum;
  code: Code3F2Enum;
  detail: string;
};
export type UserNotesPartialUpdateReminderDateErrorComponent = {
  attr: Attr136Enum;
  code: Code5A0Enum;
  detail: string;
};
export type UserNotesPartialUpdateReminderEmailErrorComponent = {
  attr: Attr5C2Enum;
  code: Code023Enum;
  detail: string;
};
export type UserNotesPartialUpdateReminderSlackErrorComponent = {
  attr: Attr100Enum;
  code: Code023Enum;
  detail: string;
};
export type UserNotesPartialUpdateReminderSmsErrorComponent = {
  attr: Attr212Enum;
  code: Code023Enum;
  detail: string;
};
export type UserNotesPartialUpdateCcMemberErrorComponent = {
  attr: AttrC53Enum;
  code: Code023Enum;
  detail: string;
};
export type UserNotesPartialUpdateUserErrorComponent = {
  attr: Attr886Enum;
  code: Code6C0Enum;
  detail: string;
};
export type UserNotesPartialUpdateTagsErrorComponent = {
  attr: AttrFe4Enum;
  code: Code2DeEnum;
  detail: string;
};
export type UserNotesPartialUpdateError =
  | ({
      attr: "non_field_errors";
    } & UserNotesPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "note";
    } & UserNotesPartialUpdateNoteErrorComponent)
  | ({
      attr: "reminder_type";
    } & UserNotesPartialUpdateReminderTypeErrorComponent)
  | ({
      attr: "reminder_date";
    } & UserNotesPartialUpdateReminderDateErrorComponent)
  | ({
      attr: "reminder_email";
    } & UserNotesPartialUpdateReminderEmailErrorComponent)
  | ({
      attr: "reminder_slack";
    } & UserNotesPartialUpdateReminderSlackErrorComponent)
  | ({
      attr: "reminder_sms";
    } & UserNotesPartialUpdateReminderSmsErrorComponent)
  | ({
      attr: "cc_member";
    } & UserNotesPartialUpdateCcMemberErrorComponent)
  | ({
      attr: "user";
    } & UserNotesPartialUpdateUserErrorComponent)
  | ({
      attr: "tags";
    } & UserNotesPartialUpdateTagsErrorComponent);
export type UserNotesPartialUpdateValidationError = {
  type: TypeA2CEnum;
  errors: UserNotesPartialUpdateError[];
};
export type UserNotesPartialUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & UserNotesPartialUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PatchedUserNote = {
  note?: string;
  reminder_type?: ReminderTypeEnum;
  reminder_date?: string | null;
  reminder_email?: boolean;
  reminder_slack?: boolean;
  reminder_sms?: boolean;
  cc_member?: boolean;
  user?: number;
  tags?: number[];
};
export type PatchedUserNoteRead = {
  id?: number;
  reminder_type_display?: string;
  created_at?: string;
  updated_at?: string;
  note?: string;
  reminder_type?: ReminderTypeEnum;
  reminder_date?: string | null;
  reminder_email?: boolean;
  reminder_slack?: boolean;
  reminder_sms?: boolean;
  cc_member?: boolean;
  reminder_sent?: boolean;
  user?: number;
  created_by?: number;
  tags?: number[];
};
export type UserNotesDestroyErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type UsersListAttendanceCheckOutErrorComponent = {
  attr: AttrE49Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type UsersListAttendanceCheckOutRangeErrorComponent = {
  attr: Attr107Enum;
  code: Code241Enum;
  detail: string;
};
export type UsersListIdInErrorComponent = {
  attr: Attr12BEnum;
  code: Code90FEnum;
  detail: string;
};
export type UsersListMigrationStatusErrorComponent = {
  attr: Attr461Enum;
  code: Code099Enum;
  detail: string;
};
export type UsersListMigrationStatusInErrorComponent = {
  attr: Attr5B2Enum;
  code: Code0E9Enum;
  detail: string;
};
export type UsersListBirthdayMonthErrorComponent = {
  attr: Attr09EEnum;
  code: Code90FEnum;
  detail: string;
};
export type UsersListDaysUntilBirthdayErrorComponent = {
  attr: Attr69DEnum;
  code: Code90FEnum;
  detail: string;
};
export type UsersListError =
  | ({
      attr: "attendance__check_out";
    } & UsersListAttendanceCheckOutErrorComponent)
  | ({
      attr: "attendance__check_out__range";
    } & UsersListAttendanceCheckOutRangeErrorComponent)
  | ({
      attr: "id__in";
    } & UsersListIdInErrorComponent)
  | ({
      attr: "migration_status";
    } & UsersListMigrationStatusErrorComponent)
  | ({
      attr: "migration_status__in";
    } & UsersListMigrationStatusInErrorComponent)
  | ({
      attr: "birthday_month";
    } & UsersListBirthdayMonthErrorComponent)
  | ({
      attr: "days_until_birthday";
    } & UsersListDaysUntilBirthdayErrorComponent);
export type UsersListValidationError = {
  type: TypeA2CEnum;
  errors: UsersListError[];
};
export type UsersListErrorResponse400 =
  | ({
      type: "validation_error";
    } & UsersListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type UsersCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type UsersCreateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code238Enum;
  detail: string;
};
export type UsersCreateEmailErrorComponent = {
  attr: Attr307Enum;
  code: Code6AeEnum;
  detail: string;
};
export type UsersCreatePhoneErrorComponent = {
  attr: AttrB8DEnum;
  code: CodeF87Enum;
  detail: string;
};
export type UsersCreateProfilePhotoErrorComponent = {
  attr: AttrC70Enum;
  code: Code40EEnum;
  detail: string;
};
export type UsersCreateLanguageErrorComponent = {
  attr: Attr6F0Enum;
  code: Code3F2Enum;
  detail: string;
};
export type UsersCreateBirthdayErrorComponent = {
  attr: AttrDe0Enum;
  code: Code0DbEnum;
  detail: string;
};
export type UsersCreateAssignedToErrorComponent = {
  attr: Attr35DEnum;
  code: Code948Enum;
  detail: string;
};
export type UsersCreateReferredByErrorComponent = {
  attr: Attr43DEnum;
  code: Code3F2Enum;
  detail: string;
};
export type UsersCreateReferrerErrorComponent = {
  attr: Attr1FbEnum;
  code: Code948Enum;
  detail: string;
};
export type UsersCreateStreetAddressErrorComponent = {
  attr: Attr8B7Enum;
  code: Code548Enum;
  detail: string;
};
export type UsersCreateCityErrorComponent = {
  attr: Attr001Enum;
  code: Code548Enum;
  detail: string;
};
export type UsersCreateStateErrorComponent = {
  attr: AttrAbdEnum;
  code: Code548Enum;
  detail: string;
};
export type UsersCreatePostalCodeErrorComponent = {
  attr: Attr1DeEnum;
  code: Code548Enum;
  detail: string;
};
export type UsersCreateCountryErrorComponent = {
  attr: Attr476Enum;
  code: Code4EfEnum;
  detail: string;
};
export type UsersCreateGuardianNameErrorComponent = {
  attr: Attr1D4Enum;
  code: Code548Enum;
  detail: string;
};
export type UsersCreateGuardianEmailErrorComponent = {
  attr: AttrA55Enum;
  code: Code548Enum;
  detail: string;
};
export type UsersCreateGuardianPhoneErrorComponent = {
  attr: Attr933Enum;
  code: CodeF87Enum;
  detail: string;
};
export type UsersCreateGroupsErrorComponent = {
  attr: Attr5B3Enum;
  code: Code2DeEnum;
  detail: string;
};
export type UsersCreateError =
  | ({
      attr: "non_field_errors";
    } & UsersCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & UsersCreateNameErrorComponent)
  | ({
      attr: "email";
    } & UsersCreateEmailErrorComponent)
  | ({
      attr: "phone";
    } & UsersCreatePhoneErrorComponent)
  | ({
      attr: "profile_photo";
    } & UsersCreateProfilePhotoErrorComponent)
  | ({
      attr: "language";
    } & UsersCreateLanguageErrorComponent)
  | ({
      attr: "birthday";
    } & UsersCreateBirthdayErrorComponent)
  | ({
      attr: "assigned_to";
    } & UsersCreateAssignedToErrorComponent)
  | ({
      attr: "referred_by";
    } & UsersCreateReferredByErrorComponent)
  | ({
      attr: "referrer";
    } & UsersCreateReferrerErrorComponent)
  | ({
      attr: "street_address";
    } & UsersCreateStreetAddressErrorComponent)
  | ({
      attr: "city";
    } & UsersCreateCityErrorComponent)
  | ({
      attr: "state";
    } & UsersCreateStateErrorComponent)
  | ({
      attr: "postal_code";
    } & UsersCreatePostalCodeErrorComponent)
  | ({
      attr: "country";
    } & UsersCreateCountryErrorComponent)
  | ({
      attr: "guardian_name";
    } & UsersCreateGuardianNameErrorComponent)
  | ({
      attr: "guardian_email";
    } & UsersCreateGuardianEmailErrorComponent)
  | ({
      attr: "guardian_phone";
    } & UsersCreateGuardianPhoneErrorComponent)
  | ({
      attr: "groups";
    } & UsersCreateGroupsErrorComponent);
export type UsersCreateValidationError = {
  type: TypeA2CEnum;
  errors: UsersCreateError[];
};
export type UsersCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & UsersCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type UsersRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type UsersUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type UsersUpdateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code238Enum;
  detail: string;
};
export type UsersUpdateEmailErrorComponent = {
  attr: Attr307Enum;
  code: Code6AeEnum;
  detail: string;
};
export type UsersUpdatePhoneErrorComponent = {
  attr: AttrB8DEnum;
  code: CodeF87Enum;
  detail: string;
};
export type UsersUpdateProfilePhotoErrorComponent = {
  attr: AttrC70Enum;
  code: Code40EEnum;
  detail: string;
};
export type UsersUpdateLanguageErrorComponent = {
  attr: Attr6F0Enum;
  code: Code3F2Enum;
  detail: string;
};
export type UsersUpdateBirthdayErrorComponent = {
  attr: AttrDe0Enum;
  code: Code0DbEnum;
  detail: string;
};
export type UsersUpdateAssignedToErrorComponent = {
  attr: Attr35DEnum;
  code: Code948Enum;
  detail: string;
};
export type UsersUpdateReferredByErrorComponent = {
  attr: Attr43DEnum;
  code: Code3F2Enum;
  detail: string;
};
export type UsersUpdateReferrerErrorComponent = {
  attr: Attr1FbEnum;
  code: Code948Enum;
  detail: string;
};
export type UsersUpdateStreetAddressErrorComponent = {
  attr: Attr8B7Enum;
  code: Code548Enum;
  detail: string;
};
export type UsersUpdateCityErrorComponent = {
  attr: Attr001Enum;
  code: Code548Enum;
  detail: string;
};
export type UsersUpdateStateErrorComponent = {
  attr: AttrAbdEnum;
  code: Code548Enum;
  detail: string;
};
export type UsersUpdatePostalCodeErrorComponent = {
  attr: Attr1DeEnum;
  code: Code548Enum;
  detail: string;
};
export type UsersUpdateCountryErrorComponent = {
  attr: Attr476Enum;
  code: Code4EfEnum;
  detail: string;
};
export type UsersUpdateGuardianNameErrorComponent = {
  attr: Attr1D4Enum;
  code: Code548Enum;
  detail: string;
};
export type UsersUpdateGuardianEmailErrorComponent = {
  attr: AttrA55Enum;
  code: Code548Enum;
  detail: string;
};
export type UsersUpdateGuardianPhoneErrorComponent = {
  attr: Attr933Enum;
  code: CodeF87Enum;
  detail: string;
};
export type UsersUpdateGroupsErrorComponent = {
  attr: Attr5B3Enum;
  code: Code2DeEnum;
  detail: string;
};
export type UsersUpdateError =
  | ({
      attr: "non_field_errors";
    } & UsersUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & UsersUpdateNameErrorComponent)
  | ({
      attr: "email";
    } & UsersUpdateEmailErrorComponent)
  | ({
      attr: "phone";
    } & UsersUpdatePhoneErrorComponent)
  | ({
      attr: "profile_photo";
    } & UsersUpdateProfilePhotoErrorComponent)
  | ({
      attr: "language";
    } & UsersUpdateLanguageErrorComponent)
  | ({
      attr: "birthday";
    } & UsersUpdateBirthdayErrorComponent)
  | ({
      attr: "assigned_to";
    } & UsersUpdateAssignedToErrorComponent)
  | ({
      attr: "referred_by";
    } & UsersUpdateReferredByErrorComponent)
  | ({
      attr: "referrer";
    } & UsersUpdateReferrerErrorComponent)
  | ({
      attr: "street_address";
    } & UsersUpdateStreetAddressErrorComponent)
  | ({
      attr: "city";
    } & UsersUpdateCityErrorComponent)
  | ({
      attr: "state";
    } & UsersUpdateStateErrorComponent)
  | ({
      attr: "postal_code";
    } & UsersUpdatePostalCodeErrorComponent)
  | ({
      attr: "country";
    } & UsersUpdateCountryErrorComponent)
  | ({
      attr: "guardian_name";
    } & UsersUpdateGuardianNameErrorComponent)
  | ({
      attr: "guardian_email";
    } & UsersUpdateGuardianEmailErrorComponent)
  | ({
      attr: "guardian_phone";
    } & UsersUpdateGuardianPhoneErrorComponent)
  | ({
      attr: "groups";
    } & UsersUpdateGroupsErrorComponent);
export type UsersUpdateValidationError = {
  type: TypeA2CEnum;
  errors: UsersUpdateError[];
};
export type UsersUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & UsersUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type UsersPartialUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type UsersPartialUpdateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code238Enum;
  detail: string;
};
export type UsersPartialUpdateEmailErrorComponent = {
  attr: Attr307Enum;
  code: Code6AeEnum;
  detail: string;
};
export type UsersPartialUpdatePhoneErrorComponent = {
  attr: AttrB8DEnum;
  code: CodeF87Enum;
  detail: string;
};
export type UsersPartialUpdateProfilePhotoErrorComponent = {
  attr: AttrC70Enum;
  code: Code40EEnum;
  detail: string;
};
export type UsersPartialUpdateLanguageErrorComponent = {
  attr: Attr6F0Enum;
  code: Code3F2Enum;
  detail: string;
};
export type UsersPartialUpdateBirthdayErrorComponent = {
  attr: AttrDe0Enum;
  code: Code0DbEnum;
  detail: string;
};
export type UsersPartialUpdateAssignedToErrorComponent = {
  attr: Attr35DEnum;
  code: Code948Enum;
  detail: string;
};
export type UsersPartialUpdateReferredByErrorComponent = {
  attr: Attr43DEnum;
  code: Code3F2Enum;
  detail: string;
};
export type UsersPartialUpdateReferrerErrorComponent = {
  attr: Attr1FbEnum;
  code: Code948Enum;
  detail: string;
};
export type UsersPartialUpdateStreetAddressErrorComponent = {
  attr: Attr8B7Enum;
  code: Code548Enum;
  detail: string;
};
export type UsersPartialUpdateCityErrorComponent = {
  attr: Attr001Enum;
  code: Code548Enum;
  detail: string;
};
export type UsersPartialUpdateStateErrorComponent = {
  attr: AttrAbdEnum;
  code: Code548Enum;
  detail: string;
};
export type UsersPartialUpdatePostalCodeErrorComponent = {
  attr: Attr1DeEnum;
  code: Code548Enum;
  detail: string;
};
export type UsersPartialUpdateCountryErrorComponent = {
  attr: Attr476Enum;
  code: Code4EfEnum;
  detail: string;
};
export type UsersPartialUpdateGuardianNameErrorComponent = {
  attr: Attr1D4Enum;
  code: Code548Enum;
  detail: string;
};
export type UsersPartialUpdateGuardianEmailErrorComponent = {
  attr: AttrA55Enum;
  code: Code548Enum;
  detail: string;
};
export type UsersPartialUpdateGuardianPhoneErrorComponent = {
  attr: Attr933Enum;
  code: CodeF87Enum;
  detail: string;
};
export type UsersPartialUpdateGroupsErrorComponent = {
  attr: Attr5B3Enum;
  code: Code2DeEnum;
  detail: string;
};
export type UsersPartialUpdateError =
  | ({
      attr: "non_field_errors";
    } & UsersPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & UsersPartialUpdateNameErrorComponent)
  | ({
      attr: "email";
    } & UsersPartialUpdateEmailErrorComponent)
  | ({
      attr: "phone";
    } & UsersPartialUpdatePhoneErrorComponent)
  | ({
      attr: "profile_photo";
    } & UsersPartialUpdateProfilePhotoErrorComponent)
  | ({
      attr: "language";
    } & UsersPartialUpdateLanguageErrorComponent)
  | ({
      attr: "birthday";
    } & UsersPartialUpdateBirthdayErrorComponent)
  | ({
      attr: "assigned_to";
    } & UsersPartialUpdateAssignedToErrorComponent)
  | ({
      attr: "referred_by";
    } & UsersPartialUpdateReferredByErrorComponent)
  | ({
      attr: "referrer";
    } & UsersPartialUpdateReferrerErrorComponent)
  | ({
      attr: "street_address";
    } & UsersPartialUpdateStreetAddressErrorComponent)
  | ({
      attr: "city";
    } & UsersPartialUpdateCityErrorComponent)
  | ({
      attr: "state";
    } & UsersPartialUpdateStateErrorComponent)
  | ({
      attr: "postal_code";
    } & UsersPartialUpdatePostalCodeErrorComponent)
  | ({
      attr: "country";
    } & UsersPartialUpdateCountryErrorComponent)
  | ({
      attr: "guardian_name";
    } & UsersPartialUpdateGuardianNameErrorComponent)
  | ({
      attr: "guardian_email";
    } & UsersPartialUpdateGuardianEmailErrorComponent)
  | ({
      attr: "guardian_phone";
    } & UsersPartialUpdateGuardianPhoneErrorComponent)
  | ({
      attr: "groups";
    } & UsersPartialUpdateGroupsErrorComponent);
export type UsersPartialUpdateValidationError = {
  type: TypeA2CEnum;
  errors: UsersPartialUpdateError[];
};
export type UsersPartialUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & UsersPartialUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type UsersDestroyErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type UsersAssignedUsersListAttendanceCheckOutErrorComponent = {
  attr: AttrE49Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type UsersAssignedUsersListAttendanceCheckOutRangeErrorComponent = {
  attr: Attr107Enum;
  code: Code241Enum;
  detail: string;
};
export type UsersAssignedUsersListIdInErrorComponent = {
  attr: Attr12BEnum;
  code: Code90FEnum;
  detail: string;
};
export type UsersAssignedUsersListMigrationStatusErrorComponent = {
  attr: Attr461Enum;
  code: Code099Enum;
  detail: string;
};
export type UsersAssignedUsersListMigrationStatusInErrorComponent = {
  attr: Attr5B2Enum;
  code: Code0E9Enum;
  detail: string;
};
export type UsersAssignedUsersListBirthdayMonthErrorComponent = {
  attr: Attr09EEnum;
  code: Code90FEnum;
  detail: string;
};
export type UsersAssignedUsersListDaysUntilBirthdayErrorComponent = {
  attr: Attr69DEnum;
  code: Code90FEnum;
  detail: string;
};
export type UsersAssignedUsersListError =
  | ({
      attr: "attendance__check_out";
    } & UsersAssignedUsersListAttendanceCheckOutErrorComponent)
  | ({
      attr: "attendance__check_out__range";
    } & UsersAssignedUsersListAttendanceCheckOutRangeErrorComponent)
  | ({
      attr: "id__in";
    } & UsersAssignedUsersListIdInErrorComponent)
  | ({
      attr: "migration_status";
    } & UsersAssignedUsersListMigrationStatusErrorComponent)
  | ({
      attr: "migration_status__in";
    } & UsersAssignedUsersListMigrationStatusInErrorComponent)
  | ({
      attr: "birthday_month";
    } & UsersAssignedUsersListBirthdayMonthErrorComponent)
  | ({
      attr: "days_until_birthday";
    } & UsersAssignedUsersListDaysUntilBirthdayErrorComponent);
export type UsersAssignedUsersListValidationError = {
  type: TypeA2CEnum;
  errors: UsersAssignedUsersListError[];
};
export type UsersAssignedUsersListErrorResponse400 =
  | ({
      type: "validation_error";
    } & UsersAssignedUsersListValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type UsersHistoryListErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type WorkingHours = {
  user: number;
  is_enabled?: boolean;
  monday_start_time?: string | null;
  monday_end_time?: string | null;
  tuesday_start_time?: string | null;
  tuesday_end_time?: string | null;
  wednesday_start_time?: string | null;
  wednesday_end_time?: string | null;
  thursday_start_time?: string | null;
  thursday_end_time?: string | null;
  friday_start_time?: string | null;
  friday_end_time?: string | null;
  saturday_start_time?: string | null;
  saturday_end_time?: string | null;
  sunday_start_time?: string | null;
  sunday_end_time?: string | null;
};
export type UsersUpdateWorkingHoursPartialUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type UsersUpdateWorkingHoursPartialUpdateUserErrorComponent = {
  attr: Attr886Enum;
  code: Code9DdEnum;
  detail: string;
};
export type UsersUpdateWorkingHoursPartialUpdateIsEnabledErrorComponentAttrEnum =
  "is_enabled";
export type UsersUpdateWorkingHoursPartialUpdateIsEnabledErrorComponent = {
  attr: UsersUpdateWorkingHoursPartialUpdateIsEnabledErrorComponentAttrEnum;
  code: Code023Enum;
  detail: string;
};
export type UsersUpdateWorkingHoursPartialUpdateMondayStartTimeErrorComponent =
  {
    attr: Attr5C6Enum;
    code: CodeE7CEnum;
    detail: string;
  };
export type UsersUpdateWorkingHoursPartialUpdateMondayEndTimeErrorComponent = {
  attr: Attr347Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type UsersUpdateWorkingHoursPartialUpdateTuesdayStartTimeErrorComponent =
  {
    attr: Attr984Enum;
    code: CodeE7CEnum;
    detail: string;
  };
export type UsersUpdateWorkingHoursPartialUpdateTuesdayEndTimeErrorComponent = {
  attr: Attr447Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type UsersUpdateWorkingHoursPartialUpdateWednesdayStartTimeErrorComponent =
  {
    attr: Attr2F9Enum;
    code: CodeE7CEnum;
    detail: string;
  };
export type UsersUpdateWorkingHoursPartialUpdateWednesdayEndTimeErrorComponent =
  {
    attr: Attr002Enum;
    code: CodeE7CEnum;
    detail: string;
  };
export type UsersUpdateWorkingHoursPartialUpdateThursdayStartTimeErrorComponent =
  {
    attr: AttrD52Enum;
    code: CodeE7CEnum;
    detail: string;
  };
export type UsersUpdateWorkingHoursPartialUpdateThursdayEndTimeErrorComponent =
  {
    attr: AttrC03Enum;
    code: CodeE7CEnum;
    detail: string;
  };
export type UsersUpdateWorkingHoursPartialUpdateFridayStartTimeErrorComponent =
  {
    attr: AttrD4AEnum;
    code: CodeE7CEnum;
    detail: string;
  };
export type UsersUpdateWorkingHoursPartialUpdateFridayEndTimeErrorComponent = {
  attr: AttrF96Enum;
  code: CodeE7CEnum;
  detail: string;
};
export type UsersUpdateWorkingHoursPartialUpdateSaturdayStartTimeErrorComponent =
  {
    attr: Attr58AEnum;
    code: CodeE7CEnum;
    detail: string;
  };
export type UsersUpdateWorkingHoursPartialUpdateSaturdayEndTimeErrorComponent =
  {
    attr: Attr5A3Enum;
    code: CodeE7CEnum;
    detail: string;
  };
export type UsersUpdateWorkingHoursPartialUpdateSundayStartTimeErrorComponent =
  {
    attr: Attr7F9Enum;
    code: CodeE7CEnum;
    detail: string;
  };
export type UsersUpdateWorkingHoursPartialUpdateSundayEndTimeErrorComponent = {
  attr: AttrF9DEnum;
  code: CodeE7CEnum;
  detail: string;
};
export type UsersUpdateWorkingHoursPartialUpdateError =
  | ({
      attr: "non_field_errors";
    } & UsersUpdateWorkingHoursPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "user";
    } & UsersUpdateWorkingHoursPartialUpdateUserErrorComponent)
  | ({
      attr: "is_enabled";
    } & UsersUpdateWorkingHoursPartialUpdateIsEnabledErrorComponent)
  | ({
      attr: "monday_start_time";
    } & UsersUpdateWorkingHoursPartialUpdateMondayStartTimeErrorComponent)
  | ({
      attr: "monday_end_time";
    } & UsersUpdateWorkingHoursPartialUpdateMondayEndTimeErrorComponent)
  | ({
      attr: "tuesday_start_time";
    } & UsersUpdateWorkingHoursPartialUpdateTuesdayStartTimeErrorComponent)
  | ({
      attr: "tuesday_end_time";
    } & UsersUpdateWorkingHoursPartialUpdateTuesdayEndTimeErrorComponent)
  | ({
      attr: "wednesday_start_time";
    } & UsersUpdateWorkingHoursPartialUpdateWednesdayStartTimeErrorComponent)
  | ({
      attr: "wednesday_end_time";
    } & UsersUpdateWorkingHoursPartialUpdateWednesdayEndTimeErrorComponent)
  | ({
      attr: "thursday_start_time";
    } & UsersUpdateWorkingHoursPartialUpdateThursdayStartTimeErrorComponent)
  | ({
      attr: "thursday_end_time";
    } & UsersUpdateWorkingHoursPartialUpdateThursdayEndTimeErrorComponent)
  | ({
      attr: "friday_start_time";
    } & UsersUpdateWorkingHoursPartialUpdateFridayStartTimeErrorComponent)
  | ({
      attr: "friday_end_time";
    } & UsersUpdateWorkingHoursPartialUpdateFridayEndTimeErrorComponent)
  | ({
      attr: "saturday_start_time";
    } & UsersUpdateWorkingHoursPartialUpdateSaturdayStartTimeErrorComponent)
  | ({
      attr: "saturday_end_time";
    } & UsersUpdateWorkingHoursPartialUpdateSaturdayEndTimeErrorComponent)
  | ({
      attr: "sunday_start_time";
    } & UsersUpdateWorkingHoursPartialUpdateSundayStartTimeErrorComponent)
  | ({
      attr: "sunday_end_time";
    } & UsersUpdateWorkingHoursPartialUpdateSundayEndTimeErrorComponent);
export type UsersUpdateWorkingHoursPartialUpdateValidationError = {
  type: TypeA2CEnum;
  errors: UsersUpdateWorkingHoursPartialUpdateError[];
};
export type UsersUpdateWorkingHoursPartialUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & UsersUpdateWorkingHoursPartialUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PatchedWorkingHours = {
  user?: number;
  is_enabled?: boolean;
  monday_start_time?: string | null;
  monday_end_time?: string | null;
  tuesday_start_time?: string | null;
  tuesday_end_time?: string | null;
  wednesday_start_time?: string | null;
  wednesday_end_time?: string | null;
  thursday_start_time?: string | null;
  thursday_end_time?: string | null;
  friday_start_time?: string | null;
  friday_end_time?: string | null;
  saturday_start_time?: string | null;
  saturday_end_time?: string | null;
  sunday_start_time?: string | null;
  sunday_end_time?: string | null;
};
export type UsersEnterFrontdeskModeCreateErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type FrontdeskMode = {};
export type FrontdeskModeWrite = {
  password: string;
};
export type UsersExitFrontdeskModeCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type UsersExitFrontdeskModeCreatePasswordErrorComponent = {
  attr: Attr080Enum;
  code: CodeA3CEnum;
  detail: string;
};
export type UsersExitFrontdeskModeCreateError =
  | ({
      attr: "non_field_errors";
    } & UsersExitFrontdeskModeCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "password";
    } & UsersExitFrontdeskModeCreatePasswordErrorComponent);
export type UsersExitFrontdeskModeCreateValidationError = {
  type: TypeA2CEnum;
  errors: UsersExitFrontdeskModeCreateError[];
};
export type UsersExitFrontdeskModeCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & UsersExitFrontdeskModeCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type UsersMeRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export type UsersRetrieveByPinCodeCreateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type UsersRetrieveByPinCodeCreatePinCodeErrorComponentAttrEnum =
  "pin_code";
export type UsersRetrieveByPinCodeCreatePinCodeErrorComponent = {
  attr: UsersRetrieveByPinCodeCreatePinCodeErrorComponentAttrEnum;
  code: CodeD7AEnum;
  detail: string;
};
export type UsersRetrieveByPinCodeCreateError =
  | ({
      attr: "non_field_errors";
    } & UsersRetrieveByPinCodeCreateNonFieldErrorsErrorComponent)
  | ({
      attr: "pin_code";
    } & UsersRetrieveByPinCodeCreatePinCodeErrorComponent);
export type UsersRetrieveByPinCodeCreateValidationError = {
  type: TypeA2CEnum;
  errors: UsersRetrieveByPinCodeCreateError[];
};
export type UsersRetrieveByPinCodeCreateErrorResponse400 =
  | ({
      type: "validation_error";
    } & UsersRetrieveByPinCodeCreateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type PinCode = {
  pin_code: string;
};
export type UsersUpdateProfileUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type UsersUpdateProfileUpdateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code238Enum;
  detail: string;
};
export type UsersUpdateProfileUpdateEmailErrorComponent = {
  attr: Attr307Enum;
  code: Code6AeEnum;
  detail: string;
};
export type UsersUpdateProfileUpdatePhoneErrorComponent = {
  attr: AttrB8DEnum;
  code: CodeF87Enum;
  detail: string;
};
export type UsersUpdateProfileUpdateProfilePhotoErrorComponent = {
  attr: AttrC70Enum;
  code: Code40EEnum;
  detail: string;
};
export type UsersUpdateProfileUpdateLanguageErrorComponent = {
  attr: Attr6F0Enum;
  code: Code3F2Enum;
  detail: string;
};
export type UsersUpdateProfileUpdateBirthdayErrorComponent = {
  attr: AttrDe0Enum;
  code: Code0DbEnum;
  detail: string;
};
export type UsersUpdateProfileUpdateAssignedToErrorComponent = {
  attr: Attr35DEnum;
  code: Code948Enum;
  detail: string;
};
export type UsersUpdateProfileUpdateReferredByErrorComponent = {
  attr: Attr43DEnum;
  code: Code3F2Enum;
  detail: string;
};
export type UsersUpdateProfileUpdateReferrerErrorComponent = {
  attr: Attr1FbEnum;
  code: Code948Enum;
  detail: string;
};
export type UsersUpdateProfileUpdateStreetAddressErrorComponent = {
  attr: Attr8B7Enum;
  code: Code548Enum;
  detail: string;
};
export type UsersUpdateProfileUpdateCityErrorComponent = {
  attr: Attr001Enum;
  code: Code548Enum;
  detail: string;
};
export type UsersUpdateProfileUpdateStateErrorComponent = {
  attr: AttrAbdEnum;
  code: Code548Enum;
  detail: string;
};
export type UsersUpdateProfileUpdatePostalCodeErrorComponent = {
  attr: Attr1DeEnum;
  code: Code548Enum;
  detail: string;
};
export type UsersUpdateProfileUpdateCountryErrorComponent = {
  attr: Attr476Enum;
  code: Code4EfEnum;
  detail: string;
};
export type UsersUpdateProfileUpdateGuardianNameErrorComponent = {
  attr: Attr1D4Enum;
  code: Code548Enum;
  detail: string;
};
export type UsersUpdateProfileUpdateGuardianEmailErrorComponent = {
  attr: AttrA55Enum;
  code: Code548Enum;
  detail: string;
};
export type UsersUpdateProfileUpdateGuardianPhoneErrorComponent = {
  attr: Attr933Enum;
  code: CodeF87Enum;
  detail: string;
};
export type UsersUpdateProfileUpdateGroupsErrorComponent = {
  attr: Attr5B3Enum;
  code: Code2DeEnum;
  detail: string;
};
export type UsersUpdateProfileUpdateError =
  | ({
      attr: "non_field_errors";
    } & UsersUpdateProfileUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & UsersUpdateProfileUpdateNameErrorComponent)
  | ({
      attr: "email";
    } & UsersUpdateProfileUpdateEmailErrorComponent)
  | ({
      attr: "phone";
    } & UsersUpdateProfileUpdatePhoneErrorComponent)
  | ({
      attr: "profile_photo";
    } & UsersUpdateProfileUpdateProfilePhotoErrorComponent)
  | ({
      attr: "language";
    } & UsersUpdateProfileUpdateLanguageErrorComponent)
  | ({
      attr: "birthday";
    } & UsersUpdateProfileUpdateBirthdayErrorComponent)
  | ({
      attr: "assigned_to";
    } & UsersUpdateProfileUpdateAssignedToErrorComponent)
  | ({
      attr: "referred_by";
    } & UsersUpdateProfileUpdateReferredByErrorComponent)
  | ({
      attr: "referrer";
    } & UsersUpdateProfileUpdateReferrerErrorComponent)
  | ({
      attr: "street_address";
    } & UsersUpdateProfileUpdateStreetAddressErrorComponent)
  | ({
      attr: "city";
    } & UsersUpdateProfileUpdateCityErrorComponent)
  | ({
      attr: "state";
    } & UsersUpdateProfileUpdateStateErrorComponent)
  | ({
      attr: "postal_code";
    } & UsersUpdateProfileUpdatePostalCodeErrorComponent)
  | ({
      attr: "country";
    } & UsersUpdateProfileUpdateCountryErrorComponent)
  | ({
      attr: "guardian_name";
    } & UsersUpdateProfileUpdateGuardianNameErrorComponent)
  | ({
      attr: "guardian_email";
    } & UsersUpdateProfileUpdateGuardianEmailErrorComponent)
  | ({
      attr: "guardian_phone";
    } & UsersUpdateProfileUpdateGuardianPhoneErrorComponent)
  | ({
      attr: "groups";
    } & UsersUpdateProfileUpdateGroupsErrorComponent);
export type UsersUpdateProfileUpdateValidationError = {
  type: TypeA2CEnum;
  errors: UsersUpdateProfileUpdateError[];
};
export type UsersUpdateProfileUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & UsersUpdateProfileUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type UsersUpdateProfilePartialUpdateNonFieldErrorsErrorComponent = {
  attr: AttrD81Enum;
  code: Code023Enum;
  detail: string;
};
export type UsersUpdateProfilePartialUpdateNameErrorComponent = {
  attr: Attr5EbEnum;
  code: Code238Enum;
  detail: string;
};
export type UsersUpdateProfilePartialUpdateEmailErrorComponent = {
  attr: Attr307Enum;
  code: Code6AeEnum;
  detail: string;
};
export type UsersUpdateProfilePartialUpdatePhoneErrorComponent = {
  attr: AttrB8DEnum;
  code: CodeF87Enum;
  detail: string;
};
export type UsersUpdateProfilePartialUpdateProfilePhotoErrorComponent = {
  attr: AttrC70Enum;
  code: Code40EEnum;
  detail: string;
};
export type UsersUpdateProfilePartialUpdateLanguageErrorComponent = {
  attr: Attr6F0Enum;
  code: Code3F2Enum;
  detail: string;
};
export type UsersUpdateProfilePartialUpdateBirthdayErrorComponent = {
  attr: AttrDe0Enum;
  code: Code0DbEnum;
  detail: string;
};
export type UsersUpdateProfilePartialUpdateAssignedToErrorComponent = {
  attr: Attr35DEnum;
  code: Code948Enum;
  detail: string;
};
export type UsersUpdateProfilePartialUpdateReferredByErrorComponent = {
  attr: Attr43DEnum;
  code: Code3F2Enum;
  detail: string;
};
export type UsersUpdateProfilePartialUpdateReferrerErrorComponent = {
  attr: Attr1FbEnum;
  code: Code948Enum;
  detail: string;
};
export type UsersUpdateProfilePartialUpdateStreetAddressErrorComponent = {
  attr: Attr8B7Enum;
  code: Code548Enum;
  detail: string;
};
export type UsersUpdateProfilePartialUpdateCityErrorComponent = {
  attr: Attr001Enum;
  code: Code548Enum;
  detail: string;
};
export type UsersUpdateProfilePartialUpdateStateErrorComponent = {
  attr: AttrAbdEnum;
  code: Code548Enum;
  detail: string;
};
export type UsersUpdateProfilePartialUpdatePostalCodeErrorComponent = {
  attr: Attr1DeEnum;
  code: Code548Enum;
  detail: string;
};
export type UsersUpdateProfilePartialUpdateCountryErrorComponent = {
  attr: Attr476Enum;
  code: Code4EfEnum;
  detail: string;
};
export type UsersUpdateProfilePartialUpdateGuardianNameErrorComponent = {
  attr: Attr1D4Enum;
  code: Code548Enum;
  detail: string;
};
export type UsersUpdateProfilePartialUpdateGuardianEmailErrorComponent = {
  attr: AttrA55Enum;
  code: Code548Enum;
  detail: string;
};
export type UsersUpdateProfilePartialUpdateGuardianPhoneErrorComponent = {
  attr: Attr933Enum;
  code: CodeF87Enum;
  detail: string;
};
export type UsersUpdateProfilePartialUpdateGroupsErrorComponent = {
  attr: Attr5B3Enum;
  code: Code2DeEnum;
  detail: string;
};
export type UsersUpdateProfilePartialUpdateError =
  | ({
      attr: "non_field_errors";
    } & UsersUpdateProfilePartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: "name";
    } & UsersUpdateProfilePartialUpdateNameErrorComponent)
  | ({
      attr: "email";
    } & UsersUpdateProfilePartialUpdateEmailErrorComponent)
  | ({
      attr: "phone";
    } & UsersUpdateProfilePartialUpdatePhoneErrorComponent)
  | ({
      attr: "profile_photo";
    } & UsersUpdateProfilePartialUpdateProfilePhotoErrorComponent)
  | ({
      attr: "language";
    } & UsersUpdateProfilePartialUpdateLanguageErrorComponent)
  | ({
      attr: "birthday";
    } & UsersUpdateProfilePartialUpdateBirthdayErrorComponent)
  | ({
      attr: "assigned_to";
    } & UsersUpdateProfilePartialUpdateAssignedToErrorComponent)
  | ({
      attr: "referred_by";
    } & UsersUpdateProfilePartialUpdateReferredByErrorComponent)
  | ({
      attr: "referrer";
    } & UsersUpdateProfilePartialUpdateReferrerErrorComponent)
  | ({
      attr: "street_address";
    } & UsersUpdateProfilePartialUpdateStreetAddressErrorComponent)
  | ({
      attr: "city";
    } & UsersUpdateProfilePartialUpdateCityErrorComponent)
  | ({
      attr: "state";
    } & UsersUpdateProfilePartialUpdateStateErrorComponent)
  | ({
      attr: "postal_code";
    } & UsersUpdateProfilePartialUpdatePostalCodeErrorComponent)
  | ({
      attr: "country";
    } & UsersUpdateProfilePartialUpdateCountryErrorComponent)
  | ({
      attr: "guardian_name";
    } & UsersUpdateProfilePartialUpdateGuardianNameErrorComponent)
  | ({
      attr: "guardian_email";
    } & UsersUpdateProfilePartialUpdateGuardianEmailErrorComponent)
  | ({
      attr: "guardian_phone";
    } & UsersUpdateProfilePartialUpdateGuardianPhoneErrorComponent)
  | ({
      attr: "groups";
    } & UsersUpdateProfilePartialUpdateGroupsErrorComponent);
export type UsersUpdateProfilePartialUpdateValidationError = {
  type: TypeA2CEnum;
  errors: UsersUpdateProfilePartialUpdateError[];
};
export type UsersUpdateProfilePartialUpdateErrorResponse400 =
  | ({
      type: "validation_error";
    } & UsersUpdateProfilePartialUpdateValidationError)
  | ({
      type: "client_error";
    } & ParseErrorResponse);
export type WorkingHoursRetrieveErrorResponse400 = {
  type: "client_error";
} & ParseErrorResponse;
export const {
  useAllUsersListQuery,
  useLazyAllUsersListQuery,
  useAllUsersRetrieveQuery,
  useLazyAllUsersRetrieveQuery,
  useAllUsersHistoryListQuery,
  useLazyAllUsersHistoryListQuery,
  useAppointmentPackagesListQuery,
  useLazyAppointmentPackagesListQuery,
  useAppointmentPackagesCreateMutation,
  useAppointmentPackagesRetrieveQuery,
  useLazyAppointmentPackagesRetrieveQuery,
  useAppointmentPackagesUpdateMutation,
  useAppointmentPackagesPartialUpdateMutation,
  useAppointmentPackagesDestroyMutation,
  useAppointmentReservationsListQuery,
  useLazyAppointmentReservationsListQuery,
  useAppointmentReservationsRetrieveQuery,
  useLazyAppointmentReservationsRetrieveQuery,
  useAppointmentReservationsCancelReservationDestroyMutation,
  useAppointmentReservationsEditReservationCreateMutation,
  useAppointmentReservationsAvailableTimesRetrieveQuery,
  useLazyAppointmentReservationsAvailableTimesRetrieveQuery,
  useAppointmentReservationsMyReservationsListQuery,
  useLazyAppointmentReservationsMyReservationsListQuery,
  useAppointmentReservationsReserveAppointmentCreateMutation,
  useAppointmentTypesListQuery,
  useLazyAppointmentTypesListQuery,
  useAppointmentTypesCreateMutation,
  useAppointmentTypesRetrieveQuery,
  useLazyAppointmentTypesRetrieveQuery,
  useAppointmentTypesUpdateMutation,
  useAppointmentTypesPartialUpdateMutation,
  useAppointmentTypesDestroyMutation,
  useAppointmentTypesStaffListQuery,
  useLazyAppointmentTypesStaffListQuery,
  useAttendancesListQuery,
  useLazyAttendancesListQuery,
  useAttendancesRetrieveQuery,
  useLazyAttendancesRetrieveQuery,
  useAttendancesAggregatedUserVisitsRetrieveQuery,
  useLazyAttendancesAggregatedUserVisitsRetrieveQuery,
  useAttendancesAggregatedUserVisitsListListQuery,
  useLazyAttendancesAggregatedUserVisitsListListQuery,
  useAttendancesMyAttendancesListQuery,
  useLazyAttendancesMyAttendancesListQuery,
  useAttendancesPerformCheckInCreateMutation,
  useAttendancesPerformCheckInWithReservationCreateMutation,
  useAttendancesPerformCheckOutCreateMutation,
  useAttendancesPerformCheckOutWithReservationCreateMutation,
  useAuthTokenCreateMutation,
  useAuthDeleteTenantCreateMutation,
  useAuthLoginCreateMutation,
  useAuthPasswordChangeCreateMutation,
  useAuthPasswordResetCreateMutation,
  useAuthPasswordResetConfirmCreateMutation,
  useBusinessSettingsRetrieveQuery,
  useLazyBusinessSettingsRetrieveQuery,
  useBusinessSettingsUpdateMutation,
  useBusinessSettingsPartialUpdateMutation,
  useBusinessSettingsHistoryListQuery,
  useLazyBusinessSettingsHistoryListQuery,
  useBusinessSettingsHistoryRetrieveQuery,
  useLazyBusinessSettingsHistoryRetrieveQuery,
  useCalendarRetrieveQuery,
  useLazyCalendarRetrieveQuery,
  useClassReservationsListQuery,
  useLazyClassReservationsListQuery,
  useClassReservationsRetrieveQuery,
  useLazyClassReservationsRetrieveQuery,
  useClassReservationsCancelReservationDestroyMutation,
  useClassReservationsReserveClassCreateMutation,
  useClassTypesListQuery,
  useLazyClassTypesListQuery,
  useClassTypesCreateMutation,
  useClassTypesRetrieveQuery,
  useLazyClassTypesRetrieveQuery,
  useClassTypesUpdateMutation,
  useClassTypesPartialUpdateMutation,
  useClassTypesDestroyMutation,
  useClassesListQuery,
  useLazyClassesListQuery,
  useClassesRetrieveQuery,
  useLazyClassesRetrieveQuery,
  useClassesUpdateMutation,
  useClassesPartialUpdateMutation,
  useClassesDestroyMutation,
  useClassesAttendeesListQuery,
  useLazyClassesAttendeesListQuery,
  useClassesCreateClassesCreateMutation,
  useCommunicationHistoryListQuery,
  useLazyCommunicationHistoryListQuery,
  useCommunicationHistoryRetrieveQuery,
  useLazyCommunicationHistoryRetrieveQuery,
  useCommunicationHistoryMarkAsReadCreateMutation,
  useCommunicationHistoryMyCommunicationHistoryListQuery,
  useLazyCommunicationHistoryMyCommunicationHistoryListQuery,
  useConnectionTestRetrieveQuery,
  useLazyConnectionTestRetrieveQuery,
  useCountriesRetrieveQuery,
  useLazyCountriesRetrieveQuery,
  useCreditPackagesListQuery,
  useLazyCreditPackagesListQuery,
  useCreditPackagesRetrieveQuery,
  useLazyCreditPackagesRetrieveQuery,
  useCreditPackagesAddByAppointmentPackageCardCreateMutation,
  useCreditPackagesAddByAppointmentPackageCashCreateMutation,
  useCreditPackagesAddCardCreateMutation,
  useCreditPackagesAddCashCreateMutation,
  useCreditPackagesBuyByAppointmentPackageCreateMutation,
  useCreditPackagesMyCreditPackagesListQuery,
  useLazyCreditPackagesMyCreditPackagesListQuery,
  useCreditPackagesRemainingCreditsRetrieveQuery,
  useLazyCreditPackagesRemainingCreditsRetrieveQuery,
  useCurrenciesRetrieveQuery,
  useLazyCurrenciesRetrieveQuery,
  useDigitalDocumentVersionsListQuery,
  useLazyDigitalDocumentVersionsListQuery,
  useDigitalDocumentVersionsRetrieveQuery,
  useLazyDigitalDocumentVersionsRetrieveQuery,
  useDigitalDocumentsListQuery,
  useLazyDigitalDocumentsListQuery,
  useDigitalDocumentsCreateMutation,
  useDigitalDocumentsRetrieveQuery,
  useLazyDigitalDocumentsRetrieveQuery,
  useDigitalDocumentsUpdateMutation,
  useDigitalDocumentsPartialUpdateMutation,
  useDigitalDocumentsDestroyMutation,
  useDigitalDocumentsHistoryListQuery,
  useLazyDigitalDocumentsHistoryListQuery,
  useDigitalDocumentsPublishPartialUpdateMutation,
  useDigitalDocumentsRequiredSignaturesListQuery,
  useLazyDigitalDocumentsRequiredSignaturesListQuery,
  useDigitalDocumentsAllowedVariablesRetrieveQuery,
  useLazyDigitalDocumentsAllowedVariablesRetrieveQuery,
  useDigitalDocumentsRequiredRetrieveQuery,
  useLazyDigitalDocumentsRequiredRetrieveQuery,
  useDigitalDocumentsSignCreateMutation,
  useDiscountsListQuery,
  useLazyDiscountsListQuery,
  useDiscountsCreateMutation,
  useDiscountsRetrieveQuery,
  useLazyDiscountsRetrieveQuery,
  useDiscountsUpdateMutation,
  useDiscountsPartialUpdateMutation,
  useDiscountsDestroyMutation,
  useDiscountsHistoryListQuery,
  useLazyDiscountsHistoryListQuery,
  useEmailTriggersListQuery,
  useLazyEmailTriggersListQuery,
  useEmailTriggersRetrieveQuery,
  useLazyEmailTriggersRetrieveQuery,
  useEmailTriggersUpdateMutation,
  useEmailTriggersPartialUpdateMutation,
  useEventReservationsListQuery,
  useLazyEventReservationsListQuery,
  useEventReservationsRetrieveQuery,
  useLazyEventReservationsRetrieveQuery,
  useEventReservationsCancelReservationDestroyMutation,
  useEventReservationsMyReservationsListQuery,
  useLazyEventReservationsMyReservationsListQuery,
  useEventsListQuery,
  useLazyEventsListQuery,
  useEventsCreateMutation,
  useEventsRetrieveQuery,
  useLazyEventsRetrieveQuery,
  useEventsUpdateMutation,
  useEventsPartialUpdateMutation,
  useEventsDestroyMutation,
  useEventsAttendeesListQuery,
  useLazyEventsAttendeesListQuery,
  useGroupsListQuery,
  useLazyGroupsListQuery,
  useGroupsRetrieveQuery,
  useLazyGroupsRetrieveQuery,
  useMembersListQuery,
  useLazyMembersListQuery,
  useMembersCreateMutation,
  useMembersRetrieveQuery,
  useLazyMembersRetrieveQuery,
  useMembersUpdateMutation,
  useMembersPartialUpdateMutation,
  useMembersDestroyMutation,
  useMembersDocumentsRequiredListQuery,
  useLazyMembersDocumentsRequiredListQuery,
  useMembersHistoryListQuery,
  useLazyMembersHistoryListQuery,
  useMembersAggregatedMembersCountsRetrieveQuery,
  useLazyMembersAggregatedMembersCountsRetrieveQuery,
  useMembersAggregatedMembersCountsListListQuery,
  useLazyMembersAggregatedMembersCountsListListQuery,
  useMembersAssignMembershipCreateMutation,
  useMembersDownloadCsvRetrieveQuery,
  useLazyMembersDownloadCsvRetrieveQuery,
  useMembersDownloadTemplateRetrieveQuery,
  useLazyMembersDownloadTemplateRetrieveQuery,
  useMembersImportUsersCreateMutation,
  useMembersMyNotificationPreferencesRetrieveQuery,
  useLazyMembersMyNotificationPreferencesRetrieveQuery,
  useMembersMyNotificationPreferencesPartialUpdateMutation,
  useMembersReferralSourcesPercentageRetrieveQuery,
  useLazyMembersReferralSourcesPercentageRetrieveQuery,
  useMembersSendEmailToAllCreateMutation,
  useMembersSendNotificationCreateMutation,
  useMembersSendWelcomeEmailCreateMutation,
  useMembershipPlansListQuery,
  useLazyMembershipPlansListQuery,
  useMembershipPlansCreateMutation,
  useMembershipPlansRetrieveQuery,
  useLazyMembershipPlansRetrieveQuery,
  useMembershipPlansUpdateMutation,
  useMembershipPlansPartialUpdateMutation,
  useMembershipPlansDestroyMutation,
  useMembershipPlansArchiveRetrieveQuery,
  useLazyMembershipPlansArchiveRetrieveQuery,
  useMembershipPlansDownloadMembersCsvRetrieveQuery,
  useLazyMembershipPlansDownloadMembersCsvRetrieveQuery,
  useMembershipPlansHistoryListQuery,
  useLazyMembershipPlansHistoryListQuery,
  useMembershipPlansSendEmailToAllCreateMutation,
  useMembershipPlansUnarchiveRetrieveQuery,
  useLazyMembershipPlansUnarchiveRetrieveQuery,
  useMembershipPlansArchivedListQuery,
  useLazyMembershipPlansArchivedListQuery,
  usePaymentsAddressRetrieveQuery,
  useLazyPaymentsAddressRetrieveQuery,
  usePaymentsAggregatedRevenueRetrieveQuery,
  useLazyPaymentsAggregatedRevenueRetrieveQuery,
  usePaymentsAggregatedRevenueListListQuery,
  useLazyPaymentsAggregatedRevenueListListQuery,
  usePaymentsInvoiceItemTaxesListQuery,
  useLazyPaymentsInvoiceItemTaxesListQuery,
  usePaymentsInvoiceItemTaxesRetrieveQuery,
  useLazyPaymentsInvoiceItemTaxesRetrieveQuery,
  usePaymentsInvoiceItemsListQuery,
  useLazyPaymentsInvoiceItemsListQuery,
  usePaymentsInvoiceItemsCreateMutation,
  usePaymentsInvoiceItemsRetrieveQuery,
  useLazyPaymentsInvoiceItemsRetrieveQuery,
  usePaymentsInvoiceItemsDestroyMutation,
  usePaymentsInvoicesListQuery,
  useLazyPaymentsInvoicesListQuery,
  usePaymentsInvoicesCreateMutation,
  usePaymentsInvoicesRetrieveQuery,
  useLazyPaymentsInvoicesRetrieveQuery,
  usePaymentsInvoicesDestroyMutation,
  usePaymentsInvoicesForgiveInvoicePartialUpdateMutation,
  usePaymentsInvoicesGeneratePdfRetrieveQuery,
  useLazyPaymentsInvoicesGeneratePdfRetrieveQuery,
  usePaymentsInvoicesHistoryListQuery,
  useLazyPaymentsInvoicesHistoryListQuery,
  usePaymentsInvoicesPayCreateMutation,
  usePaymentsInvoicesRefundCreateMutation,
  usePaymentsInvoicesRefundCardCreateMutation,
  usePaymentsInvoicesUpdateShippingAddressPartialUpdateMutation,
  usePaymentsInvoicesMyInvoicesListQuery,
  useLazyPaymentsInvoicesMyInvoicesListQuery,
  usePaymentsProductInvoiceItemsListQuery,
  useLazyPaymentsProductInvoiceItemsListQuery,
  usePaymentsProductInvoiceItemsCreateMutation,
  usePaymentsProductInvoiceItemsRetrieveQuery,
  useLazyPaymentsProductInvoiceItemsRetrieveQuery,
  usePaymentsProductInvoiceItemsDestroyMutation,
  usePaymentsProductRevenuePerIntervalRetrieveQuery,
  useLazyPaymentsProductRevenuePerIntervalRetrieveQuery,
  usePaymentsProductSalesCountPerIntervalRetrieveQuery,
  useLazyPaymentsProductSalesCountPerIntervalRetrieveQuery,
  usePaymentsTransactionsListQuery,
  useLazyPaymentsTransactionsListQuery,
  usePaymentsTransactionsRetrieveQuery,
  useLazyPaymentsTransactionsRetrieveQuery,
  usePaymentsTransactionsMyTransactionsListQuery,
  useLazyPaymentsTransactionsMyTransactionsListQuery,
  usePaymentsTransactionsReportRetrieveQuery,
  useLazyPaymentsTransactionsReportRetrieveQuery,
  usePolicyDocumentsListQuery,
  useLazyPolicyDocumentsListQuery,
  usePolicyDocumentsCreateMutation,
  usePolicyDocumentsRetrieveQuery,
  useLazyPolicyDocumentsRetrieveQuery,
  usePolicyDocumentsUpdateMutation,
  usePolicyDocumentsPartialUpdateMutation,
  usePolicyDocumentsDestroyMutation,
  useProductCategoriesListQuery,
  useLazyProductCategoriesListQuery,
  useProductCategoriesCreateMutation,
  useProductCategoriesRetrieveQuery,
  useLazyProductCategoriesRetrieveQuery,
  useProductCategoriesUpdateMutation,
  useProductCategoriesPartialUpdateMutation,
  useProductCategoriesDestroyMutation,
  useProductImagesListQuery,
  useLazyProductImagesListQuery,
  useProductImagesCreateMutation,
  useProductImagesRetrieveQuery,
  useLazyProductImagesRetrieveQuery,
  useProductImagesUpdateMutation,
  useProductImagesPartialUpdateMutation,
  useProductImagesDestroyMutation,
  useProductOptionsListQuery,
  useLazyProductOptionsListQuery,
  useProductOptionsCreateMutation,
  useProductOptionsRetrieveQuery,
  useLazyProductOptionsRetrieveQuery,
  useProductOptionsUpdateMutation,
  useProductOptionsPartialUpdateMutation,
  useProductOptionsDestroyMutation,
  useProductsListQuery,
  useLazyProductsListQuery,
  useProductsCreateMutation,
  useProductsRetrieveQuery,
  useLazyProductsRetrieveQuery,
  useProductsUpdateMutation,
  useProductsPartialUpdateMutation,
  useProductsDestroyMutation,
  useProductsHistoryListQuery,
  useLazyProductsHistoryListQuery,
  useReservationsListQuery,
  useLazyReservationsListQuery,
  useReservationsRetrieveQuery,
  useLazyReservationsRetrieveQuery,
  useReservationsCancelReservationDestroyMutation,
  useReservationsMyReservationsListQuery,
  useLazyReservationsMyReservationsListQuery,
  useReserveEventCreateMutation,
  useSchemaRetrieveQuery,
  useLazySchemaRetrieveQuery,
  useSettingsRegisterPushTokenCreateMutation,
  useStripePaymentsCheckoutSuccessCreateMutation,
  useStripePaymentsCreateCustomerPortalCreateMutation,
  useStripePaymentsCreateFinegymCheckoutSessionCreateMutation,
  useStripePaymentsCreateInvoiceCheckoutSessionCreateMutation,
  useStripePaymentsCreateInvoicePaymentIntentCreateMutation,
  useStripePaymentsCreateStripeAccountCreateMutation,
  useStripePaymentsCurrentAccountRetrieveQuery,
  useLazyStripePaymentsCurrentAccountRetrieveQuery,
  useStripePaymentsCustomerRetrieveQuery,
  useLazyStripePaymentsCustomerRetrieveQuery,
  useStripePaymentsInvoicesListQuery,
  useLazyStripePaymentsInvoicesListQuery,
  useStripePaymentsInvoicesRetrieveQuery,
  useLazyStripePaymentsInvoicesRetrieveQuery,
  useStripePaymentsInvoicesUpcomingRetrieveQuery,
  useLazyStripePaymentsInvoicesUpcomingRetrieveQuery,
  useStripePaymentsPlansListQuery,
  useLazyStripePaymentsPlansListQuery,
  useStripePaymentsPlansRetrieveQuery,
  useLazyStripePaymentsPlansRetrieveQuery,
  useStripePaymentsPricesListQuery,
  useLazyStripePaymentsPricesListQuery,
  useStripePaymentsPricesRetrieveQuery,
  useLazyStripePaymentsPricesRetrieveQuery,
  useStripePaymentsProductsListQuery,
  useLazyStripePaymentsProductsListQuery,
  useStripePaymentsProductsRetrieveQuery,
  useLazyStripePaymentsProductsRetrieveQuery,
  useStripePaymentsSessionRetrieveCreateMutation,
  useStripePaymentsSubscriptionRetrieveQuery,
  useLazyStripePaymentsSubscriptionRetrieveQuery,
  useTaxRatesListQuery,
  useLazyTaxRatesListQuery,
  useTaxRatesCreateMutation,
  useTaxRatesRetrieveQuery,
  useLazyTaxRatesRetrieveQuery,
  useTaxRatesUpdateMutation,
  useTaxRatesPartialUpdateMutation,
  useTaxRatesArchiveCreateMutation,
  useTaxRatesUnarchiveCreateMutation,
  useTimezonesRetrieveQuery,
  useLazyTimezonesRetrieveQuery,
  useUserDocumentSignaturesListQuery,
  useLazyUserDocumentSignaturesListQuery,
  useUserDocumentSignaturesRetrieveQuery,
  useLazyUserDocumentSignaturesRetrieveQuery,
  useUserDocumentSignaturesGenerateSignedDocumentRetrieveQuery,
  useLazyUserDocumentSignaturesGenerateSignedDocumentRetrieveQuery,
  useUserDocumentSignaturesAdminSignCreateMutation,
  useUserMembershipAttendancesListQuery,
  useLazyUserMembershipAttendancesListQuery,
  useUserMembershipAttendancesRetrieveQuery,
  useLazyUserMembershipAttendancesRetrieveQuery,
  useUserMembershipsListQuery,
  useLazyUserMembershipsListQuery,
  useUserMembershipsRetrieveQuery,
  useLazyUserMembershipsRetrieveQuery,
  useUserMembershipsArchiveUpdateMutation,
  useUserMembershipsChangeDiscountPartialUpdateMutation,
  useUserMembershipsChangeNextBillDatePartialUpdateMutation,
  useUserMembershipsChangePaymentTypePartialUpdateMutation,
  useUserMembershipsAddMembershipCardCreateMutation,
  useUserMembershipsAddMembershipCashCreateMutation,
  useUserMembershipsBuyMembershipCreateMutation,
  useUserMembershipsMyMembershipsListQuery,
  useLazyUserMembershipsMyMembershipsListQuery,
  useUserNoteTagsListQuery,
  useLazyUserNoteTagsListQuery,
  useUserNoteTagsCreateMutation,
  useUserNoteTagsRetrieveQuery,
  useLazyUserNoteTagsRetrieveQuery,
  useUserNoteTagsUpdateMutation,
  useUserNoteTagsPartialUpdateMutation,
  useUserNoteTagsDestroyMutation,
  useUserNotesListQuery,
  useLazyUserNotesListQuery,
  useUserNotesCreateMutation,
  useUserNotesRetrieveQuery,
  useLazyUserNotesRetrieveQuery,
  useUserNotesUpdateMutation,
  useUserNotesPartialUpdateMutation,
  useUserNotesDestroyMutation,
  useUsersListQuery,
  useLazyUsersListQuery,
  useUsersCreateMutation,
  useUsersRetrieveQuery,
  useLazyUsersRetrieveQuery,
  useUsersUpdateMutation,
  useUsersPartialUpdateMutation,
  useUsersDestroyMutation,
  useUsersAssignedUsersListQuery,
  useLazyUsersAssignedUsersListQuery,
  useUsersHistoryListQuery,
  useLazyUsersHistoryListQuery,
  useUsersUpdateWorkingHoursPartialUpdateMutation,
  useUsersEnterFrontdeskModeCreateMutation,
  useUsersExitFrontdeskModeCreateMutation,
  useUsersMeRetrieveQuery,
  useLazyUsersMeRetrieveQuery,
  useUsersRetrieveByPinCodeCreateMutation,
  useUsersUpdateProfileUpdateMutation,
  useUsersUpdateProfilePartialUpdateMutation,
  useWorkingHoursRetrieveQuery,
  useLazyWorkingHoursRetrieveQuery,
} = injectedRtkApi;
