import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import WithSuspenseLoadingSpinner from '../components/hoc/WithSuspenseLoadingSpinner';
import PermissionGuard from '@/guards/PermissionGuard';
import { GroupNames } from '@/components/hoc/UserGroupContext';

const AggregatedRevenuePage = lazy(
  () => import('../features/dashboard/reports/aggregated-revenue'),
);
const AggregatedMembersCountPage = lazy(
  () => import('../features/dashboard/reports/aggregated-members-count'),
);

const AggregateUserVisitsPage = lazy(
  () => import('../features/dashboard/reports/aggregated-user-visits'),
);

const ReferalSourcesPage = lazy(
  () => import('../features/dashboard/reports/referal-sources'),
);

export const dashboardReportsRoutes = (
  <Route
    path="reports"
    element={<PermissionGuard permissions={[GroupNames.Reports]} />}
  >
    <Route index element={<Navigate to="aggregated-revenue" replace />} />
    <Route
      path="aggregated-revenue"
      element={
        <WithSuspenseLoadingSpinner>
          <AggregatedRevenuePage />
        </WithSuspenseLoadingSpinner>
      }
    />
    <Route
      path="aggregated-members-count"
      element={
        <WithSuspenseLoadingSpinner>
          <AggregatedMembersCountPage />
        </WithSuspenseLoadingSpinner>
      }
    />
    <Route
      path="aggregated-user-visits"
      element={
        <WithSuspenseLoadingSpinner>
          <AggregateUserVisitsPage />
        </WithSuspenseLoadingSpinner>
      }
    />
    <Route
      path="referal-sources"
      element={
        <WithSuspenseLoadingSpinner>
          <ReferalSourcesPage />
        </WithSuspenseLoadingSpinner>
      }
    />
  </Route>
);
