import { PropsWithChildren, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  GroupNames,
  useHasPermission,
} from '@/components/hoc/UserGroupContext';

/**
 * A guard component that checks if the user has the required permissions to access a page.
 * If the user does not have the required permissions, it either redirects to the home page
 * or shows an error toast message based on the `noRedirect` prop.
 *
 * @param {Object} props - The props object.
 * @param {GroupNames[]} props.permissions - The list of required permissions.
 * @param {boolean} [props.noRedirect=false] - If true, does not redirect the user when they lack permissions.
 * @param {boolean} [props.ownerRequired=false] - If true, checks if the user has owner permissions.
 * @param {React.ReactNode} props.children - The child components to render if the user has the required permissions.
 *
 * @returns {React.ReactNode | null} The child components if the user has the required permissions, otherwise null or a redirect.
 */
export default function PermissionGuard({
  permissions,
  children,
  noRedirect = false,
  ownerRequired = false,
}: PropsWithChildren<{
  permissions: GroupNames[];
  noRedirect?: boolean;
  ownerRequired?: boolean;
}>) {
  const hasMemberPermission = useHasPermission(
    ownerRequired ? 'ownerRequired' : permissions,
  );

  useEffect(() => {
    if (!hasMemberPermission && !noRedirect) {
      toast.error('You do not have permission to access this page.', {
        toastId: 'permission-error',
      });
    }
  }, [hasMemberPermission, noRedirect]);

  // If the user does not have the required permissions
  if (!hasMemberPermission) {
    // If noRedirect is true, return null to prevent navigation
    if (noRedirect) {
      return null;
    }
    // Otherwise, navigate to the home page
    return <Navigate to="/" replace />;
  }

  return children ?? <Outlet />;
}
