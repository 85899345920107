import { lazy } from 'react';
import { Route } from 'react-router-dom';
import WithSuspenseLoadingSpinner from '../components/hoc/WithSuspenseLoadingSpinner';
import PermissionGuard from '@/guards/PermissionGuard';
import { GroupNames } from '@/components/hoc/UserGroupContext';

const InvoiceListPage = lazy(() => import('../features/dashboard/invoices'));
const InvoiceDetail = lazy(
  () => import('../features/dashboard/invoice-detail'),
);
const TransactionListPage = lazy(
  () => import('../features/dashboard/transactions'),
);

export const dashboardPaymentsRoutes = (
  <Route element={<PermissionGuard permissions={[GroupNames.Payments]} />}>
    <Route path="invoices">
      <Route
        index
        element={
          <WithSuspenseLoadingSpinner>
            <InvoiceListPage />
          </WithSuspenseLoadingSpinner>
        }
      />
      <Route
        path=":invoiceId"
        element={
          <WithSuspenseLoadingSpinner>
            <InvoiceDetail />
          </WithSuspenseLoadingSpinner>
        }
      />
    </Route>
    <Route path="transactions">
      <Route
        index
        element={
          <WithSuspenseLoadingSpinner>
            <TransactionListPage />
          </WithSuspenseLoadingSpinner>
        }
      />
    </Route>
  </Route>
);
