import { lazy } from 'react';
import { Route, Navigate } from 'react-router-dom';
import WithSuspenseLoadingSpinner from '../components/hoc/WithSuspenseLoadingSpinner';
import PermissionGuard from '@/guards/PermissionGuard';
import { GroupNames } from '@/components/hoc/UserGroupContext';

const StaffListPage = lazy(() => import('../features/dashboard/staff'));
const StaffProfile = lazy(
  () => import('../features/dashboard/staff/edit/profile'),
);
const StaffAssignedMembers = lazy(
  () => import('../features/dashboard/staff/edit/assigned-members'),
);
const StaffEdit = lazy(() => import('../features/dashboard/staff/edit'));
const ManageStaffNotes = lazy(
  () => import('../features/dashboard/staff/edit/notes/ManageStaffNotes'),
);
const ManageStaffPermissions = lazy(
  () =>
    import(
      '../features/dashboard/staff/edit/permissions/ManageStaffPermissions'
    ),
);

export const dashboardStaffRoutes = (
  <Route
    path="staff"
    element={<PermissionGuard permissions={[GroupNames.Staff]} />}
  >
    <Route
      index
      element={
        <WithSuspenseLoadingSpinner>
          <StaffListPage />
        </WithSuspenseLoadingSpinner>
      }
    />
    <Route
      path=":staffId"
      element={
        <WithSuspenseLoadingSpinner>
          <StaffEdit />
        </WithSuspenseLoadingSpinner>
      }
    >
      <Route index element={<Navigate to="profile" replace />} />
      <Route
        path="profile"
        element={
          <WithSuspenseLoadingSpinner>
            <StaffProfile />
          </WithSuspenseLoadingSpinner>
        }
      />
      <Route
        path="assigned-members"
        element={
          <WithSuspenseLoadingSpinner>
            <StaffAssignedMembers />
          </WithSuspenseLoadingSpinner>
        }
      />
      <Route
        path="notes"
        element={
          <WithSuspenseLoadingSpinner>
            <ManageStaffNotes />
          </WithSuspenseLoadingSpinner>
        }
      />
      <Route
        path="permissions"
        element={
          <PermissionGuard permissions={[GroupNames.Staff]} ownerRequired>
            <WithSuspenseLoadingSpinner>
              <ManageStaffPermissions />
            </WithSuspenseLoadingSpinner>
          </PermissionGuard>
        }
      />
    </Route>
  </Route>
);
