import classNames from 'classnames';
import { Sidebar } from 'flowbite-react';
import {
  HiChartPie,
  HiCog,
  HiShoppingBag,
  HiOutlineShoppingBag,
  HiTag,
  HiUsers,
  HiOutlineDuplicate,
  HiCheckCircle,
  HiCash,
  HiReceiptTax,
  HiInformationCircle,
  HiOutlineTicket,
  HiCalendar,
  HiClipboard,
  HiHome,
  HiCurrencyEuro,
  HiUserGroup,
  HiIdentification,
  HiRefresh,
} from 'react-icons/hi';
import { MdManageAccounts } from 'react-icons/md';
import { useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSidebarContext } from '../context/SidebarContext';
import isSmallScreen from '../helpers/is-small-screen';
import SidebarBusinessInfoCard from './SidebarBusinessInfoCard';
import { GroupNames, useHasPermission } from './hoc/UserGroupContext';
import PermissionGuard from '@/guards/PermissionGuard';

type ExpandableSidebarItem =
  | 'Memberships'
  | 'Appointments'
  | 'Schedule'
  | 'Products'
  | 'Payments'
  | 'Documents & Legal'
  | 'Reports';

function SidebarItemWithPermission({
  path,
  icon,
  label,
  permission,
  isActive,
}: {
  readonly path: string;
  readonly icon?: React.ElementType;
  readonly label: string;
  readonly permission: GroupNames;
  readonly isActive: () => boolean;
}) {
  const navigate = useNavigate();

  const hasPermission = useHasPermission([permission]);

  if (!hasPermission) {
    return null;
  }

  return (
    <Sidebar.Item
      onClick={() => navigate(path)}
      icon={icon}
      className={clsx([
        isActive() ? 'bg-gray-100 dark:bg-gray-700' : '',
        'cursor-pointer',
      ])}
    >
      {label}
    </Sidebar.Item>
  );
}

function SidebarContainer() {
  const { isOpenOnSmallScreens: isSidebarOpenOnSmallScreens } =
    useSidebarContext();
  const { t } = useTranslation();
  const [expandedItem, setExpandedItem] = useState<ExpandableSidebarItem>();
  const { pathname } = useLocation();

  const expandItem = useCallback((val: ExpandableSidebarItem) => {
    setExpandedItem((curr) => (curr === val ? undefined : val));
  }, []);

  return (
    <div
      className={classNames('lg:!block', {
        hidden: !isSidebarOpenOnSmallScreens,
      })}
    >
      <Sidebar
        aria-label="Sidebar with multi-level dropdown example"
        collapsed={isSidebarOpenOnSmallScreens && !isSmallScreen()}
        className="[&>*:first-child]:rounded-none"
      >
        <div className="flex h-full flex-col justify-between">
          <div className="overflow-y-auto">
            <Sidebar.Items>
              <PermissionGuard permissions={[GroupNames.Dashboard]} noRedirect>
                <Sidebar.ItemGroup>
                  <SidebarItemWithPermission
                    path="/dashboard/home"
                    icon={HiHome}
                    label={t('Dashboard')}
                    permission={GroupNames.Dashboard}
                    isActive={() => pathname.startsWith('/dashboard/home')}
                  />
                </Sidebar.ItemGroup>
              </PermissionGuard>
              <PermissionGuard
                permissions={[
                  GroupNames.Members,
                  GroupNames.MembershipPlans,
                  GroupNames.Staff,
                  GroupNames.Migrations,
                ]}
                noRedirect
              >
                <Sidebar.ItemGroup>
                  <SidebarItemWithPermission
                    path="/dashboard/members"
                    icon={HiUsers}
                    label={t('Members')}
                    permission={GroupNames.Members}
                    isActive={() =>
                      pathname.startsWith('/dashboard/members') &&
                      !pathname.startsWith('/dashboard/membership-plans')
                    }
                  />
                  <SidebarItemWithPermission
                    path="/dashboard/membership-plans"
                    icon={HiIdentification}
                    label={t('Membership Plans')}
                    permission={GroupNames.MembershipPlans}
                    isActive={() =>
                      pathname.startsWith('/dashboard/membership-plans')
                    }
                  />
                  <SidebarItemWithPermission
                    path="/dashboard/staff"
                    icon={MdManageAccounts}
                    label={t('Staff')}
                    permission={GroupNames.Staff}
                    isActive={() => pathname.startsWith('/dashboard/staff')}
                  />
                  <SidebarItemWithPermission
                    path="/dashboard/migrations"
                    icon={HiRefresh}
                    label={t('Migrations')}
                    permission={GroupNames.Migrations}
                    isActive={() =>
                      pathname.startsWith('/dashboard/migrations')
                    }
                  />
                </Sidebar.ItemGroup>
              </PermissionGuard>

              <PermissionGuard
                permissions={[
                  GroupNames.Calendar,
                  GroupNames.Appointments,
                  GroupNames.Classes,
                  GroupNames.Events,
                  GroupNames.CheckIns,
                ]}
                noRedirect
              >
                <Sidebar.ItemGroup>
                  <SidebarItemWithPermission
                    path="/dashboard/calendar"
                    icon={HiCalendar}
                    label={t('Calendar')}
                    permission={GroupNames.Calendar}
                    isActive={() => pathname.startsWith('/dashboard/calendar')}
                  />
                  <SidebarItemWithPermission
                    path="/dashboard/appointments"
                    icon={HiClipboard}
                    label={t('Private sessions')}
                    permission={GroupNames.Appointments}
                    isActive={() =>
                      pathname.startsWith('/dashboard/appointments')
                    }
                  />
                  <SidebarItemWithPermission
                    path="/dashboard/classes"
                    icon={HiUserGroup}
                    label={t('Classes')}
                    permission={GroupNames.Classes}
                    isActive={() => pathname.startsWith('/dashboard/classes')}
                  />
                  {import.meta.env.VITE_V2_EVENT_FEATURE_ENABLED === 'true' && (
                    <SidebarItemWithPermission
                      path="/dashboard/events"
                      icon={HiOutlineTicket}
                      label={t('Events')}
                      permission={GroupNames.Events}
                      isActive={() => pathname.startsWith('/dashboard/events')}
                    />
                  )}
                  <SidebarItemWithPermission
                    path="/dashboard/check-ins"
                    icon={HiCheckCircle}
                    label={t('Check-Ins')}
                    permission={GroupNames.CheckIns}
                    isActive={() => pathname.startsWith('/dashboard/check-ins')}
                  />
                </Sidebar.ItemGroup>
              </PermissionGuard>

              <Sidebar.ItemGroup>
                <PermissionGuard permissions={[GroupNames.Products]} noRedirect>
                  <Sidebar.Collapse
                    icon={HiShoppingBag}
                    label={t('Products')}
                    open={expandedItem === 'Products'}
                    onClick={() => expandItem('Products')}
                  >
                    <SidebarItemWithPermission
                      path="/dashboard/products"
                      icon={HiOutlineShoppingBag}
                      label={t('Articles')}
                      permission={GroupNames.Products}
                      isActive={() =>
                        pathname.startsWith('/dashboard/products')
                      }
                    />
                    <SidebarItemWithPermission
                      path="/dashboard/product-categories"
                      icon={HiOutlineDuplicate}
                      label={t('Categories')}
                      permission={GroupNames.Products}
                      isActive={() =>
                        pathname.startsWith('/dashboard/product-categories')
                      }
                    />
                  </Sidebar.Collapse>
                </PermissionGuard>
                <SidebarItemWithPermission
                  path="/dashboard/discounts"
                  icon={HiTag}
                  label={t('Discounts')}
                  permission={GroupNames.Discounts}
                  isActive={() => pathname.startsWith('/dashboard/discounts')}
                />

                <PermissionGuard permissions={[GroupNames.Payments]} noRedirect>
                  <Sidebar.Collapse
                    icon={HiCash}
                    label={t('Payments')}
                    open={expandedItem === 'Payments'}
                    onClick={() => expandItem('Payments')}
                  >
                    <SidebarItemWithPermission
                      path="/dashboard/invoices"
                      icon={HiReceiptTax}
                      label={t('Invoices')}
                      permission={GroupNames.Payments}
                      isActive={() =>
                        pathname.startsWith('/dashboard/invoices')
                      }
                    />
                    <SidebarItemWithPermission
                      path="/dashboard/transactions"
                      icon={HiCurrencyEuro}
                      label={t('Transactions')}
                      permission={GroupNames.Payments}
                      isActive={() =>
                        pathname.startsWith('/dashboard/transactions')
                      }
                    />
                  </Sidebar.Collapse>
                </PermissionGuard>

                <PermissionGuard permissions={[GroupNames.Reports]} noRedirect>
                  <Sidebar.Collapse
                    icon={HiChartPie}
                    label={t('Reports')}
                    open={expandedItem === 'Reports'}
                    onClick={() => expandItem('Reports')}
                  >
                    <SidebarItemWithPermission
                      path="/dashboard/reports/aggregated-revenue"
                      label={t('Revenue')}
                      permission={GroupNames.Reports}
                      isActive={() =>
                        pathname.startsWith(
                          '/dashboard/reports/aggregated-revenue',
                        )
                      }
                    />
                    <SidebarItemWithPermission
                      path="/dashboard/reports/aggregated-members-count"
                      label={t('Member Signups')}
                      permission={GroupNames.Reports}
                      isActive={() =>
                        pathname.startsWith(
                          '/dashboard/reports/aggregated-members-count',
                        )
                      }
                    />
                    <SidebarItemWithPermission
                      path="/dashboard/reports/aggregated-user-visits"
                      label={t('User Visits')}
                      permission={GroupNames.Reports}
                      isActive={() =>
                        pathname.startsWith(
                          '/dashboard/reports/aggregated-user-visits',
                        )
                      }
                    />
                    <SidebarItemWithPermission
                      path="/dashboard/reports/referal-sources"
                      label={t('Referral Sources')}
                      permission={GroupNames.Reports}
                      isActive={() =>
                        pathname.startsWith(
                          '/dashboard/reports/referal-sources',
                        )
                      }
                    />
                  </Sidebar.Collapse>
                </PermissionGuard>

                <Sidebar.Item
                  href="https://finegym-help.freshdesk.com/"
                  icon={HiInformationCircle}
                  target="_blank"
                >
                  {useTranslation().t('Help & Guides')}
                </Sidebar.Item>
              </Sidebar.ItemGroup>
              {/* )} */}

              <PermissionGuard
                permissions={[GroupNames.BusinessSettings]}
                noRedirect
              >
                <Sidebar.ItemGroup>
                  <SidebarBusinessInfoCard
                    show={!(isSidebarOpenOnSmallScreens && !isSmallScreen())}
                  />
                  <Sidebar.Items>
                    <Sidebar.ItemGroup>
                      {isSidebarOpenOnSmallScreens && !isSmallScreen() && (
                        <SidebarItemWithPermission
                          path="/dashboard/business-settings"
                          icon={HiCog}
                          label={t('Business settings')}
                          permission={GroupNames.BusinessSettings}
                          isActive={() =>
                            pathname.startsWith('/dashboard/business-settings')
                          }
                        />
                      )}
                    </Sidebar.ItemGroup>
                  </Sidebar.Items>
                </Sidebar.ItemGroup>
              </PermissionGuard>
            </Sidebar.Items>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default SidebarContainer;
