import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import WithSuspenseLoadingSpinner from '../components/hoc/WithSuspenseLoadingSpinner';
import PermissionGuard from '@/guards/PermissionGuard';
import { GroupNames } from '@/components/hoc/UserGroupContext';

const Calendar = lazy(() => import('../features/dashboard/schedule/calendar'));
const ClassesPage = lazy(
  () => import('../features/dashboard/schedule/classes'),
);
const ClassListPage = lazy(
  () => import('../features/dashboard/schedule/classes/ClassListPage'),
);
const ManageClass = lazy(
  () => import('../features/dashboard/schedule/classes/manage'),
);
const ClassTypesListPage = lazy(
  () => import('../features/dashboard/schedule/classes/class-types'),
);
const EventsPage = lazy(() => import('../features/dashboard/schedule/events'));
export const dashboardScheduleRoutes = (
  <Route>
    <Route element={<PermissionGuard permissions={[GroupNames.Classes]} />}>
      <Route
        path="classes"
        element={
          <WithSuspenseLoadingSpinner>
            <ClassesPage />
          </WithSuspenseLoadingSpinner>
        }
      >
        <Route index element={<Navigate to="list" replace />} />
        <Route
          path="list"
          element={
            <WithSuspenseLoadingSpinner>
              <ClassListPage />
            </WithSuspenseLoadingSpinner>
          }
        />
        <Route
          path="class-types"
          element={
            <WithSuspenseLoadingSpinner>
              <ClassTypesListPage />
            </WithSuspenseLoadingSpinner>
          }
        />
      </Route>
      <Route
        path="classes/:classId"
        element={
          <WithSuspenseLoadingSpinner>
            <ManageClass />
          </WithSuspenseLoadingSpinner>
        }
      />
    </Route>
    {import.meta.env.VITE_V2_EVENT_FEATURE_ENABLED === 'true' && (
      <Route element={<PermissionGuard permissions={[GroupNames.Events]} />}>
        <Route
          path="events"
          element={
            <WithSuspenseLoadingSpinner>
              <EventsPage />
            </WithSuspenseLoadingSpinner>
          }
        />
      </Route>
    )}
    <Route element={<PermissionGuard permissions={[GroupNames.Calendar]} />}>
      <Route
        path="calendar"
        element={
          <WithSuspenseLoadingSpinner>
            <Calendar />
          </WithSuspenseLoadingSpinner>
        }
      />
    </Route>
  </Route>
);
