import { createContext, useContext, useMemo, ReactNode } from 'react';
import {
  useGroupsListQuery,
  useUsersMeRetrieveQuery,
} from '../../store/finegym-rtk-query-api';
import { useAppSelector } from '../../store/hooks';
import { selectAuth } from '../../features/auth/authSlice';

export enum GroupNames {
  Dashboard = 'Dashboard',
  Members = 'Members',
  MembershipPlans = 'Membership Plans',
  Staff = 'Staff',
  Migrations = 'Migrations',
  Calendar = 'Calendar',
  Appointments = 'Appointments',
  Classes = 'Classes',
  // TODO: Note that the Events group is not used in the app, might be missing on BE
  Events = 'Events',
  CheckIns = 'Check-Ins',
  Products = 'Products',
  Discounts = 'Discounts',
  Payments = 'Payments',
  Reports = 'Reports',
  BusinessSettings = 'Business Settings',
  // TODO: These lower case group names sometimes occur, they should be removed from BE if not yet done
  members = 'members',
  staff = 'staff',
}

const UserGroupContext = createContext<string[]>([]);

export function UserGroupProvider({
  children,
}: {
  readonly children: ReactNode;
}) {
  const { isAuthenticated } = useAppSelector(selectAuth);
  const { data: user, isFetching: isFetchingUser } = useUsersMeRetrieveQuery(
    undefined,
    {
      skip: !isAuthenticated,
    },
  );
  const { data: allGroups, isFetching: isFetchingGroups } = useGroupsListQuery(
    {},
    {
      skip: !isAuthenticated,
    },
  );

  const userGroups = useMemo(() => {
    if (isFetchingUser || isFetchingGroups || !user || !allGroups) return [];

    return allGroups.results
      .filter((group) => user.groups!.includes(group.id))
      .map((group) => group.name);
  }, [user, allGroups, isFetchingUser, isFetchingGroups]);

  return (
    <UserGroupContext.Provider value={userGroups}>
      {children}
    </UserGroupContext.Provider>
  );
}

export const useUserGroups = () => {
  return useContext(UserGroupContext);
};

export const useHasPermission = (
  requiredGroups: GroupNames[] | 'ownerRequired',
) => {
  const userGroups = useUserGroups();
  const { data: user } = useUsersMeRetrieveQuery();

  if (requiredGroups === 'ownerRequired') {
    return !!user?.is_owner;
  }

  // If the user is the owner, they have permission
  if (user?.is_owner) {
    return true;
  }
  return requiredGroups.some((group) => userGroups.includes(group));
};
