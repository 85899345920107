import { Button, Label, RangeSlider, ToggleSwitch } from 'flowbite-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  StripePrice as BaseStripePrice,
  MembersCountEnum,
  PaymentIntervalEnum,
} from '@/store/finegym-rtk-query-api';
import { getStripePrices, getStripeProduct } from '@/utils/checkTenant';
import { signupPlanStepSchema } from '@/schemas/auth.schemas';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { selectSignup, setPlan } from './signupSlice';

// Extend StripePrice to include member_limit from product metadata
interface StripePrice extends BaseStripePrice {
  member_limit?: string;
}
type Props = {
  readonly onNextStepClick: () => void;
  readonly onPrevStepClick: () => void;
};

type Inputs = {
  members_count: MembersCountEnum;
  payment_interval: 'month' | 'year';
};

export default function SignupPlanStep({
  onNextStepClick,
  onPrevStepClick,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { registrantData } = useAppSelector(selectSignup);
  const schema = useMemo(() => signupPlanStepSchema(t), [t]);

  const {
    // formState: { errors },
    handleSubmit,
    watch,
    control,
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      members_count: (registrantData.membersCount as MembersCountEnum) || '50',
      payment_interval:
        (registrantData.paymentInterval as PaymentIntervalEnum) || 'month',
    },
  });

  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(
    async (action: string) => {
      if (!executeRecaptcha) {
        return;
      }
      await executeRecaptcha(action);
    },
    [executeRecaptcha],
  );

  const submit = useCallback(
    async (formValues: Inputs) => {
      await handleReCaptchaVerify('signUpPlanStep');
      dispatch(
        setPlan({
          membersCount: formValues.members_count,
          paymentInterval: formValues.payment_interval,
        }),
      );
      onNextStepClick();
    },
    [dispatch, handleReCaptchaVerify, onNextStepClick],
  );

  const pricingPlans = [
    { value: '50', label: `${t('Up to')} 50 ${t('members')}` },
    { value: '100', label: `51 - 100 ${t('members')}` },
    { value: '150', label: `101 - 150 ${t('members')}` },
    { value: '200', label: `151 - 200 ${t('members')}` },
    { value: '300', label: `201 - 300 ${t('members')}` },
    { value: '400', label: `301 - 400 ${t('members')}` },
    { value: '500', label: `401 - 500 ${t('members')}` },
    { value: 'unlimited', label: `501+ ${t('members')}` },
  ];

  const selectedMembersCount = watch('members_count');
  const selectedPaymentInterval = watch('payment_interval');

  const [stripePrices, setStripePrices] = useState<StripePrice[]>([]);
  const [selectedPrice, setSelectedPrice] = useState<number | null>(null);
  const [originalYearlyPrice, setOriginalYearlyPrice] = useState<number>(0);

  useEffect(() => {
    const fetchStripePrices = async () => {
      const pricesResponse = await getStripePrices();
      const pricesWithMetadata: StripePrice[] = await Promise.all(
        pricesResponse.map(async (price) => {
          const product = await getStripeProduct(price.product);
          return { ...price, member_limit: product.metadata.member_limit };
        }),
      );
      setStripePrices(pricesWithMetadata);
    };
    fetchStripePrices();
  }, []);

  useEffect(() => {
    if (selectedMembersCount) {
      const intervalPrice = stripePrices.find(
        (price) =>
          price.recurring.interval === selectedPaymentInterval &&
          price.member_limit === selectedMembersCount,
      );

      if (intervalPrice) {
        setSelectedPrice(intervalPrice.unit_amount / 100);

        if (selectedPaymentInterval === 'year') {
          const monthlyPrice = stripePrices.find(
            (price) =>
              price.recurring.interval === 'month' &&
              price.member_limit === selectedMembersCount,
          );

          if (monthlyPrice) {
            setOriginalYearlyPrice((monthlyPrice.unit_amount * 12) / 100);
          }
        } else {
          setOriginalYearlyPrice(0);
        }
      } else {
        setSelectedPrice(null);
        setOriginalYearlyPrice(0);
      }
    } else {
      setSelectedPrice(null);
      setOriginalYearlyPrice(0);
    }
  }, [selectedMembersCount, selectedPaymentInterval, stripePrices]);

  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="mx-auto max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16">
        <form onSubmit={handleSubmit(submit)}>
          <div className="divide-y divide-gray-200 rounded-lg bg-white shadow dark:divide-gray-700 dark:bg-gray-800 ">
            <div className="col-span-2 p-6 lg:p-8">
              <h3 className="mb-4 text-lg font-medium text-gray-900 dark:text-white">
                {t('Please select how many members you have')}
              </h3>
              <Controller
                name="members_count"
                control={control}
                render={({ field }) => (
                  <RangeSlider
                    id="plan-range-slider"
                    sizing="lg"
                    min={0}
                    max={pricingPlans.length - 1}
                    step={1}
                    value={
                      pricingPlans.findIndex(
                        (plan) => plan.value === field.value,
                      ) || 0
                    }
                    onChange={(event) => {
                      const newValue = Number(event.target.value);
                      field.onChange(
                        pricingPlans[newValue].value as MembersCountEnum,
                      );
                    }}
                  />
                )}
              />
              <Label htmlFor="plan-range-slider">
                {
                  pricingPlans[
                    pricingPlans.findIndex(
                      (plan) => plan.value === selectedMembersCount,
                    )
                  ]?.label
                }
              </Label>

              <div className="mb-2 mt-6 font-medium text-gray-900 dark:text-white">
                {t('Pricing details:')}
              </div>
              <p className="text-lg text-gray-500 dark:text-gray-400">
                {t(
                  'Pricing is based on the number of members you have. The more members you have, the more you pay. You can upgrade or downgrade your plan at any time.',
                )}
              </p>
            </div>
            <div className="flex p-6 lg:p-8">
              <div id="standard-plan" className="w-full self-center">
                <div className="flex items-center justify-center">
                  <span className="text-base font-medium text-gray-900 dark:text-white">
                    {t('Monthly')}
                  </span>
                  <div className="mx-3">
                    <Controller
                      name="payment_interval"
                      control={control}
                      render={({ field }) => (
                        <ToggleSwitch
                          checked={field.value === 'year'}
                          label=""
                          onChange={() => {
                            const newValue =
                              field.value === 'month' ? 'year' : 'month';
                            field.onChange(newValue);
                          }}
                        />
                      )}
                    />
                  </div>
                  <span className="text-base font-medium text-gray-900 dark:text-white">
                    {t('Yearly')}{' '}
                    <span className="text-green-500 dark:text-green-400">
                      {t('(15% off)')}
                    </span>
                  </span>
                </div>
                <div className="text-gray-500 dark:text-gray-400">
                  {t('Priced at')}
                </div>
                {!stripePrices.length ? (
                  <div role="status" className="max-w-sm animate-pulse">
                    <div className="h-12 bg-gray-200 rounded dark:bg-gray-700 w-24" />
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <div className="mb-4 text-5xl font-extrabold text-gray-900 dark:text-white">
                    {`$${selectedPrice}`}
                    &nbsp;
                    <span className="line-through text-gray-500 dark:text-gray-400 text-3xl">
                      {selectedPaymentInterval === 'year'
                        ? `$${originalYearlyPrice}`
                        : ''}
                    </span>
                  </div>
                )}
                <div className="flex space-x-3 justify-end my-4">
                  <Button
                    type="button"
                    className="w-full"
                    size="lg"
                    color="gray"
                    onClick={onPrevStepClick}
                  >
                    {t('Back')}
                  </Button>
                  <Button
                    type="submit"
                    className="w-full"
                    size="lg"
                    color="primary"
                  >
                    {t('Select Plan')}
                  </Button>
                </div>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {t(
                    '*No payment is required at this step. You will be prompted to enter payment details after the trial period if you decide to continue using the application.',
                  )}
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}
